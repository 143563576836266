/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  InputLabel,
  IconButton,
  Typography,
  Link,
  Alert,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginApi } from "../../redux/slices/loginInfoSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RedErrorIcon } from "../../assets/images/RedErrorIcon.svg";
import {
  isValidEmailAddress,
  hasStrongPassword,
  isEmpty,
} from "../../common/utils/CommonUtils";
import { loginEndPoint } from "../../services/apiConfig";
import { NWC_HOME_URL } from "../../common/constants/Constants";
import CustomTextField from "../../common/reusable-comps/text-field/CustomTextField";
import CustomPasswordField from "../../common/reusable-comps/password-field/CustomPasswordField";
import CustomButton from "../../common/buttons/CustomButton";
import "./Login.scss";
function LoginForm({ setShowForgetPassword, setEnteredEmail }) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formTouched, setFormTouched] = useState(false);
  const [isValidEmail, setValidEmail] = useState(false);
  const [data, setData] = useState({
    password: "",
    email: "",
    checkbox: false,
  });
  const {
    login,
    placeholder1,
    placeholder2,
    emailRequiredError,
    passwordRequiredError,
    wrongEmailError,
    wrongPasswordError,
    forgetsPassword,
    alertText,
    signinButton,
    passwordLabel,
    emailLabel,
    smalldesc,
  } = t("mainContainer", {
    userEmail: data.email,
    userPassword: data.password,
  });

  const [formError, setFormError] = useState({});
  const [apiError, setApiError] = useState(false);
  const token = localStorage.getItem("accessToken");
  const enteredEmail = localStorage.getItem("userEnteredEmail") || "";

  useEffect(() => {
    setData({ ...data, email: enteredEmail });
  }, [enteredEmail]);

  const validateEmail = (email) => {
    if (!email) {
      return emailRequiredError;
    }
    if (!isValidEmailAddress(email)) {
      return wrongEmailError;
    }
    return null;
  };

  const validatePassword = (password) => {
    if (!password) {
      return passwordRequiredError;
    }
    if (!hasStrongPassword(password)) {
      return wrongPasswordError;
    }
    return null;
  };

  const checkError = () => {
    const newErrors = {};
    for (const [key, value] of Object.entries(data)) {
      if (key === "email") {
        const emailError = validateEmail(value);
        if (emailError) {
          newErrors.email = emailError;
        }
      }
      if (key === "password") {
        const passwordError = validatePassword(value);
        if (passwordError) {
          newErrors.password = passwordError;
        }
      }
    }

    setFormError(newErrors);
    return newErrors;
  };

  const handleFormSubmit = (e) => {
    setFormTouched(true); // Set formTouched to true when any form field changes
    e.preventDefault();
    const errors = checkError();
    if (Object.keys(errors).length > 0) {
      return; // Prevent form submission if errors exist
    } else {
      let url = loginEndPoint;
      const payload = {
        email: data?.email,
        password: data?.password,
      };
      const apiPayload = { payload, url };
      dispatch(loginApi(apiPayload)).then((res) => {
        if (res?.payload?.status == "200") {
          localStorage.removeItem("userEnteredEmail");
          navigate(NWC_HOME_URL);
        } else {
          setApiError(true);
        }
      });
    }
  };
  
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "checkbox") {
      setData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setFormError((prevError) => ({ ...prevError, [name]: "" }));
  };

  const forgotPasswordCheckError = () => {
    let error = "";
    if (!data?.email) {
      error = emailRequiredError;
    } else if (data?.email && !isValidEmailAddress(data?.email)) {
      error = wrongEmailError;
    }
    setFormError({ ...formError, email: error });
    return error;
  };

  const handleShowForgetPassword = () => {
    const emailError = forgotPasswordCheckError();
    if (!emailError) {
      localStorage.setItem("userEnteredEmail", data?.email);
      setEnteredEmail(data?.email);
      setShowForgetPassword(true);
      setValidEmail(false);
    } else if (data?.email == "") {
      setValidEmail(true);
    }
  };

  const decodeToken = () => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Failed to decode token:", e);
      return null;
    }
  };

  useEffect(() => {
    if (token) {
      const id = decodeToken();
      localStorage.setItem("userId", id?.sub);
    }
  }, [token]);

  const passwordFieldStyle = {
    gap: "0px",
    borderRadius: "4px 0px 0px 0px",
    border: "1px 0px 0px 0px",
    opacity: "0px",
  };

  const getValidation = (fieldName, value, formTouched) => {
    if (fieldName === "email" && formTouched) {
      if (isEmpty(value)) {
        return emailRequiredError;
      } else if (!isValidEmailAddress(data?.email)) {
        return wrongEmailError;
      }
    } else if (fieldName === "password" && formTouched) {
      if (isEmpty(value)) {
        return passwordRequiredError;
      } else if (!hasStrongPassword(value)) {
        return wrongPasswordError;
      }
    }
    return "";
  };

  return (
    <Box
      marginTop={apiError ? "15%" : "22%"}
      marginBottom={apiError ? "1%" : "5%"}
      maxWidth={"330px"}
    >
      <Typography
        variant="h5"
        style={{
          fontSize: "24px",
          fontWeight: " 500",
          lineHeight: "28px",
        }}
      >
        {login}
      </Typography>
      <Typography className="smallDesc">{smalldesc}</Typography>
      <form onSubmit={handleFormSubmit}>
        <Stack className="customStackMargin" marginLeft={0}>
          <>
            <>
              <InputLabel
                htmlFor="standard-adornment-password"
                sx={{ marginBottom: "-10px " }}
                className="globalInputLabelStyle"
              >
                {emailLabel}
              </InputLabel>
              <CustomTextField
                id="email"
                name="email"
                placeholder={placeholder1}
                handleChange={handleChange}
                value={data?.email}
                error={getValidation(
                  "email",
                  data?.email,
                  formTouched || isValidEmail
                )}
                helperText={getValidation(
                  "email",
                  data?.email,
                  formTouched || isValidEmail
                )}
                // helperText={formError?.email && emailRequiredError}
                width="328px"
              />
            </>
            <>
              <InputLabel
                htmlFor="standard-adornment-password"
                sx={{ marginBottom: "-4px " }}
                className="globalInputLabelStyle"
              >
                {passwordLabel}
              </InputLabel>
              <CustomPasswordField
                value={data?.password}
                handleChange={handleChange}
                error={getValidation("password", data?.password, formTouched)}
                helperText={getValidation(
                  "password",
                  data?.password,
                  formTouched
                )}
                // helperText={formError?.password && passwordRequiredError}
                name="password"
                placeholder={placeholder2}
                style={passwordFieldStyle}
              />
            </>
            <Box>
              <Link
                style={{ cursor: "pointer" }}
                onClick={handleShowForgetPassword}
                underline="none"
                sx={{
                  float: "right",
                  marginLeft: "10px",
                  marginTop: 1,
                  marginBottom: 0.5,
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  letterSpacing: " 0.4px",
                }}
              >
                {forgetsPassword}
              </Link>
            </Box>
            {apiError && (
              <Collapse in={open}>
                <Alert
                  severity="error"
                  icon={<RedErrorIcon />}
                  sx={{
                    border: {
                      borderLeft: "3px solid #DA1E28",
                      borderTop: "1px solid #DA1E284D",
                      borderRight: "1px solid #DA1E284D",
                      borderBottom: "1px solid #DA1E284D",
                    },
                    mb: 2,
                    fontSize: "12px",
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {alertText}
                </Alert>
              </Collapse>
            )}
            <CustomButton
              type="submit"
              variant="contained"
              backgroundColor="primary"
              onClick={handleFormSubmit}
              height="48px"
              background="#0F62FE"
              fontSize="16px"
              fontWeight="400"
              width="330px"
              padding="12px 16px"
            >
              {signinButton}
            </CustomButton>
          </>
        </Stack>
      </form>
    </Box>
  );
}

export default LoginForm;
