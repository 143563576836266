import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  stepper: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    lineheight: "16px",
  },

  step: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: " 16px",
  },

  stepNumber: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "#ffffff",
    border: "1px dashed black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2px ",
    marginRight: "3px ",
    zIndex: 2,
    lineHeight: "16px",
  },
  stepName: {
    fontSize: "14px",
    marginLeft: "8px",
  },
  activeStepNumber: {
    background: "linear-gradient(to right, #0F62FE 50%, transparent 50%)",
    backgroundColor: "white",
    color: "#0F62FE",
    border: "1px dashed #0F62FE",
  },
  materialSymbolsOutlined: {
    fontVariationSettings: "FILL 0 wght 400 GRAD 0 opsz 20",
  },
  "& .active .step-number": {
    background: `linear-gradient(to right, #000 50%, transparent 50%)`,
    backgroundColor: "white",
    color: "#0F62FE",
    border: "1px solid #0F62FE",
  },
  complete: {
    backgroundColor: "white",
    color: "#0F62FE",
    border: "1px solid #0F62FE",
  },
  error: {
    height: "16px",
    width: "16px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
  },
  // errorIcon: {
  //   fontSize: "24px",
  //   borderRadius: "50%",
  //   color: "red",
  // },
  errorIcon: {
    // fontSize: "24px",
    borderRadius: "50%",
    color: "red",
  },
}));
