import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../services/axiosInstance";
import { verifyEmailEndPoint } from "../../services/apiConfig";

const initialState = {
  loading: "idle",
  apiError: false,
  formStatus: "",
  isReduxUpdated: false,
  access: {},
  refresh: {},
  edgeDeviceToken: "",
  verifyEmailToken: "",
  changePasswordLoader: "",
  _id: "",
  formInfo: {
    email: "",
    password: "",
    checkbox: false,
    // phone: "",
  },
  user: {},
  defaultLanguage: "en",
};

// Define a function to create async thunk with rejection handling
const createAsyncThunkWithRejection = (name, thunkFn) => {
  return createAsyncThunk(name, async (arg, thunkAPI) => {
    try {
      return await thunkFn(arg);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

// login api//
export const loginApi = createAsyncThunkWithRejection(
  "loginInfo/loginApi",
  async ({ payload, url }) => {
    const response = await axios.post(url, payload);
    return response;
  }
);

// refresh token async thunk//
export const refreshTokenApi = createAsyncThunkWithRejection(
  "loginInfo/refreshTokenApi",
  async ({ url, payload }) => {
    try {
      const response = await axiosInstance.post(url, payload);
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// forgot password async thunk //
export const forgotPasswordApi = createAsyncThunkWithRejection(
  "loginInfo/forgotPasswordApi",
  async ({ url, payload }) => {
    try {
      const response = await axios.post(url, payload);
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// Create the async thunk for updating language
export const updateLanguageApi = createAsyncThunkWithRejection(
  "loginInfo/updateLanguageApi",
  async (payload) => {
    try {
      // // Make the POST request to your API endpoint
      // const response = await axios.post(url, payload);
      // // Return the response data
      // return response.data;

      const response = await axiosInstance.patch(
        payload?.url,
        payload?.payload,
        {
          headers: {
            Authorization: `Bearer ${payload?.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      // Throw the error to be handled by the Redux middleware
      throw error;
    }
  }
);

// reset-password //

export const resetPasswordApi = createAsyncThunkWithRejection(
  "loginInfo/resetPasswordApi",
  async (payload) => {
    try {
      const accessToken = payload?.token;
      const newUrl = `${payload?.url}${accessToken}`;
      const response = await axios.post(newUrl, payload.payload);
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

//change password api
export const changePasswordApi = createAsyncThunkWithRejection(
  "loginInfo/changePasswordApi",
  async ({ url, token, payload }) => {
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.log("change password api error", error);
      // Propagate the error response details
      throw error.response ? error.response : error;
    }
  }
);

// verify-email password api//
export const verifyEmailApi = createAsyncThunkWithRejection(
  "loginInfo/verifyEmailApi",
  async (payload) => {
    try {
      const newUrl = `${verifyEmailEndPoint}${payload}`;
      const response = await axios.post(newUrl);
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// logout api //
export const logoutApi = createAsyncThunkWithRejection(
  "loginInfo/logoutApi",
  async ({ url, payload }) => {
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${payload}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

/* below are the reducer functions which will receive payload from components, 
and builders for reject, pending and fulfilled state of above apis */
const loginInfoSlice = createSlice({
  name: "loginInfo",
  initialState,
  reducers: {
    updateVerifyEmailToken: (state, action) => {
      state.verifyEmailToken = action.payload;
    },

    updateLoginUserInfo: (state, action) => {
      state.formInfo = { ...state.formInfo, ...action.payload };
    },
    updateEdgeDeviceToken: (state, action) => {
      state.edgeDeviceToken = action.payload;
    },
    fetchSelectedLanguage: (state, action) => {},
    updateLanguage: (state, action) => {
      const { i18n, code } = action.payload;
      i18n?.changeLanguage(code);
      state.defaultLanguage = code;
    },
    updateLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginApi.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(loginApi.fulfilled, (state, action) => {
        const { tokens, user, customer } = action?.payload?.data || {};
        state.user = { ...state.user, ...user, customer: customer || [] }; // Add the customer data here
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...user, customer: customer || [] })
        );
        state.access = { ...(tokens?.access || {}) };
        state.refresh = { ...(tokens?.refresh || {}) };
        localStorage.setItem("accessToken", tokens?.access?.token || "");
        localStorage.setItem("accessExpire", tokens?.access?.expires || "");
        localStorage.setItem("refreshToken", tokens?.refresh?.token || "");
        localStorage.setItem("refreshExpire", tokens?.refresh?.expires || "");
        state.loading = "idle";
      })
      .addCase(loginApi.rejected, (state, action) => {
        if (action?.payload) {
          if (
            action?.payload?.status === 401 ||
            action?.payload?.status === 400
          ) {
            state.apiError = true;
          }
        } else {
          state.apiError = true;
        }
      })
      .addCase(refreshTokenApi.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(refreshTokenApi.fulfilled, (state, action) => {
        const { data } = action?.payload;
        state.accessToken = data?.access?.token || "";
        state.refreshToken = data?.refresh?.token || "";
        localStorage.setItem("accessToken", data?.access?.token || "");
        localStorage.setItem("refreshToken", data?.refresh?.token || "");
        localStorage.setItem("accessExpire", data?.access?.expires || "");
        localStorage.setItem("refreshExpire", data?.refresh?.expires || "");
        state.loading = "idle";
      })
      .addCase(refreshTokenApi.rejected, (state, action) => {
        state.apiError = true;
        state.loading = "idle";
      })
      .addCase(verifyEmailApi.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(verifyEmailApi.fulfilled, (state, action) => {
        state.loading = "idle";
      })
      .addCase(verifyEmailApi.rejected, (state, action) => {
        state.apiError = true;
        state.loading = "idle";
      })
      .addCase(resetPasswordApi.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(resetPasswordApi.fulfilled, (state, action) => {
        state.formInfo = action?.payload?.data;
        state.loading = "idle";
      })
      .addCase(resetPasswordApi.rejected, (state, action) => {
        state.apiError = true;
        state.loading = "idle";
      })
      .addCase(changePasswordApi.pending, (state, action) => {
        state.changePasswordLoader = "loading";
      })
      .addCase(changePasswordApi.fulfilled, (state, action) => {
        state.changePasswordLoader = "idle";
      })
      .addCase(changePasswordApi.rejected, (state, action) => {
        state.apiError = true;
        state.changePasswordLoader = "idle";
      })
      .addCase(forgotPasswordApi.fulfilled, (state, action) => {
        state.loading = "idle";
      })
      .addCase(forgotPasswordApi.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(forgotPasswordApi.rejected, (state, action) => {
        state.apiError = true;
        state.loading = "idle";
      })
      .addCase(logoutApi.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(logoutApi.fulfilled, (state, action) => {
        state.loading = "idle";
      })
      .addCase(logoutApi.rejected, (state, action) => {
        state.apiError = true;
        state.loading = "idle";
      });
  },
});
export const {
  updateLoginUserInfo,
  updateLanguage,
  fetchSelectedLanguage,
  updateLoader,
  updateVerifyEmailToken,
  updateEdgeDeviceToken,
} = loginInfoSlice.actions;
export default loginInfoSlice.reducer;
