import React, { createContext, useContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();

  // Initialize user data from localStorage on mount
  useEffect(() => {
    const initializeAuth = () => {
      try {
        const storedUser = localStorage.getItem('userData');
        // const storedToken = localStorage.getItem('token');
        
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        }
      } catch (error) {
        console.error('Error restoring auth state:', error);
        // Clear potentially corrupted data
        localStorage.removeItem('userData');
        // localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const loginHandler = (userData) => {
    try {
      // Store user data and token in localStorage
      // localStorage.setItem('user', JSON.stringify(userData));
      // localStorage.setItem('token', userData.token); // Assuming token is part of userData
      setUser(userData);
    } catch (error) {
      console.error('Error storing auth data:', error);
    }
  };

  const logoutHandler = async () => {
    try {
      // Clear all auth-related data from localStorage
      // localStorage.removeItem('user');
      // localStorage.removeItem('token');
      // Clear any other auth-related items you might have
      setUser(null);
      // navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (loading) {
    // You can replace this with a loading spinner component
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, loginHandler, logoutHandler }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};