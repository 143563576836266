import { PiNumpadFill } from "react-icons/pi";
import { prefectures } from "../../components/setting/CustomerData";
export const isValidEmailAddress = (value) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(value);
};
export const hasStrongPassword = (value) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*[^a-zA-Z\d!@#$%^]).{8,}$/;
  return regex.test(value);
};

export const isAlphaNumeric = (value) => {
  return /^[\p{L}\p{N}\s]*$/u.test(value);
};

export const isNumeric = (value) => {
  return /^[0-9]+$/.test(value);
};
// export const isValidJapanesePostalCode = (value) => {
//   return /^\d{3}-\d{4}$/.test(value);
// };

export const isValidJapanesePostalCode = (value) => {
  // Implement your logic to validate Japanese postal code format
  const regex = /^\d{3}-\d{4}$/;
  return regex.test(value);
};

export const isNumericWithHyphens = (value) => {
  return /^[0-9-]+$/.test(value);
};
// export const isValidJapanesePhoneNumber = (value) => {
//   return /^(\(0\d{1,3}\)|0\d{1,3})-\d{1,4}-\d{4}$/.test(value);
// };
export const isValidJapanesePhoneNumber = (value) => {
  // Remove hyphens to validate only the numbers
  const digits = value?.replace(/-/g, "");
  // Check if the number starts with a valid Japanese prefix and has the correct length
  return /^0\d{9,10}$/.test(digits);
};

export const isEmpty = (value) => {
  return !value?.toString()?.trim();
};

export const isValidUrl = (value) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "(www\\.)?" + // www
      "(" +
      "([a-zA-Z\\d-]+\\.)+" + // domain name prefix (optional)
      "(" +
      "[a-zA-Z\\d-]{2,}" + // domain name
      "|" +
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}" + // IP address
      ")" +
      ")" +
      "(\\:\\d{1,5})?" + // port (optional)
      "(\\/[-a-zA-Z\\d%_.~+]*)*" + // path (optional)
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string (optional)
      "(\\#[-a-zA-Z\\d_]*)?$", // fragment locator (optional)
    "i"
  );
  return pattern.test(value);
};

export const getRequestCustomerApi = (
  objKeys = { name: "", limit: 10, page: 1 }
) => {
  let finalText = "";
  Object.keys(objKeys).forEach((key1) => {
    if (objKeys?.[key1]) {
      finalText =
        finalText + `${finalText ? "&" : ""}${key1}=${objKeys?.[key1]}`;
    }
  });
  return finalText;
};

export const setSiteApiRequest = (siteObj, resData) => {
  let finalObj = {};
  Object.keys(siteObj).forEach((key) => {
    let tmpObj = siteObj[key];
    Object.keys(siteObj?.[key])?.forEach((v1) => {
      tmpObj = finalObj[key];
      finalObj = { ...finalObj, [key]: { ...tmpObj, [v1]: resData?.[v1] } };
    });
  });
  return finalObj;
};
export const formatDate = (dateString) => {
  // Check if dateString is undefined, null, or an empty string
  if (!dateString) {
    return ""; // Return empty string if dateString is invalid
  }
  const date = new Date(dateString);
  // Check if date is invalid
  if (isNaN(date?.getTime())) {
    console.error("Invalid date:", dateString);
    return ""; // Return empty string or handle error case
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const convertDateTimeToTimestamp = (dateTimeString) => {
  const [date, time] = dateTimeString.split(" ");
  const [year, month, day] = date.split("/").map(Number);
  const [hours, minutes, seconds] = time.split(":").map(Number);

  // Convert 2-digit year to 4-digit year
  const adjustedYear = year < 50 ? 2000 + year : 1900 + year;

  // Create and return the timestamp
  return Math.floor(
    Date.UTC(adjustedYear, month - 1, day, hours, minutes, seconds) / 1000
  );
};
export const convertTimestampToDateTimeString = (timestamp) => {
  // Create a Date object from the timestamp (in seconds)
  const date = new Date(timestamp * 1000); // Convert to milliseconds

  // Extract the year, month, day, hours, and minutes
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Format and return the date string
  return `${year}/${month}/${day} ${hours}:${minutes}`;
};
export const formatToCustomDateString = (dateString) => {
  const [date, time] = dateString.split(" ");
  const [year, month, day] = date.split("/").map(Number);
  const [hours, minutes] = time.split(":").map(Number);

  // Convert to 2-digit year
  const twoDigitYear = year % 100; // Get last two digits of the year

  // Format the new date string
  const formattedDate = `${twoDigitYear.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${day.toString().padStart(2, "0")} ${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`; // Append seconds as '00'

  return formattedDate;
};

// function to get stateName by state code

export const getStateLabelForListing = (stateCode) => {
  const prefecture = prefectures?.find((pref) => pref?.value === stateCode);
  return prefecture ? prefecture?.label : stateCode;
};

// function to convert single state code to state name
export const getSingleStateName = (code) => {
  const prefectureObj = prefectures?.find((item) => item?.value === code);
  return prefectureObj ? prefectureObj?.label : "Unknown";
};

// function to convert single state code to state name
export const getSingleStateCode = (name) => {
  const prefectureObj = prefectures?.find((item) => item?.label === name);
  return prefectureObj ? prefectureObj?.value : "Unknown";
};

export const formatUnit = (value) => {
  if (value === 0) {
    return "";
  } else if (value < 1_000_000) {
    return "";
  } else if (value >= 1_000_000 && value < 1_000_000_000) {
    return "M";
  } else if (value >= 1_000_000_000) {
    return "B";
  }
};

export const fullYearAndDateFormat = (dateString) => {
  if (!dateString) {
    return ""; // Handle invalid input gracefully
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return ""; // Handle invalid dates
  }

  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");

  let hours = date?.getHours();
  const minutes = String(date?.getMinutes())?.padStart(2, "0");
  const seconds = String(date?.getSeconds())?.padStart(2, "0");

  // Convert to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM

  return `${year}/${month}/${day} ${String(hours)?.padStart(
    2,
    "0"
  )}:${minutes}:${seconds}`;
};
