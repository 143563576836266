import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from "@mui/icons-material/Close";
import { alpha, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as SearchIcon } from '../../../assets/images/SearchIcon.svg';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'alarmName', numeric: false, disablePadding: true, label: 'Tag Name' },
    { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
];

function EnhancedTableToolbar(props) {
    const { rowCount,numSelected } = props;
  
    return (
      <Box
       sx={{p:0,
                    display: 'flex',
                    flex: '1 1 100%' ,
                    alignItems: 'center', 
                    borderColor: 'divider',
                    borderRadius: 2,   
                    
                }}
      >
        <Typography 
          color="inherit"
          variant="subtitle1"
          component="div"
          sx={{fontSize:"12px",color:"#525252"}}
        >
         Total {rowCount} 
        </Typography>
        {numSelected > 0 && (
         <>
          <Divider      sx={{ 
          ml: { sm: 2 },
          mr: { sm: 2 },
          height:"9px"
        }} orientation="vertical" variant="middle" />
          <Typography 
            color="inherit"
            variant="subtitle1"
            component="div"
            sx={{fontSize:"12px",color:"#525252"}}
          >
            Selected {numSelected} 
          </Typography>
         
          </> 
        )} 
     
      </Box>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, onSelectAllClick, numSelected, rowCount } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select All' }}
                        sx={{
                            color: "#161616",
                            '&.Mui-checked,&.MuiCheckbox-indeterminate': {
                              color: "#161616",
                            },
                          }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled('input')(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    textIndent:'100px',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc( ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    border:"none",
    [theme.breakpoints.up('sm')]: {
        width: '10px',
        '&:focus': {
            textIndent:'1.2rem',
            width: '20ch',
        },
    },
}));

export default function EnhancedTable({ alarmData, selectedRows, onSelect }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [searchQuery, setSearchQuery] = React.useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            onSelect(alarmData);
            return;
        }
        onSelect([]);
    };

    const handleClick = (event, row) => {
        const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.id === row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedRows, row];
        } else if (selectedIndex === 0) {
            newSelected = selectedRows.slice(1);
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = selectedRows.slice(0, -1);
        } else if (selectedIndex > 0) {
            newSelected = [
                ...selectedRows.slice(0, selectedIndex),
                ...selectedRows.slice(selectedIndex + 1),
            ];
        }

        onSelect(newSelected);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const numSelected = selectedRows.length;
    const rowCount = alarmData?.length;

    const filteredAndSortedRows = React.useMemo(() => {
        const filtered = alarmData?.filter((row) =>
            row.alarmName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.description.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return stableSort(filtered, getComparator(order, orderBy));
    }, [alarmData, order, orderBy, searchQuery]);


    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Toolbar className="gridInfoHeader" sx={{diplay:"none"}}> 
            <EnhancedTableToolbar rowCount={rowCount} numSelected={numSelected} />        
            
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    onChange={handleSearchChange}
                    value={searchQuery}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
            {/* <CloseIcon /> */}
        </Toolbar>
        <TableContainer sx={{ maxHeight: 581,marginRight:"14px" }}>
            <Table stickyHeader aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                    numSelected={numSelected}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rowCount}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {filteredAndSortedRows.map((row, index) => (
                        <TableRow
                            hover
                            onClick={(event) => handleClick(event, row)}
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            selected={selectedRows.findIndex(selectedRow => selectedRow.id === row.id) !== -1}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                  sx={{
                                    color: "#161616",
                                    '&.Mui-checked': {
                                      color: "#161616",
                                    },
                                  }}
                                    color="primary"
                                    checked={selectedRows.findIndex(selectedRow => selectedRow.id === row.id) !== -1}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                                {row.alarmName}
                            </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
    );
}

EnhancedTable.propTypes = {
    alarmData: PropTypes.array.isRequired,
    selectedRows: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
};
