import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;
export default function TableActions(props) {
  const { options, handleActionSelection, actionIcon, setSelectedAction = () => {} } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAction, setSelectedActionState] = React.useState(""); // State to track the selected action
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option, index) => {
    setSelectedActionState(option);
    handleActionSelection(option, index);
    setSelectedAction(option);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {actionIcon ? actionIcon : <MoreVertIcon />}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: options?.length === 3 ? "20ch" : "",
            marginLeft: options?.length === 4 ? "-36px" : "-91px",
          },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={option}
            selected={option === selectedAction} // Highlight the selected item
            onClick={() => handleMenuItemClick(option,index)}
            disabled={option === "Disabled"}
            className="paginationSelect"
        
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
