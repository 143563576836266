import React, { useState } from 'react';
import { Grid, Paper, Tabs, Tab, Box } from '@mui/material';
import EnhancedTable from './EnhancedTable';
import FormComponent from './FormComponent'; 
import { createRuleFormModel } from './FormElementsModel';
import EmailPreview from './EmailPreview';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			sx={{ bgcolor: '#000', width: 500 }}
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const CreateRuleInfo = ({
	formValues,
	formErrors,
	setFormValues,
	setFormErrors,
	selectedRows,
	onSelectRows,
	alaramTagsData,
}) => {
	const [value, setValue] = useState(0);

	const alarmData = alaramTagsData?.map((item, index) => ({
		...item,
		// id: index + 1 // Assuming you want to assign a unique id starting from 1
	}));

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleSelect = newSelectedRows => {
		onSelectRows(newSelectedRows);
	};

	return (
		<React.Fragment>
			<Grid
				container
				spacing={2}
				direction="row"
				justifyContent="space-evenly"
				alignItems="stretch"
			>
				{/* Second container (30% width on medium screens and up) */}
				<Grid item xs={12} md={3}>
					<Paper style={{ textAlign: 'center', padding: '20px 15px' }}>
						<FormComponent
							formElements={createRuleFormModel}
							formValues={formValues}
							formErrors={formErrors}
							setFormValues={setFormValues}
							setFormErrors={setFormErrors}
						/>
					</Paper>
				</Grid>

				{/* First container (70% width on medium screens and up) */}
				<Grid item xs={12} md={9}>
					<Box sx={{ paddingLeft: '8px' }}>
						<Box>
							<Tabs
								className="CustomTabs"
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
							>
								<Tab className="tag" label="Tag" {...a11yProps(0)} />
								<Tab
									className="emailpreview"
									label="Email preview"
									{...a11yProps(1)}
								/>
							</Tabs>
						</Box>
						<CustomTabPanel value={value} index={0}>
							<EnhancedTable
								alarmData={alarmData}
								selectedRows={selectedRows}
								onSelect={handleSelect}
							/>
						</CustomTabPanel>
						<CustomTabPanel value={value} index={1}>
							<EmailPreview 	formValues={formValues}  setFormValues={setFormValues} />
						</CustomTabPanel>
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default CreateRuleInfo;
