import React, { useState } from 'react';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Card from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import CustomInputField from '../../../common/formComponents/CustomInputField';
import emailPreviewImage from "../../../assets/images/emailPreview.png" 


const EmailPreview = ({ formValues, setFormValues }) => {
  const element = {
    label: 'Subject',
    placeholder: 'Enter subject',
    value:'alarm notification'
  };
  

  // State to manage whether the subject is editable or not
  const [subjectEditable, setSubjectEditable] = useState(true); 

  // Function to handle toggling of subject edit mode
  const handleEditSubject = () => {
    setSubjectEditable(!subjectEditable);
  };

  // Function to handle subject field change
  const handleSubjectChange = (value) => { 
    setFormValues({ ...formValues, [element.label]: value });
  };

  return (
    <div className="previewTab"> 
        <Card
        onClick={handleEditSubject} 
      variant="outlined" 
      sx={{
        width: "fit-content",
        cursor:"pointer",
        display: 'flex',
        marginLeft: "auto", 
        border:"none", 
        color:"#161616",
        height:"24px",
        position: "relative",
        bottom: "-14px", 
        zIndex: "10",
      }}
    >
     
   {subjectEditable ? <BorderColorOutlinedIcon/> : ''} 
    </Card>
        <CustomInputField
          key={element.label}
          label={element.label}
          placeholder={element.placeholder}
          value={formValues[element.label]}
          onChange={(e) => handleSubjectChange(e.target.value)}
          sx={{ p: 0, m: 0, color: '#161616' }}
          disabled = {subjectEditable}
        />
      
    	<FormLabel align="left" sx={{marginTop:"20px",display:"block",marginBottom:"10px"}}>Body</FormLabel>

      <img src={emailPreviewImage} style={{width:"100%"}} alt="previewe-mail"/>

    </div>
  );
};

export default EmailPreview;
