 export const createRuleFormModel = [
  {
    type: 'textField',
    label: 'Name',
    placeholder:"Enter rule name",
    value: '', // Initial or current value of the field
    onChange: (event) => {}, // Handler function for field value change
    error: false // Optional: Indicates if there's an error with this field
  },
  {
    type: 'radioGroup',
    label: 'Mail format',
    value: '', // Selected value from the radio group
    onChange: (event) => {}, // Handler function for radio group value change
    options: [
      { value: 'html', label: 'HTML' },
      { value: 'plaintext', label: 'Plain text' }
    ],
    error: false // Optional: Indicates if there's an error with this group
  },
  {
    type: 'checkboxGroup',
    label: 'Alarm Priority',
    value: [], // Selected values from the checkbox group
    onChange: (event) => {}, // Handler function for checkbox group value change
    options: [
      { value: 'high', label: 'High' },
      { value: 'medium', label: 'Medium' },
      { value: 'low', label: 'Low' }
    ],
    error: false // Optional: Indicates if there's an error with this group
  },
  {
    type: 'checkboxGroup',
    label: 'Alarm Type', 
    value: [], // Selected values from the checkbox group
    onChange: (event) => {}, // Handler function for checkbox group value change
    options: [
      { value: 'normal', label: 'Normal' },
      { value: 'alarm', label: 'Alaram' }, 
    ],
    error: false // Optional: Indicates if there's an error with this group
  }, 
  {
    type: 'addMultiItems',
    label: 'Recipients',
    placeholder:"Add email address",  
    value:'', // Initial or current value of the field
    recipients:[],
    onChange: (event) => {}, // Handler function for field value change
    onClick: (event) => {},  // Handler function for field value click
    error: false // Optional: Indicates if there's an error with this field
  }, 
  
  // {
  //   type: 'autocomplete',
  //   label: 'Recipients',
  //   placeholder:"Add email address",
  //   value: [], // Selected values from the checkbox group
  //   onChange: (event) => {}, // Handler function for checkbox group value change
  //   options: [     
  //     { title: 'abhinav.jalla@atmecs.com' },
  //     { title: 'venkata.nadella@atmecs.com'},
  //     {title:'anusha.avvaru@atmecs.com'},
  //     {title:'sadaf.mushtaque@atmecs.com'},
  //     { title: 'sanjeev.krishnarajulu@atmecs.com' },
  //   ],
  //   error: false // Optional: Indicates if there's an error with this group
  // }, 
];


export const emailPreviewFormModel =  {
  type: 'textField',
  label: 'Subject',
  placeholder:"Enter subject",
  value: '', // Initial or current value of the field
  onChange: (event) => {}, // Handler function for field value change
  error: false // Optional: Indicates if there's an error with this field
}