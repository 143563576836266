import { getAllSiteApi } from "../../redux/slices/customerSlice";
import { getAllSiteEndpoint } from "../../services/apiConfig";
import { siteOptions } from "../../components/setting/siteDetails/siteDetails.Data";
import { ReactComponent as GreenCircleIcon } from "../../assets/images/green_circle.svg";
import { ReactComponent as RedCircle } from "../../assets/images/redCircle.svg";
import { ReactComponent as GreyCircle } from "../../assets/images/greyCircle.svg";
import { formatDate } from "../../common/utils/CommonUtils";
import { useTranslation } from "react-i18next";

export const ApiPayload = (requestUrl) => {
  const payload = {
    url: requestUrl,
    token: localStorage.getItem("accessToken"),
  };
  return payload;
};

export const requestSiteListApi = (dispatch) => (limit, page, customerId) => {
 const { t } = useTranslation();
const { gridAction } =
t("mainContainer");
  try {
    dispatch(
      getAllSiteApi(ApiPayload(getAllSiteEndpoint(limit, page, customerId)))
    ).then((res) => {
      if (res?.payload?.status == "200") {
        let resullt =
          res.payload?.data?.results?.map((res) => {
            const updatedStatus = res?.deviceStatus;
            const filteredActionMenu=  gridAction?.filter((item,idx)=>{
              return !( idx===2 && updatedStatus!== "online")
            })
            return {
              ...res,
              actionMenu: filteredActionMenu,
              updatedDate: (
                <div>
                  {res?.deviceStatus === "online" ? (
                    <GreenCircleIcon />
                  ) : res?.deviceStatus === "offline" ? (
                    <RedCircle />
                  ) : res?.deviceStatus === "disable" ? (
                    <GreyCircle />
                  ) : null}
    
                  {formatDate(res?.lastOnline)}
                </div>
              ),
            };
          }) ?? [];

        return {
          result: resullt,
          totalResults: res?.payload?.data?.totalResults || 0,
          totalPages: res?.payload?.data?.totalPages || 0,
        };
      } else {
        return {};
      }
    });
  } catch (err) {
    console.log(err);
  }
};
