import React from "react";
import PropTypes from "prop-types";
import { Collapse, Alert, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import RedErrorIcon from "@mui/icons-material/ErrorOutline"; // Assuming you have this icon, replace with your custom icon if needed

const CustomAlert = ({
  open,
  alertText,
  width,
  height,
  severity,
  onClose,
  icon,
  border,
  alertHeader,
  backgroundColor,
}) => {
  return (
    <Collapse
      in={open}
      sx={{
        "& .MuiPaper-root": {
          border: {
            borderLeft: "3px solid #DA1E28",
            borderTop: "1px solid #DA1E284D",
            borderRight: "1px solid #DA1E284D",
            borderBottom: "1px solid #DA1E284D",
          },
        },
      }}
    >
      <Alert
        severity={severity}
        icon={icon}
        style={{
          borderLeft: border?.borderLeft,
          borderTop: border?.borderTop,
          borderRight: border?.borderRight,
          borderBottom: border?.borderBottom,
          fontSize: "12px",
          width: width || "auto",
          height: height || "auto",
          backgroundColor: backgroundColor,
        }}
        sx={{
          "& .MuiAlert-message": { overflow: "hidden" },
          mb: 1,
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <Typography fontWeight={"500"} fontSize={"14px"}>
          {alertHeader}
        </Typography>
        <Typography fontSize={"14px"} marginTop={0.5}>
          {alertText}
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default CustomAlert;
