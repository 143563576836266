// breadcrumbStyles.js

// Define your style objects
export const topNavigationStyles = {
    box : {
        borderTop: "1px solid #E0E0E0", 
        borderLeft: "1px solid #E0E0E0", 
        background: "var(--Default-Neutral-White, #FFF)",
        padding: "8px 16px",
        position: "sticky",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1030
    }
  }; 