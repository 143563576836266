import React, { useState } from 'react';
import UserGrid from './UserGrid';
import { Box, Typography, Divider, IconButton  } from '@mui/material'; 
import { useTranslation } from "react-i18next";
import UserCreation from './UserCreation'; 
import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon.svg";
import "./userManagement.scss";


function UserManagement() {
    const { t } = useTranslation();
    const { userManagementContent } = t("mainContainer").settingsPageContent;
    const [isCreatingUser, setIsCreatingUser] = useState(false);  
    const [headerText, setHeaderText] = useState('Users');
    const [openModal, setOpenModal] = useState(false);

    const handleOpenUserCreation = () => {
        setIsCreatingUser(true);
        setHeaderText('Add User');
    };

    const handleCloseUserCreation = () => {
        setIsCreatingUser(false);
        setHeaderText('Users');
    };

    const handleEditUser = () => {
        setIsCreatingUser(true);
        setHeaderText('Edit User');
    };

    return (
        <Box className="userManagement container">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className="header">{headerText}</Typography>
            {/* Only show close icon when creating or editing a user */}
            {isCreatingUser && (
                <IconButton aria-label="close" className="closeIconBtn" onClick={() => setOpenModal(true)}>
                    <CloseIcon />
                </IconButton>
            )}
        </Box> 
        <Divider className="dividerStyle" />
        {!isCreatingUser ? (
            <>
                <Box className="gridBox">
                    <UserGrid onAddUser={handleOpenUserCreation} onEditUser={handleEditUser} />
                </Box>
            </>
        ) : <UserCreation onClose={handleCloseUserCreation} openModal={openModal} setOpenModal={setOpenModal} /> }
        </Box>
    );
}

export default UserManagement;

