import React from "react";
import { Grid, Typography } from "@mui/material";
import CustomButton from "../../common/buttons/CustomButton";
import { ReactComponent as AddIcon } from "../../assets/images/SmallAddIcon.svg";
import "../langing-page/LandingPages.scss";
import { useNavigate } from "react-router-dom";
import { NWC_SETTING_URL } from "../../common/constants/Constants";
import { useTranslation } from "react-i18next";

function NoSite({ showAddCustomer, setShowAddCustomer }) {
  const navigate = useNavigate();
  const handleClick = () => {
    setShowAddCustomer(true);
    navigate(NWC_SETTING_URL, { state: { showAddCustomer: showAddCustomer } });
  };
  const { t } = useTranslation();
  const { noSites } = t("mainContainer");

  const { title, info } = noSites;
  return (
    <Grid
      item
      xs={10.175}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Typography
        color="#0043CE"
        align="center"
        marginTop={"-1rem"}
        fontSize="42px"
        fontWeight="400"
        lineHeight="50px"
        letterSpacing="0.25px"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography
        align="center"
        // marginBottom={"6px"}
        fontSize="16px"
        fontWeight="400"
        lineHeight="24px"
        letterSpacing="0.15px"
        marginBottom={"8px"}
      >
        {info}
      </Typography>
       
    </Grid>
  );
}

export default NoSite;
