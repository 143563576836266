import React, { useState } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import TableActions from "./TableActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { withStyles } from "@mui/styles";
import { ReactComponent as AscArrow } from "../../assets/images/AscArrow.svg";
import { ReactComponent as DescArrow } from "../../assets/images/DescArrow.svg";
import { getSingleStateCode } from "../utils/CommonUtils";
import { sortingKeys } from "../../components/setting/siteDetails/siteDetails.Data";
import spinner from "../../assets/animation/spinner.json";

const CustomTable = (props) => {
  const { t } = useTranslation();
  const { settingsPageContent } = t("mainContainer");
  const { customerDetails } = settingsPageContent?.siteManagementContent;
  const { siteGridHeaderStructure } =
    settingsPageContent?.siteManagementContent;
  const siteLists = useSelector(
    (state) => state?.customerInfo?.siteList?.results
  );
  const {siteState,siteList} = useSelector( (state) => state?.customerInfo );
  const {
    siteLength,
    headerStructure,
    tableData,
    actionRequest,
    sortOptions,
    tooltipColumns = [0, 1],
    handleTableSorting,
    siteListCount,
  } = props;

  const { addCustomerForm  } =  t('mainContainer').settingsPageContent?.siteManagementContent;
const {  prefecturesField } = addCustomerForm;

  const [order, setOrder] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "1em",
    },
  })(Tooltip);

  const handleActionSelection = (action, rowVal) => {
    actionRequest(action, rowVal);
  };

  const handleRequestSort = (property) => {
    const isAscending = order === 0;
    const newOrder = isAscending ? 1 : 0;
    setOrder(newOrder);
    setOrderBy(property);
    // Trigger sorting API call
    handleTableSorting(newOrder);
  };

  const renderTableCellContent = (content, colIndex, columnLabel, row) => {
    let prefectureObj;
    if (columnLabel === "state") {
      const stateCode = getSingleStateCode(columnLabel === "state" && content);
      prefectureObj = prefecturesField?.options?.find((item) => item?.value === stateCode);
    }

    const isDisabled = row?.deviceStatus === "disable" && 
                       (columnLabel === "siteName" || columnLabel === "areaName" || columnLabel === "lastOnline");

    return tooltipColumns?.includes(colIndex) ? (
      <CustomTooltip title={content} placement="bottom-start">
        <span className={`ellipsis ${isDisabled ? 'disabled' : ''}`}>
          {columnLabel === "state" ? prefectureObj?.label : content}
        </span>
      </CustomTooltip>
    ) : (
      <>
        {columnLabel === "state" ? "Pref" : content}
      </>
    );
  };

  return (
    <div
      className="table-container emailNotificationBox"
      style={{
        marginLeft: "0",
        marginRight: "0",
        width: "100%",
        height: "100%",
      }}
    >
     <TableContainer
        component={Paper}
        sx={{
          marginLeft: "0",
          marginRight: "0",
          "&. MuiTableContainer-root": { height: "100%", },
          "&.MuiPaper-root": { height: "100%",
            boxShadow:"none" ,
            paddingRight: "18px",
          
          },
        
        }}
      >
        <Table
          aria-label="simple table"
          className="customTableWidth"
          sx={{ tableLayout: "fixed" }}
        >
          {
            <TableHead
              sx={{
                backgroundColor: "#f5f5f5",
                boxShadow: "0px 1px 0px 0px #C6C6C6 inset",
                top: 0,
                position: "sticky",
                zIndex: 1,
              }}
            >
              <TableRow sx={{ height: "40px" }}>
                {headerStructure?.map((header, colIndex) => {
                  const isActiveColumn = orderBy === header.accessor;
                  const headerBackgroundColor = isActiveColumn
                    ? "#E0E0E0"
                    : "#f5f5f5"; // Adjust the color as needed

                  return (
                    <TableCell
                      key={`table_header_action_${header.label}`}
                      sortDirection={
                        orderBy === header.accessor ? sortingKeys[order] : false
                      }
                      sx={{
                        padding: "6px 6px 6px 16px",
                        height: "40px",
                        lineHeight: "40px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width:
                          colIndex === 0 || colIndex === 1
                            ? "30%"
                            : colIndex === headerStructure.length - 1
                            ? "8%"
                            : "auto",
                        backgroundColor: headerBackgroundColor, // Apply background color
                        cursor: header?.label ? "pointer" : "default", // Change cursor to pointers
                      }}
                      onClick={() =>
                        header?.label && handleRequestSort(header.accessor)
                      } // Trigger sorting when header is clicked
                    >
                      {header?.label && (
                        <TableSortLabel
                          sx={{"& .MuiTableSortLabel-icon": {
                          marginRight: "16px"
                        },}}
                          active={orderBy === header.accessor}
                          direction={orderBy === header.accessor ? sortingKeys[order]  :sortingKeys[2] }
                          style={{display: "flex",justifyContent: "space-between"}}
                          IconComponent={
                            orderBy === header.accessor
                              ? order === 1
                                ? AscArrow
                                : DescArrow
                              : AscArrow
                          }
                        >
                          {siteGridHeaderStructure[colIndex]}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          }
          {/* {siteListCount!='blank' && <TableBody> */}
         {  siteList?.length > 0 && siteState!=="loading" && siteState ==="idle"&&
            (<TableBody>
              {tableData?.map((row, index) => (
                <TableRow key={`table_row_${row?.id}`}>
                  {headerStructure?.map((headerVal, headerIndx) =>
                    headerVal?.accessor === "actionMenu" ? (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        sx={{
                          padding: "0px",
                          height: "20px",
                          lineHeight: "20px",
                          textAlign: "end",
                          width: "100px",
                        }}
                        dataCustomTableCellId={headerVal?.accessor || ""}
                      >
                        <TableActions
                          options={row?.[headerVal?.accessor]}
                          handleActionSelection={(selectionAction) =>
                            handleActionSelection(selectionAction, row)
                          }
                          actionIcon={<MoreVertIcon />}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        key={`column_${row?.id}_${headerIndx}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          height: "20px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          ...(row?.deviceStatus === "disable" && {
                            color: "gray", // Apply gray color for disabled state
                            pointerEvents: "none", // Disable pointer events
                          }),
                        }}
                        dataCustomTableCellId={headerVal?.accessor || ""}
                      >
                        {renderTableCellContent(
                          row?.[headerVal?.accessor],
                          headerIndx,
                          headerVal?.accessor,
                          row // Pass the entire row to check the status
                        )}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>)
          }
        </Table>
        { siteState==="loading" && siteState!=="idle" &&  <div className="loading siteGrid spinner-wrapper blue-spinner">
            <Lottie className="spinner" animationData={spinner} autoplay loop />
          </div> }
        {/* {(siteLength === 0 && siteListCount != 'blank') && ( */}
        {siteList?.length === 0 && siteState!=="loading" && siteState==="idle"&&(
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 20%)",
            }}
          >
            <Typography
              fontSize={"32px"}
              color="#0043CE"
              fontWeight="400"
              lineHeight="40px"
              letterSpacing="0.25px"
            >
              {customerDetails.noResultMessage} 
            </Typography>
          </Stack>
        )}
      </TableContainer>
    </div>
  );
};

export default CustomTable;
