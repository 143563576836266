export const Languages = [
  {
    code: "jp",
    language: "日本語",
  },
  {
    code: "en",
    language: "English",
  },
];
