import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomerLeftNavigation from './leftNavigations/Index';
import DateRangeCalendar from '../../common/DateRangePicker/DateRangeCalendar';
import { topNavigationStyles } from './TopNavigationStyles'; 
import usePost from '../../common/hook/usePost';
import { setDateRangeFilter } from '../../redux/slices/customerFilterSlice';
import { electricityConsumptionURL } from '../../services/apiConfig';
import { updateAccordionList } from '../../redux/slices/customerSlice';
import { formatToCustomDateString } from "../../common/utils/CommonUtils";
import { convertDateTimeToTimestamp } from "../../common/utils/CommonUtils";
import { formatDate } from "../../common/utils/CommonUtils";


/**
 * TopNavigation Component
 *
 * This component serves as the top navigation for the customer view.
 * It includes a left navigation menu and a date range picker for filtering data.
 */
const TopNavigation = () => {
  const dispatch = useDispatch(); // Hook to dispatch Redux actions
  const { recentSearchId } = useSelector((state) => state?.customer); // Accessing recentSearchId from the Redux store

  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost(); // Custom hook for handling API requests

  /**
   * Handle changes in the date range picker.
   * 
   * @param {Array} dateRange - The selected date range from the DateRangeCalendar component.
   */
  const handleDateRangeChange = (dateRange) => {
    // Get the start and end dates
    const startDate = dateRange.value[0];
    const endDate = dateRange.value[1];
 
    const [dateStartString, dateEndString] = dateRange.value; 

      // To convert a date string from the format YYYY/MM/DD HH:mm 
      const customDateStartString = formatToCustomDateString(formatDate(dateStartString)); 
      const customDateEndString = formatToCustomDateString(formatDate(dateEndString));  
 
    // Update Redux state with the selected date range
    dispatch(setDateRangeFilter({ label: dateRange.label, value: [startDate, endDate] }));

    const requestPayload = {
        searchId: recentSearchId, 
        startDate: convertDateTimeToTimestamp(customDateStartString),
        endDate:convertDateTimeToTimestamp(customDateEndString) 
    };

    doPostPrefectureApi(electricityConsumptionURL, requestPayload); // Send the date range to the API
};


  useEffect(() => {
    // Effect to update the accordion list when API data changes
    if (prefectureApiData && !prefectureApiError && !prefectureApiLoading) {
      if (prefectureApiData?.result.length > 0) {
        dispatch(updateAccordionList(prefectureApiData?.result)); // Update the Redux store with new data
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefectureApiData, prefectureApiError, prefectureApiLoading]);

  return (
    <Box
      sx={topNavigationStyles.box} // Styles for the Box component
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <CustomerLeftNavigation />  {/* Left navigation component */}
      <DateRangeCalendar 
        setInitialLoad={true}
        onDateRangeChange={handleDateRangeChange}  // Callback for date range changes
        id="topNavDatePicker"
      />
      
    </Box>
  );
};

export default TopNavigation;
