import React, { useEffect, useState } from "react";
import { Box, Grid, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploadComponent from "../../common/reusable-comps/file-upload/FileUploadComponent";
import CustomTextField from "../../common/reusable-comps/text-field/CustomTextField";
import CustomTextArea from "../../common/reusable-comps/text-area/CommonTextArea";
import { isEmpty } from "../../common/utils/CommonUtils";
import { siteImageUploadURL } from "../../services/apiConfig";
import usePost from "../../common/hook/usePost";
import { ReactComponent as SuccessIcon } from "../../assets/images/bluecheckMark.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/smallCloseIcon.svg";
import spinner from "../../assets/animation/spinner.json";
import { ReactComponent as RedErrorIcon } from "../../assets/images/redExclamation.svg";
import Lottie from "lottie-react";
import { siteImageDeleteURL } from "../../services/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { deleteImageApi, } from "../../redux/slices/customerSlice";
const SiteDetails = (props) => {
  const {
    siteDetail,
    setFormData,
    formError,
    setFormError,
    formTouched,
    siteIsDuplicate,
    nameApiLoading,
    nameApiError,
    nameApiData,
    setSiteIsDuplicate,
    editSiteId,
    handleNameMockApi,
    siteScreenType,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { settingsPageContent } = t("mainContainer");
  const [updatedField, setUpdatedField] = useState(null);
  const [deletable, setDeletable] = useState(false);
  const [fileName, setFileName] = useState("");
  const { siteName, description, fileUpload, uploadErrors } =
    settingsPageContent?.siteManagementContent?.customerDetails?.siteDetails;
  const [displayCrossIcon, setDisplayCrossIcon] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const { siteFormData, selectedCustomer, siteList } = useSelector((state) => state?.customerInfo);
  const {
    loading: siteFileLoading,
    error: siteFileError,
    data: siteFileData,
    postData: doPostSiteFile,
  } = usePost();
  useEffect(() => {
    let successTimeout;
    if (siteFileData) {
      setFormData((prevData) => ({
        ...prevData,
        siteDetail: {
          ...prevData?.siteDetail,
          imageName: siteFileData?.imageName,
          imageUrl: siteFileData?.imageUrl,
          thumbnailUrl: siteFileData?.thumbnailUrl
        },
      }));
      setDisplayCrossIcon(true);
      successTimeout = setTimeout(() => {
        setDisplayCrossIcon(false);
        setDeletable(true);
      }, 4000); // Change the icon after 4 seconds
    }
    return () => clearTimeout(successTimeout);
  }, [siteFileData, setFormData]);
  useEffect(() => {
    if (editSiteId && siteFormData?.imageName) {
      setFileName(siteFormData?.imageName);
      setFormData((prevData) => ({
        ...prevData,
        siteDetail: {
          ...prevData?.siteDetail,
          imageUrl: siteFormData?.imageUrl,
          imageName: siteFormData?.imageName,
          thumbnailUrl: siteFileData?.thumbnailUrl
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSiteId, siteFormData, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      siteDetail: {
        ...prevState.siteDetail,
        [name]: value,
        ...(name === "name" && { displayName: value }), // Conditionally add displayName
      },
    }));
    // Remove specific field error when typing starts
    setFormError((prevErrors) => ({
      ...prevErrors,
      siteDetail: {
        ...prevErrors?.siteDetail,
        [name]: "",
      },
    }));
    if (name === "name" && siteScreenType === "addSite") {
      setUpdatedField(value);
      handleNameMockApi(value?.toLowerCase());
    }
  };

  useEffect(() => {
    if (siteScreenType === "editSite" && siteList?.length) {
      const customerSiteList = siteList?.filter(
        (site) => site.selectedCustomer === selectedCustomer
      );
      // Exclude the site being edited
      const filteredSites = customerSiteList?.filter(
        (site) => site.id !== siteDetail?.id
      );
      const duplicateSite = filteredSites?.find(
        (site) => site?.name?.toLowerCase() === siteDetail?.name?.toLowerCase()
      );
      setSiteIsDuplicate(!!duplicateSite);
    }
  }, [
    siteScreenType,
    siteDetail?.name,
    siteList,
    selectedCustomer,
    setSiteIsDuplicate,
    siteDetail?.id,
    siteIsDuplicate,
  ]);

  useEffect(() => {
    setUpdatedField(siteDetail?.name);
  }, [editSiteId, siteScreenType, siteDetail?.name]);

  useEffect(() => {
    if (editSiteId && siteDetail?.imageName) setDeletable(true);
  }, [editSiteId, siteDetail]);

  useEffect(() => {
    if (updatedField !== null && !nameApiLoading) {
      if (nameApiError) {
        setSiteIsDuplicate(siteName?.error2);
      } else if (nameApiData) {
        setSiteIsDuplicate(null);
      }
    }
  }, [
    updatedField,
    nameApiError,
    nameApiLoading,
    nameApiData,
    setSiteIsDuplicate,
    formTouched,
    siteName?.error2,
  ]);
  const supportedTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
  const renderIcon = (isLoading, isError, isSuccess, uploadError) => {
    if (isLoading) {
      return (
        <Lottie
          className="fileUploadSpinner"
          animationData={spinner}
          autoplay
          loop
        />
      );
    }

    if (isError || uploadError) {
      return <RedErrorIcon />;
    }

    if (editSiteId && siteDetail?.imageName) {
      return <CrossIcon />;
    }

    if (isSuccess) {
      return displayCrossIcon ? <SuccessIcon /> : <CrossIcon />;
    }

    return null;
  };

  const getValidation = (fieldName, value, formTouched, errorMessage = "") => {
    if (fieldName === "name" && formTouched) {
      if (isEmpty(value)) {
        return siteName?.error;
      } else if (errorMessage) {
        return siteName?.error2;
      }
    }
    if (fieldName === "imageFile") {
      if (value && value.size > 500 * 1024) {
        return uploadErrors[0]; // Error for file size exceeding 500KB
      } else if (value && !supportedTypes.includes(value?.type)) {
        return uploadErrors[1]; // Error for unsupported file type
      }
    }


    return "";
  };

  const handleFileChange = async (e) => {
    setDeletable(false);
    const file = e?.target?.files[0];
    const error = getValidation("imageFile", file);
    setUploadError(error);
    setFormData((prevData) => ({
      ...prevData,
      siteDetail: {
        ...prevData?.siteDetail,
        imageUrl: file,
        imageName: file?.name,
        imageFile: file
      },
    }));
    if (file && file.size <= 500 * 1024 && supportedTypes.includes(file?.type)) {
      const formData = new FormData();
      formData.append("fileName", file);
      doPostSiteFile(siteImageUploadURL, formData);
    }
  };
  const handleDeleteImage = () => {
    const apiPayload = {
      url: siteImageDeleteURL,
      payload: siteDetail?.imageUrl,
      token: localStorage?.getItem("accessToken"),
    };
    dispatch(deleteImageApi(apiPayload)).then((res) => {
      if (res?.payload?.status == 200) {
        setFormData((prevData) => ({
          ...prevData,
          siteDetail: {
            ...prevData?.siteDetail,
            imageUrl: null,
            imageName: "",
            imageFile: null,
            thumbnailUrl: null,
          },
        }));
      }
    });
  };

  return (
    <Box spacing={2}>
      <CustomTextField
        id="name"
        name="name"
        label={siteName.label}
        placeholder={siteName.placeholder}
        value={siteDetail?.displayName}
        handleChange={handleChange}
        width="100%"
        error={getValidation(
          "name",
          siteDetail?.name,
          formTouched,
          siteIsDuplicate
        )}
        helperText={getValidation(
          "name",
          siteDetail?.name,
          formTouched,
          siteIsDuplicate
        )}
      />
      <CustomTextArea
        id="description test"
        name="description"
        label={description.label}
        placeholder={description.placeholder}
        handleChange={handleChange}
        value={siteDetail?.description}
        width="100%"
        alignText="left"
        minRows={2}
        maxRows={5}
        height="64px"
        error={!!formError?.description?.length}
        helperText={formError?.description && description.error}
      />
      <Grid container justifyContent="flex-end">
        <span display="flex">{siteDetail?.description?.length}/300</span>
      </Grid>
      <Box>
        <InputLabel
          className="globalInputLabelStyle"
          sx={{
            textAlign: "left",
            marginBottom: "8px",
            mt: 2.5,
            whiteSpace: "wrap",
          }}
        >
          {fileUpload.lable}
        </InputLabel>
        <FileUploadComponent
          onFileChange={handleFileChange}
          initialValue={siteDetail?.imageName ?? null}
          value={siteDetail?.imageFile}
          text={
            siteScreenType === "addSite" ? fileUpload.text : fileUpload?.textEdit
          }
          fileName={fileName}
          setFileName={setFileName}
          fieldName="imageUrl"
          statusIcon={renderIcon(
            siteFileLoading,
            siteFileError,
            siteFileData,
            uploadError
          )}
          error={getValidation(
            "imageFile",
            siteDetail?.imageFile,
            formTouched,
            siteIsDuplicate,
          )}
          helperText={getValidation(
            "imageFile",
            siteDetail?.imageFile,
            formTouched,
            siteIsDuplicate
          )}
          handleIconClick={deletable ? handleDeleteImage : null}
          siteScreenType={siteScreenType}
          editSiteId={editSiteId}
        />
      </Box>
    </Box>
  );
};

export default SiteDetails;
