import React from 'react';
import { usePermissions } from './usePermissions';

export const PermissionGuard = ({ 
  userRole, 
  resource, 
  action, 
  children, 
  fallback = null 
}) => {
  const { hasPermission } = usePermissions(userRole);

  if (!hasPermission(resource, action)) {
    return fallback;
  }

  return children;
};