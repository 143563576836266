import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Link } from "@mui/material";
import { ReactComponent as BellIcon } from "../../assets/images/BellIcon.svg";
import { ReactComponent as BlueArrow } from "../../assets/images/BlueRightArrow.svg";
function AlarmCard({ totalAlarms, cards, noDataText }) {
  const navigate = useNavigate();
  const handleViewAll = () => { 
    navigate('/alarm'); // Update with your actual alarm path 
  };
  return (
    <Card className="electricityAlarmBox">
      <div className="cardBox">
        <Typography variant="span"> {cards.alarmTitle} </Typography>
      </div>
      <div className="innerContent">
        {totalAlarms !== 0 ? <>
          <span className="electricitySpaned">{<BellIcon />}</span>
          <Typography className="electricityText"> {totalAlarms} </Typography>
          <span className="alarmSpan">
            <Link underline="none" href="#" onClick={handleViewAll}>
              {cards.viewAllButton}
            </Link>
            <BlueArrow />
          </span>
        </> :
          <span className="noSearchContent">
            <Typography className="commonNoSearchFoundText">
              {noDataText}
            </Typography>
          </span>

        }
      </div>
    </Card>
  );
}

export default AlarmCard;
