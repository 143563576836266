import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from '@mui/material';

const NoCustomerPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { noCustomer } = t("mainContainer");

  return (
    <Box
      className="noCustomerPage"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh" 
    >
      <Typography variant="h4">{noCustomer.title}</Typography>
      <Typography  variant="body">
      {noCustomer.message}
      </Typography> 
    </Box>
  );
};

export default NoCustomerPage;