/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import "./Login.scss";
import { forgotPasswordApi } from "../../redux/slices/loginInfoSlice";
import { useDispatch } from "react-redux";
import { forgotPasswordEndPoint } from "../../services/apiConfig";
import CustomButton from "../../common/buttons/CustomButton";
function ForgotPassword({
  forgotPassword,
  theme,
  // enteredEmail,
  // setForgotPasswordClicked,
  setShowResetPassword,
  defaultLanguage,
}) {
  const {
    sendLinkButton,
    headerOne,
    headerThree,
    headerFour,
    forgotPasswordNewLabel,
  } = forgotPassword;
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();
  const enteredEmail = localStorage.getItem("userEnteredEmail");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const apiPayload = {
      url: forgotPasswordEndPoint,
      payload: { email: enteredEmail, language: defaultLanguage },
    };
    dispatch(forgotPasswordApi(apiPayload))
      .then((res) => {
        if (res?.payload?.status == 204) {
          setEmailSent(true);
        } else {
          console.error("Forgot Password API call failed", res);
        }
      })
      .catch((error) => {
        console.error("Forgot Password API call failed with error", error);
      });
  };
  // const handleShowResetPasswordScreen = () => {
  //   setShowResetPassword(true);
  //   navigate(NWC_RESET_PASSWORD);
  // };
  useEffect(() => {
    if (enteredEmail) {
      setEmail(enteredEmail);
    }
  }, [enteredEmail]);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      marginTop={emailSent ? "42%" : "35%"}
      marginBottom={"8%"}
      width={"330px"}
      className={defaultLanguage === "ja" ? "boxChildFont" : ""}
      // fontSize={defaultLanguage === "ja" ? "12px" : ""}
    >
      <>
        <Typography variant="h5" fontWeight={"500"}>
          {emailSent ? headerThree : headerOne}
        </Typography>
        <Typography theme={theme} variant="pFontSize">
          {emailSent ? headerFour : forgotPasswordNewLabel}
        </Typography>
        {!emailSent ? (
          <>
            {" "}
            <Typography
              theme={theme}
              color="primary"
              style={{
                marginTop: "-6px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.25px",
              }}
            >
              {enteredEmail}
            </Typography>
            <CustomButton
              type="submit"
              variant="contained"
              backgroundColor="primary"
              onClick={handleFormSubmit}
              background="#0F62FE"
              fontSize="16px"
              fontWeight="400"
              height="48px"
              padding="12px 16px"
            >
              {sendLinkButton}
            </CustomButton>{" "}
          </>
        ) : (
          <Typography color="primary" fontWeight="500">
            {enteredEmail}
          </Typography>
        )}
      </>
    </Box>
  );
}

export default ForgotPassword;
