/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Stack, Typography, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import LargeReusableModal from "../common/reusable-comps/modals/LargeReusableModal";
import CustomPasswordField from "../common/reusable-comps/password-field/CustomPasswordField";
import { hasStrongPassword } from "../common/utils/CommonUtils";
import SmallReusableModal from "../common/reusable-comps/modals/SmallReusableModal";
import { resetPasswordApi } from "../redux/slices/loginInfoSlice";
import { useDispatch } from "react-redux";
import { resetPasswordEndPoint } from "../services/apiConfig";
import CustomButton from "../common/buttons/CustomButton";
const ChangePasswordModal = ({ open, handleClose, user }) => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formError, setFormError] = useState({});
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const { oldPassword, newPassword, confirmNewPassword } = formData;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { forgotPasswordScreen, changePassword } = t("mainContainer");
  const {
    oldPasswordField,
    newPasswordFiled,
    confirmNewPasswordField,
    cancelBtn,
    submitBtn,
  } = changePassword;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleCloseSmallModal = () => {
    setOpenSmallModal(false);
  };
  const smallModalChildren = () => {
    return (
      <h5 style={{ fontSize: "14px" }}>
        Your password has been changed successfully.
      </h5>
    );
  };
  const handleCheckError = () => {
    let newErrors = {};
    let oldPassword = "password1";
    for (const [key, value] of Object.entries(formData)) {
      if (key === "oldPassword") {
        if (!value) {
          newErrors.oldPassword =
            forgotPasswordScreen?.oldPasswordRequiredError;
        }
        if (value && value !== oldPassword) {
          newErrors.oldPassword = forgotPasswordScreen?.oldPasswordMatchError;
        }
      }
      if (key === "newPassword") {
        if (!value) {
          newErrors.newPassword = forgotPasswordScreen?.newPasswordError;
        }
        if (value && !hasStrongPassword(value)) {
          newErrors.newPassword = forgotPasswordScreen?.invalidNewPasswordError;
        }
      } else if (key === "confirmNewPassword") {
        if (!value) {
          newErrors.confirmNewPassword =
            forgotPasswordScreen?.confirmPasswordRequiredError;
        } else if (value && value !== formData.newPassword) {
          newErrors.confirmNewPassword =
            forgotPasswordScreen?.confirmPasswordMatchError;
        }
      }
    }
    setFormError(newErrors);
    return newErrors;
  };
  const style = {
    width: "340px",
    height: "40px",
    gap: "0px",
    borderRadius: "4px 0px 0px 0px",
    border: "1px 0px 0px 0px",
    opacity: "0px",
  };
  const childrenRender = () => {
    return (
      <>
        <Stack className="stackMarginTop">
          <form id="changePasswordForm">
            <>
              <Typography>{changePassword?.userId}</Typography>
              <InputLabel
                htmlFor="standard-adornment-password"
                // style={{ marginLeft: "7px" }}
                style={{ fontWeight: "600" }}
              >
                {user?.email}
              </InputLabel>
            </>
            <Stack gap={2} className="labelMargin">
              <>
                <InputLabel htmlFor="standard-adornment-password">
                  {oldPasswordField?.label}
                </InputLabel>
                <CustomPasswordField
                  // label="Old Password"
                  value={oldPassword}
                  handleChange={handleChange}
                  error={!!formError?.oldPassword?.length}
                  helperText={formError?.oldPassword && oldPassword?.error}
                  name="oldPassword"
                  placeholder={oldPasswordField?.placeholder}
                  style={style}
                />
              </>
              <>
                <InputLabel htmlFor="standard-adornment-password">
                  {newPasswordFiled?.label}
                </InputLabel>
                <CustomPasswordField
                  // label="New Password"
                  value={newPassword}
                  handleChange={handleChange}
                  error={!!formError?.newPassword?.length}
                  helperText={formError?.newPassword && newPasswordFiled.error}
                  name="newPassword"
                  placeholder={newPasswordFiled?.placeholder}
                  style={style}
                />
              </>
              <>
                <InputLabel htmlFor="standard-adornment-password">
                  {confirmNewPasswordField?.label}
                </InputLabel>
                <CustomPasswordField
                  value={confirmNewPassword}
                  handleChange={handleChange}
                  error={!!formError?.confirmNewPassword?.length}
                  helperText={
                    formError?.confirmNewPassword &&
                    confirmNewPasswordField?.error
                  }
                  name="confirmNewPassword"
                  placeholder={confirmNewPasswordField?.placeholder}
                />
              </>
            </Stack>
          </form>
        </Stack>
      </>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Implement logic to handle password change based on event.target.name and event.target.value
    handleCheckError();
    const errors = handleCheckError();
    if (Object.keys(errors).length > 0) {
      setPasswordChanged(false);
      return; // Prevent form submission if errors exist
    } else {
      const apiPayload = {
        url: resetPasswordEndPoint,
        payload: { password: newPassword },
      };
      dispatch(resetPasswordApi(apiPayload));
    }
    setPasswordChanged(true);
    handleClose();
    setOpenSmallModal(true);
  };

  const footerVal = [
    {
      label: cancelBtn,
      onClick: handleClose,
      variant: "outlined",
      color: "primary",
      style: {
        width: "50%",
      },
    },
    {
      label: submitBtn,
      onClick: handleSubmit, // Call handleSubmit when the "Submit" button is clicked
      type: "submit", // Set type to "submit"
      variant: "contained",
      color: "primary",
      style: {
        width: "50%",
      },
    },
  ];
  const smallModalFooter = () => {
    return (
      <div>
        <CustomButton
          variant="outlined"
          onClick={handleCloseSmallModal}
          marginRight=".5rem"
          width="108px"
          padding="11px 16px"
        >
          Close
        </CustomButton>
      </div>
    );
  };
  return (
    <>
      <LargeReusableModal
        isOpen={open}
        onClose={handleClose}
        header={changePassword.title}
        footer={footerVal}
        buttonPosition="center"
        dialogWidth="100%" // Default width for the dialog
        dialogHeight="468px"
        maxWidth={"md"}
        // dialogActionStyle={footerStyle}
        footerMarginRight="15px"
        footerMarginLeft="15px"
      >
        {childrenRender()}
      </LargeReusableModal>
      <SmallReusableModal
        open={openSmallModal}
        footer={smallModalFooter()}
        dialogWidth="442px"
        dialogHeight="220px"
        handleClose={handleCloseSmallModal}
        title="Password changed !"
        divider={true}
      >
        {smallModalChildren()}
      </SmallReusableModal>
    </>
  );
};

export default ChangePasswordModal;
