import React from "react";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  NWC_DEFAULT_URL,
  NWC_HOME_URL,
  NWC_RESET_PASSWORD,
  NWC_LOGIN_URL,
  NWC_SETTING_URL,
  NWC_ALARM_URL,
  NWC_AUDIT_URL,
} from "./common/constants/Constants";
import LoginPage from "./components/login-components/LoginPage";
import Main from "./components/langing-page/Main";
import PrivateRoute from "./routes/PrivateRoute";
import { RESOURCES, ACTIONS } from "./common/utils/Permissions";
import UnauthorizedPage from "./components/login-components/UnauthorizedPage";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
// import ErrorTest from "./components/error-boundary/ErrorTest";

function App() {
  return (
    <ErrorBoundary>
      {/* <ErrorTest /> */}
      <Routes>
        <Route
          path={NWC_DEFAULT_URL}
          element={<Navigate to={NWC_HOME_URL} replace />}
        ></Route>
        {/* Home route with permission check */}
        <Route
        path={NWC_HOME_URL}
        element={
          <PrivateRoute 
            resource={RESOURCES.HOME} 
            action={ACTIONS.VIEW}
          >
            <Main type="home" />
          </PrivateRoute>
        }
      />

      {/* Settings route with permission check */}
      <Route
        path={NWC_SETTING_URL}
        element={
          <PrivateRoute 
            resource={RESOURCES.SETTINGS} 
            action={ACTIONS.VIEW}
          >
            <Main type="setting" />
          </PrivateRoute>
        }
      />

      {/* Audit route with permission check */}
      <Route
        path={NWC_AUDIT_URL}
        element={
          <PrivateRoute 
            resource={RESOURCES.AUDIT_LOG} 
            action={ACTIONS.VIEW}
          >
            <Main type="audit" />
          </PrivateRoute>
        }
      />

    {/* Alarms route with permission check */}
    <Route
        path={NWC_ALARM_URL}
        element={
          <PrivateRoute 
            resource={RESOURCES.ALARMS} 
            action={ACTIONS.VIEW}
          >
            <Main type="alarm" />
          </PrivateRoute>
        }
      />
      {/* Public routes */}
      <Route path={NWC_LOGIN_URL} element={<LoginPage />} />
      
      <Route
  path={NWC_RESET_PASSWORD}
  element={
    <PrivateRoute>
      <LoginPage />
    </PrivateRoute>
  }
/>
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
