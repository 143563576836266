import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from '@mui/material';

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { unAuthorized } = t("mainContainer");
  
  return (
    <Box
      className="unauthorized"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh" 
    >
      <Typography variant="h4">{unAuthorized.title}</Typography>
      <Typography  variant="body">
      {unAuthorized.message}
      </Typography>
      <Button 
        variant="contained" 
        onClick={() => navigate(-1)}
        className="backBtn"
      >
      {unAuthorized.backBtnText}
      </Button>
    </Box>
  );
};

export default UnauthorizedPage;