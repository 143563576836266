import React from "react";
import { CircularProgress, Box } from "@mui/material";

const CustomCircularProgress = ({ inputSx, size = 88 }) => (
  <Box
    sx={
      inputSx ?? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 9999,
      }
    }
  >
    <CircularProgress size={size} />
  </Box>
);

export default CustomCircularProgress;
