/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Divider, InputAdornment, Paper, Typography } from "@mui/material";
import CustomTextField from "../../common/reusable-comps/text-field/CustomTextField";
import CustomSelect from "../../common/reusable-comps/select/CustomSelect";
import CustomButton from "../../common/buttons/CustomButton";
import "./setting.scss";
import { ReactComponent as Japan } from "../../assets/images/Japan.svg";
import { ReactComponent as India } from "../../assets/images/India.svg";
import { ReactComponent as China } from "../../assets/images/China.svg";
import { ReactComponent as Vietnam } from "../../assets/images/Vietnam.svg";
import { ReactComponent as US } from "../../assets/images/US.svg";
import { ReactComponent as UK } from "../../assets/images/UK.svg";
import { ReactComponent as downArrow } from "../../assets/images/smallArrowDown.svg";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useTranslation } from "react-i18next";
import { prefectures } from "./CustomerData";
import {
  addCustomerEndPoint,
  updateCustomerEndpoint,
} from "../../services/apiConfig";
import {
  addCustomerApi,
  updateCustomerApi,
} from "../../redux/slices/customerSlice";
import {
  isValidEmailAddress,
  isValidJapanesePostalCode,
  isValidJapanesePhoneNumber,
  isEmpty,
  isNumericWithHyphens,

} from "../../common/utils/CommonUtils";
import { useDispatch } from "react-redux";
import SmallReusableModal from "../../common/reusable-comps/modals/SmallReusableModal";

export default function AddCustomerForm({
  user,
  setScreenType,
  setIsEdit,
  isEdit,
  editableData,
  customerList,
  setCustomerList,
  setShowAddCustomer,
}) {
  const { t } = useTranslation();
  const { settingsPageContent, exitModal } = t("mainContainer");
  const [formTouched, setFormTouched] = useState(false);

  const { addCustomerForm, editCustomer, addCustomerBtn, cancelBtn, editCustomerBtn } =
    settingsPageContent?.siteManagementContent;
  const {
    customerName,
    email,
    country,
    postalCode,
    prefecturesField,
    city,
    buildingName,
    contactNumber,
  } = addCustomerForm;
  const [formData, setFormData] = useState({
    name: "",
    displayName: "",
    email: "",
    country: "jp",
    prefecture: "",
    city: "",
    postalCode: "",
    buildingName: "",
    contactNumber: "",
  });
  const [isOpen, setIsOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();

  const countries = country?.options?.map(option => ({
    ...option,
    icon: <Japan />
  }));
 
  function getPrefecturesByCountry(countryValue) {
    return prefecturesField?.options?.filter(
      (prefecture) => prefecture?.country === countryValue
    );
  }
  const handleChange = (event) => {
    const { name, value } = event?.target || event;
    if (formError[name]) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    if (
      name === "contactNumber" &&
      value != "" &&
      !isNumericWithHyphens(value)
    ) {
      return null;
    }
    setFormData({
      ...formData, [name]: value,
      ...(name === "name" && { displayName: value }), // Conditionally add displayName

    });
  };

  function getCountryCode(country) {
    let countryCode = "";
    switch (country) {
      case "jp":
        countryCode = "+81"; // Japan country code
        break;
      case "ch":
        countryCode = "+86"; // China country code
        break;
      case "in":
        countryCode = "+91"; // India country code
        break;
      case "vi":
        countryCode = "+84"; // Vietnam country code
        break;
      case "us":
        countryCode = "+1"; // United States country code
        break;
      case "uk":
        countryCode = "+44"; // United Kingdom country code
        break;
      default:
        countryCode = ""; // Default country code
        break;
    }
    return countryCode;
  }
  useEffect(() => {
    if (isEdit) {
      const country = countries?.find((code) => {
        return code.value === editableData?.country;
      });
      const prefecture = getPrefecturesByCountry(country?.value)?.find(
        (code) => {
          return code?.value === editableData?.state;
        }
      );
      const editableBuildingName = editableData?.address1;
      const tempData = {
        ...editableData,
        prefecture: prefecture ? prefecture?.value : "",
        buildingName: editableBuildingName ? editableBuildingName : "",
        country: country ? country.value : "",
      };
      setFormData(tempData);
    }
  }, [isEdit, editableData]);
  const handleErrorCheck = () => {
    setFormTouched(true); // Set formTouched to true when any form field changes
    let newErrors = {};
    for (const [key, value] of Object.entries(formData)) {
      switch (key) {
        case "name":
          if (isEmpty(value)) {
            newErrors.name = "Please enter customer name.";
          }
          break;
        case "email":
          if (isEmpty(value)) {
            newErrors.email = "Please enter email address.";
          } else if (!isValidEmailAddress(value)) {
            newErrors.email = "Invalid email format.";
          } else if (value === user?.email) {
            newErrors.email = "Please enter a new email address.";
          }
          break;
        case "prefecture":
          if (isEmpty(value)) {
            newErrors.prefecture = "Please select prefectures.";
          }
          break;
        case "country":
          if (isEmpty(value)) {
            newErrors.country = "Please select country.";
          }
          break;
        case "city":
          if (isEmpty(value)) {
            newErrors.city = "Enter a city name.";
          }
          break;
        case "postalCode":
          if (isEmpty(value)) {
            newErrors.postalCode = "Please enter postal code";
          } else if (!isValidJapanesePostalCode(value)) {
            newErrors.postalCode = "Please enter a valid Japan postal code.";
          }
          break;
        case "buildingName":
          if (isEmpty(value)) {
            newErrors.buildingName = "Enter a building name or unit number.";
          }
          break;
        case "contactNumber":
          if (isEmpty(value)) {
            newErrors.contactNumber = "Please enter contact number.";
          } else if (!isValidJapanesePhoneNumber(value)) {
            newErrors.contactNumber = "Enter a valid contact number.";
          }
          break;
        default:
          break;
      }
    }
    setFormError(newErrors);
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = handleErrorCheck();
    if (Object.keys(errors).length > 0) {
      return; // Prevent form submission if errors exist
    } else {
      const token = localStorage.getItem("accessToken");
      if (isEdit) {
        const editablePayload = {
          id: editableData?.id,
          name: formData?.name,
          displayName: formData?.name,
          country: formData?.country,
          postalCode: formData?.postalCode,
          state: formData?.prefecture,
          city: formData?.city,
          address1: formData?.buildingName,
          contactNumber: formData?.contactNumber,
        };
        const url = updateCustomerEndpoint;
        const apiPayload = { editablePayload, url, token };
        dispatch(updateCustomerApi(apiPayload)).then((res) => {
          if (res?.payload?.status == 200) {
            const updatedList = customerList?.map((customer) =>
              customer?.id === res?.payload?.data?.id
                ? res?.payload?.data
                : customer
            );
            setCustomerList(updatedList);
            setShowAddCustomer(false);
            setScreenType("customerList");
            handleClearFields()
          } else {
            // Handle other possible scenarios (like 400 Bad Request)
            if (res?.payload?.response?.status === 400) {

              const message = res?.payload?.response?.data?.message;
              // Check if the message contains "name" and "email"
              if (message.includes("Name")) {
                // Only "name" is mentioned in the message
                setFormError((prevErrors) => ({
                  ...prevErrors,
                  name: message,
                }));
                // Validate "name" field
                getValidation("name", "", true, message);
              }
              // Log or handle other errors if needed
              console.log("Error adding customer:", res?.payload?.response?.data?.message);
            } else {
              // Handle other status codes or unexpected scenarios
              console.log("Unexpected response: ", res);
            }
          }
        });
      } else {
        let url = addCustomerEndPoint;
        const payload = {
          email: formData?.email,
          password: "password1",
          name: formData?.name,
          displayName: formData?.name,
          role: "customer",
          country: formData?.country,
          postalCode: formData?.postalCode,
          state: formData?.prefecture,
          city: formData?.city,
          address1: formData?.buildingName,
          contactNumber: formData?.contactNumber,
          customerRole: "admin",
        };
        const apiPayload = { payload, url, token };
        dispatch(addCustomerApi(apiPayload))
          .then((res) => {
            if (res?.payload?.status === 201) {
              // Successful creation of customer
              const newCustomer = res?.payload?.data;
              setCustomerList((prevLocalCust) => [...prevLocalCust, newCustomer]);
              setShowAddCustomer(false);
              setScreenType("customerList");
            } else {
              // Handle other possible scenarios (like 400 Bad Request)
              if (res?.payload?.response?.status === 400) {
                const message = res?.payload?.response?.data?.message.toLowerCase();
                // Check if the message contains "name" and "email"
                if (message.includes("name") && message.includes("email")) {
                  // Both "name" and "email" are mentioned in the message
                  setFormError((prevErrors) => ({
                    ...prevErrors,
                    name: message,
                    email: message,
                  }));
                  // Validate both fields
                  getValidation("name", "", true, message);
                  getValidation("email", "", true, message);
                } else if (message.includes("name")) {
                  // Only "name" is mentioned in the message
                  setFormError((prevErrors) => ({
                    ...prevErrors,
                    name: message,
                  }));
                  // Validate "name" field
                  getValidation("name", "", true, message);
                } else if (message.includes("email")) {
                  // Only "email" is mentioned in the message
                  setFormError((prevErrors) => ({
                    ...prevErrors,
                    email: message,
                  }));
                  // Validate "email" field
                  getValidation("email", formData?.email, true, message);
                }
                // Log or handle other errors if needed
                console.log("Error adding customer:", message);
              } else {
                // Handle other status codes or unexpected scenarios
                console.log("Unexpected response: ", res);
              }
            }
          })
          .catch((error) => {
            // Handle network errors or unexpected errors from the API call
            console.error("Error adding customer:", error);
            // Example: setNetworkError(true);
          });


      }
    }
  };

  const smallModalChildren = () => {
    return <h5>{exitModal.content}</h5>;
  };
  const handleCloseSmallModal = () => {
    setModalOpen(false);
  };
  const handleClearFields = () => {
    setScreenType(customerList?.length > 0 && Array.isArray(customerList) ? "customerList" : "noCustomer");
    setShowAddCustomer(false);
    handleCloseSmallModal();
    setFormData({
      name: "",
      email: "",
      country: "",
      prefecture: "",
      city: "",
      postalCode: "",
      buildingName: "",
      contactNumber: "",
    });
    setFormError({});
    setIsEdit(false);
  };
  const handleCrossClick = () => {
    setModalOpen(true);
  };
  const renderFooter = () => {
    return (
      <div>
        <CustomButton
          variant="text"
          onClick={handleCloseSmallModal}
          marginRight=".5rem"
          width="108px"
          padding="11px 16px"
        >
          {exitModal.actionBtnNo}
        </CustomButton>
        <CustomButton
          variant="contained"
          backgroundColor="primary"
          onClick={handleClearFields}
          width="108px"
          background="#0F62FE"
        >
          {exitModal.actionBtnYes}
        </CustomButton>
      </div>
    );
  };
  const handleCancelClick = () => {
    setShowAddCustomer(false);
    setScreenType(customerList?.length > 0 && Array.isArray(customerList) ? "customerList" : "noCustomer");
    setIsEdit(false);
  };
  const customPaddingLeft =
    formData?.country === "jp"
      ? "0px"
      : formData?.country === ""
        ? "15px"
        : "9px";

  const getValidation = (fieldName, value, formTouched, errorMessage = "") => {
    if (fieldName === "name" && formTouched) {
      if (isEmpty(value)) {
        return customerName?.error;
      } else if (errorMessage) {
        return customerName.error1;
      }
    }
    else if (fieldName === "email" && formTouched) {
      if (isEmpty(value)) {
        return email.error1;
      } else if (!isValidEmailAddress(value)) {
        return email.error2;
      } else if (value === user?.email) {
        return email.error3;
      } else if (errorMessage && errorMessage == "\"email\" must be a valid email") {
        // Specific error from the backend when the email doesn't exist
        return email.error5;
      }
      else if (errorMessage && errorMessage == "email already taken") {
        // backend erorr when email is already taken
        return email.error4;
      }
    }
    else if (fieldName === "prefecture" && formTouched) {
      if (isEmpty(value)) {
        return prefecturesField.error;
      }
    } else if (fieldName === "city" && formTouched) {
      if (isEmpty(value)) {
        return city.error;
      }
    } else if (fieldName === "postalCode" && formTouched) {
      if (isEmpty(value)) {
        return postalCode.error1;
      } else if (!isValidJapanesePostalCode(value)) {
        return postalCode.error2;
      }
    } else if (fieldName === "country" && formTouched) {
      if (isEmpty(value)) {
        return country.error1;
      }
    } else if (fieldName === "buildingName" && formTouched) {
      if (isEmpty(value)) {
        return buildingName.error;
      }
    } else if (fieldName === "contactNumber" && formTouched) {
      if (isEmpty(value)) {
        return contactNumber.error1;
      } else if (!isValidJapanesePhoneNumber(value)) {
        return contactNumber.error2;
      }
    }
    return "";
  };

  return (
    <>
      <Box
        className={`box ${isOpen ? "open" : "closed"}`}
        style={{
          paddingRight: "0px",
          paddingBottom: "0px",
          width: "100%",
          maxWidth: "410px",
          height: "calc(100% - 23px)",
          display: "inline-block",
        }}
      >
        {isOpen && (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "28px",
                }}
              >
                {isEdit ? editCustomer?.title : addCustomerForm?.title}
              </Typography>
              <span
                className="close"
                onClick={handleCrossClick}
                style={{ cursor: "pointer", marginRight: "1rem" }}
              >
                &times;
              </span>
            </Box>
            <Divider sx={{ marginLeft: "-20px", marginBottom: "0px" }} />
            <div className="addCustInnerBox">
              <form
                style={{
                  paddingRight: "16px",
                  "& .inputSelectedBg:InternalAutofillSelected": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <CustomTextField
                  label={customerName?.label}
                  id="name"
                  name="name"

                  placeholder={customerName?.placeholder}
                  handleChange={handleChange}
                  value={formData?.displayName}
                  error={getValidation("name", formData?.name, formTouched, formError?.name)}
                  helperText={getValidation(
                    "name",
                    formData?.name,
                    formTouched, formError?.name
                  )}
                  width="100%"
                />
                <CustomTextField
                  label={email?.label}
                  id="email"
                  name="email"
                  disabled={isEdit}
                  placeholder={email?.placeholder}
                  type="email"
                  handleChange={handleChange}
                  value={formData?.email}
                  error={getValidation("email", formData?.email, formTouched, formError?.email)}
                  helperText={getValidation(
                    "email",
                    formData?.email,
                    formTouched, formError?.email
                  )}
                  width="100%"
                />
                <CustomSelect
                  label={country?.label}
                  options={countries}
                  id="country"
                  name={"country"}
                  value={formData?.country}
                  displayEmpty={true}
                  onChange={handleChange}
                  error={getValidation(
                    "country",
                    formData?.country,
                    formTouched
                  )}
                  helperText={getValidation(
                    "country",
                    formData?.country,
                    formTouched
                  )}
                  customPaddingLeft={customPaddingLeft}
                  width="100%"
                  inputComponent={downArrow}
                  noMarginIcon={false}
                />

                <CustomTextField
                  label={postalCode?.label}
                  id="postalCode"
                  name="postalCode"
                  placeholder="000-0000"
                  handleChange={handleChange}
                  value={formData?.postalCode}
                  error={getValidation(
                    "postalCode",
                    formData?.postalCode,
                    formTouched
                  )}
                  helperText={getValidation(
                    "postalCode",
                    formData?.postalCode,
                    formTouched
                  )}
                  width="100%"
                />
                <CustomSelect
                  label={
                    formData?.country === "jp"
                      ? prefecturesField.label[0]
                      : prefecturesField.label[1]
                  }
                  options={getPrefecturesByCountry(formData?.country)}
                  id="prefecture"
                  name={"prefecture"}
                  value={formData?.prefecture}
                  onChange={handleChange}
                  error={getValidation(
                    "prefecture",
                    formData?.prefecture,
                    formTouched
                  )}
                  helperText={getValidation(
                    "prefecture",
                    formData?.prefecture,
                    formTouched
                  )}
                  displayEmpty={true}
                  customPaddingLeft={formData?.prefecture === "" ? "18px" : ""}
                  disabled={formData?.country === ""}
                  width="100%"
                  inputComponent={UnfoldMoreIcon}
                  noMarginIcon={true}
                />

                <CustomTextField
                  label={city.label}
                  id="city"
                  name="city"
                  placeholder={city.placeholder}
                  handleChange={handleChange}
                  value={formData?.city}
                  error={getValidation("city", formData?.city, formTouched)}
                  helperText={getValidation(
                    "city",
                    formData?.city,
                    formTouched
                  )}
                  // width="30.4vw"
                  width="100%"
                />

                <CustomTextField
                  label={buildingName.label}
                  id="buildingName"
                  name="buildingName"
                  placeholder={buildingName.placeholder}
                  handleChange={handleChange}
                  value={formData?.buildingName}
                  error={getValidation(
                    "buildingName",
                    formData?.buildingName,
                    formTouched
                  )}
                  helperText={getValidation(
                    "buildingName",
                    formData?.buildingName,
                    formTouched
                  )}
                  // width="30.4vw"
                  width="100%"
                />
                <CustomTextField
                  label={contactNumber.label}
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="000-000-0000"
                  handleChange={handleChange}
                  value={formData?.contactNumber}
                  error={getValidation(
                    "contactNumber",
                    formData?.contactNumber,
                    formTouched
                  )}
                  helperText={getValidation(
                    "contactNumber",
                    formData?.contactNumber,
                    formTouched
                  )}
                  // width="30.4vw"
                  width="100%"
                  startAdornment={
                    <>
                      <InputAdornment
                        position="start"
                        disablePointerEvents
                        style={{ width: "33px" }}
                      >
                        {getCountryCode(formData?.country)}
                      </InputAdornment>
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{
                          marginRight: "8px",
                          borderColor: "#8D8D8D",
                        }}
                      />
                    </>
                  }
                />
              </form>
            </div>
          </>
        )}
        <div
          style={{
            marginLeft: "18px",
          }}
        >
          <Divider
            sx={{
              marginLeft: "-36px",
              marginBottom: "0px",
              marginTop: "9px",
            }}
          />
          <Box
            style={{
              display: "flex",
              position: "sticky",
              justifyContent: "flex-end",
              marginRight: ".5vw",
              gap: "0.7rem",
              padding: "16px 24px 16px 24px",
              border: "1px 0px 0px 0px",
            }}
          >
            <CustomButton
              variant="outlined"
              width="126px"
              onClick={handleCancelClick}
              height="40px"
            >
              {cancelBtn}
            </CustomButton>
            <CustomButton
              variant="contained"
              backgroundColor="primary"
              onClick={handleSubmit}
              background="#0F62FE"
              width="142px"
              height="40px"
            >
              {isEdit ? editCustomerBtn : addCustomerBtn}
            </CustomButton>
          </Box>
        </div>
        {modalOpen && (
          <SmallReusableModal
            open={modalOpen}
            handleClose={handleCloseSmallModal}
            title={exitModal.title}
            footer={renderFooter()}
            divider={false}
            dialogWidth="410px"
            dialogHeight="152px"
            titleAndIconPadding="0"
            contentPadding="0 0 32px 0"
          >
            {smallModalChildren()}
          </SmallReusableModal>
        )}
      </Box>

      {/* <div style={{ display: "inline-block" }}>
        <Paper style={{ minWidth: "10%" }} />
      </div> */}
    </>
  );
}
