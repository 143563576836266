import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
  Stack,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
} from "@mui/material";
import Lottie from "lottie-react";
import { customerTabStyles } from "./AllCustomerTabStyle";
import spinner from "../../../../../../assets/animation/spinner.json";
import { ReactComponent as RightArrow } from "../../../../../../assets/images/RightArrow.svg";
import {
  setSelectedCategory,
  setSelectedAreas,
  setSelectedSites,
  setUpdateBeadcrumbs
} from "../../../../../../redux/slices/customerFilterSlice";
import {
  getHomeAllSiteList,
} from "../../../../../../services/apiConfig";
import useFetch from "../../../../../../common/hook/useFetch";
import { prefectures } from "../../../../../setting/CustomerData";
import { getSingleStateCode } from "../../../../../../common/utils/CommonUtils";
import CustomButton from "../../../../../../common/buttons/CustomButton";
import { NWC_SETTING_URL } from "../../../../../../common/constants/Constants";

const AllCustomerTabContent = ({
  setData,
  setSiteAvailable,
  setDisableSubmit,
  searchType = "customer",
  searchQuery = "",
}) => {
  const dispatch = useDispatch();
  const { myFavoriteLists, allCustomers, selectedCategory, selectedAreas, selectedSites } =
    useSelector((state) => state.customer);
  const [categories, setCategories] = useState([]);
  const [sitesData, setSitesData] = useState([]);
  const [categoryDatum, setCategoryDatum] = useState(null);
  const [selectedCustomer, setSelectiedCustomer] = useState(false);
  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState(selectedCategory);
  const categoryListRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    landingPage, customerTopNavigation
  } = t("mainContainer");
  const { customerInfoHeader, areaInfoMessage, customerrInfoMessage, selectAllArea, selectAllSites } = customerTopNavigation;

  const { addCustomerForm, customerDetails } = t('mainContainer').settingsPageContent?.siteManagementContent;
  const { prefecturesField } = addCustomerForm;
  const {
    data: fetchsitesData,
    error: fetchsiteError,
    loading: fetchsiteErrorLoading,
    fetchData: getSitesDataList,
  } = useFetch();

  const groupedSites = sitesData?.reduce((acc, site) => {
    // Find the label based on the state value
    const stateLabel = prefectures?.find(
      (pref) => pref?.value === site?.state
    )?.label;

    // Only process if the stateLabel is valid
    if (stateLabel) {
      // Initialize the array for the state if it doesn't exist
      if (!acc[stateLabel]) {
        acc[stateLabel] = [];
      }
      // Add the site to the appropriate state array
      acc[stateLabel].push(site);
    }

    return acc;
  }, {});

  const areas = Object.keys(groupedSites).sort((a, b) => a.localeCompare(b));


  useEffect(() => {
    if (allCustomers?.length > 0) {
      // const sortedCategories = allCustomers?.sort((a, b) => a.name.localeCompare(b.name));
      setCategories(allCustomers);
      // dispatch(setSelectedCategory(selectedCategory));
      getSitesDataList(getHomeAllSiteList(selectedCategory?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCustomers]);

  useEffect(() => {
    if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
      // Filter out sites with invalid or undefined states
      const validSites = fetchsitesData?.filter((site) => {
        // Check if the state is valid by finding its label
        const stateLabel = prefectures?.find(
          (pref) => pref?.value === site?.state
        )?.label;
        return stateLabel !== undefined;
      });

      const sortedSitesData = validSites?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSitesData(sortedSitesData);
      if (fetchsitesData.length === 0) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchsitesData, fetchsiteError, fetchsiteErrorLoading]);

  useEffect(() => {
    if (
      !fetchsiteErrorLoading &&
      !fetchsiteError &&
      fetchsitesData &&
      fetchsitesData.length > 0 &&
      sitesData.length > 0 &&
      fetchsitesData.length > 0
    ) {
      setSiteAvailable(true);
      const newAreas = areas;
      const newSites = sitesData?.map((site) => ({
        name: site.name,
        id: site.id,
        state: prefectures?.find((pref) => pref?.value === site?.state)?.label,
      }));
      if (selectedCustomer) {
        dispatch(setSelectedAreas(newAreas));
        dispatch(setSelectedSites(newSites));
      }
      setSelectiedCustomer(false);
    } else {
      setSiteAvailable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesData, setSitesData]);

  // to set the selectedCategory for Add-Site screen
  useEffect(() => {
    setCategoryDatum(selectedCategory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setData({ selectedCategory, selectedAreas, selectedSites });
    if (selectedAreas.length == 0 || selectedSites.length == 0) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedAreas, selectedSites, setData]);

  const handleCategoryChange = (category) => {
    let foundMatch = false;

    // // Check if there's a match and prepare the dispatch action
    // myFavoriteLists.forEach(item => {
    //   if (item.customerId === category.id && item.defaultFavourite) {
    //     foundMatch = true;
    //     // Dispatch the category with favouriteName if a match is found
    //     dispatch(setSelectedCategory({ ...category, favouriteName: item.favouriteName }));
    //   }
    // });

    // // If no match was found, dispatch the category without favouriteName
    // if (!foundMatch) {
    //   dispatch(setSelectedCategory(category));
    // }
    dispatch(setSelectedCategory(category));
    // Additional actions
    getSitesDataList(getHomeAllSiteList(category?.id));
    setSelectiedCustomer(true);
    dispatch(setUpdateBeadcrumbs(false));
    setCategoryDatum(category)
  };

  const handleAreaChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    // Update the selected areas
    const newAreas = isChecked
      ? [...selectedAreas, value]
      : selectedAreas.filter((area) => area !== value);

    // Filter sites based on the new selected areas
    const newSites = sitesData
      .filter((site) =>
        newAreas.includes(
          prefectures?.find((pref) => pref?.value === site?.state)?.label
        )
      )
      .map((site) => ({
        name: site.name,
        id: site.id,
        state: prefectures?.find((pref) => pref?.value === site?.state)?.label,
      }));
    //do not update bredcrum
    dispatch(setUpdateBeadcrumbs(false));
    // Dispatch the updated areas and sites to the Redux store
    dispatch(setSelectedAreas(newAreas));
    dispatch(setSelectedSites(newSites));
  };

  const handleSiteChange = (event) => {

    //do not update bredcrum
    dispatch(setUpdateBeadcrumbs(false));
    const { value, checked } = event.target;
    const site = sitesData.find((site) => site.name === value);

    const newSites = checked
      ? [
        ...selectedSites,
        {
          name: site.name,
          id: site.id,
          state: prefectures?.find((pref) => pref?.value === site?.state)
            ?.label,
        },
      ]
      : selectedSites.filter((site) => site.name !== value);


    dispatch(setSelectedSites(newSites));
  };

  const handleSelectAllAreas = (event) => {
    //do not update bredcrum
    dispatch(setUpdateBeadcrumbs(false));
    if (event.target.checked) {
      // Select all areas
      dispatch(setSelectedAreas(areas));

      // Select all sites in selected areas
      const allSitesInSelectedAreas = sitesData
        .filter((site) =>
          areas.includes(
            prefectures?.find((pref) => pref?.value === site?.state)?.label
          )
        )
        .map((site) => ({
          name: site.name,
          id: site.id,
          state: prefectures?.find((pref) => pref?.value === site?.state)?.label,
        }));


      dispatch(setSelectedSites(allSitesInSelectedAreas));

    } else {
      // Deselect all areas
      dispatch(setSelectedAreas([]));

      // Deselect all sites
      dispatch(setSelectedSites([]));
    }

  };

  const handleSelectAllSites = (event) => {

    //do not update bredcrum
    dispatch(setUpdateBeadcrumbs(false));

    if (event.target.checked) {
      const selectedSitesInAreas = sitesData
        .filter((site) =>
          selectedAreas.includes(
            prefectures?.find((pref) => pref?.value === site?.state)?.label
          )
        )
        .map((site) => ({
          name: site.name,
          id: site.id,
          state: prefectures?.find((pref) => pref?.value === site?.state)
            ?.label,
        }));
      dispatch(setSelectedSites(selectedSitesInAreas));
    } else {
      dispatch(setSelectedSites([]));
    }
  };

  const allSitesSelected = sitesData
    .filter((site) =>
      selectedAreas.includes(
        prefectures?.find((pref) => pref?.value === site?.state)?.label
      )
    )
    .every((site) =>
      selectedSites.some(
        (selectedSite) =>
          selectedSite.name === site.name && selectedSite.id === site.id
      )
    );

  const noResultFunction = (siteData = false) => {
    if (
      (!fetchsiteErrorLoading &&
        !fetchsiteError &&
        fetchsitesData &&
        fetchsitesData.length === 0) ||
      (!fetchsiteErrorLoading &&
        !fetchsiteError &&
        fetchsitesData &&
        fetchsitesData.length > 0)
    ) {
      return (
        <>
          {(fetchsitesData.length === 0 && sitesData.length === 0) ||
            (fetchsitesData.length !== 0 &&
              sitesData.length !== 0 &&
              Object.keys(selectedCategory).length > 0 &&
              selectedAreas.length === 0) ? (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={250}
              minWidth={sitesData.length === 0 && 564}
              display="flex"
            >
              <Typography sx={customerTabStyles.infoTitle}>
                {customerrInfoMessage["noResult"]}
              </Typography>
              <Typography sx={customerTabStyles.infoDesc}>
                {fetchsitesData.length === 0 &&
                  sitesData.length === 0 &&
                  customerrInfoMessage["infoText"]
                }
                {fetchsitesData.length !== 0 &&
                  sitesData.length !== 0 &&
                  Object.keys(selectedCategory).length > 0 &&
                  selectedAreas.length === 0 &&
                  areaInfoMessage["infoText"]
                }

              </Typography>
              {!siteData && <div style={customerTabStyles.addSiteButContainer}>
                <CustomButton
                  variant="contained"
                  backgroundColor="primary"
                  width="119px"
                  padding="16px"
                  onClick={handleAddSiteClick}
                  textColor="#FFFFFF"
                  fontWeight="400"
                >
                  <span style={customerTabStyles.addSiteButtonIcon}>
                    {customerDetails?.addSiteBtn}
                  </span>
                  +
                </CustomButton>
              </div>}

            </Stack>
          ) : null}
        </>
      );
    } else if (!fetchsitesData.length > 0) {
      return (
        <div className="spinner-wrapper blue-spinner">
          <Lottie className="spinner" animationData={spinner} autoplay loop />
        </div>
      );
    }
  };
  const handleAddSiteClick = () => {
    dispatch(setSelectedCategory(selectedCategory));
    navigate(NWC_SETTING_URL, { state: { from: "addSiteButton", selectedIdDatum: categoryDatum } })
  }
  const noResultFunctionSearch = () => {
    return (
      <>
        <Stack
          sx={customerTabStyles?.myNoStack}
        >
          <Typography sx={customerTabStyles.infoTitle}>
            {landingPage.noResultFound}
          </Typography>
        </Stack >
      </>
    );
  };


  useEffect(() => {
    const scrollToActive = () => {
      const activeCategory =
        categoryListRef.current?.querySelector(".activeClass");
      if (activeCategory) {
        activeCategory.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    // Delay scrolling until after the lists are rendered
    const timeoutId = setTimeout(scrollToActive, 100);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, [selectedCategory, selectedAreas, selectedSites]);


  const filteredCust = categories?.filter((item) => {
    if (searchType === "customer") {
      return item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    } else return true;
  });



  useEffect(() => {
    if (
      filteredCust?.length &&
      searchQuery !== "" &&
      searchType === "customer"
    ) {
      handleCategoryChange(filteredCust?.[0]);
    } else if (filteredCust?.length &&
      searchQuery === "" &&
      searchType === "customer") {
      handleCategoryChange(defaultSelectedCategory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const myArea = areas?.filter((item) => {
    if (searchType === "area") {
      return item?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    } else return true;
  });

  const mySite = selectedAreas?.map((item) => {
    return groupedSites[item]?.filter((site) => {
      if (searchType === "site") {
        return site?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase());
      }
      else return true;
    })?.length;
  })?.filter((i) => i !== 0);


  const areaName = (area) => {
    let prefectureObj;

    const stateCode = getSingleStateCode(area)
    prefectureObj = prefecturesField?.options?.find((item) => item?.value === stateCode);
    return prefectureObj?.label

  }

  return (
    <Box sx={{ display: "flex", height: "308px" }}>
      <Box sx={{ flex: 1 }} className="customerList" ref={categoryListRef}>
        <Typography variant="h6" sx={customerTabStyles.customerList.h6}>
          {customerInfoHeader["customer"]}
        </Typography>
        {filteredCust?.length !== 0 ? (
          <FormGroup>
            {filteredCust?.map((category) => (
              <FormControlLabel
                sx={
                  selectedCategory?.name === category.name
                    ? customerTabStyles.customerList.activeClass
                    : customerTabStyles.customerList
                }
                className={
                  selectedCategory?.id === category.id && "activeClass"
                }
                key={category.id}
                control={
                  <Checkbox
                    sx={customerTabStyles.customerList.checkBoxStyle}
                    checked={selectedCategory?.name === category.name}
                    onChange={() => handleCategoryChange(category)}
                    value={category.name}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="div">{category.displayName}</Typography>
                    <RightArrow sx={{ marginLeft: "auto" }} />
                  </Box>
                }
                labelPlacement="end"
              />
            ))}
          </FormGroup>) : (
          noResultFunctionSearch('cust')
        )}
      </Box>

      {!fetchsiteErrorLoading &&
        !fetchsiteError &&
        fetchsitesData &&
        fetchsitesData.length > 0 &&
        selectedCategory &&
        sitesData?.length > 0 && (
          <Box sx={{ flex: 2, p: 0 }} className="areaList">
            <Typography variant="h6" sx={customerTabStyles.areaList.h6}>
              {customerInfoHeader["area"]}
            </Typography>
            {
              <FormGroup>
                {filteredCust?.length === 0 && <></>}
                {(myArea?.length && filteredCust?.length !== 0) ? (
                  <FormControlLabel
                    sx={customerTabStyles.areaList}
                    control={
                      <Checkbox
                        checked={selectedAreas.length === areas.length}
                        onChange={handleSelectAllAreas}
                        sx={customerTabStyles.areaList.checkBoxStyle}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography Typography variant="div">
                          {selectAllArea}
                        </Typography>
                      </Box>
                    }
                  />
                ) : (
                  <></>
                )}
                {(myArea?.length > 0 && filteredCust?.length !== 0)
                  ? myArea?.map((area) => (
                    <FormControlLabel
                      key={area}
                      sx={customerTabStyles.areaList}
                      control={
                        <Checkbox
                          checked={selectedAreas.includes(area)}
                          onChange={handleAreaChange}
                          value={area}
                          sx={customerTabStyles.areaList.checkBoxStyle}
                        />
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >


                          <Typography variant="div">{areaName(area)}</Typography>
                        </Box>
                      }
                      labelPlacement="end"
                    />
                  ))
                  : (filteredCust?.length !== 0 ? noResultFunctionSearch('area') : <></>)
                }
              </FormGroup>
            }
          </Box>
        )}

      <Box className="siteList" sx={{ flex: 2, p: 0 }}>
        {!fetchsiteErrorLoading &&
          !fetchsiteError &&
          fetchsitesData &&
          fetchsitesData.length > 0 &&
          sitesData?.length > 0 &&
          selectedAreas.length > 0 && filteredCust?.length !== 0 && (
            <>
              <Typography variant="h6" sx={customerTabStyles.siteList.h6}>
                {customerInfoHeader["site"]}
              </Typography>
              {filteredCust?.length === 0 && <></>}
              {mySite?.length === 0 && noResultFunctionSearch("site")}
              {filteredCust?.length !== 0 ? (
                (!!myArea?.length && mySite?.length !== 0) && (
                  <FormGroup>
                    <FormControlLabel
                      sx={customerTabStyles.siteList}
                      control={
                        <Checkbox
                          checked={allSitesSelected}
                          onChange={handleSelectAllSites}
                          sx={customerTabStyles.areaList.checkBoxStyle}
                        />
                      }
                      label={selectAllSites}
                    />
                    {selectedAreas?.map((area) => (
                      <React.Fragment key={area}>
                        {groupedSites[area]?.map((site) => {
                          if (searchType !== "site")
                            return (
                              <FormControlLabel
                                key={site.id}
                                sx={customerTabStyles.siteList}
                                control={
                                  <Checkbox
                                    checked={selectedSites.some(
                                      (selectedSite) =>
                                        selectedSite.name === site.name &&
                                        selectedSite.id === site.id
                                    )}
                                    onChange={handleSiteChange}
                                    value={site.name}
                                    sx={customerTabStyles.areaList.checkBoxStyle}
                                  />
                                }
                                label={site.name}
                              />
                            );
                          if (
                            searchType === "site" &&
                            site?.name
                              ?.toLowerCase()
                              ?.includes(searchQuery?.toLowerCase())
                          ) {
                            return (
                              <FormControlLabel
                                key={site.id}
                                sx={customerTabStyles.siteList}
                                control={
                                  <Checkbox
                                    checked={selectedSites.some(
                                      (selectedSite) =>
                                        selectedSite.name === site.name &&
                                        selectedSite.id === site.id
                                    )}
                                    onChange={handleSiteChange}
                                    value={site.name}
                                    sx={customerTabStyles.areaList.checkBoxStyle}
                                  />
                                }
                                label={site.name}
                              />
                            );
                          }
                          return null;
                        })}
                      </React.Fragment>
                    ))}
                  </FormGroup>
                )) : <></>}
            </>
          )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {fetchsitesData.length == 0 && sitesData.length === 0 && (
            <Typography variant="h6" sx={customerTabStyles.areaList.h6}>
              {customerInfoHeader["area"]}
            </Typography>
          )}
          {((!fetchsiteErrorLoading &&
            fetchsitesData.length != 0 &&
            sitesData.length !== 0 &&
            selectedAreas.length === 0) || filteredCust?.length === 0) && (
              <Typography variant="h6" sx={customerTabStyles.areaList.h6}>
                {customerInfoHeader["site"]}
              </Typography>
            )}
        </Box>
        {filteredCust?.length === 0 ? <>
          <Stack
            sx={customerTabStyles?.noCustBox}
          >
            <Divider orientation="vertical" variant="middle"
              sx={customerTabStyles.custDividerStyle}
            // sx={{ height: '309px', marginTop: '28px !important' }}
            ></Divider>
          </Stack>
        </> : noResultFunction(!!myArea?.length && mySite?.length == 0)}
      </Box>
    </Box>
  );
};

export default AllCustomerTabContent;