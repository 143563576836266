import { useState, useCallback } from 'react';

/**
 * A custom hook for making PATCH requests.
 * 
 * @param {string} url - The endpoint URL for the PATCH request.
 * @returns {Object} - An object containing:
 *    - `loading`: Boolean indicating if the request is in progress.
 *    - `error`: Error message if the request fails.
 *    - `data`: Response data from the server.
 *    - `patch`: Function to trigger the PATCH request.
 */
const  usePatch=(url)=> {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    // Ensure `token` is always up-to-date
    const token = localStorage.getItem('accessToken');

    const patch = useCallback(async (body) => {
        setLoading(true);
        setError(null);
        setData(null);

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                       Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            setData(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [url, token]); // Include `token` in the dependency array

    return { loading, error, data, patch };
}

export default usePatch;