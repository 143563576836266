export const siteOptions = ["Edit", "Disabled", "Delete"];
export const siteHeaderStructure = [
  { key: 1, width: "10px", label: "Site Name", accessor: "displayName" },
  {
    key: 2,
    width: "10px",
    label: "Area Name",
    accessor: "state",
  },
  {
    key: 3,
    width: "10px",
    label: "Last Online",
    accessor: "updatedDate",
  },
  { key: 4, width: "10px", label: "", accessor: "actionMenu" },
];
export const homeSiteGridHeaderStructure = [
  { key: 1, width: "10px", label: "Site Name", accessor: "siteName" },
  {
    key: 2,
    width: "10px",
    label: "Electricity",
    accessor: "electricity",
  },
  {
    key: 3,
    width: "10px",
    label: "Alarms",
    accessor: "alarmCount",
  },
  { key: 4, width: "10px", label: "", accessor: "actionMenu" },
];

export const sortingKeys = {
  "Newest on top": "createdAt:desc",
  "Oldest on top": "createdAt:asc",
  "A to Z": "name:asc",
  "Z to A": "name:desc",
};

export const siteStepKeys = {
  siteDetail: {
    name: "",
    imageUrl: null,
    thumbnailUrl:null,
    imageFile: null,
    imageName: "",
    displayName: "",
    description: "",
    country: "",
    id: "",
    nextStep: "",
  },
  siteAddress: {
    // country: "",
    postalCode: "",
    state: "",
    city: "",
    address1: "",
    id: "",
    nextStep: "",
  },
  edgeDevice: {
    webAccessUrl: "",
    webAccessUser: "",
    webAccessPassword: "",
    emsAccessPassword: "",
    emsAccessUrl: "",
    emsAccessUser: "",
    id: "",
    nextStep: "",
    lastOnline: null,
    webAccessConnectionVerified: false,
    emsConnectionVerified: false,
    emsProjectInfo: [],
    webAccessProjectInfo: [],
    lastSyncTime: null,
    tagCount: 0,
  },
  emailNotification: {},
};

export const siteInitialObj = {
  siteDetail: {
    name: "",
    displayName: "",
    imageName: "",
    description: "",
    imageUrl: null,
    thumbnailUrl:null,
    imageFile: null,
  },
  siteAddress: {
    // country: "",
    postalCode: "",
    state: "",
    city: "",
    address1: "",
  },
  edgeDevice: {
    webAccessUrl: "",
    webAccessUser: "",
    webAccessPassword: "",
    emsAccessUrl: "",
    emsAccessUser: "",
    emsAccessPassword: "",
    lastOnline: null,
    webAccessConnectionVerified: false,
    emsConnectionVerified: false,
    emsProjectInfo: [],
    webAccessProjectInfo: [],
    deviceStatus: "",
    lastSyncTime: null,
    tagCount: 0,
  },
  emailNotification: {},

  // Add other initial form data as needed
};

export const siteErrorObj = {
  siteDetail: {
    name: "",
    description: "",
    imageUrl: "",
  },
  siteAddress: {
    postalCode: "",
    state: "",
    city: "",
    address1: "",
  },
  edgeDevice: {
    webAccessUrl: "",
    webAccessUser: "",
    webAccessPassword: "",
    emsAccessUrl: "",
    emsAccessUser: "",
    emsAccessPassword: "",
  },
  emailNotification: {},
};

export const getNameByActiveStep = (step) => {
  switch (step) {
    case 1:
      return "siteDetail";
    case 2:
      return "siteAddress";
    case 3:
      return "edgeDevice";
    case 4:
      return "emailNotification";
    default:
      return "";
  }
};
