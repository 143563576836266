import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import CustomButton from "../../common/buttons/CustomButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddIcon } from "../../assets/images/SmallAddIcon.svg";
import "./setting.scss";
function NoCustomer({ setScreenType, setShowAddCustomer, setIsSiteEdit=()=>{},customerList }) {
  const handleClick = () => {
    setShowAddCustomer(true);
    setScreenType("addCustomer");
    customerList?.length &&  setIsSiteEdit(false);
  };

  const { t } = useTranslation();
  const { settingsPageContent } = t("mainContainer");
  const { title, addCustomerBtn, messageTitle, messageDescription } =
    settingsPageContent?.siteManagementContent;
  return (
    <Box className="noCustomBoxStyle">
      <Typography
        variant="h6"
        style={{
          fontSize: "18px",
          fontWeight: " 500",
          lineHeight: "24px",
          letterSpacing: "0.25px",
          marginBottom: "4px",
        }}
      >
        {title}
      </Typography>
      <CustomButton
        variant="contained"
        backgroundColor="primary"
        icon={<AddIcon />}
        width="158px"
        iconPosition="end"
        onClick={handleClick}
        textColor="#FFFFFF"
      >
        {addCustomerBtn}
      </CustomButton>
      <Stack
        className="noCustomerStack"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "10%",
        }}
      >
        <Typography
          fontSize={"32px"}
          color="#0043CE"
          fontWeight="400"
          lineHeight="40px"
          letterSpacing="0.25px"
        >
          {messageTitle}
        </Typography>
        <Typography
          fontSize={"16px"}
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.15px"
        >
          {messageDescription}
        </Typography>
      </Stack>
    </Box>
  );
}

export default NoCustomer;
