/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import CustomerList from "./CustomerList";
import SiteForm from "./SiteForm";
import SiteList from "./SiteList";
import NoSite from "./NoSite";
import { ReactComponent as GreenCircleIcon } from "../../assets/images/green_circle.svg";
import {
  getAllSiteApi,
  updateSiteFormInitialState,
} from "../../redux/slices/customerSlice";
import { useDispatch } from "react-redux";
import { siteStepKeys } from "./siteDetails/siteDetails.Data";
import SmallReusableModal from "../../common/reusable-comps/modals/SmallReusableModal";
import { Stack } from "@mui/system";
import { ApiPayload } from "./ApiAction";
import {
  formatDate,
  getRequestCustomerApi,
} from "../../common/utils/CommonUtils";
import { sortingKeys } from "./siteDetails/siteDetails.Data";
import { getSiteWithPaginationEndPoint } from "../../services/apiConfig";
import { ReactComponent as RedCircle } from "../../assets/images/redCircle.svg";
import { ReactComponent as GreyCircle } from "../../assets/images/greyCircle.svg";

function CustomerAndSite({
  customerList,
  setScreenType,
  setIsEdit,
  setEditableData,
  setShowAddCustomer,
  isEdit,
  showAddCustomer,
  screenType,
  type,
  setSiteLength,
  siteLength,
  setShowEmptyPage,
  showEmptyPage,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  currentFilter,
  setcurrentFilter,
  custApiLoading,
  setCustApiLoading,
}) {
  const [siteScreenType, setSiteScreenType] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isSiteEdit, setIsSiteEdit] = useState(false);
  const [customerSiteList, setCustomerSiteList] = useState([]);
  const [siteListCount, setSiteListCount] = useState("blank");
  const [sitePagesList, setSitePagesList] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [editSiteId, setEditSiteId] = useState("");
  const [open, setOpen] = useState(true)
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const [siteCount, setSiteCount] = useState(0);
  const [triggerCustApi, setTriggerCustApi] = useState(false);
  const [localSiteList, setLocalSiteList] = useState([]);
  const [currentSiteFilter, setcurrentSiteFilter] = useState("A to Z");
  const [currentSitePage, setCurrentSitePage] = useState(1);
  const [rowsSitePerPage, setRowsSitePerPage] = useState(10);
  const [isSiteCreated, setIsSiteCreated] = useState(false);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  const [enteredText, setEnteredText] = useState("");
  const dispatch = useDispatch();
  const location = useLocation()
  const { from, selectedIdDatum } = location?.state || {};
  const [selectedCustId, setSelectedCustId] = useState(null);
  const hasRun = useRef(false);
  const { t } = useTranslation();
  const { gridAction } = t("mainContainer");
  function siteDetailsOutside(item) {
    setSelectedCustomerData(item);
    setCustomerId(item?.id);
    setSiteCount(item?.siteCount);
    setSiteScreenType(item?.siteCount > 0 ? "siteList" : "noSite");

  }
  useEffect(() => {
    if (!hasRun.current && from === "addSiteButton") {
      setSelectedCustId(selectedIdDatum?.id);
      if (selectedCustomerData?.id === selectedIdDatum?.id) {
        setSiteScreenType("addSite");
        setOpen(false)
        hasRun.current = true;
      }
    }
    else {
      setSiteScreenType(selectedCustomerData?.siteCount > 0 ? "siteList" : "noSite");
    }
  }, [from, selectedIdDatum, selectedCustomerData])

  const resetSiteList = (res) => {
    let result =
      res.payload?.data?.results?.map((res) => {
        const updatedStatus = res?.deviceStatus;
        const filteredActionMenu = gridAction?.filter((item, idx) => {
          return !(idx === 2 && updatedStatus !== "online");
        });
        return {
          ...res,
          actionMenu: filteredActionMenu,
          updatedDate: (
            <div>
              {res?.deviceStatus === "online" ? (
                <GreenCircleIcon />
              ) : res?.deviceStatus === "offline" ? (
                <RedCircle />
              ) : res?.deviceStatus === "disable" ? (
                <GreyCircle />
              ) : null}

              {res?.lastOnline && formatDate(res?.lastOnline)}
            </div>
          ),
        };
      }) ?? [];
    return result;
  };
  const requestSiteListApi = (custId) => {
    let requestParams = getRequestCustomerApi({
      limit: rowsSitePerPage,
      page: currentSitePage,
      sortBy: sortingKeys?.[currentSiteFilter],
    });
    if (custId) {
      dispatch(
        getAllSiteApi(
          ApiPayload(
            getSiteWithPaginationEndPoint(
              `${requestParams}&customerId=${custId}`
            )
          )
        )
      ).then((res) => {
        if (res?.payload?.status == "200") {
          setSiteListCount(
            res?.payload?.data?.totalResults == 0
              ? 'empty'
              : res?.payload?.data?.totalResults
          );
          // setSiteListCount(res?.payload?.data?.totalResults);
          setSitePagesList(res?.payload?.data?.totalPages || "");
          let resullt = resetSiteList(res);
          setCustomerSiteList(resullt);
          setSiteLength(resullt?.length);
        }
      });
    }
  };

  useEffect(() => {
    requestSiteListApi(customerId);
  }, [customerId, siteScreenType, t, setLocalSiteList]);

  useEffect(() => {
    if (selectedCustId) {
      requestSiteListApi(selectedCustId);
    }
  }, [selectedCustId]);

  useEffect(() => {
    // Effect to reset site list when language changes
    if (customerId && !["addSite", "editSite"].includes(siteScreenType)) {
    } else if (siteScreenType === "addSite") {
      setSiteId("");
      dispatch(updateSiteFormInitialState(siteStepKeys));
    }
  }, [customerId, siteScreenType, t]); // Added `t` as a dependency

  const handleCloseSmallModal = () => {
    setOpenSmallModal(false);
  };
  const smallModalChildren = () => {
    return (
      <Stack style={"flex"}>
        <div>Edit</div>
        <div>Disable</div>
        <div>Delete</div>
      </Stack>
    );
  };
  return (
    <Box
      style={{
        display: "flex",
        spacing: 2,
        height: "100%",
      }}
    >
      <CustomerList
        customerList={customerList}
        isEdit={isEdit}
        screenType={screenType}
        setScreenType={setScreenType}
        setIsEdit={setIsEdit}
        setEditableData={setEditableData}
        showAddCustomer={showAddCustomer}
        setShowAddCustomer={setShowAddCustomer}
        siteDetailsOutside={siteDetailsOutside}
        selectedIdDatum={selectedIdDatum}
        type={type}
        setSiteLength={setSiteLength}
        siteLength={siteLength}
        setShowEmptyPage={setShowEmptyPage}
        triggerCustApi={triggerCustApi}
        setTriggerCustApi={setTriggerCustApi}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        currentFilter={currentFilter}
        setcurrentFilter={setcurrentFilter}
        selectedCustId={selectedCustId}
        setSelectedCustId={setSelectedCustId}
        setSelectedCustomerData={setSelectedCustomerData}
        selectedCustomerData={selectedCustomerData}
        custApiLoading={custApiLoading}
        from={from}
        enteredText={enteredText}
        setEnteredText={setEnteredText}
        setSiteScreenType={setSiteScreenType}
      />
      {!showEmptyPage ? (
        ["addSite", "editSite"].includes(siteScreenType) ? (
          <SiteForm
            setSiteScreenType={setSiteScreenType}
            selectedCustomerData={selectedCustomerData}
            homeCustomer={selectedCustId}
            defaultCustomerId={customerId}
            isSiteEdit={isSiteEdit}
            setIsSiteEdit={setIsSiteEdit}
            siteId={siteId}
            siteScreenType={siteScreenType}
            editSiteId={editSiteId}
            setSiteId={setSiteId}
            setEditSiteId={setEditSiteId}
            setShowEmptyPage={setShowEmptyPage}
            setTriggerCustApi={setTriggerCustApi}
            setIsSiteCreated={setIsSiteCreated}
            isSiteCreated={isSiteCreated}
          />
        ) : (
          <SiteList
            localSiteList={localSiteList}
            setLocalSiteList={setLocalSiteList}
            currentPage={currentSitePage}
            setCurrentPage={setCurrentSitePage}
            rowsPerPage={rowsSitePerPage}
            custRowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsSitePerPage}
            currentSiteFilter={currentSiteFilter}
            setcurrentSiteFilter={setcurrentSiteFilter}
            setSiteScreenType={setSiteScreenType}
            selectedCustomerData={selectedCustomerData}
            customerId={customerId}
            siteLength={siteLength}
            customerList={customerList}
            customerSiteList={customerSiteList}
            siteListCount={siteListCount}
            sitePagesList={sitePagesList}
            setIsSiteEdit={setIsSiteEdit}
            setEditSiteId={setEditSiteId}
            siteScreenType={siteScreenType}
            editSiteId={editSiteId}
            setSiteId={setSiteId}
            setSiteCount={setSiteCount}
            siteCount={siteCount}
            setTriggerCustApi={setTriggerCustApi}
            setSiteLength={setSiteLength}
            currentCustFilter={currentFilter}
            currentCustPage={currentPage}
            setIsSiteCreated={setIsSiteCreated}
            setCustApiLoading={setCustApiLoading}
          />
        )
      ) : (
        <div style={{ width: "100%" }}>
          <NoSite
            screenType={screenType}
            showEmptyPage={showEmptyPage}
            customerName={selectedCustomerData?.displayName}

          />
        </div>
      )}
      <SmallReusableModal
        open={openSmallModal}
        dialogWidth="442px"
        dialogHeight="220px"
        handleClose={handleCloseSmallModal}
        divider={true}
      >
        {smallModalChildren()}
      </SmallReusableModal>
    </Box>
  );
}
export default CustomerAndSite;