import React from "react";
import { Navigate } from "react-router-dom";
import { NWC_LOGIN_URL } from "../common/constants/Constants";
const PrivateRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  // const accessExpire = localStorage.getItem("accessExpire");
  // const expirationTime = accessToken ? new Date(accessExpire) : null;
  // const isAuthenticated = accessToken && new Date(expirationTime) > new Date();
  return accessToken ? children : <Navigate to={NWC_LOGIN_URL} replace />;
};

export default PrivateRoute;
