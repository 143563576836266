import React, { useState, useEffect } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { DateRangePicker, Stack, CustomProvider } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import enUS from 'rsuite/locales/en_US';
import jaJP from 'rsuite/locales/ja_JP';
import { FaCalendar } from 'react-icons/fa';
import { addDays, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'; 
import './DateRangeCalendar.scss'; 
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { setDateRangeFilter } from '../../redux/slices/customerFilterSlice';

// Helper function to convert to UTC+9
const convertToUTCPlus9 = (date) => {
  const utcTime = new Date(date.toUTCString().slice(0, -3)); // Convert to UTC
  return new Date(utcTime.getTime() + 9 * 60 * 60 * 1000); // Add 9 hours
};

// Helper function to check if an object is not empty
const isNotEmpty = (obj) => Object.keys(obj).length > 0;

// Styled tooltip for displaying additional information
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="bottom-end" />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 138,
    height: 54,
  },
});

/**
 * DateRangeCalendar Component
 *
 * This component provides a date range selection interface using the rsuite DateRangePicker.
 * It supports predefined date ranges and allows users to select custom date ranges.
 */
const DateRangeCalendar = ({ 
  initialLoad,
  setInitialLoad,
  onDateRangeChange,  // Callback function to handle date range changes
  id
}) => {
  const now = new Date(); // Get current date
  const utcPlus9Now = convertToUTCPlus9(now); // Convert current date to UTC+9
  const { t } = useTranslation(); // Initialize translation hook
  const { shortCuts } = t('mainContainer').DateRangeSelection; // Get shortcut labels from translation

  const defaultLanguage = localStorage.getItem("i18nextLng") || "jp"; // Default language setting

  // Calculate predefined ranges adjusted for UTC+9
  const predefinedRanges = [
    { label: "Today", value: [startOfDay(utcPlus9Now), utcPlus9Now], placement: 'left' },
    { label: "Last 24 Hours", value: [convertToUTCPlus9(new Date(Date.now() - 24 * 60 * 60 * 1000)), utcPlus9Now], placement: 'left' },
    { label: "Yesterday", value: [startOfDay(addDays(utcPlus9Now, -1)), endOfDay(addDays(utcPlus9Now, -1))], placement: 'left' },
    { label: "This Week", value: [startOfWeek(utcPlus9Now, { weekStartsOn: 1 }), utcPlus9Now], placement: 'left' },
    { label: "Last Week", value: [startOfWeek(addDays(utcPlus9Now, -7), { weekStartsOn: 1 }), endOfWeek(addDays(utcPlus9Now, -7), { weekStartsOn: 1 })], placement: 'left' },
    { label: "This Month", value: [startOfMonth(utcPlus9Now), utcPlus9Now], placement: 'left' },
    { label: "Last Month", value: [startOfMonth(addDays(utcPlus9Now, -30)), endOfMonth(addDays(utcPlus9Now, -30))], placement: 'left' },
    { label: "This Year", value: [startOfYear(utcPlus9Now), utcPlus9Now], placement: 'left' },
    { label: "Last Year", value: [startOfYear(addDays(utcPlus9Now, -365)), endOfYear(addDays(utcPlus9Now, -365))], placement: 'left' },
    { label: "Cancel", value: [startOfDay(utcPlus9Now), utcPlus9Now], placement: 'bottom' }
  ];

  const [startDate, setStartDate] = useState(null); // State for storing start date
  const [isOpen, setIsOpen] = useState(false); // State for controlling the date picker visibility
  const [shortCurLabel, setShortCurLabel] = useState(predefinedRanges[0]); // Default to "Today" 
  const [language, setLanguage] = useState(); // State for language selection
  const locale = language === 'jp' ? jaJP : enUS; // Locale selection based on language
  const [value, setValue] = useState([startOfDay(utcPlus9Now), utcPlus9Now]); // State for selected date range
  const { DateRangeFilter } = useSelector((state) => state?.customer); // Access date range filter from Redux store

  const dispatch = useDispatch();

useEffect(() => {
  // alert("hut")
  setLanguage(defaultLanguage); // Set language on component mount
}, [defaultLanguage]);

useEffect(() => { 
  if(initialLoad){ 
    onDateRangeChange(shortCurLabel); // Set language on component mount
    setInitialLoad(false)
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [shortCurLabel, initialLoad]);

  // New useEffect to dispatch initial DateRangeFilter
  useEffect(() => {
    if (!DateRangeFilter || Object.keys(DateRangeFilter).length === 0 || initialLoad) {
      const initialFilter = {
        label: "Today",
        value: [startOfDay(utcPlus9Now), utcPlus9Now]
      };
      dispatch(setDateRangeFilter(initialFilter));
      setShortCurLabel(initialFilter);
      setValue(initialFilter.value);
    } else {
      setShortCurLabel(DateRangeFilter);
      setValue(DateRangeFilter.value);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad]);

  // Handle change in the selected date range
const handleRangeChange = (range) => {
  if (range[0] && range[1]) {
    setValue([range[0], range[1]]); // Update value state with the selected range

    // Check if the selected range matches any predefined range
    const isCustomRange = !predefinedRanges.some(predefined => 
      predefined.value[0].getTime() === range[0].getTime() && 
      predefined.value[1].getTime() === range[1].getTime()
    );

    if (isCustomRange) {
      setShortCurLabel({ label: "Custom", value: range });
    }
  }
};

  // Handle date selection 
const handleSelect = (date) => {
  if (date) {
    const selectedDate = startOfDay(date);

    // If startDate is not set, set it to the selected date
    if (!startDate) {
      setStartDate(selectedDate);
      setShortCurLabel({
        label: "Custom",
        value: [selectedDate, ''],
      });
    } else {
      // Check if the selected date is before or after the start date
      if (selectedDate < startDate) {
        setStartDate(selectedDate);

        const duration = Math.abs(startDate - selectedDate);
        const isValidRange = duration <= 366 * 24 * 60 * 60 * 1000; // Validate range for 366 days

        if (isValidRange) {
          const end = endOfDay(startDate);
          setValue([selectedDate, end]);
          setShortCurLabel({
            label: "Custom",
            value: [selectedDate, end],
          });
        } else {
          setValue(['', '']); // Reset if invalid
        }
      } else {
        const duration = Math.abs(selectedDate - startDate);
        
        // Check if the selected end date exceeds 366 days from the start date
        const maxEndDate = addDays(startDate, 366);

        if (selectedDate <= maxEndDate) {
          const end = endOfDay(selectedDate);
          setValue([startDate, end]);
          setShortCurLabel({
            label: "Custom",
            value: [startDate, end],
          });
        } else {
          // If the end date exceeds 366 days, reset the value
          setValue(['', '']);
        }
      }
      setStartDate(null); // Reset start date
    }
  }
};


  // Handle shortcut button clicks
  const handleShortcutClick = (shortcut) => {
    setShortCurLabel({
      label: shortcut.labelValue,
      value: shortcut.value,
    });
    setIsOpen(true); // Open the picker on shortcut click
    setValue(shortcut?.value);

    // Handle "Cancel" shortcut
    if (shortcut.labelValue === "Cancel") {
      if (isNotEmpty(DateRangeFilter)) {
        setIsOpen(false); // Close the picker
        setShortCurLabel({
          label: DateRangeFilter?.label,
          value: DateRangeFilter?.value,
        });
        setValue(DateRangeFilter?.value);
      } else {
        setIsOpen(false); // Close the picker
        setShortCurLabel({
          label: "Today",
          value: [startOfDay(utcPlus9Now), utcPlus9Now],
        });
        setValue([startOfDay(utcPlus9Now), utcPlus9Now]);
      }
    }
  };

  // Include the custom range in the list of ranges
  const rangesWithCustom = [
    ...predefinedRanges,
    {
      label: "Custom",
      value: [startOfDay(utcPlus9Now), utcPlus9Now],
      placement: 'left'
    }
  ];

  // Save the selected date range
  const onSaveHandler = () => {
    if (onDateRangeChange) {
      onDateRangeChange(shortCurLabel); // Call the provided callback
    }
  };

  // Handle closing the date picker
  const handleClose = (event) => {
    if (event == 0) { // If closed without selection
      if (isNotEmpty(DateRangeFilter)) {
        // Reset to existing filter
        setShortCurLabel({
          label: DateRangeFilter?.label,
          value: DateRangeFilter?.value,
        });
        setValue(DateRangeFilter?.value);
      } else {
        // Reset to default
        setShortCurLabel({
          label: "Today",
          value: [startOfDay(utcPlus9Now), utcPlus9Now],
        });
        setValue([startOfDay(utcPlus9Now), utcPlus9Now]);
      }
    }
    setIsOpen(false); // Close the date picker
  };

  const longText = `All data shown in Japan standard time zone.`; // Tooltip text
  
 // Do Not Delete Time Selection
  //  // Modify the class of the element with data-testid="picker-popup"
  //   useEffect(() => {
  //     const pickerPopup = document?.querySelector('[data-testid="picker-popup"]');
  //     // const timeDropdown = document?.querySelector('.rs-calendar-time-dropdown');
  //     if (pickerPopup) {
  //       if (shortCurLabel?.label === 'Custom') {
  //         pickerPopup.classList.add('custom-class'); // Add custom class
  //       } else {
  //         pickerPopup.classList.remove('custom-class'); // Remove custom class
  //       }

  //       // Check if the selected range is in the same month and year
  //       const isSameMonth = isSameMonthAndYear(value[0], value[1]);
  //       if (isSameMonth) {
  //         pickerPopup.classList.add('same-month-class'); // Add another class
  //         // if (timeDropdown) {
  //         //   timeDropdown.classList.remove('show'); // Remove the 'show' class
  //         // }
  //       } else {
  //         pickerPopup.classList.remove('same-month-class'); // Remove it
  //       }
  //     }
  //   }, [shortCurLabel, value]); // Runs whenever shortCurLabel or value changes

  //   // Inside your component
  // useEffect(() => {


  //     const handleStartClick = () => {
  //       // Find the time dropdown element
  //       const timeDropdown = document.querySelector('.rs-calendar[data-testid="calendar-start"]');

  //       if (timeDropdown) {
  //         // Remove the 'show' class if it exists
  //         timeDropdown.classList.remove('rs-calendar-time-view');
  //         console.log('Show class removed from time dropdown.');
  //       }
  //       // console.log('Time button clicked!');
  //       // Add any additional logic you want to perform on click here
  //     };
  //     const handleEndClick = () => {
  //       // Find the time dropdown element
  //       const timeDropdown = document.querySelector('.rs-calendar[data-testid="calendar-end"]');

  //       if (timeDropdown) {
  //         // Remove the 'show' class if it exists
  //         timeDropdown.classList.remove('rs-calendar-time-view');
  //         console.log('Show class removed from time dropdown.');
  //       }
  //       // console.log('Time button clicked!');
  //       // Add any additional logic you want to perform on click here
  //     };
  //     const observer = new MutationObserver(() => { 
  //       const timeEndButton = document.querySelector('.rs-calendar[data-testid="calendar-end"] .rs-calendar-header-title.rs-calendar-header-title-time');
  //        const timeStartButton = document.querySelector('.rs-calendar[data-testid="calendar-start"] .rs-calendar-header-title.rs-calendar-header-title-time');

  //       if (timeEndButton ) {

  //         timeEndButton.addEventListener('click', handleStartClick); 
  //         console.log('Time button found and event listener added.');

  //         // Stop observing once the button is found
  //         observer.disconnect();
  //       }
  //       if(timeStartButton){
  //           timeStartButton.addEventListener('click', handleEndClick); 
  //           // Stop observing once the button is found
  //         observer.disconnect();
  //       }
  //     });

  //     // Start observing the body for child node changes
  //     observer.observe(document.body, { childList: true, subtree: true });

  //     // Cleanup function to disconnect the observer when the component unmounts
  //     return () => {
  //       observer.disconnect();
  //     };
  //   }, []);

  //   // New variable to determine if the selected dates are in the same month and year
  // const isSameMonthAndYear = (start, end) => {
  //   return start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth();
  // };
  
  return (
    <React.Fragment>
      <label className="datepickerLabel" variant="text" htmlFor={id}>{shortCuts[shortCurLabel?.label?.replace(/\s+/g, '')] || 'Select Range'}</label>
      <Stack spacing={10} direction="column" alignItems="flex-end">
        <CustomProvider locale={{
          ...locale,
          DateRangePicker: { ...locale.DateRangePicker, ok: locale === jaJP ? '確認' : 'Apply' },
        }} >
          <DateRangePicker
            id={id}
            open={isOpen}
            character="   |   "
            value={value}
            placement="autoVerticalEnd"
            format="yyyy/MM/dd HH:mm"
            defaultValue={value} // Set the default value
            ranges={rangesWithCustom.map((range) => ({
              ...range,
              labelValue: range?.label,
              label: shortCuts[range?.label?.replace(/\s+/g, '')], // Ensure the label is correctly set
              className: shortCurLabel?.label === range.label ? 'active-shortcut' : ''
            }))}
            showHeader={false}
            isoWeek
            caretAs={FaCalendar}
            onChange={handleRangeChange} // Handle custom date selection
            onShortcutClick={(shortcut) => handleShortcutClick(shortcut)} // Handle shortcut selection
            onSelect={handleSelect}
            onOpen={() => setIsOpen(true)} // Open the picker on focus
            onClose={handleClose} // Close the picker on blur  
            onOk={onSaveHandler}
          />
        </CustomProvider>
      </Stack>
      <div className="tomeZone">
        UTC+9
        <CustomWidthTooltip title={longText}>
          <i className="infoIcon"><InfoIcon /></i>
        </CustomWidthTooltip>
      </div>
    </React.Fragment>
  );
};

export default DateRangeCalendar;
