import React from "react";
import { Box, InputLabel, TextField } from "@mui/material";

const CustomTextArea = ({
  label,
  id,
  name,
  placeholder,
  error,
  fullWidth = true,
  handleChange,
  helperText,
  value,
  width = "100%",
  color = "#F4F4F4",
  alignText = "left", // Default to left alignment
  minRows = 3,
  maxRows,
}) => {
  return (
    <Box sx={{ marginTop: "16px", width }}>
      {label && (
        <InputLabel htmlFor={id} sx={{ textAlign: alignText, marginBottom: 1 }}>
          {label}
        </InputLabel>
      )}
      <TextField
        multiline
        minRows={minRows}
        maxRows={maxRows}
        fullWidth={fullWidth}
        id={id}
        name={name}
        error={error}
        helperText={helperText}
        inputProps={{ maxLength: 300 }} // Restrict input to 300 characters
        sx={{
          width: width,
          "& .MuiInputBase-root": {
            backgroundColor: color ? color : "transparent",
            padding: "12.5px 14px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: error ? "#DA1E28" : "#8D8D8D",
              borderWidth: error ? "2px" : "1px",
            },
            "&:hover fieldset": {
              borderColor: error ? "#DA1E28" : "#8D8D8D",
              borderWidth: error ? "2px" : "1px",
            },
            "&.Mui-focused fieldset": {
              borderColor: error ? "#DA1E28" : "#0F62FE",
              borderWidth: error ? "2px" : "1px",
            },
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px white inset", // Override autofill background
            WebkitTextFillColor: "inherit", // Override autofill text color
          },
        }}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </Box>
  );
};

export default CustomTextArea;
