// breadcrumbStyles.js

// Define your style objects
export const leftNavStyles = {
    box : {
        borderRadius: "4px 0 0 4px",
        background: "#F4F4F4",  
        padding:"9px 16px",   
    },
    marker:{
        borderRadius: "0 4px 4px 0",
        padding: "13px 16px",
        marginRight:"auto",
        background: "#F4F4F4",
        height:"100%"
    }
  }; 