import { useState, useCallback } from 'react';
import axios from 'axios';

const useDelete = (initialUrl) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(false);
  
    // Ensure `token` is always up-to-date
    const token = localStorage.getItem('accessToken');
    
  const deleteRequest = useCallback(async (body) => {
    setLoading(true);
    setError(false);
    setData(false);

    try { 
      const response = await axios.delete(initialUrl, {
        method: 'DELETE',
        headers: {
               Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        data: body,
    });

      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [initialUrl,token]);

  return { loading, error, data, deleteRequest };
};

export default useDelete;
