import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import { BackendModule } from "i18next";
import en from "../src/locales/en/translation.json";
import jp from "../src/locales/jp/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
let language = localStorage.getItem("i18nextLng");
if (!language||language==="null") {
  language = "jp"; // If the key does not exist or is falsy, use "jp"
} // Default to 'jp' if no language is set in localStorage

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: language,
    fallbackLng: language,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
    resources: {
      zh: {
        translation: {
          greeting: "你好，欢迎",
        },
      },
      en: {
        translation: en,
      },
      jp: {
        translation: jp,
      },
    },
  });
