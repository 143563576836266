import React, { useState } from 'react';
import { Container, Tabs, Tab, Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import AlarmSummary from './AlarmSummaryTab/AlarmSummary';
import './AlarmHome.scss';
import AlarmLogs from './AlarmLogsTab/AlarmLogs';

function AlarmHome() {

  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");

  const [value, setValue] = useState(0);
  const [shouldFetch, setShouldFetch] = useState(true); // Add this line

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShouldFetch(false); // Prevent fetching when changing tabs
  };

  return   <Box className="flex-container alarmSummary">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs example"
            sx={{ borderBottom: 1, borderColor: 'divider', width: "100%"  }}
          >
            <Tab label={alarm.AlarmSummary} />
            <Tab label={alarm.Alarmlog} /> 
          </Tabs>
          <Box sx={{ p: 0 ,height:"100%",width:"100%"}}>
            {value === 0 &&   <AlarmSummary/> }
            {value === 1 &&  <AlarmLogs/> } 
          </Box>
        </Box> ;
}

export default AlarmHome;
