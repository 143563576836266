/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "../sidenav/Sidenav";
import CenterContent from "../center-contents/CenterContent";
import { Grid } from "@mui/material";
import "./LandingPages.scss";
import NoCustomerPage from "../login-components/NoCustomerPage";
import { ROLES } from "../../common/utils/Permissions";

function Main(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedUserData = JSON.parse(userData);
        if (parsedUserData && typeof parsedUserData === "object") {
          setUser(parsedUserData);
        } else {
          console.error("Invalid user data stored in localStorage.");
        }
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    }
  }, []);

   // Add conditional rendering logic
  if (user?.role === ROLES?.ORG_ADMIN && !user?.customerId) {
    return (
      <>
        <Header user={user} />
        <NoCustomerPage/>
      </>
    );
  }

  return (
    <>
      <Header user={user} />
      {
  (user?.role === ROLES?.SUPER_ADMIN && !user?.customerId) || 
  (user?.role === ROLES?.ORG_ADMIN && user?.customerId) 
  ? (
    <Grid container className="mainContentHeight">
      <Grid item lg={1.5} xs={3} xl={1.5} sm={2} md={2}>
        <Sidenav user={user} />
      </Grid>
      <Grid
        item
        lg={10.5}
        xl={10.5}
        xs={9}
        sm={10}
        md={10}
        sx={{
          height: "100%",
          padding: "0px",
          margin: "0px",
        }}
        className="centerContent"
      >
        <CenterContent type={props.type} user={user} />
      </Grid>
    </Grid>
  ) : null
}

    </>
  );
}

export default Main;
