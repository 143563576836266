import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ReactComponent as VisibilityIcon } from "../../../assets/images/passwordShowIcon.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/images/passwordHideIcon.svg";
function CustomPasswordField({
  label,
  value,
  handleChange,
  helperText,
  error,
  name,
  style = {},
  width,
  color = "#F4F4F4",
  placeholder,
  disabled = false,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <TextField
        label={label}
        type={showPassword ? "text" : "password"}
        value={value}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        style={style}
        disabled={disabled}
        sx={{
          width: width,
          "& .MuiInputBase-root": {
            height: "40px",
            backgroundColor: color ? color : "transparent",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: error ? "#DA1E28" : "#8D8D8D",
              borderWidth: error ? "2px" : "1px",
            },
            "&:hover fieldset": {
              borderColor: error ? "#DA1E28" : "#8D8D8D",
              borderWidth: error ? "2px" : "1px",
            },
            "&.Mui-focused fieldset": {
              borderColor: error ? "#DA1E28" : "#0F62FE",
              borderWidth: error ? "2px" : "1px",
            },
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px white inset", // Override autofill background
            WebkitTextFillColor: "inherit", // Override autofill text color
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default CustomPasswordField;
