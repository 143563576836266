import React from "react";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  NWC_DEFAULT_URL,
  NWC_HOME_URL,
  NWC_RESET_PASSWORD,
  NWC_LOGIN_URL,
  NWC_SETTING_URL,
  NWC_ALARM_URL,
  NWC_AUDIT_URL,
} from "./common/constants/Constants";
import LoginPage from "./components/login-components/LoginPage";
import Main from "./components/langing-page/Main";
import PrivateRoute from "./routes/PrivateRoute";
function App() {
  return (
    <>
      <Routes>
        <Route
          path={NWC_DEFAULT_URL}
          element={<Navigate to={NWC_HOME_URL} replace />}
        ></Route>
        <Route
          path={NWC_HOME_URL}
          element={
            <PrivateRoute path={NWC_HOME_URL} Component={<Main />}>
              <Main type="home" />
            </PrivateRoute>
          }
        />
        <Route
          path={NWC_SETTING_URL}
          element={
            <PrivateRoute path={NWC_SETTING_URL}>
              <Main type="setting" />
            </PrivateRoute>
          }
        />
        <Route
          path={NWC_AUDIT_URL}
          element={
            <PrivateRoute path={NWC_AUDIT_URL}>
              <Main type="audit" />
            </PrivateRoute>
          }
        />
        <Route
          path={NWC_ALARM_URL}
          element={
            <PrivateRoute>
              <Main type="alarm" />
            </PrivateRoute>
          }
        />
        <Route path={NWC_LOGIN_URL} element={<LoginPage />} />
        <Route path={NWC_RESET_PASSWORD} element={<LoginPage />} />
      </Routes>
    </>
  );
}

export default App;
