import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider, IconButton, Box
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme, useMediaQuery } from '@mui/material';
import Lottie from "lottie-react";
import { ReactComponent as BellIcon } from "../../assets/images/SmallAlarm.svg";
import { ReactComponent as ChartIcon } from "../../assets/images/ChartColumn.svg";
import { homeSiteGridHeaderStructure } from "../setting/siteDetails/siteDetails.Data";
import { formatUnit, getStateLabelForListing } from "../../common/utils/CommonUtils";
import "./LandingPages.scss";
import SiteGrid from "./SiteGrid";
import { calculateTotals } from "./MockApi";
import usePost from "../../common/hook/usePost";
import CustomModal from "../../common/commonPopup/CustomModal";
import { electricityConsumptionAnalyticsURL } from "../../services/apiConfig";
import spinner from "../../assets/animation/spinner.json";
import AmChartComponent from "./Chart";
import DateRangeCalendar from "../../common/DateRangePicker/DateRangeCalendar";
import { formatToCustomDateString } from "../../common/utils/CommonUtils";
import { convertDateTimeToTimestamp } from "../../common/utils/CommonUtils";
import { formatDate } from "../../common/utils/CommonUtils";


const formatValue = (value) => {
  if (value >= 1e9) {
    const formattedValue = (value / 1e9).toFixed(0);
    return `${formattedValue}B`;
  }
  if (value >= 1e6) {
    const formattedValue = (value / 1e6).toFixed(0);
    return `${formattedValue}M`;
  }
  // For values less than 1 million, show two decimal places only if necessary
  const formattedValue = value.toFixed(2);
  return Number(formattedValue) === Math.floor(value) ? value.toFixed(0) : formattedValue;
};

const PrefectureAccordion = ({
  title,
  data,
  expanded,
  onChange,
  landingSearchQuery
}) => {
  const [calculatedTotalData, setCalculatedTotalData] = useState({});
  const { t } = useTranslation();
  const {
    landingPage: { accordionHeader, cards },energyChart,
    DateRangeSelection,
  } = t("mainContainer");
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [presetLabel, setPresetLabel] = useState('Today');
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [isChartClicked, setIsChartClicked] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { recentSearchId } = useSelector((state) => state?.customer); // Accessing recentSearchId from the Redux store

  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost(); // Custom hook for handling API requests

  const useStyles = makeStyles({
    accordionOverride: {
      borderRadius: '8px !important', // Set the border radius and ensure it's important to override MUI default styles
      "&:first-of-type": {
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
      },
      "&:last-of-type": {
        borderBottomLeftRadius: '8px !important',
        borderBottomRightRadius: '8px !important',
      },
      "&.MuiPaper-root": {
        transition: 'border-color 0.3s ease',
      },
      border: (!expanded && landingSearchQuery?.length > 0) ? "2px solid #0F62FE" : "1px solid var(--Light- Border - border - subtle-01, #c6c6c6)"
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const totalData = calculateTotals(data);
    setCalculatedTotalData(totalData);
  }, [data]);

  const transformApiResponse = (data) => {
    return data?.map((item) => ({
      ...item,
      state: getStateLabelForListing(item?.state),
    }));
  };

  const transformedData = transformApiResponse(data);

  const handleIconClick = (event, iconType) => {
    event.stopPropagation();
    // console.log(`${iconType} clicked`);

    if (iconType === 'Chart') {
      // Group sites by siteID
      const groupedSites = data.reduce((acc, site) => {
        if (!acc[site.siteID]) {
          acc[site.siteID] = site;
        }
        return acc;
      }, {});

      // Convert the grouped sites object to an array of siteIDs
      const selectedSiteIdsArray = Object.values(groupedSites).map(site => site.state);

      // Log the full site data for the clicked chart icon
      // console.log('Clicked sites data:', Object.values(groupedSites));

      setSelectedSiteIds(selectedSiteIdsArray);
      setIsChartClicked(true);
      setOpenModal(true);
      setPresetLabel('Today'); // Reset presetLabel to 'Today'
    }
    if (iconType === 'Bell') {
      navigate('/alarm', { state: { state: data[0].state } }); // Indicate the source of navigation
    }
  };




  /**
   * Closes the modal dialog.
   */
  const handleClose = () => {
    setOpenModal(false);
  };

  /**
   * Handle changes in the date range picker.
   * 
   * @param {Array} dateRange - The selected date range from the DateRangeCalendar component.
   */
  const handleDateRangeChange = (dateRange) => {
    const start = dateRange.value[0];
    const end = dateRange.value[1];

    setStartDate(start);
    setEndDate(end);

    const [dateStartString, dateEndString] = dateRange.value;

    const addOffsetToTimestamp = (dateString) => {
      const dateObject = new Date(dateString);
      const gmtTimestamp = dateObject.getTime();
      const offsetInMilliseconds = (5 * 60 * 60 * 1000) + (30 * 60 * 1000); // +05:30 hours
      return gmtTimestamp + offsetInMilliseconds;
    };

     // To convert a date string from the format YYYY/MM/DD HH:mm 
   const customDateStartString = formatToCustomDateString(formatDate(dateStartString)); 
   const customDateEndString = formatToCustomDateString(formatDate(dateEndString));  

    const newStartTimestamp = addOffsetToTimestamp(dateStartString);
    const newEndTimestamp = addOffsetToTimestamp(dateEndString);

    setStartDate(start);
    setEndDate(end);
    setPresetLabel(dateRange.label);
  
    // Calculate the difference in milliseconds
    const diffInMs = newEndTimestamp - newStartTimestamp;

    // Convert milliseconds to days
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // Determine the interval based on the difference
    let interval;
    if (diffInDays <= 1) { // 24 hours or less
      interval = "1h";
    } else if (diffInDays > 1 && diffInDays < 31) { // More than 24 hours and less than 31 days
      interval = "1d";
    } else if (diffInDays >= 31 && diffInDays < 365) { // More than 31 days and less than 12 months
      interval = "1mo";
    } else { // More than 12 months
      interval = "1y"; // Adjust this if you have a different interval for years
    }

    const requestPayload = {
      searchId: recentSearchId,
      startDate: convertDateTimeToTimestamp(customDateStartString),
      endDate: convertDateTimeToTimestamp(customDateEndString),
      interval: interval // Use the calculated interval
    };

    doPostPrefectureApi(electricityConsumptionAnalyticsURL, requestPayload); // Send the date range and interval to the API
  };


  // Filter and group the selected sites from prefectureApiData
  const selectedSitesData = isChartClicked && prefectureApiData?.result?.sites
    ? prefectureApiData.result.sites.filter(site => selectedSiteIds.includes(site.state))
    : [];

  // Calculate the sum of electricity from selectedSitesData
  const selectedSitesElectricitySum = selectedSitesData.reduce((sum, site) => sum + (site.electricity || 0), 0);

  return (
    <>
      <Accordion
        className={`accordionBox ${classes.accordionOverride}`}
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary
          className={`prefectureAccSum ${expanded ? 'expanded' : 'collapsed'}`}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="accordionSumChild">
            <div className="sumInnerChild">
              <Typography className="stateName">
                {title?.split(" ")[2]}
              </Typography>
              <Typography className="accordionSubHeaderStyle">
                {accordionHeader[0]}
                {calculatedTotalData?.totalSiteCount}
              </Typography>
            </div>
            <div className="sumInnerChild" >
              <div>
                <span className="prefectAlarmText">
                  <Typography className="accordionHeaderStyle">
                    {calculatedTotalData?.totalElectricity !== 0 ? calculatedTotalData?.totalElectricity : "—"}
                    {calculatedTotalData?.totalElectricity !== 0 &&
                      <>
                        <span className="mElectricity">
                          {formatUnit(calculatedTotalData?.totalElectricity)}
                        </span>
                        <span className="kwhTypo">
                          kWh
                        </span>
                      </>

                    }
                  </Typography>
                  <Typography className="accordionSubHeaderStyle">
                    {accordionHeader[1]}
                  </Typography>
                </span>
              </div>
            </div>
            <div className="sumInnerChild iconPosition">
              <div className="icon-hover">
                <IconButton size="small" onClick={(e) => handleIconClick(e, 'Chart')}>
                  <ChartIcon />
                </IconButton>
                <IconButton size="small" onClick={(e) => handleIconClick(e, 'Bell')}>
                  <BellIcon />
                </IconButton>
              </div>
              <div className="alarmContainer">
                <span
                  className="prefectAlarmText" >
                  <Typography className="accordionHeaderStyle">
                    {calculatedTotalData?.totalAlarmCount !== 0 ? calculatedTotalData?.totalAlarmCount : '—'}
                  </Typography>
                  <Typography className="accordionSubHeaderStyle">
                    {accordionHeader[2]}
                  </Typography>
                </span>
              </div>

            </div>
          </div>
        </AccordionSummary >
        <Divider />
        <AccordionDetails
          sx={{
            "&.MuiAccordionDetails-root": {
              padding: "16px",
            },
          }}
        >
          <SiteGrid
            siteLength={data?.length}
            headerStructure={homeSiteGridHeaderStructure}
            tableData={transformedData}
            actionRequest={(action, rowVal) => {
              // console.log(action, rowVal);
            }}
            sortOptions={["asc", "desc"]}
            tooltipColumns={[0, 1]}
            handleTableSorting={(order) => {
              // console.log(order);
            }}
            siteListCount={data?.length}
          />
        </AccordionDetails>
      </Accordion >
      <CustomModal
        open={openModal}
        onClose={handleClose}
        title={title?.split(" ")[2]}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl"
      >
        <Box className="chart-info" sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <Box sx={{
            display: "inline-flex",
          }}>
            <div className="chartInfo">
              <span className="sourceType">{energyChart?.Electricity} </span>
              {selectedSitesElectricitySum === 0 ? (
                <>
                  <span className="EnergyValue">{" — "}</span>
                  <span className="energyFormat"> kWh</span>
                  {" / "}
                </>
              ) : (
                <>
                  <span className="EnergyValue">{formatValue(selectedSitesElectricitySum)}</span>
                  <span className="energyFormat"> kWh</span>
                  {" / "}
                  <span className="comparisonLabel">{DateRangeSelection?.shortCuts[presetLabel]} vs. {energyChart?.PreviousPeriod}</span>
                </>
              )}
            </div>
            <div className="DateRangeCalender">
              <DateRangeCalendar
                initialLoad={initialLoad}
                setInitialLoad={setInitialLoad}
                onDateRangeChange={handleDateRangeChange}  // Callback for date range changes
                id="CharttopNavDatePicker"
              />
            </div>
          </Box>
          {!prefectureApiData && !prefectureApiError && prefectureApiLoading && (
            <div className="loading spinner-wrapper blue-spinner">
              <Lottie className="spinner" animationData={spinner} autoplay loop />
            </div>
          )}
          {prefectureApiData && !prefectureApiError && !prefectureApiLoading && (
            selectedSitesData.length === 0 || prefectureApiData?.result?.sites?.length === 0 ? (
              <div className="no-data-message">{energyChart.noDataText}</div>
            ) : (
              <AmChartComponent
                startDate={startDate}
                endDate={endDate}
                presetLabel={presetLabel}
                electricityData={selectedSitesData}
                customerName={prefectureApiData?.result?.customerName}
                descriptionName={title?.split(" ")[2]}
              />
            )
          )}
        </Box>
      </CustomModal>
    </>
  );
}

export default PrefectureAccordion;