import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

const CustomCheckboxGroup = ({
	label,
	options,
	value,
	onChange,
	error,
	sx,
}) => {
	return (
		<FormControl error={error} variant="standard">
			<FormLabel align="left">{label}</FormLabel>
			<FormGroup row error={error}>
				{options.map(option => (
					<FormControlLabel
						key={option.value}
						control={
							<Checkbox
								checked={value.includes(option.value)}
								onChange={onChange}
								value={option.value}
								sx={sx}
							/>
						}
						label={option.label}
					/>
				))}
			</FormGroup>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
};

export default CustomCheckboxGroup;
