import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Popover, Typography, IconButton, TextField, Checkbox, Button, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from "@mui/icons-material/Error";
import { ReactComponent as FavStar } from "../../../../assets/images/FavStar.svg";
import { ReactComponent as FavBlueStar } from "../../../../assets/images/FavBlueStar.svg";
import { prefectures } from '../../../setting/CustomerData';
import { saveFaorite } from '../../../../services/apiConfig';
import { ReactComponent as DownButtonArrow } from "../../../../assets/images/downButtonArrow.svg";
import usePatch from '../../../../common/hook/usePatch';
import './AddFavoriteDropdown.scss'
import { ReactComponent as CloseIcon } from "../../../../assets/images/CloseIcon.svg";
import useFetch from '../../../../common/hook/useFetch';
import { getSavedFavorite } from '../../../../services/apiConfig';
import { setMyFavoriteLists, setDefaultDelete, setSelectedCategory, setUpdateBeadcrumbs, setSelectedAreas, setSelectedSites } from '../../../../redux/slices/customerFilterSlice';
import { Visibility } from '@mui/icons-material';
import { getRecentSearch } from '../../../../services/apiConfig';
import { setIsDefaultFavorite } from '../../../../redux/slices/customerFilterSlice';

const AddFavoriteDropdown = ({ breadcrumbData, handleClick, customerPopoverisOpen, actionType }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [favouriteName, setFavouriteName] = useState('');
    const [homeChecked, setHomeChecked] = useState(false);
    const [alarmChecked, setAlarmChecked] = useState(false);
    const [defaultChecked, setDefaultChecked] = useState(false);
    const [isSaved, setIsSaved] = useState(false); // State to track save success
    const [defaultFavoriteStatus, setDefaultFavoriteStatus] = useState(false);
    const [isMatching, setIsMatching] = useState(true);


    const [errors, setErrors] = useState({
        favouriteName: '',
        favouriteType: '',
    });

    const dispatch = useDispatch();
    const { loading, error, data, patch } = usePatch(saveFaorite);

    const { myFavoriteLists, selectedCategory, selectedSites, isDefaultDeleted, myRecentSearchList, isDefaultFavorite } = useSelector(state => state.customer);
    const userId = localStorage.getItem("userId");

    // Language Parameter
    const { t } = useTranslation();
    const { customerTopNavigation } = t("mainContainer");
    const { favoriteLabel, addFavNameError, addFavSelectionError, favNameDupErrorMessage, addFav, favoriteName, favCheckBoxes, actions } = customerTopNavigation;

    const { recentSearchId } = useSelector(
        (state) => state?.customer
    );

    const { data: savedFavorite,
        error: savedFavoriteError,
        loading: savedFavoriteLoading,
        fetchData: getAllSavedFavorite } = useFetch();

    useEffect(() => {
        if (!loading && !error && data) {
            getAllSavedFavorite(getSavedFavorite(userId))
            handleClose();  // Close the popover if save is successful
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data])

    useEffect(() => {
        if (savedFavorite && !savedFavoriteLoading && !savedFavoriteError) {
            dispatch(setMyFavoriteLists(savedFavorite));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFavorite, savedFavoriteLoading, savedFavoriteError])


    useEffect(() => {

        if (!loading && !error && data && data?.result) {
            if (data?.defaultFavorite) {

                dispatch(setSelectedCategory({
                    id: data.result.customerId,
                    name: data.result.customerName,
                    displayName: data.result.customerName,
                    favouriteName: data.result.favouriteName
                }));
                dispatch(setUpdateBeadcrumbs(true));
            } else {
                dispatch(setSelectedCategory({
                    id: data?.result.customerId,
                    name: data.result.customerName,
                    displayName: data.result.customerName,
                    favouriteName: data.result.favouriteName
                }));
                dispatch(setUpdateBeadcrumbs(true));
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error])

    useEffect(() => {
        if (!loading && error && !data) {
            setErrors(prevErrors => ({
                ...prevErrors,
                favouriteName: favNameDupErrorMessage
            }));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data])

    useEffect(() => {

        // To check if there is any item with defaultFavourite true
        const hasDefaultFavorite = myFavoriteLists?.some(item => item.defaultFavourite) || false;
        if (hasDefaultFavorite) {
            // Check if selectedCategory is the default favorite whenever selectedCategory changes
            const defaultFavorite = myFavoriteLists?.find(item => item.defaultFavourite);
            setDefaultFavoriteStatus(hasDefaultFavorite ? defaultFavorite.customerId === selectedCategory?.id : false);
        } else {

            setDefaultFavoriteStatus(false);
        }


    }, [selectedCategory, myFavoriteLists]); // Re-run when selectedCategory or myFavoriteLists changes





    useEffect(() => {
        // Check if selectedCategory is the default favorite whenever selectedCategory changes
        const defaultFavorite = myFavoriteLists?.find(item => item.defaultFavourite)?.siteList;
        if (myFavoriteLists?.length == 0) {
            dispatch(setIsDefaultFavorite(false));
        }
        const hasDefaultFavorite = myFavoriteLists?.some(item => item.defaultFavourite) || false;
        if (defaultFavorite !== undefined&&!isDefaultDeleted && hasDefaultFavorite) {
            const compareLists = (savedList, NewList) => {
                // Create sets of IDs from both lists
                const savedIds = new Set(savedList.map(item => item.siteId));
                const newIds = new Set(NewList.map(item => item.id));

                // Check if both sets have the same size
                if (savedIds.size !== newIds.size) {
                    return false;
                }

                // Check if every ID in NewList exists in savedIds
                for (let id of newIds) {
                    if (!savedIds.has(id)) {
                        return false;
                    }
                }

                return true;
            };

            // Perform the comparison whenever savedList or NewList changes
            const result = compareLists(defaultFavorite, selectedSites);

            dispatch(setIsDefaultFavorite(result));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myFavoriteLists]); // Dependency array to trigger useEffect when these change

    const handleClickAddFav = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // Reset form values and errors
        setFavouriteName('');
        setHomeChecked(false);
        setAlarmChecked(false);
        setDefaultChecked(false);
        setErrors({
            favouriteName: '',
            favouriteType: '',
        });
    };


    // Validate form fields
    const validate = () => {
        const newErrors = {
            favouriteName: errors?.favouriteName ? errors?.favouriteName : '',
            favouriteType: '',
        };

        if (!favouriteName.trim()) {
            newErrors.favouriteName = addFavNameError;
        }

        if (!homeChecked && !alarmChecked) {
            newErrors.favouriteType = addFavSelectionError;
        }

        setErrors(newErrors);
        return !newErrors.favouriteName && !newErrors.favouriteType;
    };

    // Handle Save button click
    const handleSave = async () => {
        if (validate()) {
            const { selectedCategory, selectedSites } = breadcrumbData;
            const userId = localStorage.getItem("userId");

            const siteList = selectedSites?.map(site => ({
                siteId: site.id,
                state: prefectures?.find(pref => pref?.label === site?.state)?.value,
                siteName: site.name
            }));

            const payload = {
                id: recentSearchId,
                userId,
                customerId: selectedCategory?.id,
                customerName: selectedCategory?.displayName,
                favouriteName,  // Use the favouriteName state here
                siteList,
                favouriteType: [
                    homeChecked ? 'home' : null,
                    alarmChecked ? 'alarm' : null
                ].filter(Boolean),
                defaultFavourite: defaultChecked,
                isFavourite: true,
                lastUsed: new Date().getTime()
            };
            try {
                await patch(payload);
                setIsSaved(true); // Set success state 

            } catch (err) {
                console.error('Save failed:', err);

                setIsSaved(false); // Optionally handle error state
            }
        }
    };



    // Handle change for favouriteName input
    const handleFavouriteNameChange = (e) => {
        const newValue = e.target.value;

        // Reset the error for favouriteName
        setErrors(prevErrors => ({
            ...prevErrors,
            favouriteName: ''
        }));

        // Update the state with the new value
        setFavouriteName(newValue);

        // // Perform validation after resetting the error
        // validate(newValue);

    };

    // Handle change for home checkbox
    const handleHomeChange = (e) => {
        setHomeChecked(e.target.checked);
        if (errors.favouriteType) {
            validate(); // Validate immediately when the home checkbox changes
        }
    };

    // Handle change for alarm checkbox
    const handleAlarmChange = (e) => {
        setAlarmChecked(e.target.checked);
        if (errors.favouriteType) {
            validate(); // Validate immediately when the alarm checkbox changes
        }
    };

    // Effect to validate when state changes, but only if there are existing errors
    useEffect(() => {
        if (errors.favouriteType || errors.favouriteName) {
            validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favouriteName, homeChecked, alarmChecked]); // Validate when checkboxes change



    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // const defaultSelection = myFavoriteLists?.find((item)=>item.defaultFavourite)


    const shouldHideFavorites = !customerPopoverisOpen && myFavoriteLists?.length === 0;
    const isActionByCustomer = myFavoriteLists?.length === 0 && actionType === "actionbyCustomer";

    const className = `${shouldHideFavorites || isActionByCustomer ? 'hidefav' : ''} ${actionType} FavoriteActions`;

    return (
        <div className={className}>

            {isDefaultFavorite ? (
                <IconButton aria-label="delete" size="small" onClick={handleClick}>
                    <FavBlueStar fontSize="inherit" />
                </IconButton>
            ) : (
                <IconButton aria-label="delete" size="small" onClick={handleClickAddFav}>
                    <FavStar fontSize="inherit" />
                </IconButton>
            )}


            <Button size="small" className="favoriteButton" aria-describedby={id} variant="outlined" onClick={handleClick}>
                {/* {defaultSelection?.favouriteName} */}
                {favoriteLabel}  <span className="length">{myFavoriteLists?.length}</span>
                <DownButtonArrow />
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="favoriteDialog">

                    {/* Header with title and close icon */}
                    <div className="dialog-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6">{addFav}</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider />

                    {/* Content */}
                    <div className="content">
                        <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                                marginBottom: "8px",
                            }}
                            className="globalInputLabelStyle"
                        >
                            {favoriteName}
                        </InputLabel>
                        <TextField
                            fullWidth
                            value={favouriteName}
                            onChange={handleFavouriteNameChange}
                            InputProps={{
                                endAdornment: Boolean(errors.favouriteName) && (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="error">
                                            <ErrorIcon color="error" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(errors.favouriteName)}
                            helperText={errors.favouriteName}
                            placeholder={favoriteName}
                        />
                        <div className="checkbox-group" style={{ marginTop: '12px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={homeChecked}
                                        onChange={handleHomeChange}
                                    />
                                }
                                label={favCheckBoxes["home"]}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={alarmChecked}
                                        onChange={handleAlarmChange}
                                    />
                                }
                                label={favCheckBoxes["alarm"]}
                            />
                            {errors.favouriteType && (
                                <FormHelperText error>{errors.favouriteType}</FormHelperText>
                            )}
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size='small'
                                    checked={defaultChecked}
                                    onChange={(e) => setDefaultChecked(e.target.checked)}
                                />
                            }
                            label={favCheckBoxes["default"]}
                        />
                        <Button variant="contained" className="save-btn" color="primary" onClick={handleSave}>
                            {actions["save"]}
                        </Button>
                    </div>
                </div>
            </Popover>

        </div>)
}
export default AddFavoriteDropdown;
