import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserGrid from './UserGrid';
import { Box, Typography, Divider, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import UserCreation from './UserCreation';
import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon.svg";
import "./userManagement.scss";
import { updateUserManagementFirstRendered } from '../../../redux/slices/customerFilterSlice';
function UserManagement() {
    const { t } = useTranslation();
    const { userManagementContent } = t("mainContainer").settingsPageContent;
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const [headerText, setHeaderText] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch()

    const handleOpenUserCreation = () => {
        setIsCreatingUser(true);
        setHeaderText(1);
        setSelectedUser(null);
    };

    const handleCloseUserCreation = () => {
        setIsCreatingUser(false);
        setHeaderText(0);
    };

    const handleEditUser = (userData) => {
        setIsCreatingUser(true);
        setHeaderText(2);
        setSelectedUser(userData);
    };
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(1);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        // make the value true when component mounts
        dispatch(updateUserManagementFirstRendered(false));
        return () => {
            // make the value false when component unmounts
            dispatch(updateUserManagementFirstRendered(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box className="userManagement container">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className="header">
                    {
                        headerText === 0 ? userManagementContent.userHeader :
                            headerText === 1 ? userManagementContent.addUser :
                                headerText === 2 ? userManagementContent.editUser : ''
                    }
                </Typography>
                {/* Only show close icon when creating or editing a user */}
                {isCreatingUser && (
                    <IconButton aria-label="close" className="closeIconBtn" onClick={() => setOpenModal(true)}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>
            <Divider className="dividerStyle" />
            {!isCreatingUser ? (
                <>
                    <Box className="gridBox">
                        <UserGrid
                            onAddUser={handleOpenUserCreation}
                            onEditUser={handleEditUser}
                            onPageSizeChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                            page={page}
                            setPage={setPage}
                        />
                    </Box>
                </>
            ) : <UserCreation onClose={handleCloseUserCreation} openModal={openModal} setOpenModal={setOpenModal} userData={selectedUser} />}
        </Box>
    );
}

export default UserManagement;