import React, { useState, useEffect, useRef } from "react";
import { Divider, Box, Typography } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../../assets/images/smallCloseIcon.svg";
function FileUploadComponent({
  onFileChange,
  error,
  initialValue = null,
  text,
  statusIcon,
  helperText,
  fieldName,
  handleIconClick,
  fileName,
  setFileName,
  siteScreenType,
  editSiteId
}) {
  const [showStatusBox, setShowStatusBox] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // State to manage drag state
  const dragImageRef = useRef(null);
  useEffect(() => {
    if (initialValue) {
      setFileName(initialValue);
      setShowStatusBox(true);
    } else {
      setFileName("");
    }
  }, [initialValue, setFileName,siteScreenType]);
useEffect(()=>{
if(editSiteId && initialValue){
  setShowStatusBox(true)
}
}, [initialValue,editSiteId])
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileChange(e); // Pass the event to the parent component
      // Reset the input value to allow the same file to be selected again
      e.target.value = "";
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false); // Reset drag state
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      onFileChange({ target: { files: [file], name: fieldName } }); // Simulate the change event
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true); // Set drag state
  };

  const handleDragLeave = () => {
    setIsDragging(false); // Reset drag state when leaving the drop zone
  };

  const handleDragStart = (e) => {
    const file = e.dataTransfer.files[0];
    if (file) {
      const img = dragImageRef.current;
      e.dataTransfer.setDragImage(img, 10, 10);
      img.textContent = file.name;
      img.style.display = "block"; // Ensure the custom drag image is displayed
    }
  };

  const spanStyle = {
    color: "#0f62fe",
    fontSize: "14px",
  };

  const handleHideErrorBox = () => {
    setShowStatusBox(false);
    setFileName("");
  };

  return (
    <div>
      <Box
        sx={{
          border: `2px ${isDragging ? "solid #0F62FE" : "dotted grey"}`,
          borderRadius: "5px",
          padding: "16px",
          height: "45px",
          display: "flex",
          position: "relative",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <label
          htmlFor={`file-upload-${fieldName}`}
          style={{ cursor: "pointer" }}
        >
          {fileName && !editSiteId ? (
            <Typography sx={spanStyle}>{fileName}</Typography>
          ) : (
            <Typography sx={spanStyle}>{text}</Typography>
          )}
        </label>
        <input
          type="file"
          id={`file-upload-${fieldName}`}
          name={fieldName}
          // accept=".jpg, .jpeg, .png, .webp"
          onChange={handleChange}
          onDragStart={handleDragStart}
          style={{ display: "none" }} // Hide the default file input
          error={error}
          helperText={helperText}
        />
      </Box>
      <div
        ref={dragImageRef}
        className="fileUploadInnerBox"
      ></div>
      {showStatusBox && (
        <div className={`statusBox ${error ? "errorStyle" : ""}`}>
          {initialValue && (
            <div className="backgroundLightGrey">
              <span className="initialVal">{fileName}</span>
              <div>
                <span className="status-icon" onClick={handleIconClick}>
                  {statusIcon}
                </span>
                {error && (
                  <span className="cross-icon" onClick={handleHideErrorBox}>
                    <CrossIcon />
                  </span>
                )}
              </div>
            </div>
          )}
          {error && (
            <div className="fileError">
              <Divider orientation="horizontal" className="errorDivider" />
              <div className="fileName">{helperText}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FileUploadComponent;
