import React, { useState, useEffect } from "react";
import {
  ListItemIcon,
  SvgIcon,
  ListItemButton,
  ListItem,
  Divider,
  List,
  Drawer,
  Box,
  Link,
} from "@mui/material";
import { useAuth } from "../../common/utils/AuthContext";
import { SidenavContent } from "./SidenavContent";
import { useNavigate, useLocation } from "react-router-dom";
import { NWC_LOGIN_URL } from "../../common/constants/Constants";
import { useDispatch } from "react-redux";
import { updateLoader, logoutApi, updateEdgeDeviceToken } from "../../redux/slices/loginInfoSlice";
import { logoutEndPoint, edgeDeviceUrl } from "../../services/apiConfig";
import "../langing-page/LandingPages.scss";
import { useTranslation } from "react-i18next";
import { setRecentSearchId } from "../../redux/slices/customerFilterSlice"; 
import { PermissionGuard } from "../../common/utils/PermissionGuard";
import { RESOURCES, ACTIONS } from "../../common/utils/Permissions";
import { resetCustomerState } from "../../redux/slices/customerSlice";
import { resetCustomerFilterState } from "../../redux/slices/customerFilterSlice";

export default function Sidenav() {
  const drawerWidth = 180;
  const [menuData, setMenuData] = useState(SidenavContent);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const edgeDeviceLogoutUrl = `${edgeDeviceUrl}auth/logout`;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sideNav } = t("mainContainer"); 
  // const userData = JSON.parse(localStorage.getItem("userData"));

  const { user } = useAuth();

  // Set selected index based on current path
  useEffect(() => {
    const currentPath = location?.pathname;
    const foundIndex = menuData?.findIndex(
      (item) => item?.path === currentPath
    );
    if (foundIndex !== -1) {
      setSelectedIndex(foundIndex);
    }
  }, [location?.pathname, menuData]);

  const handleSignout = () => {
    const token = localStorage.getItem("refreshToken");
    const payload = { refreshToken: token };
    const url = logoutEndPoint;
    const apiPayload = { url, payload };
    // Parse the data from window.name, or initialize an empty object if not set
    let tabData = JSON.parse(window.name || '{}');
    tabData.emsTabUrl = edgeDeviceLogoutUrl; // Set the logout URL
    window.name = JSON.stringify(tabData);
    // Ensure emsTabReferences exists and is an array before proceeding
    if (Array.isArray(window?.emsTabReferences) && window?.emsTabReferences?.length > 0) {
      // Loop through and close each open tab
      window?.emsTabReferences?.forEach(({ tab }) => {
        if (tab && !tab?.closed) {
          tab.location.href = edgeDeviceLogoutUrl;
          // Delay closing the tab slightly to ensure the logout URL loads
          setTimeout(() => {
            tab.close();
          }, 7000);
        }
      });
      // Clear the array after closing all tabs
      window.emsTabReferences = [];
    } else {
      console.log("No EMS tabs are currently open.");
    }
    dispatch(logoutApi(apiPayload)).then((res) => {
      if (res?.payload?.status == "204") {
        dispatch(updateEdgeDeviceToken(""))
        dispatch(resetCustomerState());  
        dispatch(resetCustomerFilterState());  
        const lang = localStorage.getItem("i18nextLng");
        dispatch(updateLoader("loading"));
        localStorage.clear();
        navigate(NWC_LOGIN_URL, { state: { from: location?.pathname } });
        localStorage.setItem("i18nextLng", lang);
        dispatch(setRecentSearchId(""));
      }
    });
  };

  const handleNavigate = (item, index) => {
    setSelectedIndex(index);
    if (item?.path === "/login") {
      handleSignout();
    } else {
      navigate(item?.path, { state: { from: "sideNav" } });
    }
  };
  const listItemStyles = {
    width: "100%",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#0353E9",
      boxShadow: "none",
      width: "100%",
      color: "white",
      borderRadius: "4px",
      "& .listIcon .svgColor": {
        // stroke: "#ffffff",
        filter: "invert(1)",
      },
    },
    "&.Mui-selected": {
      width: "100%",
      backgroundColor: "#0F62FE",
      color: "white",
      borderRadius: "4px",
      "& .listIcon .svgColor": {
        // stroke: "#ffffff",
        filter: "invert(1)",
      },
    },
  };
  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          // height: "calc(100vh - 48px)",
          height: "100%",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            overflow: "hidden",
            position: "relative",
          },
        }}
      >
        <Box>
          <List className="sidenavListPadding">
            {menuData?.map((item, index) => (
              <div key={index}>
                {index === menuData?.length - 1 && (
                  <Divider sx={{ margin: "16px 0px" }} />
                )} <PermissionGuard 
                userRole={user?.role} //"Organization administrator"   //{userRole}
                resource={item.resource}
                action={ACTIONS.VIEW}
              >   
                  <ListItem
                    sx={listItemStyles}

                    disablePadding
                    selected={selectedIndex === index}
                    onClick={() => handleNavigate(item, index)}
                    className="listItems"
                  >
                    <ListItemButton sx={{ padding: "10px" }}>
                      <ListItemIcon className="listIcon">
                        <SvgIcon className="svgColor">{item?.icon}</SvgIcon>
                      </ListItemIcon>
                      <Link
                        marginTop={0.5}
                        marginLeft={"10px"}
                        color="inherit"
                        className="noTextDecoration"
                        fontSize={"14px"}
                        fontWeight={"400"}
                      >
                        {sideNav[index]}
                        {/* {item?.name} */}
                      </Link>
                    </ListItemButton>
                  </ListItem> 
                </PermissionGuard>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}