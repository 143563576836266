import React, { useRef, useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Select,
    InputAdornment,
    MenuItem,
    ListItemText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/system";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchIcon.svg";
import { ReactComponent as downArrow } from "../../assets/images/smallArrowDown.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import { ReactComponent as Check } from "../../assets/images/blackCheckIcon.svg";
import { isAlphaNumeric } from '../../common/utils/CommonUtils';
import { customerTabStyles } from '../top-common-navigation/leftNavigations/customer-filter-dropdown/tabs/allCustomerTab/AllCustomerTabStyle';
import './LandingPages.scss';
function LandingPageSearchBar({ onSearch, searchType, setSearchType, searchQuery, setSearchQuery, areaCount, resetKey }) {
    const [isFocused, setIsFocused] = useState(false);
    const { t } = useTranslation();
    const [clearType, setClearType] = useState(false);
    const {
        landingPage: { landingPageSearchOptions, landingPageSearchPlaceHolder, areaCountLabel,
        }
    } = t("mainContainer");

    const customMenuProps = {
        PaperProps: {
            sx: {
                borderRadius: "6px",
                border: "1px solid #A3A5A7",
                background: "#F0F0F0",
                boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.25)",
                "& .MuiMenuItem-root:hover": {
                    backgroundColor: "#0F62FE",
                    color: "white",
                },
                "& .MuiList-root": {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
    };

    const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2.5),
        background: '#f4f4f4',
        "& .MuiButtonBase-root": { paddingLeft: "0px" },
        "& .MuiListItemIcon-root": {
            minWidth: theme.spacing(4),
        },
        "&.Mui-selected": {
            backgroundColor: "#E0E0E0",
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
            },
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    }));

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setSearchQuery('');
        onSearch(event.target.value, '');
    };

    useEffect(() => {
        if ((searchQuery.length == 0 || searchQuery == null) && !clearType) {
            resetKey > 1 ? setSearchType('area') : setSearchType('site');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetKey])

    const handleSearchChange = (event) => {
        const query = event?.target?.value;
        const isPasteEvent = event.inputType === 'insertFromPaste';
        if (isPasteEvent || query === '' || isAlphaNumeric(query)) {
            setSearchQuery(query);
            onSearch(searchType, query);
            if (query === '') {
                setClearType(true);
            }
        }
    };
    const handleClear = () => {
        setClearType(true)
        setSearchQuery("");
    }
    const getSearchInputProps = onClose => ({
        endAdornment: (
            <InputAdornment
                position="end"
                sx={{ '& .MuiSvgIcon-root': { padding: '1px' } }}
            >
                {searchQuery?.length === 0 ? <SearchIcon style={{ marginRight: '-5px' }} /> : <CloseIcon onClick={() => onClose()} style={{ cursor: 'pointer', marginRight: "-2px" }} />}
            </InputAdornment>
        ),
    });

    return (
        <Box sx={customerTabStyles?.landingPageSearchContainer}  >
            <div>
                <div className="sumInnerChild">
                    <Typography sx={customerTabStyles?.areaLabel}>
                        {landingPageSearchOptions[0]?.label}
                    </Typography>
                    <Typography className="accordionSubHeaderStyle">
                        {`${areaCountLabel} ${areaCount}`}
                    </Typography>
                </div>
            </div>
            <Box
                sx={customerTabStyles?.landingSearchBar}
                className="selectParent"
            >
                <Select
                    value={searchType}
                    onChange={handleSearchTypeChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Search type' }}
                    MenuProps={customMenuProps}
                    sx={customerTabStyles?.landingPageSelect}
                    disableUnderline
                    variant="standard"
                    IconComponent={downArrow}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    {landingPageSearchOptions?.map((option) => (
                        <CustomMenuItem
                            key={option?.label}
                            value={option?.value}
                            selected={searchType === option?.value}
                            disabled={option?.value === 'area' && areaCount === 1}
                            sx={{ "&.MuiButtonBase-root": { paddingLeft: "0px" } }}
                        >
                            <ListItemText
                                className="optionText"
                                primary={option?.label}
                            />
                            {searchType === option?.value && (
                                <div className="checkIcon">
                                    <Check />
                                </div>
                            )}
                        </CustomMenuItem>
                    ))}
                </Select>
                <TextField
                    id="search"
                    name="search"
                    autoComplete="off"
                    value={searchQuery}
                    onChange={(e) => handleSearchChange(e)}
                    placeholder={landingPageSearchPlaceHolder}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={getSearchInputProps(handleClear)}
                    sx={{ ...customerTabStyles?.landingPageSearchInput, border: isFocused ? '2px solid #0F62FE' : '1px solid #E0E0E0', }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </Box>
        </Box >
    )
}

export default LandingPageSearchBar
