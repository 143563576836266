import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomButton from "../../common/buttons/CustomButton";
import "./setting.scss";
import { getEditSiteId } from "../../redux/slices/customerSlice";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";

function NoSite({
  setSiteScreenType,
  screenType,
  showEmptyPage = false,
  siteListCount = "default",
  customerName = "",
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { settingsPageContent } = t("mainContainer");
  const { customerDetails } = settingsPageContent?.siteManagementContent;

  const [buttonDisabled, setButtonDisbaled] = useState(false);
  const handleClick = () => {
    setSiteScreenType("addSite");
    dispatch(getEditSiteId(""));
  };

  useEffect(() => {
    if (screenType == "addCustomer" || showEmptyPage) {
      setButtonDisbaled(true);
    } else {
      setButtonDisbaled(false);
    }
  }, [screenType, showEmptyPage]);

  return (
    <Paper
      className="noSiteBox"
      sx={{
        height: "calc(100% - 45px)",
        padding: "24px 24px 16px 24px",
        marginLeft: "16px",
        boxShadow: "none",
      }}
    >
      <Typography
        variant="h6"
        style={{
          fontSize: "18px",
          fontWeight: " 500",
          lineHeight: "24px",
          letterSpacing: "0.25px",
          marginBottom: "4px",
          color:
            screenType == "addCustomer" || showEmptyPage
              ? "rgba(22, 22, 22, 0.25)"
              : "inherit",
        }}
      >
        {customerName}
        {/* {customerDetails.title} */}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <CustomButton
          variant="contained"
          backgroundColor="primary"
          width="158px"
          iconPosition="end"
          onClick={handleClick}
          textColor="#FFFFFF"
          disabled={buttonDisabled}
        >
          {customerDetails.addSiteBtn} +
        </CustomButton>
      </Box>

      {!showEmptyPage && (
        <Stack
          // className="noCustomerStack"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 5%)",
          }}
        >
          {siteListCount == "blank" ? (
            <CustomCircularProgress
              inputSx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
              size={44}
            />
          ) : (
            <>
              <Typography
                fontSize={"32px"}
                color="#0043CE"
                fontWeight="400"
                lineHeight="40px"
                letterSpacing="0.25px"
              >
                {customerDetails.noResultMessage}
              </Typography>
              <Typography
                fontSize={"16px"}
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.15px"
              >
                {customerDetails.noResultDescription}
              </Typography>
            </>
          )}
        </Stack>
      )}
    </Paper>
  );
}

export default NoSite;
