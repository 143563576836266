import React from "react";
import { Languages } from "./Languages";
import { Button, FormControl, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateLanguage } from "../../redux/slices/loginInfoSlice";
import { updateLanguageApi } from "../../redux/slices/loginInfoSlice";
import { changeLanguage } from "../../services/apiConfig";

function LanguageSelector({ backgroundShowBtn = false }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);

  const handleLanguageChange = (code) => {
    const payload = { code, i18n };
    dispatch(updateLanguage(payload));

    if (parsedUserData) {
      const { id, preferredLanguage } = parsedUserData;
      const updatedUserData = { ...parsedUserData, preferredLanguage: code };

      // Update userData in local storage
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
      localStorage.setItem("selectedLanguage", JSON.stringify(code));

      const apiPayload = {
        url: changeLanguage,
        token,
        payload: {
          id,
          preferredLanguage: code,
        },
      };

      // Dispatch the updateLanguageApi action with the payload
      dispatch(updateLanguageApi(apiPayload))
        .then((response) => {
          // Handle success
          console.log("Language updated successfully:", response);
        })
        .catch((error) => {
          // Handle error
          console.error("Failed to update language:", error);
        });
    }
  };
  const savedLanguage = localStorage?.getItem("selectedLanguage");
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Grid>
        {Languages?.map((item) => (
          <Button
            key={item?.code}
            onClick={() => handleLanguageChange(item?.code)}
            sx={{
              fontSize: "12px",
              fontWeight: backgroundShowBtn ? "400" : "300",
              lineHeight: " 16px",
              letterSpacing: "0.4px",
              color:
                JSON.parse(savedLanguage) === item?.code ? "#0F62FE" : "black",
              backgroundColor:
                JSON.parse(savedLanguage) === item?.code && backgroundShowBtn
                  ? "#6F6F6F1F"
                  : "#ffff",
            }}
          >
            {item?.language}
          </Button>
        ))}
      </Grid>
    </FormControl>
  );
}

export default LanguageSelector;
