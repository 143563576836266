import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Card,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Lottie from 'lottie-react';
import AmChartComponent from "./Chart";
import CustomModal from "../../common/commonPopup/CustomModal";
import { ReactComponent as BsLightning } from "../../assets/images/Lightening.svg";
import { ReactComponent as Chart } from "../../assets/images/ChartColumn.svg";
import DateRangeCalendar from '../../common/DateRangePicker/DateRangeCalendar';
import { electricityConsumptionAnalyticsURL } from '../../services/apiConfig';
import usePost from '../../common/hook/usePost';
import spinner from "../../assets/animation/spinner.json";
import './ElectricityCard.scss'
import { formatUnit } from '../../common/utils/CommonUtils';
import { formatToCustomDateString } from "../../common/utils/CommonUtils";
import { convertDateTimeToTimestamp } from "../../common/utils/CommonUtils";
import { formatDate } from "../../common/utils/CommonUtils";




const formatValue = (value) => {
  if (value >= 1e9) {
    const formattedValue = (value / 1e9).toFixed(0);
    return `${formattedValue}B`;
  }
  if (value >= 1e6) {
    const formattedValue = (value / 1e6).toFixed(0);
    return `${formattedValue}M`;
  }
  // For values less than 1 million, show two decimal places only if necessary
  const formattedValue = value.toFixed(2);
  return Number(formattedValue) === Math.floor(value) ? value.toFixed(0) : formattedValue;
};


function ElectricityCard({ totalElectricity, cards, noDataText }) {

  const { t } = useTranslation();
  const { energyChart,
    DateRangeSelection,
  } = t("mainContainer");

  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const theme = useTheme();
  const [initialLoad, setInitialLoad] = useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [presetLabel, setPresetLabel] = useState('Today');

  const [electricityData, setElectricityData] = useState([])

  const { recentSearchId } = useSelector((state) => state?.customer); // Accessing recentSearchId from the Redux store

  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost(); // Custom hook for handling API requests

  /**
   * Opens the modal dialog.
   */
  const handleOpen = () => {
    setOpenModal(true);
    setPresetLabel('Today'); // Reset presetLabel to 'Today'
    // Assuming selectedPostalCode is set when you click ViewMap button
setInitialLoad(true)
    // fetchLocationData(siteAddress.postalCode);
  };

  /**
   * Closes the modal dialog.
   */
  const handleClose = () => {
    setOpenModal(false);
    setInitialLoad(false)
  };

  /**
  * Handle changes in the date range picker.
  * 
  * @param {Array} dateRange - The selected date range from the DateRangeCalendar component.
  */
  const handleDateRangeChange = (dateRange) => {
    const start = dateRange.value[0];
    const end = dateRange.value[1];

  
    const [dateStartString, dateEndString] = dateRange.value; 

    const addOffsetToTimestamp = (dateString) => {
      const dateObject = new Date(dateString);
      const gmtTimestamp = dateObject.getTime();
      const offsetInMilliseconds = (5 * 60 * 60 * 1000) + (30 * 60 * 1000); // +05:30 hours
      return gmtTimestamp + offsetInMilliseconds;
  };

   // To convert a date string from the format YYYY/MM/DD HH:mm 
   const customDateStartString = formatToCustomDateString(formatDate(dateStartString)); 
   const customDateEndString = formatToCustomDateString(formatDate(dateEndString));  

  const newStartTimestamp = addOffsetToTimestamp(dateStartString);
  const newEndTimestamp = addOffsetToTimestamp(dateEndString); 

    setStartDate(start);
    setEndDate(end);
    setPresetLabel(dateRange.label); 

 

    // Calculate the difference in milliseconds
    const diffInMs = newEndTimestamp - newStartTimestamp;

    // Convert milliseconds to days
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // Determine the interval based on the difference
    let interval;
    if (diffInDays <= 1) { // 24 hours or less
        interval = "1h";
    } else if (diffInDays > 1 && diffInDays < 31) { // More than 24 hours and less than 31 days
        interval = "1d";
    } else if (diffInDays >= 31 && diffInDays < 365) { // More than 31 days and less than 12 months
        interval = "1mo";
    } else { // More than 12 months
        interval = "1y"; // Adjust this if you have a different interval for years
    }

    const requestPayload = {
        searchId: recentSearchId,
        startDate: convertDateTimeToTimestamp(customDateStartString),
        endDate: convertDateTimeToTimestamp(customDateEndString),
        interval: interval // Use the calculated interval
    };

    doPostPrefectureApi(electricityConsumptionAnalyticsURL, requestPayload); // Send the date range and interval to the API
};



  // in the above code startDate=dateRange.value[0] endDate=dateRange.value[1]
  // set interval based on date range difference 
  // 1.if range difference is 24 hours or below 24 hours then set interval is "1h"
  // 2.if  range difference is  above 24 hours and below 31 days(month) then  set interval is "1d"
  // 3.if  range difference is  above 31 days(month) and below 12 month(year) then  set interval is "1mo"

  useEffect(() => {
    // Effect to update the accordion list when API data changes
    if (prefectureApiData && !prefectureApiError && !prefectureApiLoading) {
      if (prefectureApiData?.result.length > 0) {
        setElectricityData(prefectureApiData?.result?.sites); // Update the Redux store with new data
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefectureApiData, prefectureApiError, prefectureApiLoading,startDate,endDate]);

  // useEffect(() => {
  //   // console.log("electricityData", electricityData)
  // }, [electricityData])

  // Calculate the sum of electricity
  const totalElectricitySum = prefectureApiData?.result?.sites?.reduce((sum, item) => sum + (item.electricity || 0), 0) || 0;

  return (
    <><Card className="electricityAlarmBox">
      <>
        <div className="cardBox">
          <Typography variant="span"> {cards?.electricityTitle} </Typography>
          <IconButton aria-label="delete" size="small" onClick={handleOpen}>
            <Chart fontSize="small" />
          </IconButton>
        </div>

        <div className="innerContent">
          {totalElectricity !== 0 ?
            <>
              <span className="electricitySpaned">{<BsLightning />}</span>
              <Typography className="electricityText">
                {totalElectricity}
              </Typography>
              <span className="electricitySpan">{formatUnit(totalElectricity)}</span>
              <span className="kiloUnit">
                kWh
              </span>
            </>
            :
            <span className="noSearchContent">
              <Typography className="commonNoSearchFoundText">
                {noDataText}
              </Typography>
            </span>

          }
        </div>
      </>
    </Card >
      <CustomModal
        open={openModal}
        onClose={handleClose}
        title={prefectureApiData?.result?.customerName}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl"
      >
        <Box className="chart-info" sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <Box sx={{
            display: "inline-flex",
          }}>
            <div className="chartInfo">
              <span className="sourceType">{energyChart?.Electricity}  </span>
              {totalElectricitySum === 0 ? (
                <>
                  <span className="EnergyValue">{" — "}</span>
                  <span className="energyFormat"> kWh</span>
                  {" / "}
                </>
              ) : (
                <>
                  <span className="EnergyValue">{formatValue(totalElectricitySum)}</span>
                  <span className="energyFormat"> kWh</span>
                  {" / "}
                  <span className="comparisonLabel">{DateRangeSelection?.shortCuts[presetLabel]} vs. {energyChart?.PreviousPeriod}</span>
                </>
              )}
            </div>
            <div className="DateRangeCalender">
              <DateRangeCalendar 
               initialLoad={initialLoad}
               setInitialLoad={setInitialLoad}
                onDateRangeChange={handleDateRangeChange}  // Callback for date range changes
                id="CharttopNavDatePicker"
              />
            </div>
          </Box>
          {!prefectureApiData && !prefectureApiError && prefectureApiLoading && <div className="loading spinner-wrapper blue-spinner">
            <Lottie className="spinner" animationData={spinner} autoplay loop />
          </div>}

          {prefectureApiData && !prefectureApiError && !prefectureApiLoading && (
            prefectureApiData?.result?.sites?.length === 0 || prefectureApiData?.result?.sites?.length === 0 ? (
              <div className="no-data-message">{energyChart.noDataText}</div>
            ) : (
              <AmChartComponent
                startDate={startDate}
                endDate={endDate}
                presetLabel={presetLabel}
                electricityData={prefectureApiData?.result?.sites}
                customerName={prefectureApiData?.result?.customerName}
                descriptionName ="Electricity"
              />
            )
          )}

        </Box>
      </CustomModal>
    </>
  );
}

export default ElectricityCard;