import React from 'react'; 
import Stack from '@mui/material/Stack';  
import CustomInputField from '../../../common/formComponents/CustomInputField';
import CustomRadioGroup from '../../../common/formComponents/CustomRadioGroup';
import CustomCheckboxGroup from '../../../common/formComponents/CustomCheckboxGroup'; 
// import CustomAutocomplete from '../../../common/formComponents/CustomAutocomplete'; 
  
const maxLimit=10;

const FormComponent = ({
  formElements,
  formValues,
  formErrors,
  setFormValues,
  setFormErrors, 
}) => {
 

  
	const handleFieldChange = (fieldName, value) => {
		// Check if the field exists in formValues, initialize if it doesn't
		if (formValues[fieldName] === undefined) {
			// Initialize an empty array for checkbox groups
			setFormValues({ ...formValues, [fieldName]: [] });
		}
	
		// Determine the type of the form element
		const fieldType = formElements.find(element => element.label === fieldName)?.type;
	
		// Update formValues based on fieldType
		if (fieldType === 'checkboxGroup' || fieldType === 'autocomplete') {
			// Ensure formValues[fieldName] is an array
			const currentValues = Array.isArray(formValues[fieldName]) ? formValues[fieldName] : [];
	
			// Toggle or update the checkbox values
			let updatedValues;
			if (fieldType === 'autocomplete') {
				// For autocomplete, directly set the value (assuming value is an array)
				updatedValues = [...value];
			} else {
				// For checkboxGroup, toggle the checkbox value in the array
				if (currentValues.includes(value)) {
					updatedValues = currentValues.filter(item => item !== value);
				} else {
					updatedValues = [...currentValues, value];
				}
			}
	
			// Update formValues state with the updated values
			setFormValues({ ...formValues, [fieldName]: updatedValues });
		} else {
			// For other types (like text field or radio group), directly update the value
			setFormValues({ ...formValues, [fieldName]: value });
		}

    if (fieldType === 'addMultiItems') {
      const currentRecipients = formValues?.recipients || [];
      const updatedRecipients = [...currentRecipients, value];
  
      if (formValues?.recipients?.includes(value)){
        setFormErrors({ ...formErrors, [fieldName]: `${fieldName} Email already exists` }); 
        return
      } 

      // Validate email format before adding to recipients
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    if (!isValidEmail) {
      setFormErrors({ ...formErrors, [fieldName]: 'Please enter a valid email' });
      return;
    }

    // Check for duplicate email
    const isDuplicate = formValues?.recipients?.includes(value);
    if (isDuplicate) {
      setFormErrors({ ...formErrors, [fieldName]: 'Email already exists' });
      return;
    }


    }
	
		// Reset error for the field when user starts typing
		if (formErrors[fieldName]) {
			setFormErrors({ ...formErrors, [fieldName]: false });
		}
	};
	
  const handleAddItem = (fieldName) => { 
    const currentRecipients = formValues?.recipients || [];
    const updatedRecipients = [...currentRecipients, formValues[fieldName]]; 
    
    setFormValues({
      ...formValues, 
        recipients: updatedRecipients,
       [fieldName]: ''
    });
    
  }; 
  

  const handleRemoveItem =  (index) => {
     // Ensure formValues and recipients exist
  if (formValues && formValues.recipients) {
    // Filter out the item at the specified index
    const updatedRecipients = formValues.recipients.filter((_, i) => i !== index);
    
    // Update formValues with the updated recipients array
    setFormValues({
      ...formValues,
      recipients: updatedRecipients,
    });
  }
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;
    const updatedErrors = {};

    formElements?.forEach((element) => {
      if (
        element.type === 'textField' &&
        (formValues[element.label] === undefined ||
          formValues[element.label] === '')
      ) {
        updatedErrors[element.label] = true;
        isValid = false;
      } else if (element.type === 'radioGroup' && !formValues[element.label]) {
        updatedErrors[element.label] = true;
        isValid = false;
      } else if (
        element.type === 'checkboxGroup' &&
        (!formValues[element.label] || formValues[element.label].length === 0)
      ) {
        updatedErrors[element.label] = true;
        isValid = false;
      } else if (element.type === 'autocomplete' && !formValues[element.label]) {
        updatedErrors[element.label] = true;
        isValid = false;
      }else if (element.type === 'addMultiItems' && (!formValues?.recipients || formValues?.recipients.length === 0)) {
        updatedErrors[element.label] = true;
        isValid = false;
        }
    });

    if (!isValid) {
      setFormErrors(updatedErrors);
      return;
    }

    console.log('Form submitted successfully!');
    console.log('Form values:', formValues);

    setFormValues({});
    setFormErrors({});
  };

  const handleFieldClear = (fieldName)=>{
    setFormValues({ ...formValues, [fieldName]: '' }); 
    setFormErrors({ ...formErrors, [fieldName]: false });
    // setEmailExist(false)
  }
  const renderFormElement = (element) => {
    switch (element.type) {
      case 'textField':
        return (
          <CustomInputField
            key={element.label}
            label={element.label}
            placeholder={element.placeholder}
            value={formValues[element.label] || ''}
            onChange={(e) => handleFieldChange(element.label, e.target.value)}
            error={formErrors[element.label]}
            sx={{p:0,m:0,color:"#161616"}}
          />
        );
      case 'radioGroup':
        return (
          <CustomRadioGroup
            key={element.label}
            label={element.label}
            options={element.options || []}
            value={formValues[element.label] || 'html'}
            onChange={(e) => handleFieldChange(element.label, e.target.value)}
            error={formErrors[element.label]}
            sx={{
              color: "#161616", 
              '&.Mui-checked': {
                color: "#161616",
              },
            }}
          />
        );
      case 'checkboxGroup':
        return (
          <CustomCheckboxGroup
            key={element.label}
            label={element.label}
            options={element.options || []}
            value={formValues[element.label] || []}
            onChange={(e) => handleFieldChange(element.label, e.target.value)}
            error={formErrors[element.label]}
            sx={{
              color: "#161616",
              '&.Mui-checked': {
                color: "#161616",
              },
            }}
          />
        );
        case 'addMultiItems':          
          return (
            <CustomInputField
            key={element.label}
            label={element.label}
            placeholder={element.placeholder}
            value={formValues[element.label] || ''}
            selectedItem={formValues?.recipients}
            onRemove={(e) => handleRemoveItem(e)}
            onChange={(e) => handleFieldChange(element.label, e.target.value)}
            onClick={() => handleAddItem(element.label)}
            onClear={()=>handleFieldClear(element.label)}
            error={formErrors[element.label]}
            sx={{p:0,m:0,color:"#161616"}}
            addItem={true} 
            disabled={formValues?.recipients?.length==maxLimit}
          />
          );
          // Don't Delete Feature Reference
      // case 'autocomplete':
      //   return (
      //     <CustomAutocomplete
      //       key={element.label}
      //       label={element.label}
      //       placeholder={element.placeholder}
      //       options={element.options || []}
      //       value={formValues[element.label] || []}
      //       onChange={(value) => handleFieldChange(element.label, value)}
      //       error={formErrors[element.label]}
      //       limitTags={2}
      //       maxLimit={10}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        {formElements.map((element) => renderFormElement(element))}
      </Stack>
    </form>
  );
};


export default FormComponent;
