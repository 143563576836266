import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";


/**
 * Reusable Modal component using Material-UI.
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Controls whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close event.
 * @param {string} props.title - Title of the modal.
 * @param {string} props.content - Main content of the modal.
 * @param {Array<Object>} props.actions - Array of action buttons to display at the bottom of the modal.
 * @param {boolean} [props.fullScreen] - Whether the dialog should be displayed in full-screen mode.
 * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl' | false} [props.maxWidth] - Determines the max-width of the dialog.
 * @param {boolean} [props.fullWidth] - If true, the dialog will take up the full width of its container.
 * @returns {JSX.Element} Modal component.
 */
const CustomModal = ({
  open,
  onClose,
  title,
  content,
  actions, fullScreen,
  maxWidth,
  fullWidth,
  children,
  contentPadding
}) => {
  return (

    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ padding: "20px 0px 12px 16px", fontSize: "20px", height: "32px" }} id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={{ padding: contentPadding ? "0" : "16px" }}>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      <Divider />
      {actions && <DialogActions>
        {actions?.map((action, index) => (
          <Button
            key={index}
            variant={action.variant}
            color="primary"
            onClick={action.onClick}
            sx={action.sx}
          >
            {action.label}
          </Button>
        ))}

      </DialogActions>}
    </Dialog>
  );
};

// PropTypes for type checking
CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullWidth: PropTypes.bool,
};

export default CustomModal;