import React from "react";
import {
  NWC_HOME_URL,
  NWC_ALARM_URL,
  NWC_LOGIN_URL,
  NWC_SETTING_URL,
} from "../../common/constants/Constants";
import { ReactComponent as HomeIcon } from "../../assets/images/home.svg";
import { ReactComponent as NotificationsNoneIcon } from "../../assets/images/alarm.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings.svg";
import { ReactComponent as ExitToAppIcon } from "../../assets/images/signout.svg";

export const SidenavContent = [
  {
    name: "Home",
    id: 1,
    path: NWC_HOME_URL,
    icon: <HomeIcon />,
    resource: "Home", action: "VIEW"
  },
  {
    name: "Alarm",
    id: 2,
    path: NWC_ALARM_URL,
    icon: <NotificationsNoneIcon />,resource: "Alarms", action: "VIEW"
  },

  { name: "Settings", id: 3, path: NWC_SETTING_URL, icon: <SettingsIcon />, resource: "Settings", action: "VIEW",},
  { name: "Sign out", path: NWC_LOGIN_URL, icon: <ExitToAppIcon />, resource: "Signout", action: "VIEW" },
];
