import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { createTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as NwcWhiteLogo } from "../../assets/images/WhiteLogoGroup.svg"; 
import BannerImage from '../../assets/images/cityBackground.png'
import illus from '../../assets/images/Illustrations.svg';

function LeftSection() {
  const { t } = useTranslation();
  const { aboutNWC, greeting } = t("mainContainer");
  const gridStyle = {
    background: `url(${illus}) no-repeat 3.5rem 26.3rem / 91%, url(${BannerImage}) no-repeat center center / cover`, 
    // background: `linear-gradient(136.4deg, #0F62FE 0%, #8270DB 121.84%)`,
  };
  const theme = createTheme({
    typography: {
      h6: {
        fontSize: 12,
      },
      customSpan: {
        fontSize: 9,
        color: "black",
      },
      h5: {
        fontSize: "24px",
        fontWeight: 500,
      },
      customFontPara: {
        fontSize: 42,
        lineHeight: "50px",
        fontWeight: "400",
        textAlign: "left",
        marginTop: "22px",
      },
      paragraphFont: {
        overflowWrap: "break-word",
        textAlign: "left",
        letterSpacing: "0.15px",
        fontWeight: "400",
        lineHeight: "28px",
        fontSize: "20px",
      },
      pFontSize: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        letterSpacing: "0.5px",
      },
    },
  });

  return (
    <Grid item lg={6} xs={12} style={gridStyle}>
      <Stack padding={3} color={"white"} gap={2}> 
        <NwcWhiteLogo /> 
        <Typography theme={theme} component="div" variant="customFontPara">
          {greeting}
        </Typography>
        <Typography theme={theme} component="div" variant="paragraphFont">
          {aboutNWC}
        </Typography>
      </Stack>
    </Grid>
  );
}

export default LeftSection;
