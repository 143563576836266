// Mock API function to simulate data fetching
export const fetchData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          siteName: "goli",
          state: "Tokyo",
          siteId: "66852c9d76369e5cf413c9c5",
          electricity: 14,
          alarmCount: 5,
          address1: "1-2-3 Shinjuku",
          postalCode: "160-0022",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "abc site",
          state: "Shibuya",
          siteId: "66852d6276369e5cf413c9cf",
          electricity: 12,
          alarmCount: 7,
          address1: "4-5-6 Harajuku",
          postalCode: "150-0001",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "xyz site",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a0",
          electricity: 20,
          alarmCount: 3,
          address1: "10-1-5 Akihabara",
          postalCode: "101-0021",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "asahi site",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a1",
          electricity: 35,
          alarmCount: 4,
          address1: "2-12-9 Nakano",
          postalCode: "164-0001",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "tokyo tower",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a2",
          electricity: 25,
          alarmCount: 6,
          address1: "4-4-4 Minato",
          postalCode: "105-0011",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "sakura park",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a3",
          electricity: 15,
          alarmCount: 2,
          address1: "6-7-8 Ueno",
          postalCode: "110-0005",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "central office",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a4",
          electricity: 40,
          alarmCount: 7,
          address1: "3-9-1 Marunouchi",
          postalCode: "100-0005",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "roppongi site",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a5",
          electricity: 55,
          alarmCount: 5,
          address1: "1-2-1 Roppongi",
          postalCode: "106-0032",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "midtown site",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a6",
          electricity: 30,
          alarmCount: 8,
          address1: "9-7-6 Roppongi",
          postalCode: "107-0052",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "bima ",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cbc",
          electricity: 45,
          alarmCount: 8,
          address1: "7-8-9 Ebisu",
          postalCode: "150-0013",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "delta tech",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a0",
          electricity: 20,
          alarmCount: 3,
          address1: "10-1-5 Akihabara",
          postalCode: "101-0021",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "echo plaza",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a1",
          electricity: 35,
          alarmCount: 4,
          address1: "2-12-9 Nakano",
          postalCode: "164-0001",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "foxtrot station",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a2",
          electricity: 25,
          alarmCount: 6,
          address1: "4-4-4 Minato",
          postalCode: "105-0011",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "golf resort",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a3",
          electricity: 15,
          alarmCount: 2,
          address1: "6-7-8 Ueno",
          postalCode: "110-0005",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "hotel k",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a4",
          electricity: 40,
          alarmCount: 7,
          address1: "3-9-1 Marunouchi",
          postalCode: "100-0005",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "india gate",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a5",
          electricity: 55,
          alarmCount: 5,
          address1: "1-2-1 Roppongi",
          postalCode: "106-0032",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "juliet center",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a6",
          electricity: 30,
          alarmCount: 8,
          address1: "9-7-6 Roppongi",
          postalCode: "107-0052",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "kilo hub",
          state: "Tokyo",
          siteId: "66852e2376369e5cf413d0a7",
          electricity: 18,
          alarmCount: 3,
          address1: "5-5-5 Shibuya",
          postalCode: "150-0041",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "lima park",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cbe",
          electricity: 22,
          alarmCount: 9,
          address1: "1-2-3 Omotesando",
          postalCode: "150-0001",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "mike square",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cbf",
          electricity: 38,
          alarmCount: 6,
          address1: "4-5-6 Shibuya",
          postalCode: "150-0042",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "november mall",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cc0",
          electricity: 29,
          alarmCount: 7,
          address1: "7-8-9 Daikanyama",
          postalCode: "150-0034",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "oscar point",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cc1",
          electricity: 19,
          alarmCount: 5,
          address1: "1-1-1 Shoto",
          postalCode: "150-0046",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "papa palace",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7c8",
          electricity: 45,
          alarmCount: 4,
          address1: "5-6-7 Sakura",
          postalCode: "285-0034",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "quebec quarters",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7c9",
          electricity: 28,
          alarmCount: 6,
          address1: "8-9-10 Funabashi",
          postalCode: "273-0005",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "romeo retreat",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7ca",
          electricity: 36,
          alarmCount: 3,
          address1: "2-3-4 Narita",
          postalCode: "282-0004",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "sierra shore",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7cb",
          electricity: 50,
          alarmCount: 8,
          address1: "7-8-9 Kashiwa",
          postalCode: "277-0005",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "tango terrace",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7cc",
          electricity: 42,
          alarmCount: 9,
          address1: "1-2-3 Ichikawa",
          postalCode: "272-0034",
          image: "https://via.placeholder.com/50",
        },

        {
          siteName: "raghav",
          state: "Chiba",
          siteId: "668ec439f986ee1818cd4cbd",
          electricity: 33,
          alarmCount: 6,
          address1: "2-2-2 Mihama",
          postalCode: "261-0023",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "nishit",
          state: "Chiba",
          siteId: "668ec439f986ee1818cd4cbf",
          electricity: 50,
          alarmCount: 9,
          address1: "3-3-3 Inage",
          postalCode: "263-0023",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "city center",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cbe",
          electricity: 28,
          alarmCount: 10,
          address1: "10-11-12 Daikanyama",
          postalCode: "150-0034",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "kiran",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7c7",
          electricity: 92,
          alarmCount: 2,
          address1: "1-1-1 Chuo",
          postalCode: "260-0013",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "rohan",
          state: "Osaka",
          siteId: "668ec71970a6554c38c3a7c8",
          electricity: 55,
          alarmCount: 3,
          address1: "3-3-3 Namba",
          postalCode: "542-0076",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Riah",
          state: "Kyoto",
          siteId: "668ec439f986ee1818cd4cbe",
          electricity: 27,
          alarmCount: 4,
          address1: "4-4-4 Gion",
          postalCode: "605-0073",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Mk",
          state: "Nagoya",
          siteId: "668ec71970a6554c38c3a7c9",
          electricity: 38,
          alarmCount: 5,
          address1: "5-5-5 Sakae",
          postalCode: "460-0008",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Sanu",
          state: "Tokyo",
          siteId: "66852c9d76369e5cf413c9c5",
          electricity: 14,
          alarmCount: 5,
          address1: "1-2-3 Shinjuku",
          postalCode: "160-0022",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "billu",
          state: "Shibuya",
          siteId: "66852d6276369e5cf413c9cf",
          electricity: 12,
          alarmCount: 7,
          address1: "4-5-6 Harajuku",
          postalCode: "150-0001",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Bhumi",
          state: "Shibuya",
          siteId: "668ec439f986ee1818cd4cbc",
          electricity: 45,
          alarmCount: 8,
          address1: "7-8-9 Ebisu",
          postalCode: "150-0013",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Suraj",
          state: "Chiba",
          siteId: "668ec71970a6554c38c3a7c7",
          electricity: 92,
          alarmCount: 2,
          address1: "1-1-1 Chuo",
          postalCode: "260-0013",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Siddy",
          state: "Chiba",
          siteId: "668ec439f986ee1818cd4cbd",
          electricity: 33,
          alarmCount: 6,
          address1: "2-2-2 Mihama",
          postalCode: "261-0023",
          image: "https://via.placeholder.com/50",
        },
        {
          siteName: "Sana",
          state: "Hiroshima",
          siteId: "668f439f986ee1818cd4cc3",
          electricity: 34,
          alarmCount: 4,
          address1: "9-9-9 Naka-ku",
          postalCode: "730-0013",
          image: "https://via.placeholder.com/50",
        },

        {
          siteName: "Adam",
          state: "Saitama",
          siteId: "668f71970a6554c38c3a7ce",
          electricity: 36,
          alarmCount: 9,
          address1: "12-12-12 Omiya",
          postalCode: "330-0801",
          image: "https://via.placeholder.com/50",
        },
      ]);
    }, 1000); // Simulate network delay
  });
};

// to generate uniqueId for siteList data

function generateUniqueId() {
  return (
    "xxxxxx".replace(/[x]/g, () => {
      const random = Math.floor(Math.random() * 16);
      return random.toString(16);
    }) + Date.now().toString(16)
  );
}
// Function to consolidate data by state
// MockApi.js

// export const consolidateByState = (sites) => {
//   const consolidated = {};
//   sites?.forEach((site) => {
//     const { state, electricity, alarmCount } = site;

//     if (!consolidated[state]) {
//       consolidated[state] = { electricity: 0, alarmCount: 0 };
//     }
//     consolidated[state].electricity += electricity;
//     consolidated[state].alarmCount += alarmCount;
//   });
//   console.log("consolidated", consolidated);
//   return consolidated;
// };

// function to get total electricity and alarm count for all states

export const getTotalForAll = (data) => {
  // Ensure data is an array
  if (!Array.isArray(data)) {
    return { totalElectricity: 0, totalAlarms: 0 };
  }

  return data?.reduce(
    (totals, site) => {
      totals.totalElectricity += site.electricity || 0;
      totals.totalAlarms += site.alarmCount || 0;
      return totals;
    },
    { totalElectricity: 0, totalAlarms: 0 }
  );
};
export const consolidateByState = (sites) => {
  const consolidated = {};
  sites?.forEach((site) => {
    const {
      state,
      electricity,
      alarmCount,
      siteName,
      address1,
      postalCode,
      image,
    } = site;

    if (!consolidated[state]) {
      consolidated[state] = {
        uniqId: generateUniqueId(), // Add unique ID
        electricity: 0,
        alarmCount: 0,
        siteName, // Capture the first site's details for this state
        address1,
        postalCode,
        image,
      };
    }
    consolidated[state].electricity += electricity;
    consolidated[state].alarmCount += alarmCount;
  });
  return consolidated;
};

export const calculateTotals = (sites) => {
  let totalElectricity = 0;
  let totalAlarmCount = 0;
  let totalSiteCount = 0;

  sites?.forEach((site) => {
    totalElectricity += site.electricity;
    totalAlarmCount += site.alarmCount;
    totalSiteCount += 1;
  });

  return {
    totalElectricity,
    totalAlarmCount,
    totalSiteCount,
  };
};
