import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Typography, Button } from '@mui/material';
import Tabs from './tabs/Index';
import { ReactComponent as DownArrow } from "../../../../assets/images/DownArrow.svg";
import { customerListURL } from '../../../../services/apiConfig';
import useFetch from '../../../../common/hook/useFetch';
import { setAllCustomers, setSelectedCategory, setSelectedAreas, setSelectedSites } from '../../../../redux/slices/customerFilterSlice';
import { getHomeAllSiteList } from '../../../../services/apiConfig';
import { prefectures } from '../../../setting/CustomerData';
import { recentSearchURL, getRecentSearch } from '../../../../services/apiConfig';
import usePost from '../../../../common/hook/usePost';
import { setRecentSearchId, setDefaultDelete } from '../../../../redux/slices/customerFilterSlice';

const CustomerFilterDropdown = ({ setbreadcrumbData, breadcrumbData, handleClick, anchorEl, setAnchorEl, tabActiveFrom, setTabActive }) => {

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Button sx={{ p: 0, fontSize: "16px", justifyContent: "left",height:"24px" }} size="small" aria-describedby={id} variant="text" onClick={handleClick} className="customer-dropdown">
        {breadcrumbData?.selectedCategory?.favouriteName||breadcrumbData?.selectedCategory?.displayName}  <DownArrow />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <Typography sx={{ p: 2 }}></Typography> */}
        <Tabs handleClose={handleClose} setbreadcrumbData={setbreadcrumbData} tabActiveFrom={tabActiveFrom} setTabActive={setTabActive} />
      </Popover>
    </React.Fragment>)
}
export default CustomerFilterDropdown;
