/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  Box,
  Typography,
  List,
  Divider,
  SvgIcon,
} from "@mui/material";
import debounce from "lodash/debounce";
import { ReactComponent as EditIcon } from "../../assets/images/EditBlackIcon.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllCustomersEndpoint, getAllCustomeWithPaginationEndPoint2 } from "../../services/apiConfig";
import { ApiPayload } from "./ApiAction";
import { sortingKeys } from "./siteDetails/siteDetails.Data";
import CommonPagination from "../../common/reusable-comps/custom-pagination/CustomPagination";
import {
  getAllCustomersApi,
  getSingleCustomerApi,
  selectedCustomer,
  updateTriggerSingleCustApi,
} from "../../redux/slices/customerSlice";
import "./setting.scss";
import FilterSearch from "../../common/filterSearch/FilterSearch";
import { getRequestCustomerApi } from "../../common/utils/CommonUtils";
import TableActions from "../../common/customTable/TableActions";
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";

const CustomerList = ({
  customerList,
  isEdit,
  setScreenType,
  setIsEdit,
  setEditableData,
  setShowAddCustomer,
  siteDetailsOutside,
  type,
  setShowEmptyPage,
  triggerCustApi,
  setTriggerCustApi,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  currentFilter,
  setcurrentFilter,
  selectedCustId,
  selectedCustomerData,
  setSelectedCustomerData,
  custApiLoading,
  from,
  enteredText,
  setEnteredText,
  siteLength,
}) => {

  const [listCount, setListCount] = useState(0);
  const [pagesList, setPagesList] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endItemIndex, setEndIndexItem] = useState(0);
  const [selectedAction, setSelectedAction] = useState("");
  const refToTop = useRef(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customersList, isSingleCustApiCalled, triggerSingleCustApi } = useSelector(
    (state) => state?.customerInfo
  );
  const { settingsPageContent, customerSorting, sortBy } = t("mainContainer");
  const { title, addCustomerBtn, searchPlaceholder, customerListText } =
    settingsPageContent?.siteManagementContent;

  const StyledCard = styled(Card)(({ theme, selected }) => ({
    marginBottom: theme.spacing(2),
    height: "76px",
    "&:hover": {
      backgroundColor: "#0F62FE",
      color: "white",
      "& .MuiTypography-root": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        filter: "invert(1)",
        color: "white",
      },
    },
    "& .MuiSvgIcon-root": {
      filter: selected && "invert(1)",
    },
  }));
  useEffect(() => {
    const payload = {
      url: getAllCustomeWithPaginationEndPoint2(
        getRequestCustomerApi({
          page: currentPage,
          limit: rowsPerPage,
          sortBy: sortingKeys?.[currentFilter],
        })
      ),
      token: localStorage.getItem("accessToken"),
    };
    if (from !== "addSiteButton") {
      dispatch(getAllCustomersApi(payload)).then((res) => {
        if (res?.payload?.status == "200") {
          setListCount(res?.payload?.data?.totalResults || "");
          setPagesList(res?.payload?.data?.totalPages || "");
        }
      });
    }
  }, [type, currentFilter]);
  useEffect(() => {
    if (triggerCustApi || isEdit) {
      let requestParams = getRequestCustomerApi({
        name: enteredText,
        limit: rowsPerPage,
        page: currentPage,
        sortBy: sortingKeys?.[currentFilter],
      });
      handlePageChange(currentPage, rowsPerPage, "sortOrder", requestParams);
      setTriggerCustApi(false);
    }
  }, [triggerCustApi, isEdit]);

  // Debounce the search function to limit how often it is called
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here (e.g., API call)
    if (value === "") {
      let requestParamsEmpty = getRequestCustomerApi({
        name: value,
        limit: rowsPerPage,
        page: currentPage,
        sortBy: sortingKeys?.[currentFilter]
      });
      handlePageChange(1, 10, "sortOrder", requestParamsEmpty);
    }
    else {
      let requestParams = getRequestCustomerApi({
        name: value,
        limit: rowsPerPage,
        page: currentPage,
      });
      handlePageChange(1, 10, "sortOrder", requestParams);
    }
  }, 600); // Adjust the delay time as needed (e.g., 600ms)

  const handleAddCustomer = () => {
    setShowAddCustomer(true);
    setScreenType("addCustomer");
    setShowEmptyPage(true);
  };
  const handleClear = () => {
    // Logic to clear the search input or reset state
    setEnteredText("");

    let requestParams = getRequestCustomerApi({
      name: "",
      limit: rowsPerPage,
      page: currentPage,
      sortBy: sortingKeys?.[currentFilter],
    });
    handlePageChange(currentPage, rowsPerPage, "sortOrder", requestParams);
  };

  const handleActionSelection = (selectedOrder) => {
    setcurrentFilter(selectedOrder);
    let requestParams = getRequestCustomerApi({
      name: enteredText,
      limit: rowsPerPage,
      page: currentPage,
      sortBy: sortingKeys?.[selectedOrder],
    });
    // handlePageChange(1, 10, "sortOrder", requestParams);
  };

  const handlePageChange = (
    newPage,
    newRowsPerPage,
    keyOfAction,
    reqParams
  ) => {
    setRowsPerPage(newRowsPerPage);
    refToTop.current && refToTop.current.scrollIntoView();
    if (
      ["itemsPerPage", "previous", "next", "sortOrder", "pageChange"].includes(
        keyOfAction
      )
    ) {
      setCurrentPage(newPage);
    }
    dispatch(
      getAllCustomersApi(
        ApiPayload(getAllCustomeWithPaginationEndPoint2(`${reqParams}`))
      )
    ).then((res) => {
      if (res?.payload?.status == "200") {
        setListCount(res?.payload?.data?.totalResults || 0);
      }
    });
  };

  useEffect(() => {
    setEnteredText("");
  }, [customerList, setEnteredText]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setEnteredText(value);
    debouncedSearch(value); // Call debounced function with input value
  };

  useEffect(() => {
    if (from === "addSiteButton" && selectedCustId) {
      setListCount(customersList?.length);
      if (!triggerSingleCustApi && !isSingleCustApiCalled) {
        onHandleClick(customersList?.[0] ?? {});
      }
    } else if (from === "sideNav" && !triggerSingleCustApi) {
      if (!isSingleCustApiCalled) { // Check if we are not fetching
        onHandleClick(customersList?.[0] ?? {});
      }
    }
  }, [selectedCustId, from, customersList]); // Add isFetchingCustomer to dependencies



  const handleEditCustomer = (item) => {
    setIsEdit(true);
    setShowAddCustomer(true);
    setScreenType("addCustomer");
    setEditableData(item);
  };

  const onHandleClick = (item) => {
    dispatch(selectedCustomer(item?.id));
    setSelectedCustomerData(item);
    siteDetailsOutside(item);
    setShowEmptyPage(false);
  };

  const fetchSingleCustomer = async () => {
    const payload = {
      url: getAllCustomersEndpoint,
      customerId: selectedCustomerData?.id,
      token: localStorage.getItem("accessToken"),
      isAddSite: false,
    };
    await dispatch(getSingleCustomerApi(payload)).then((res) => {
      if (res?.payload?.status === 200) {
      }
    }).catch((err) => {
      console.log("single cust api failed", err)
    })
  };

  useEffect(() => {
    if (triggerSingleCustApi) {
      fetchSingleCustomer();
      dispatch(updateTriggerSingleCustApi(false))
    }
  }, [triggerSingleCustApi]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxWidth: "426px",
        width: "100%",
        boxShadow: 1,
        borderRadius: 1,
        backgroundColor: "white",
      }}
      className="boxBorderCustomer"
    >
      <Box className="custInnerBox">
        <Typography fontWeight={"500"} fontSize={"18px"} lineHeight={"24px"}>
          {title}
        </Typography>
        <FilterSearch
          handleClear={handleClear}
          setEnteredText={setEnteredText}
          enteredText={enteredText}
          handleSearchChange={handleSearchChange}
          refToTop={refToTop}
          sortOptions={customerSorting}
          handleAddCustomer={handleAddCustomer}
          btnText={addCustomerBtn}
          searchPlaceholder={searchPlaceholder}
          handleActionSelection={handleActionSelection}
          searhButtonWidth="220px"
          buttonMarginLeft="16px"
        />
        <Box
          sx={{
            marginTop: 1,
            overflowY: "auto",
            height: "calc(100% - 100px)",
          }}
        >
          <div
            style={{
              marginLeft: "14px",
              marginRight: "14px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {selectedAction && (
              <Typography
                sx={{
                  marginLeft: 2,
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "24px",
                }}
              >
                {sortBy}: {selectedAction}
              </Typography>
            )}
            <TableActions
              options={customerSorting}
              setSelectedAction={setSelectedAction}
              handleActionSelection={handleActionSelection}
              actionIcon={<FilterIcon />}
            />
          </div>
          <List className="listMarginRight">
            {custApiLoading ? <CustomCircularProgress
              inputSx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                marginTop: "50%"
              }}
              size={44} ></CustomCircularProgress>
              : customersList?.map((item, index) => (
                <StyledCard
                  key={index}
                  variant="outlined"
                  onClick={() => {
                    onHandleClick(item);
                  }}
                  selected={selectedCustomerData?.id === item?.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: '#0353e9',
                    },
                    cursor: "pointer",
                    backgroundColor:
                      selectedCustomerData?.id == item.id ? "#0F62FE" : "white",
                    color:
                      selectedCustomerData?.id == item.id ? "white" : "black",
                  }}
                >
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="listIcon"
                  >
                    <div>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          textAlign: "left",
                        }}
                      >
                        {item?.displayName}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          letterSpacing: "0.25px",
                          textAlign: "left",
                        }}
                      >
                        {customerListText?.siteCount}: {selectedCustId && customersList?.length === 1 && typeof siteLength === 'number' ? siteLength : item?.siteCount}
                      </Typography>
                    </div>
                    <SvgIcon
                      className="svgColor"
                      onClick={() => handleEditCustomer(item)}
                    >
                      <EditIcon />
                    </SvgIcon>
                  </CardContent>
                </StyledCard>
              ))}
          </List>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ height: "40px" }}>
        <CommonPagination
          endItemIndex={endItemIndex}
          setEndIndexItem={setEndIndexItem}
          count={listCount}
          page={currentPage}
          pagesList={pagesList}
          onChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          searchText={enteredText}
          rowsPerPageOptions={[5, 10, 25, 50]}
          arrowWidth="18%"
          // arrowBoxMarginRight="8px"
          currentFilter={currentFilter}
          startIndex={startIndex}
          setStartIndex={setStartIndex}
        />
      </Box>
    </Box >
  );
};

export default CustomerList;
