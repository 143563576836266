//all urls is declared here, so we can use in any file and avoid using hard-coded urls .
export const NWC_HOME_URL = "/home";
export const NWC_REGISTRATION_URL = "/register";
export const NWC_LOGIN_URL = "/login";
export const NWC_DEFAULT_URL = "/";
export const NWC_CONTACT_URL = "/contact";
export const NWC_USER_URL = "/user";
export const NWC_ALARM_URL = "/alarm";
export const NWC_PROJECT_DETAIL = "/pro";
export const NWC_RESET_PASSWORD = "/resetPassword";
export const NWC_SETTING_URL = "/setting";
export const NWC_AUDIT_URL = "/audit";
export const API_SITE_ACCESS = {
    URL: "http://202.216.0.19",
    USERNAME: "admin",
    PASSWORD: "Nwc@0188"
  };
//export const BASE_URL = "http://localhost:5000/";
