import React, { useState, useEffect ,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, InputLabel } from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import CustomTextField from '../../common/reusable-comps/text-field/CustomTextField';
import CustomPasswordField from '../../common/reusable-comps/password-field/CustomPasswordField';
import CustomAlert from '../../common/reusable-comps/alert-bar/CustomAlert';
import { ReactComponent as RedErrorIcon } from '../../assets/images/RedErrorIcon.svg';
import { ReactComponent as RedButtonIcon } from '../../assets/images/redExclamation.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/tickIcon.svg';
import { ReactComponent as SyncIcon } from '../../assets/images/SyncIcon.svg';
import CustomButton from '../../common/buttons/CustomButton';
import { isValidUrl } from '../../common/utils/CommonUtils'; 
import spinner from '../../assets/animation/spinner.json';
import ButtonWithIcon from '../../common/buttons/ButtonWithIcon';
import { formatDate } from '../../common/utils/CommonUtils'; 
import { updateEdgeDevice } from '../../redux/slices/customerSlice';
 

const EdgeDevice = ({ 
	locationInfo,
	isSiteEdit,
	edgeDevice,
	setFormData,
	setFormError,
	showErrorBar,
	setShowErrorBar,
	formTouched,
	setFormTouched,
	handleWebAccessTest,
	handleEMSTest,
	connectionType,
	setConnectionType,
	postEMSData,
	postEMSError,
	postEMSLoading,
	postWebAccessData,
	postWebAccessError,
	postWebAccessLoading,
	// setHandleProceedClickedOnEdgeDevice,
	setProceedClickedOnEdgeDevice,
	siteName,
	handleSync,
	syncButtonStatus,
	alaramTagsData,
	alaramTagsError,
	alaramTagsLoading,
	syncTime, setEmsFormUpdated,emsFormUpdated,setWebFormUpdated,webFormUpdated,  
}) => {
	const connectionError = false;
	// useSelector to access state from Redux store
	const {webAccessConnectionVerified,emsConnectionVerified} = useSelector(
		state => state?.customerInfo?.siteFormData
	);
	const dispatch = useDispatch();
	const siteData = useSelector(state => state?.customerInfo?.siteFormData);
	const edgeDeviceFromData = useSelector(state => state?.customerInfo?.siteFormData?.edgeDevice);

	const [showErrorAlert, setErrorAlert] = useState(false); // State to control WebAccessFailedAlert visibility
	const [showSuccessAlert, setSuccessAlert] = useState(false); // State to control WebAccessFailedAlert visibility

	const [webaccessSuccess,setWebaccessSuccess]=useState(false)
const[formChangeType,setFormChangeType]=useState('')


	const { t } = useTranslation();
	const {
		settingsPageContent: { siteManagementContent },
		syncModal,
	} = t('mainContainer');
	const { syncData } = siteManagementContent;
	const {
		alert,
		title1,
		title2,
		url1,
		url2,
		user1,
		user2,
		password1,
		password2,
		connectionFaildBtn,
		testConnectionBtn,
		WebSuccessAlert,
		WebFailedAlert,
		EMSSuccessAlert,
		SyncSuccessAlert,
		SyncFailedAlert,
		EMSFailedAlert,
	} = siteManagementContent?.customerDetails?.edgeDevice;
	const [webAccessBtnLabel, setWebAccessBtnLabel] = useState({
		text: testConnectionBtn.default,
		icon: '' //<SyncIcon />
	  });

	  const [syncBtnLabel, setSyncBtnLabel] = useState();

	  const [emsAccessBtnLabel, setEmsAccessBtnLabel] = useState({
		text: testConnectionBtn.default,
		icon: '' //<SyncIcon />
	  });

	useEffect(() => {
		setFormData(prevData => ({
			...prevData,
			edgeDevice: {
				...prevData?.edgeDevice,
				lastOnline:
					postEMSData || postWebAccessData ? new Date().getTime() : null,
				// webAccessConnectionVerified : postWebAccessData ? true : false,
				// emsConnectionVerified: postEMSData ? true : false
			},
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postEMSData, postWebAccessData, postWebAccessError, postEMSError]);
 
	 
	
	const redBorder = {
		borderLeft: '3px solid #DA1E284D',
		borderTop: '1px solid #DA1E284D',
		borderRight: '1px solid #DA1E284D',
		borderBottom: '1px solid #DA1E284D',
	};
	const greenBorder = {
		borderLeft: '3px solid #24A148',
		borderTop: '1px solid #24A1484D',
		borderRight: '1px solid #24A1484D',
		borderBottom: '1px solid #24A1484D',
	};
	const alertProps = {
		border: redBorder,
		backgroundColor: '#FFF1F1',
		alertHeader: alert?.alertHeader,
		alertText: connectionError ? alert.alertText[0] : alert.alertText[1],
	};

	const alerInfoText = (type) => { 
		if(type==="webAccess"){
			return WebSuccessAlert.alertText[0]
		}else if(type==="ems"){
			return EMSSuccessAlert.alertText[0]
		}else if(type === 'sync'){
			return SyncSuccessAlert.alertText[0]
		}
	}

	const WebAccessSuccessAlert = {
		border: greenBorder,
		backgroundColor: '#DEFBE6',
		alertHeader:
			connectionType === 'sync'
				? SyncSuccessAlert?.alertHeader
				: WebSuccessAlert?.alertHeader,
		alertText: alerInfoText(connectionType) 
	};
	const WebAccessFailedAlert = {
		border: redBorder,
		backgroundColor: '#FFF1F1',
		alertHeader:
			connectionType === 'sync'
				? SyncFailedAlert.alertHeader
				: WebFailedAlert?.alertHeader,
		alertText:
			connectionType === 'ems'
				? EMSFailedAlert.alertText[0]
				: connectionType === 'sync'
				? SyncFailedAlert.alertText[0]
				: connectionType === 'webAccess'
				? WebFailedAlert.alertText[0]
				: null,
	};

	useEffect(() => {
		let data = { emsConnectionVerified: true };
		let isWebAccess = false;
		if (connectionType === 'webAccess') {
			data = { webAccessConnectionVerified: true };
			isWebAccess = true;
		}
		if (!postWebAccessLoading && !postWebAccessError && postWebAccessData) {
			setFormData(prevData => ({
				...prevData,
				edgeDevice: {
					...prevData?.edgeDevice,
					...data,
				},
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		postWebAccessError,
		postWebAccessLoading,
		postWebAccessData,
		connectionType,
	]);

	const handleChange = (e,type) => {


		const { name, value } = e?.target;
		if (
			['webAccessUrl', 'webAccessUser', 'webAccessPassword'].includes(name) &&
			value
		) {
			setFormTouched(false);
			setShowErrorBar(false);
		} else if (
			['emsAccessUrl', 'emsAccessUser', 'emsAccessPassword'].includes(name) &&
			value
		) {
			setFormTouched(false);
			setShowErrorBar(false);
		}  
		setFormError(prevErrors => ({
			...prevErrors,
			edgeDevice: {
				...prevErrors?.edgeDevice,
				[name]: '',
			},
		}));
		setFormChangeType(type)
		dispatch(updateEdgeDevice({[name]: value}))
		setFormData(prevData => ({
			...prevData,
			edgeDevice: { ...prevData?.edgeDevice, [name]: value },
		})); 
	};


	useEffect(()=>{
		 // Check if `emsAccessUser`, `emsAccessUrl`, and `emsAccessPassword` are empty
		 const isEmsAccessFieldsEmpty = !edgeDeviceFromData?.emsAccessUser &&
		 !edgeDeviceFromData?.emsAccessUrl &&
		 !edgeDeviceFromData?.emsAccessPassword;
		 // Check if `emsAccessUser`, `emsAccessUrl`, and `emsAccessPassword` are empty
		 const isWebAccessFieldsEmpty = !edgeDeviceFromData?.webAccessPassword &&
		 !edgeDeviceFromData?.webAccessUrl &&
		 !edgeDeviceFromData?.webAccessPassword;
		if(formChangeType=="web"){
			if (isWebAccessFieldsEmpty&&!isSiteEdit) {
				setWebFormUpdated(false);
			} else {
				setWebFormUpdated(true);
			}
		}else if(formChangeType==="ems"){
			if (isEmsAccessFieldsEmpty&&!isSiteEdit) {
				setEmsFormUpdated(false);
			} else {
				setEmsFormUpdated(true);
			}
		}



		// if(!isEmsAccessFieldsEmpty&&!isWebAccessFieldsEmpty){
		// 	setEmsFormUpdated(true);
		// 	setWebFormUpdated(true);
		// } 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[edgeDeviceFromData])

	const getValidation = (fieldName, value) => {
		if (fieldName === 'webAccessUrl' && value && !isValidUrl(value)) {
			return url1?.error;
		}
		if (fieldName === 'emsAccessUrl' && value && !isValidUrl(value)) {
			return url2?.error;
		}
		return '';
	};

	const isWebAccessFilled =
		edgeDevice?.webAccessUrl && edgeDevice?.webAccessUser;
	const isEMSAccessFilled =
		edgeDevice?.emsAccessUrl && edgeDevice?.emsAccessUser;

	const testEmsCall = async () => { 
		setProceedClickedOnEdgeDevice(false); 
		await handleEMSTest();
	};
	const testWebAccessCall = async () => {
		setProceedClickedOnEdgeDevice(false); 
		await handleWebAccessTest();
	};

	const testSyncCall = async () => {  
		await handleSync();
	};
	const buttonText = {
		loading: syncData?.syncButton?.loading,
		failed: syncData?.syncButton?.failed,
		success: syncData?.syncButton?.success,
		actionBtnSync: syncData?.syncButton?.default,
	};

	
	useEffect(() => {
		// Check for postWebAccessData condition
		if (postWebAccessData && !postWebAccessError && !postWebAccessLoading) {
			setSuccessAlert(true);
			setErrorAlert(false);
			setWebaccessSuccess(true) 
		}else if (postWebAccessError && !postWebAccessLoading&&!postWebAccessData) {
			setSuccessAlert(false);
			setErrorAlert(true);
			setWebaccessSuccess(false)
		}else if(!postWebAccessError&&!postWebAccessLoading&&isSiteEdit&&webAccessConnectionVerified){
			setWebaccessSuccess(webAccessConnectionVerified)
		}else {
			setSuccessAlert(false);
			setErrorAlert(false); 
		}
		setConnectionType("webAccess")
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postWebAccessData, postWebAccessError, postWebAccessLoading,webAccessConnectionVerified]);

	useEffect(() => { 
		if (postEMSData && !postEMSLoading && !postEMSError) {
			setSuccessAlert(true);
			setErrorAlert(false);
			
		} 
		else if(!postEMSData && !postEMSLoading && postEMSError) {
			setSuccessAlert(false);
			setErrorAlert(true);
		} 
		else {
			setSuccessAlert(false);
			setErrorAlert(false);
		}
		setConnectionType("ems")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postEMSData, postEMSLoading, postEMSError]);

	useEffect(() => {
		// Check for postEMSData condition
		if (alaramTagsData && !alaramTagsError && !alaramTagsLoading) {
			setSuccessAlert(true);
			setErrorAlert(false);
		} else if (!alaramTagsData && alaramTagsError && !alaramTagsLoading) {
			setSuccessAlert(false);
			setErrorAlert(true);
		} else {
			setSuccessAlert(false);
			setErrorAlert(false);
		}
		setConnectionType("sync")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alaramTagsData, alaramTagsLoading, alaramTagsError]);

 
const webAccessActionBtns = () => {
    if (postWebAccessLoading && !postWebAccessData && !postWebAccessError) {
      return {
        text: testConnectionBtn.loading,
        icon: <Lottie className="spinner" animationData={spinner} autoplay loop />
      };
    } else if ((postWebAccessData && !postWebAccessLoading && !postWebAccessError) ) {
      return {
        text: testConnectionBtn.success,
        icon: <SuccessIcon />
      };
    } else if (postWebAccessError && !postWebAccessLoading && !postWebAccessData) {
      return {
        text: testConnectionBtn.failed,
        icon: <RedButtonIcon />
      };
    } else {
      return {
		text: testConnectionBtn.default,
		icon: ''  //<SyncIcon />
      };
    } 
  }; 
  
  useEffect(() => {
    setWebAccessBtnLabel(webAccessActionBtns());
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postWebAccessData, postWebAccessLoading, postWebAccessError, webAccessConnectionVerified]);
  
// if Form update change the button state of Test connection
  useEffect(() => {
	if(webFormUpdated)
   {
	 setWebAccessBtnLabel({
		text:  testConnectionBtn.default,
		icon: '' // <SyncIcon />
      });
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webFormUpdated]);


  const syncActionBtns = () => {
    if (alaramTagsLoading && !alaramTagsData && !alaramTagsError) {
      return {
        text: buttonText.loading,
        icon: <Lottie className="spinner" animationData={spinner} autoplay loop />
      };
    } else if ((alaramTagsData && !alaramTagsLoading && !alaramTagsError) ) {
      return {
        text: buttonText.success,
        icon: <SuccessIcon />
      };
    } else if (alaramTagsError && !alaramTagsLoading && !alaramTagsData) {
      return {
        text: buttonText.failed,
        icon: <RedButtonIcon />
      };
    } else {
      return { 
		text:  buttonText.actionBtnSync,
		icon: <SyncIcon />
      };
    }
	 
  };
  useEffect(() => {
    setSyncBtnLabel(syncActionBtns());
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alaramTagsData, alaramTagsLoading, alaramTagsError,webAccessConnectionVerified]);
  

  useEffect(() => {
	if(webFormUpdated)
   {
	setSyncBtnLabel({
		text:  buttonText.actionBtnSync,
		icon:  <SyncIcon />
      });
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webFormUpdated]);
 
  const emsAccessActionBtns = () => {
    if (postEMSLoading && !postEMSData && !postEMSError) {
      return {
        text: testConnectionBtn.loading,
        icon: <Lottie className="spinner" animationData={spinner} autoplay loop />
      };
    } else if ((postEMSData && !postEMSLoading && !postEMSError) ) {
      return {
        text: testConnectionBtn.success,
        icon: <SuccessIcon />
      };
    } else if (postEMSError && !postEMSLoading && !postEMSData) {
      return {
        text: testConnectionBtn.failed,
        icon: <RedButtonIcon />
      };
    } else {
      return {
		text:  testConnectionBtn.default,
		icon: '' //<SyncIcon />
      };
    } 
  }; 
  
  useEffect(() => {
    setEmsAccessBtnLabel(emsAccessActionBtns());
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postEMSData, postEMSLoading, postEMSError,emsConnectionVerified]);
  // if Form update change the button state of Test connection
  useEffect(() => {
	if(emsFormUpdated)
   {
	 setEmsAccessBtnLabel({
		text:  testConnectionBtn.default,
		icon: '' // <SyncIcon />
      });
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emsFormUpdated]);
	return (
		<>
			{showErrorBar && (
				<CustomAlert
					severity="error"
					open={showErrorBar}
					{...alertProps}
					onClose={() => setShowErrorBar(false)}
					icon={
						<span sx={{ backgroundColor: 'white' }}>
							<RedErrorIcon color="white" />
						</span>
					}
				></CustomAlert>
			)}

			{showErrorAlert && (
				<CustomAlert
					severity="error"
					open={true}
					{...WebAccessFailedAlert}
					onClose={() => setErrorAlert(false)} // Close handler for WebAccessFailedAlert
					icon={
						<span sx={{ backgroundColor: 'white' }}>
							<RedErrorIcon color="white" />
						</span>
					}
				/>
			)}

			{showSuccessAlert && (
				<CustomAlert
					severity="success"
					open={true}
					{...WebAccessSuccessAlert}
					onClose={() => setSuccessAlert(false)}
					icon={
						<span sx={{ backgroundColor: 'white' }}>
							<SuccessIcon color="white" />
						</span>
					}
				></CustomAlert>
			)}

			<Box
				sx={{
					py: '16px',
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
				}}
			>
				<Box
					sx={{ display: 'flex', flexDirection: 'column', width: '50%', mr: 2 }}
				>
					<Typography className="commonSubheaderStyle">{title1}</Typography>
					<CustomTextField
						id="webAccessUrl"
						name="webAccessUrl"
						label={url1.label}
						isUrl={isValidUrl(edgeDevice?.webAccessUrl)}
						placeholder={url1.placeholder}
						value={edgeDevice?.webAccessUrl}
						handleChange={(e)=>handleChange(e,"web")}
						width="100%"
						error={
							formTouched &&
							getValidation('webAccessUrl', edgeDevice?.webAccessUrl)
						}
						helperText={
							formTouched &&
							getValidation('webAccessUrl', edgeDevice?.webAccessUrl)
						}
					/>
					<CustomTextField
						id="webAccessUser"
						name="webAccessUser"
						label={user1.label}
						placeholder={user1.placeholder}
						handleChange={(e)=>handleChange(e,"web")}
						value={edgeDevice?.webAccessUser}
						width="100%"
						required
						maxLength={16}
					/>
					<InputLabel
						htmlFor="input-with-icon-adornment"
						sx={{
							marginBottom: '6px',
							mt: 2,
						}}
						className="globalInputLabelStyle"
					>
						{password1.label}
					</InputLabel>
					<CustomPasswordField
						id="webAccessPassword"
						name="webAccessPassword"
						placeholder={password1.placeholder}
						handleChange={(e)=>handleChange(e,"web")}
						value={edgeDevice?.webAccessPassword}
						width="100%"
						height="40px"
						maxLength={8}
					/>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					 
					 <CustomButton
							id="webaccess"
							onClick={testWebAccessCall} // Assuming this function is defined elsewhere
							disabled={!isWebAccessFilled}
							variant="contained"
						> 
							{webAccessBtnLabel.text}
							<span className="inline-icon">
							{webAccessBtnLabel.icon}  
							</span>
						</CustomButton> 
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
					<Typography className="commonSubheaderStyle">{title2}</Typography>
					<CustomTextField
						id="emsAccessUrl"
						name="emsAccessUrl"
						label={url2.label}
						placeholder={url2.placeholder}
						handleChange={(e)=>handleChange(e,"ems")}
						value={edgeDevice?.emsAccessUrl} 
						isUrl={isValidUrl(edgeDevice?.emsAccessUrl)}
						width="100%" 
						error={
							formTouched &&
							getValidation('emsAccessUrl', edgeDevice?.emsAccessUrl)
						}
						helperText={
							formTouched &&
							getValidation('emsAccessUrl', edgeDevice?.emsAccessUrl)
						}
					/>
					<CustomTextField
						id="emsAccessUser"
						name="emsAccessUser"
						label={user2.label}
						placeholder={user2.placeholder}
						handleChange={(e)=>handleChange(e,"ems")}
						value={edgeDevice?.emsAccessUser}
						width="100%"
						// disabled={disableTab === 2}
						maxLength={256}
						minLength={1}
						error={
							formTouched &&
							getValidation('emsAccessUser', edgeDevice?.emsAccessUser)
						}
						helperText={
							formTouched &&
							getValidation('emsAccessUser', edgeDevice?.emsAccessUser)
						}
					/>
					<InputLabel
						htmlFor="input-with-icon-adornment"
						sx={{
							marginBottom: '6px',
							mt: 2,
						}}
						className="globalInputLabelStyle"
					>
						{password2.label}
					</InputLabel>
					<CustomPasswordField
						id="emsAccessPassword"
						name="emsAccessPassword"
						placeholder={password2.placeholder}
						handleChange={(e)=>handleChange(e,"ems")}
						value={edgeDevice?.emsAccessPassword}
						width="100%"
						// disabled={disableTab === 2}
						maxLength={128}
						minLength={6}
					/>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
						<CustomButton
						id="emsaccess"
							onClick={testEmsCall} // Assuming this function is defined elsewhere
							disabled={!isEMSAccessFilled}
							variant="contained"
						>	{emsAccessBtnLabel.text} 
							<span className="inline-icon">
							{emsAccessBtnLabel.icon}   
							</span>
						</CustomButton>
					</Box>
				</Box>
			</Box>
			<Box className="edgeDeviceBox">
				<Typography className="commonSubheaderStyle cardTitle">
					{syncModal?.title}
				</Typography>
				<Box className="edgeDeviceCard">
					<Typography variant="span">
						{syncModal?.content1} {siteName} {syncModal?.content2}
					</Typography>
					<Box className="syncInnerBox">
						<Box sx={{ paddingRight: 4 }}>
							<Typography className="header1">{syncModal?.header1}</Typography>
							<Typography variant="span">
								{formatDate(
									siteData?.lastSyncTime ? siteData?.lastSyncTime : syncTime
								)}
							</Typography>
						</Box>
						<Box>
							<Typography className="header1">{syncModal?.header2}</Typography>
							<Typography variant="span">
								{siteData?.tagCount
									? siteData?.tagCount
									: alaramTagsData?.alarmTags?.length}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginTop: '16px',
					}}
				>  
				 <CustomButton
						onClick={testSyncCall} // Assuming this function is defined elsewhere
						disabled={!(webaccessSuccess)}
						variant="contained"
						sx={{
							width: '170px',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						 {syncBtnLabel?.text} 
						<span className="inline-icon">
						{syncBtnLabel?.icon}  
						</span>
					</CustomButton> 
				</Box>
			</Box>
		</>
	);
};

export default EdgeDevice;
