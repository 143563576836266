import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  Table,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { withStyles } from "@mui/styles";
import Lottie from 'lottie-react';
import { ReactComponent as LocationIcon } from "../../assets/images/LocationOutlined.svg";
import { ReactComponent as EmsLogo } from "../../assets/images/EmsBlackicon.svg";
import { ReactComponent as BellIcon } from "../../assets/images/SmallAlarm.svg";
import { ReactComponent as ChartIcon } from "../../assets/images/ChartColumn.svg";
import { updateEdgeDeviceToken } from "../../redux/slices/loginInfoSlice";
import { getSiteImage, edgeDeviceUrl } from "../../services/apiConfig";
import GMap from '../../components/setting/CustomGoogleMap';
import "./LandingPages.scss";
import CustomModal from '../../common/commonPopup/CustomModal';
import { formatUnit } from "../../common/utils/CommonUtils";
import usePost from "../../common/hook/usePost";
import { electricityConsumptionAnalyticsURL } from "../../services/apiConfig";
import spinner from "../../assets/animation/spinner.json";
import AmChartComponent from "./Chart";
import DateRangeCalendar from "../../common/DateRangePicker/DateRangeCalendar";
import { formatToCustomDateString } from "../../common/utils/CommonUtils";
import { convertDateTimeToTimestamp } from "../../common/utils/CommonUtils";
import { formatDate } from "../../common/utils/CommonUtils";
import { cloudfrontURL } from "../../services/apiConfig";

const formatValue = (value) => {
  if (value >= 1e9) {
    const formattedValue = (value / 1e9).toFixed(0);
    return `${formattedValue}B`;
  }
  if (value >= 1e6) {
    const formattedValue = (value / 1e6).toFixed(0);
    return `${formattedValue}M`;
  }
  // For values less than 1 million, show two decimal places only if necessary
  const formattedValue = value.toFixed(2);
  return Number(formattedValue) === Math.floor(value) ? value.toFixed(0) : formattedValue;
};


const SiteGrid = (props) => {
  const { t } = useTranslation();
  const {
    landingPage: {
      homeTableHeaderStructure,
      siteListTableHeader,
      gridLoadingText,
    }, energyChart,
    DateRangeSelection,
  } = t("mainContainer");
  const {
    siteLength,
    tableData,
    tooltipColumns = [0, 1],
    siteListCount,
  } = props;

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "1em",
    },
  })(Tooltip);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [itemsToShow, setItemsToShow] = useState(5); // Number of items to show initially
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true); // Check if there are more items to load
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [presetLabel, setPresetLabel] = useState('Today');
  const { recentSearchId } = useSelector((state) => state?.customer); // Accessing recentSearchId from the Redux store
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [selectedSiteName, setSelectedSiteName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openChartModal, setOpenChartModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [imageLoading, setImageLoading] = useState(false)
  const [imageError, setImageError] = useState(false)
  const observer = useRef();
  const loadMoreRef = useRef();
  const theme = useTheme()
  const [updatedData, setUpdatedData] = useState([])
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost(); // Custom hook for handling API requests

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedSites = useMemo(() => {
    if (!tableData) return [];
    const sortFunction = (a, b) => {
      if (orderBy === "siteName") {
        return order === "asc"
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy]);
      } else {
        return order === "asc"
          ? (a[orderBy] || 0) - (b[orderBy] || 0)
          : (b[orderBy] || 0) - (a[orderBy] || 0);
      }
    };

    return tableData.slice(0, itemsToShow).sort(sortFunction); // Only show items based on itemsToShow
  }, [tableData, order, orderBy, itemsToShow]);

  useEffect(() => {
    const updatedDatum = tableData?.length && tableData?.map(({ address1, location, description, imageUrl, thumbnailUrl, siteId, ...rest }) => ({
      ...rest,
      address: address1,
      country: "Japan",
      location: location,
      imageUrl,
      thumbnailUrl,
      key: siteId,
      description: description
    }));
    setUpdatedData(updatedDatum);
  }, [tableData])

  const loadMoreItems = () => {
    if (itemsToShow > siteListCount) {
      setHasMoreItems(false);
    } else if (isLoading || !hasMoreItems) return;
    setIsLoading(true);
    // Show the loader for 2 seconds
    setTimeout(() => {
      const newItemsToShow = itemsToShow + 5;
      if (newItemsToShow >= siteListCount) {
        setHasMoreItems(false);
      }
      setItemsToShow(newItemsToShow);
      setIsLoading(false);
    }, 1200);
  };

  useEffect(() => {
    const currentObserver = loadMoreRef?.current;
    if (!currentObserver) return;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    };
    observer.current = new IntersectionObserver(handleIntersect, options);
    observer.current.observe(currentObserver);
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMoreItems]);


  const renderTableCellContent = (content, colIndex) => {
    return tooltipColumns?.includes(colIndex) ? (
      <CustomTooltip title={content} placement="bottom-start">
        <span>{content === 0 ? "—" : content}</span>
      </CustomTooltip>
    ) : (
      <>{content === 0 ? "—" : content}</>
    );
  };

  const handleOpenGMap = async (row) => {
    let imageResponse = "";
    try {
      setImageLoading(true);
      setImageError(false);
      if (row?.imageUrl) {
        const payload = { imageUrl: row?.imageUrl };
        const token = localStorage?.getItem("accessToken");

        imageResponse = await axios.post(getSiteImage, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      }
      const updatedSite = {
        ...row,
        key: row?.siteId,
        address: row?.address1,
        country: "Japan",
        imageUrl: imageResponse?.data?.signedUrl,
      };
      setSelectedSite(updatedSite);
      // Preload the image before setting loading to false
      const img = new Image();
      img.onload = () => {
        setImageLoading(false);
      };
      img.onerror = () => {
        setImageError(true);
        setImageLoading(false);
      };
      img.src = updatedSite?.imageUrl;
      // Open the modal after setting the selected site
      setOpenModal(true);
    } catch (error) {
      setImageError(true);
      setImageLoading(false);
      console.error("Failed to fetch image:", error);
      // Still open the modal, but with the error state set
      setOpenModal(true);
    }
  };


  const handleClose = () => {
    setOpenModal(false);
  };
  const dummyImage = "https://via.placeholder.com/50";


  /**
 * Opens the modal dialog.
 */
  const handleOpen = (item, row) => {
    console.log(item, row);
    setOpenChartModal(true);
    setSelectedSiteId(row.siteId);
    setSelectedSiteName(row.siteName);
    setPresetLabel('Today'); // Reset presetLabel to 'Today'
  };

  /**
 * Closes the modal dialog.
 */
  const handleCloseChart = () => {
    setOpenChartModal(false);
  };


  /**
   * Handle changes in the date range picker.
   * 
   * @param {Array} dateRange - The selected date range from the DateRangeCalendar component.
   */
  const handleDateRangeChange = (dateRange) => {
    const start = dateRange.value[0];
    const end = dateRange.value[1];


    const [dateStartString, dateEndString] = dateRange.value;

    const addOffsetToTimestamp = (dateString) => {
      const dateObject = new Date(dateString);
      const gmtTimestamp = dateObject.getTime();
      const offsetInMilliseconds = (5 * 60 * 60 * 1000) + (30 * 60 * 1000); // +05:30 hours
      return gmtTimestamp + offsetInMilliseconds;
    };

    const newStartTimestamp = addOffsetToTimestamp(dateStartString);
    const newEndTimestamp = addOffsetToTimestamp(dateEndString);

    // To convert a date string from the format YYYY/MM/DD HH:mm 
    const customDateStartString = formatToCustomDateString(formatDate(dateStartString));
    const customDateEndString = formatToCustomDateString(formatDate(dateEndString));

    setStartDate(start);
    setEndDate(end);
    setPresetLabel(dateRange.label);



    // Calculate the difference in milliseconds
    const diffInMs = newEndTimestamp - newStartTimestamp;

    // Convert milliseconds to days
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // Determine the interval based on the difference
    let interval;
    if (diffInDays <= 1) { // 24 hours or less
      interval = "1h";
    } else if (diffInDays > 1 && diffInDays < 31) { // More than 24 hours and less than 31 days
      interval = "1d";
    } else if (diffInDays >= 31 && diffInDays < 365) { // More than 31 days and less than 12 months
      interval = "1mo";
    } else { // More than 12 months
      interval = "1y"; // Adjust this if you have a different interval for years
    }

    const requestPayload = {
      searchId: recentSearchId,
      startDate: convertDateTimeToTimestamp(customDateStartString),
      endDate: convertDateTimeToTimestamp(customDateEndString),
      interval: interval // Use the calculated interval
    };

    doPostPrefectureApi(electricityConsumptionAnalyticsURL, requestPayload); // Send the date range and interval to the API
  };

  const filteredElectricityData = useMemo(() => {
    if (!prefectureApiData || !selectedSiteId) return [];
    return prefectureApiData.result.sites.filter(site => site.siteId === selectedSiteId);
  }, [prefectureApiData, selectedSiteId]);

  const totalElectricitySum = useMemo(() => {
    return filteredElectricityData.reduce((sum, item) => sum + (item.electricity || 0), 0);
  }, [filteredElectricityData]);

  const viewSiteAlarm = (site) => {
    navigate('/alarm', { state: { siteName: site.siteName } }); // Indicate the source of navigation

  };

  const handleEmsClicked = (row) => {
    window.emsTabReferences = window.emsTabReferences || [];
    const language = localStorage.getItem("i18nextLng");
    const edgeDeviceLoginUrl = `${row?.emsAccessUrl}/auth?token=${row?.edgeDeviceToken}`;
    const languageChangeUrl = `${row?.emsAccessUrl}/home?lang=${language}`;
    dispatch(updateEdgeDeviceToken(row?.edgeDeviceToken))
    // Open a new tab and generate a unique ID for it
    const newTab = window.open(edgeDeviceLoginUrl, '_blank');
    const tabId = Date.now(); // Using timestamp as a unique ID for each tab

    // Only add the tab to the array if it was successfully opened
    if (newTab) {
      window?.emsTabReferences?.push({ id: tabId, tab: newTab });
      // Store the URL in window.name if needed
      window.name = JSON.stringify({ key: "value", emsTabUrl: edgeDeviceLoginUrl });

      // Set the language change URL after a delay
      setTimeout(() => {
        if (!newTab.closed) {
          newTab.location.href = languageChangeUrl;
        }
      }, 10000);
    } else {
      console.error("Failed to open the new EMS tab.");
    }
  }

  return (
    <div className="site-grid-container emailNotificationBox">
      <TableContainer
        component={Paper}
        sx={{
          marginLeft: "0",
          marginRight: "0",
          "&.MuiTableContainer-root": { height: "100%" },
          "&.MuiPaper-root": {
            height: "100%",
            boxShadow: "none",
          },
        }}
      >
        <Table aria-label="custom table" className="customSiteGrid">
          <TableHead className="siteGridTableHead">
            <TableRow>
              {siteListTableHeader?.map((header, colIndex) => {
                const isActiveColumn = orderBy === header.accessor;
                const headerBackgroundColor = isActiveColumn ? "#C6C6C6" : "#E0E0E0";
                return (
                  <TableCell
                    key={`table_header_action_${header.label}`}
                    sortDirection={isActiveColumn ? order : false}
                    className="siteGridHeadCell"
                    sx={{
                      backgroundColor: headerBackgroundColor,
                      cursor: header?.label ? "pointer" : "default",
                      position: "relative",
                      width: header.accessor === "actionMenu" ? "4%" : "32%",
                      overflow: "hidden",
                    }}
                    onClick={() => header?.label && handleRequestSort(header.accessor)}
                  >
                    {header?.label && (
                      <div
                        className={`siteGridHeaderDiv ${header.accessor === "electricity"
                          ? "electricity"
                          : header.accessor === "alarmCount"
                            ? "alarmCount"
                            : ""
                          }`}
                      >
                        {header.accessor !== "siteName" && (
                          <TableSortLabel
                            active={isActiveColumn}
                            direction={isActiveColumn ? order : "asc"}
                            className="sortLabel"
                          />
                        )}
                        <div>{homeTableHeaderStructure[colIndex]}</div>
                        {header.accessor === "siteName" && (
                          <TableSortLabel
                            active={isActiveColumn}
                            direction={isActiveColumn ? order : "asc"}
                            className="sortLabel"
                          />
                        )}
                      </div>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSites?.map((row, index) => (
              <TableRow
                key={`table_row_${row?.siteName}`}
                className="gridBodyRow"
                onMouseEnter={() => setHoveredRowIndex(index)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                {siteListTableHeader?.map((headerVal, headerIndx) => {
                  if (headerVal?.accessor === "siteName") { 
                    let updatedUrl = ""
                    if (row?.imageUrl) {
                      updatedUrl = row?.imageUrl.replace(/^(https?:\/\/)[^/]+/, cloudfrontURL);
                    }
                    return (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        className="siteGridNameCell"
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        <img
                          src={updatedUrl || dummyImage}
                          alt="Site"
                          className="siteGridImageStyle"
                        />
                        <div className="siteGridNameAndLocation">
                          <Typography className="body1">
                            {row?.siteName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" className="stateAndCountry">
                            {row?.state}, Japan
                          </Typography>
                        </div>
                      </TableCell>
                    );
                  } else if (headerVal?.accessor === "electricity") {
                    return (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        className="siteGridElectricityCell"
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        <div className={`siteGridValueAndUnit ${row?.electricity === 0 ? "noData" : "withData"}`}>
                          <Typography className="body1">
                            {row?.electricity === 0 ? "—" : row?.electricity}
                          </Typography>
                          {row?.electricity !== 0 &&
                            <Typography className="unitBlock">
                              <>
                                <span span className="accordionUnitStyle">
                                  {formatUnit(row?.electricity)}
                                </span>
                                <span className="kwhTypo">
                                  kWh
                                </span>
                              </>

                            </Typography>}
                        </div>
                      </TableCell>
                    );
                  }
                  else {
                    return (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        className="siteGridAlarmCell"
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        {hoveredRowIndex === index ?
                          <div className="iconContainer">
                            <IconButton size="small" className="actionIcons" disabled={!row?.edgeDeviceToken} onClick={() => handleEmsClicked(row)}
                              sx={{ opacity: !row?.edgeDeviceToken ? 0.3 : 1, }}
                            ><EmsLogo /></IconButton>
                            <IconButton size="small" className="actionIcons" onClick={() => handleOpenGMap(row)}><LocationIcon /></IconButton>
                            <IconButton size="small" className="actionIcons" onClick={() => handleOpen(headerVal, row)}><ChartIcon /></IconButton>
                            <IconButton size="small" className="actionIcons" onClick={() => viewSiteAlarm(row)} ><BellIcon /></IconButton>
                          </div>
                          :
                          <div className="alarmCountContainer">
                            {renderTableCellContent(row?.[headerVal?.accessor], headerIndx)}
                          </div>
                        }
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && hasMoreItems && (
        <div className="loading-indicator">
          <Typography>{gridLoadingText}</Typography>
        </div>
      )
      }
      <div ref={loadMoreRef} />
      <CustomModal
        title={selectedSite?.siteName}
        open={openModal}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg"
        contentPadding={true}
      >
        {!updatedData && <div>Something went wrong</div>}
        {updatedData?.length &&
          <GMap
            isSiteGridMap={true}
            locat={updatedData}
            selectedSite={selectedSite}
            imageLoading={imageLoading}
            imageError={imageError}
            setSelectedSite={setSelectedSite}
          />}
      </CustomModal>

      <CustomModal
        open={openChartModal}
        onClose={handleCloseChart}
        title={selectedSiteName}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl"
      >
        <Box className="chart-info" sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <Box sx={{
            display: "inline-flex",
          }}>
            <div className="chartInfo">
              <span className="sourceType">{energyChart?.Electricity} </span>
              {totalElectricitySum === 0 ? (
                <>
                  <span className="EnergyValue">{" — "}</span>
                  <span className="energyFormat"> kWh</span>
                  {" / "}
                </>
              ) : (
                <>
                  <span className="EnergyValue">{formatValue(totalElectricitySum)}</span>
                  <span className="energyFormat"> kWh</span>
                  {" / "}
                  <span className="comparisonLabel">{DateRangeSelection?.shortCuts[presetLabel]} vs. {energyChart?.PreviousPeriod}</span>
                </>
              )}
            </div>
            <div className="DateRangeCalender">
              <DateRangeCalendar
                initialLoad={initialLoad}
                setInitialLoad={setInitialLoad}
                onDateRangeChange={handleDateRangeChange}  // Callback for date range changes
                id="CharttopNavDatePicker"
              />
            </div>
          </Box>
          {!prefectureApiData && !prefectureApiError && prefectureApiLoading && <div className="loading spinner-wrapper blue-spinner">
            <Lottie className="spinner" animationData={spinner} autoplay loop />
          </div>}
          {prefectureApiData && !prefectureApiError && !prefectureApiLoading && (
            filteredElectricityData.length === 0 || prefectureApiData?.result?.sites?.length === 0 ? (
              <div className="no-data-message">{energyChart.noDataText}</div>
            ) : (
              <AmChartComponent
                startDate={startDate}
                endDate={endDate}
                presetLabel={presetLabel}
                electricityData={filteredElectricityData}
                customerName={prefectureApiData?.result?.customerName}
                descriptionName={selectedSiteName}
              />
            )
          )}
            {(!prefectureApiData && prefectureApiError && !prefectureApiLoading) &&  <div className="no-data-message">{energyChart.noDataText}</div> }
        </Box>
      </CustomModal>
    </div>
  );
};
export default SiteGrid;