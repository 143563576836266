/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import RightSection from "./RightSection";
import LeftSection from "./LeftSection";
import { useNavigate } from "react-router-dom/dist";
import { NWC_HOME_URL } from "../../common/constants/Constants";
function LoginPage() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (accessToken) {
      navigate(NWC_HOME_URL);
    }
  }, []);
  return (
    <Grid container className="loginContainer">
      <LeftSection />
      <RightSection isAuthenticated={accessToken} />
    </Grid>
  );
}

export default LoginPage;
