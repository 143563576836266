import { useContext } from 'react';
import { ROLE_PERMISSIONS, RESOURCES, ACTIONS } from './Permissions';

export const usePermissions = (userRole) => {
  const hasPermission = (resource, action) => {
    const rolePermissions = ROLE_PERMISSIONS[userRole];
    if (!rolePermissions) return false;

    const resourcePermissions = rolePermissions.permissions.find(
      p => p.resource === resource
    );
    
    return resourcePermissions?.actions.includes(action) || false;
  };

  const canView = (resource) => hasPermission(resource, ACTIONS.VIEW);
  const canEdit = (resource) => hasPermission(resource, ACTIONS.EDIT);
  const canAcknowledge = (resource) => hasPermission(resource, ACTIONS.ACKNOWLEDGE);

  return {
    hasPermission,
    canView,
    canEdit,
    canAcknowledge
  };
};