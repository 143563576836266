// src/redux/customerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCustomers: [],
  myFavoriteLists: [],
  myRecentSearchList: [],
  recentSearchId: "",
  selectedCategory: {},
  selectedAreas: [],
  selectedSites: [],
  isUpdateRecentSearchId: false,
  isDefaultDeleted: false,
  updateBeadcrumbs: true,
  DateRangeFilter: {},
  isUserManagementFirstRendered: true,
  isDefaultFavorite: false,
};
const customerFilterSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetCustomerFilterState: (state) => {
      return initialState;
    },
    setIsDefaultFavorite(state, action) {
      state.isDefaultFavorite = action.payload;
    },
    setAllCustomers(state, action) {
      state.allCustomers = action.payload;
    },
    setUpdateBeadcrumbs(state, action) {
      state.updateBeadcrumbs = action.payload;
    },
    setDateRangeFilter(state, action) {
      state.DateRangeFilter = action.payload;
    },
    updateUserManagementFirstRendered(state, action) {
      state.isUserManagementFirstRendered = action.payload;
    },
    setUpdateRecentSearchId(state, action) {
      state.isUpdateRecentSearchId = action.payload;
    },
    setDefaultDelete(state, action) {
      state.isDefaultDeleted = action.payload;
    },
    setMyRecentSearchList(state, action) {
      state.myRecentSearchList = action.payload;
    },
    setMyFavoriteLists(state, action) {
      state.myFavoriteLists = action.payload;
    },
    deleteFavoriteItem(state, action) {
      const idToRemove = action.payload; // assuming payload contains the id of the item to remove
      state.myFavoriteLists = state.myFavoriteLists.filter(
        (item) => item.id !== idToRemove
      );
    },
    setRecentSearchId(state, action) {
      state.recentSearchId = action.payload;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setSelectedAreas(state, action) {
      state.selectedAreas = action.payload;
    },
    setSelectedSites(state, action) {
      state.selectedSites = action.payload;
    },
    setSelection(state, action) {
      state.selectedCategory = action.payload.selectedCategory;
      state.selectedAreas = action.payload.selectedAreas;
      state.selectedSites = action.payload.selectedSites;
    },
  },
});

export const {
  setIsDefaultFavorite,
  setDateRangeFilter,
  setUpdateBeadcrumbs,
  setUpdateRecentSearchId,
  setMyRecentSearchList,
  setDefaultDelete,
  setAllCustomers,
  setMyFavoriteLists,
  deleteFavoriteItem,
  setRecentSearchId,
  setSelectedCategory,
  setSelectedAreas,
  setSelectedSites,
  updateUserManagementFirstRendered,
  setSelection,
  resetCustomerFilterState
} = customerFilterSlice.actions;
export default customerFilterSlice.reducer;
