import { useEffect } from 'react';
import { useDispatch } from "react-redux";
/**
 * Custom hook for handling 'updatedAlarmCount' socket messages and updating local state
 */
export const useSocketUpdatedAlarmSummary = (socket, setAlarmSummaryList, alarmSummaryList, recentSearchId) => {
  
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
  
      // Handle socket connection errors
      socket.on("connect_error", (error) => {
        // Optionally retry connection or notify the user
        console.error("Socket connection error:", error);
      });
  
    // Handler for the updated alarm count from socket
    const handleUpdatedAlarmSummary = (message) => {
      try {
        const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
        setAlarmSummaryList(parsedMessage);
      } catch (error) {
        console.error("Error parsing the message:", error);
      }
    };
  
    // Notify the backend that this user is connected with the recentSearchId
    socket.emit('alarm-summary-connected', recentSearchId);
    
    // Listen for 'updatedAlarmCount' event from the socket
    socket.on('updatedAlarmData', handleUpdatedAlarmSummary);
  
    // Clean up socket listener on unmount or when socket changes
    return () => { 
      socket.off('alarm-summary-disconnect');
      socket.off('updatedAlarmData', handleUpdatedAlarmSummary);
      socket.off('connect_error');
    };
  }, [socket, setAlarmSummaryList, recentSearchId]); // Remove accordionList dependency if not directly used
  
 
}; 

export const useSocketUpdatedAlarmLog = (socket, setAlarmLogList, socketAlarmLog, recentSearchId) => {
  
   
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
  
    // Handle socket connection errors
    socket.on("connect_error", (error) => {
      // Optionally retry connection or notify the user
      console.error("Socket connection error:", error);
    });


    // Handler for the updated alarm count from socket
    const handleUpdatedAlarmLog = (message) => {
      try {
        const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
        setAlarmLogList(parsedMessage);
      } catch (error) {
        console.error("Error parsing the message:", error);
      }
    };
  
    // Notify the backend that this user is connected with the recentSearchId
    socket.emit('alarm-log-connected', recentSearchId);
    
    // Listen for 'updatedAlarmCount' event from the socket
    socket.on('fetchLatestAlarmLogs', handleUpdatedAlarmLog);
  
    // Clean up socket listener on unmount or when socket changes
    return () => {
      socket.off('alarm-log-disconnect');
      socket.off('fetchLatestAlarmLogs', handleUpdatedAlarmLog);
      socket.off('connect_error');
    };
  }, [socket, setAlarmLogList, recentSearchId]); // Remove accordionList dependency if not directly used

}; 


export const useSocketAlarmCountOnLandingPage = (socket,updateAccordionList,accordionList, recentSearchId) => {  
  const dispatch=useDispatch()
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
        // Handle socket connection errors
     socket.on("connect_error", (error) => {
           // Optionally retry connection or notify the user
        console.error("Alarm count socket connection error:", error);
      });
    if (recentSearchId) {
          // Handler for the updated alarm count from socket
      const handleUpdatedAlarmCount = (socketData) => {
      try {
            if (socketData && socketData?.siteId) {
            // Map over accordionList and update the alarmCount for the matching siteId
            const updatedAccordionList = accordionList?.map(accordionItem => {
              if (accordionItem?.siteId === socketData?.siteId) {
                return {
                  ...accordionItem,
                  alarmCount: socketData.alarmCount
                };
              }
              return accordionItem;
            });
            // Check if any changes were made in alarmCount
            const hasUpdates = updatedAccordionList?.some(
              (item, index) => item.alarmCount !== accordionList[index]?.alarmCount
            );
            // Dispatch only if there are updates in alarmCount
            if (hasUpdates) {
              dispatch(updateAccordionList(updatedAccordionList));
            }
          }
      } catch (error) {
        console.error("Error parsing the alarm count socket data:", error);
      }
    };
      socket.emit('alarm-count-connected', recentSearchId);
      socket.on('updatedAlarmCount',handleUpdatedAlarmCount)
      // Clean up socket listeners on component unmount
      return () => {
        socket.off("updatedAlarmCount",handleUpdatedAlarmCount);
        socket.off("connect_error");
        socket.off('alarm-count-connected');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket,accordionList]);
 
}; 

export const useSocketElectricityOnLandingPage = (socket,updateAccordionList,DateRangeFilter,accordionList, recentSearchId) => {  
  const dispatch = useDispatch()
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
    // Handle socket connection errors
    socket.on("connect_error", (error) => {
        console.error("Energy socket connection error:", error);
    });
    //check if recentSearchId is available and Today is selected in the calendar
    if (recentSearchId && DateRangeFilter?.label === "Today") {
          // Handler for the updated electricity count from socket
      const handleUpdatedElectricityCount = (socketData) => {
      try {
        if (socketData && socketData?.siteId) {
            // Map over accordionList and update the electricity for the matching siteId
          const updatedAccordionList = accordionList?.map(accordionItem => {
              if (accordionItem?.siteId === socketData?.siteId) {
                const newElectricityValue = Number(accordionItem?.electricity) + Number(socketData?.electricity);
                return {
                  ...accordionItem,
                  electricity:newElectricityValue
                };
              }
              return accordionItem;
            });
            // Check if any changes were made in electricity
            const hasUpdates = updatedAccordionList?.some(
              (item, index) => item.electricity !== accordionList[index]?.electricity
            );
            // Dispatch only if there are updates in electricity
            if (hasUpdates) {
              dispatch(updateAccordionList(updatedAccordionList));
            }
          }
      } catch (error) {
        console.error("Error parsing the energy socket data:", error);
      }
    };
      socket.emit('electricity-connected', recentSearchId);
      socket.on('fetchRecentEnergyConsumption', handleUpdatedElectricityCount);
      // Clean up socket listeners on component unmount
      return () => {
        socket.off("fetchRecentEnergyConsumption",handleUpdatedElectricityCount);
        socket.off("connect_error");
        socket.off('electricity-connected');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket,accordionList]);
 
}; 