import React, { useState } from 'react';
import { TextField, MenuItem, Select, Box, InputAdornment } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ReactComponent as downArrow } from "../../../../../assets/images/smallArrowDown.svg";
import { ReactComponent as SearchIcon } from '../../../../../assets/images/SearchIcon.svg';
import { ReactComponent as CloseIcon } from "../../../../../assets/images/CloseIcon.svg";
import { isAlphaNumeric } from '../../../../../common/utils/CommonUtils';
import { customerTabStyles } from './allCustomerTab/AllCustomerTabStyle';

const CommonSearch = ({ onSearch, activeTab, searchType, setSearchType, searchQuery, setSearchQuery, filteredSearchOptions}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { t } = useTranslation();
    const {
        landingPage: { commonSearchOptions, landingPageSearchPlaceHolder }
    } = t("mainContainer");
     const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setSearchQuery('');
        onSearch(event.target.value, '', activeTab);
    };

    const handleSearchChange = (event) => {
        const query = event?.target?.value;
        const isPasteEvent = event.inputType === 'insertFromPaste';
        if (isPasteEvent || query === '' || isAlphaNumeric(query)) {
            setSearchQuery(query);
            onSearch(searchType, query, activeTab);
        }
    };

    const handleClear = () => {
        setSearchQuery("");
    }

    const getSearchInputProps = onClose => ({
        endAdornment: (
            <InputAdornment
                position="end"
                sx={{
                    '& .MuiSvgIcon-root': {
                        padding: '1px',
                    },
                }}
            >
                {searchQuery?.length === 0 ? <SearchIcon style={{ marginRight: '-5px' }} /> : <CloseIcon onClick={() => onClose()} style={{ cursor: 'pointer', marginRight: "-2px" }} />}
            </InputAdornment>
        ),
    });

    return (
        <Box
            sx={{

                ...customerTabStyles?.searchBox,
                border: isFocused ? '2px solid #0F62FE' : '1px solid #E0E0E0',
            }}
        >
            <Select
                value={searchType}
                onChange={handleSearchTypeChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Search type' }}
                sx={customerTabStyles?.searchSelect}
                disableUnderline
                variant="standard"
                IconComponent={downArrow}
                onFocus={handleFocus}
                onBlur={handleBlur}
            > 
                {filteredSearchOptions?.map(option => (
                    <MenuItem key={option.id} value={option?.value} disabled={option?.value === "favorite" && activeTab == 1} >{option?.label}</MenuItem>
                ))}
            </Select>
            <TextField
                autoComplete="off"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e)}
                placeholder={landingPageSearchPlaceHolder}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={getSearchInputProps(handleClear)}
                sx={{ ...customerTabStyles?.searchTextfield, borderLeft: isFocused ? '2px solid #0F62FE' : '1px solid #E0E0E0', }}
                onFocus={handleFocus}
                onBlur={handleBlur}

            />
        </Box>
    );
};


export default CommonSearch;