import React from "react";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const CustomButton = ({
  className,
  variant = "contained",
  size = "medium",
  fontSize = "",
  onClick,
  disabled = false,
  children,
  icon = "",
  width = "",
  iconPosition = "",
  marginRight = "",
  height = "40px",
  marginBottom = "",
  padding = "",
  textColor = "",
  backgroundColor = "primary",
  fontWeight = "",
  lineHeight,
  letterSpacing,
  type,
  buttonColor = "primary",
  buttonMarginLeft,
  sx
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0F62FE", // rgba(15, 98, 254, 1)
      },
      secondary: {
        main: "#ffcc80", //Another orange-ish color
      },
      hoverColorForPrimary: {
        main: "#0353E9",
      },
    },
  });
  const iconWithMargin = (
    <span
      style={{ margin: iconPosition === "end" ? "0 0 0 8px" : "0 8px 0 0" }}
    >
      {icon}
    </span>
  );


  const combinedStyles = { 
      marginLeft: buttonMarginLeft,
      letterSpacing: letterSpacing,
      
      boxShadow: "none",
      minWidth: width ? width : "auto", // Ensure minWidth is set if width is specified
      "&:hover":
        variant === "text" || variant === "outlined"
          ? { boxShadow: "none" }
          : {
              boxShadow: "none",
              backgroundColor: theme?.palette?.hoverColorForPrimary?.main, // Change hover background color
            }, 
    ...sx // Spread the sx prop to override any conflicting properties
  };

  

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          type={type}
          variant={variant}
          size={size}
          onClick={onClick}
          disabled={disabled}
          className={className}
          endIcon={iconPosition === "end" ? iconWithMargin : null}
          style={{
            fontSize: fontSize,
            fontWeight: fontWeight,
            width: width,
            lineHeight: lineHeight,
            marginRight: marginRight,
            letterSpacing: letterSpacing,
            height: height,
            backgroundColor: backgroundColor,
            marginBottom: marginBottom,
            padding: padding,
            color: textColor,
          }}
          color={buttonColor}
          sx={combinedStyles}
        >
          {children}
        </Button>
      </ThemeProvider>
    </>
  );
};

export default CustomButton;
