/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack, Typography, InputLabel, Alert, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Close from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import LargeReusableModal from "../common/reusable-comps/modals/LargeReusableModal";
import { ReactComponent as RedErrorIcon } from "../assets/images/RedErrorIcon.svg";
import CustomPasswordField from "../common/reusable-comps/password-field/CustomPasswordField";
import { hasStrongPassword } from "../common/utils/CommonUtils";
import SmallReusableModal from "../common/reusable-comps/modals/SmallReusableModal";
import { changePasswordApi } from "../redux/slices/loginInfoSlice";
import { passwordChangeEndPoint } from "../services/apiConfig";
import CustomButton from "../common/buttons/CustomButton";
const ChangePasswordModal = ({ open, handleClose, user }) => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formError, setFormError] = useState({});
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const { oldPassword, newPassword, confirmNewPassword } = formData;
  const [alertOpen, setAlertOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { forgotPasswordScreen, changePassword } = t("mainContainer");
  const {
    oldPasswordField,
    newPasswordFiled,
    confirmNewPasswordField,
    cancelBtn,
    confirmPasswordSuccess,
    closeBtnTxt,
    changePasswordBtn,
    passwordChangeTitle,
    apiFailError,
    incorrectOldPasswordError,
    incorrectUserIdError,
  } = changePassword;

  const [apiError, setApiError] = useState(""); // State to hold API error message

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleCloseSmallModal = () => {
    setOpenSmallModal(false);
  };
  const smallModalChildren = () => {
    return (
      <h5 style={{ fontSize: "14px", fontWeight: "400", width: "60%" }}>
        {confirmPasswordSuccess}
      </h5 >
    );
  };

  useEffect(() => {
    setFormData({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    })
    setFormError({})
    setAlertOpen(false)
  }, [open])

  const handleCheckError = () => {
    let newErrors = {};
    // Validate old password
    if (!oldPassword) {
      newErrors.oldPassword = forgotPasswordScreen?.oldPasswordRequiredError;
    }
    // Validate new password
    if (!formData.newPassword) {
      newErrors.newPassword = forgotPasswordScreen?.newPasswordError;
    } else if (!hasStrongPassword(formData.newPassword)) {
      newErrors.newPassword = forgotPasswordScreen?.invalidNewPasswordError;
    }
    // Validate confirm new password
    if (!formData.confirmNewPassword) {
      newErrors.confirmNewPassword = forgotPasswordScreen?.confirmPasswordRequiredError;
    } else if (formData.confirmNewPassword !== formData.newPassword) {
      newErrors.confirmNewPassword = forgotPasswordScreen?.confirmPasswordMatchError;
    }
    setFormError(newErrors);
    return newErrors;
  };

  const style = {
    width: "100%",
    height: 'auto',
    gap: "0px",
    borderRadius: "4px 0px 0px 0px",
    border: "1px 0px 0px 0px",
    opacity: "0px",
  };
  const handleBlur = () => {
    handleCheckError();
  }
  const preventPaste = (e) => {
    e.preventDefault();
  };
  const childrenRender = () => {
    return (
      <>
        <Stack className="stackMarginTop">
          <form id="changePasswordForm">
            <>
              <Typography className="changePasswordUserId">{changePassword?.userId}</Typography>
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ fontWeight: "400", fontSize: "14px", marginBottom: "16px" }}
              >
                {user?.email}
              </InputLabel>
            </>
            <Stack gap={2} className="labelMargin">
              <>
                <InputLabel htmlFor="standard-adornment-password">
                  {oldPasswordField?.label}
                </InputLabel>
                <CustomPasswordField
                  value={oldPassword}
                  handleChange={handleChange}
                  error={!!formError?.oldPassword?.length}
                  helperText={formError?.oldPassword} // Display the error message directly
                  name="oldPassword"
                  placeholder={oldPasswordField?.placeholder}
                  style={style}
                  onBlur={handleBlur}
                  onPaste={preventPaste}
                />
              </>
              <>
                <InputLabel htmlFor="standard-adornment-password">
                  {newPasswordFiled?.label}
                </InputLabel>
                <CustomPasswordField
                  value={newPassword}
                  handleChange={handleChange}
                  error={!!formError?.newPassword?.length}
                  helperText={formError?.newPassword} // Display the error message directly
                  name="newPassword"
                  placeholder={newPasswordFiled?.placeholder}
                  style={style}
                  onBlur={handleBlur}
                  onPaste={preventPaste}
                />
              </>
              <>
                <InputLabel htmlFor="standard-adornment-password">
                  {confirmNewPasswordField?.label}
                </InputLabel>
                <CustomPasswordField
                  value={confirmNewPassword}
                  handleChange={handleChange}
                  error={!!formError?.confirmNewPassword?.length}
                  helperText={formError?.confirmNewPassword} // Display the error message directly
                  name="confirmNewPassword"
                  placeholder={confirmNewPasswordField?.placeholder}
                  style={style}
                  onBlur={handleBlur}
                  onPaste={preventPaste}
                />
              </>
            </Stack>
          </form>
          {alertOpen && (
            <Collapse in={open}>
              <Alert
                severity="error"
                icon={<RedErrorIcon />}
                sx={{
                  "& .MuiPaper-root": {
                    height: "64px",
                  },
                  marginTop: "16px",
                  border: {
                    borderLeft: "3px solid #DA1E28",
                    borderTop: "1px solid #DA1E284D",
                    borderRight: "1px solid #DA1E284D",
                    borderBottom: "1px solid #DA1E284D",
                  },
                  fontSize: "12px",
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
              >
                {apiError}

              </Alert>
            </Collapse>
          )}
        </Stack>
      </>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = handleCheckError();
    if (Object.keys(errors)?.length > 0) {
      setPasswordChanged(false);
      return; // Prevent form submission if errors exist
    }
    const apiPayload = {
      url: passwordChangeEndPoint(),
      token: localStorage.getItem("accessToken"),
      payload: {
        newPassword: formData.newPassword,
        id: user?.id,
        oldPassword: formData.oldPassword,
      },
    };

    try {
      const response = await dispatch(changePasswordApi(apiPayload));
      if (response.payload?.status === 200) {
        setPasswordChanged(true);
        handleClose();
        setOpenSmallModal(true);
      } else {
        setAlertOpen(true);
        const errorMessage = response.payload?.data?.error;
        if (response.payload?.status === 400 && errorMessage === "Error: Incorrect old password") {
          setApiError(incorrectOldPasswordError);
        } else if (response.payload?.status === 400 && errorMessage === "Error: Not a valid user id") {
          setApiError(incorrectUserIdError);
        } else {
          setApiError(apiFailError);
        }
      }
    } catch (error) {
      setApiError(apiFailError);
    }
  };

  const footerVal = [
    {
      label: cancelBtn,
      onClick: handleClose,
      variant: "outlined",
      color: "primary",
      style: {
        width: "50%",
        height: "48px"
      },
    },
    {
      label: changePasswordBtn,
      onClick: handleSubmit, // Call handleSubmit when the "Submit" button is clicked
      type: "submit", // Set type to "submit"
      variant: "contained",
      color: "primary",
      style: {
        width: "50%",
        height: "48px"
      },
    },
  ];
  const smallModalFooter = () => {
    return (
      <div>
        <CustomButton
          variant="outlined"
          onClick={handleCloseSmallModal}
          marginRight=".5rem"
          width="108px"
          padding="11px 16px"
        >
          {closeBtnTxt}
        </CustomButton >
      </div >
    );
  };
  return (
    <>
      {/* Large Modal */}
      <LargeReusableModal
        isOpen={open}
        onClose={handleClose}
        header={changePassword.title}
        footer={footerVal}
        buttonPosition="center"
        dialogWidth="100%"
        dialogHeight="auto"
        maxWidth={"md"}
        footerBgColor="#F4F4F4"
      >
        {/* Children Render */}
        <div style={{ position: "relative", zIndex: 1 }}>
          {childrenRender()}
        </div>

        {/* Error Message */}

      </LargeReusableModal>

      {/* Small Modal */}
      {passwordChanged && (
        <SmallReusableModal
          open={openSmallModal}
          footer={smallModalFooter()}
          dialogWidth="442px"
          dialogHeight="220px"
          handleClose={handleCloseSmallModal}
          title={passwordChangeTitle}
          divider={false}
          contentPadding="20px 0px"
          headerHeight="28px"
        >
          {smallModalChildren()}
        </SmallReusableModal>
      )}
    </>
  );
};

export default ChangePasswordModal;
