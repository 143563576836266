import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { lineHeight } from '@mui/system';
import SiteListTable from './SiteListTable';
import CustomButton from '../../common/buttons/CustomButton';
import CustomModal from '../../common/commonPopup/CustomModal';
import CreateRuleInfo from './createRule/CreateRuleInfo';
import { createRuleFormModel } from './createRule/FormElementsModel';
import usePost from '../../common/hook/usePost';
import { createRuleURL } from '../../services/apiConfig';


const EmailNotification = ({alaramTagsData}) => { 

	const [openModal, setOpenModal] = useState(false);
	const [formValues, setFormValues] = useState({

		Subject: 'Alarm Notification', // Initial value for Subject field
	});
	const [formErrors, setFormErrors] = useState({}); 
	const [selectedRows, setSelectedRows] = useState([]); // Define and initialize selectedRows

	const { t } = useTranslation();
	const { settingsPageContent } = t('mainContainer');
	const { title, createRuleBtn, emailNotificationHeader } =
		settingsPageContent?.siteManagementContent?.customerDetails
			?.emailNotification;

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const {
		loading: postRuleLoading,
		error: postRuleError,
		data: postRuleData,
		postData: PostRuleData,
	  } = usePost();

	  

	/**
	 * Opens the modal dialog.
	 */
	const handleOpen = () => {
		setOpenModal(true);
		// Assuming selectedPostalCode is set when you click ViewMap button

		// fetchLocationData(siteAddress.postalCode);
	};

	/**
	 * Closes the modal dialog.
	 */
	const handleClose = () => {
		setOpenModal(false);
		setSelectedRows([])
	};

	 /**
   * Handles actions performed within the modal.
   */
	 const handleAction = () => {
		// Validate if at least one row is selected in CreateRuleInfo
		if (selectedRows.length === 0) {
		  alert('Please select at least one row.');
		  return;
		}
	
		// Validate the form
		let isValid = true;
		const updatedErrors = {};
	
		createRuleFormModel.forEach((element) => {
		  if (
			element.type === 'textField' &&
			(formValues[element.label] === undefined || formValues[element.label] === '')
		  ) {
			updatedErrors[element.label] = `${[element.label]} field is required`;
			isValid = false;
		  } else if (element.type === 'radioGroup' && !formValues[element.label]) { 
			updatedErrors[element.label] = `Please select an ${[element.label]}.`;
			isValid = false;
		  } else if (
			element.type === 'checkboxGroup' &&
			(!formValues[element.label] || formValues[element.label].length === 0)
		  ) {
			updatedErrors[element.label] = `Please select an ${[element.label]}.`;
			isValid = false;
		  } else if (element.type === 'addMultiItems' && (!formValues?.recipients?.length || formValues?.recipients?.length === 0)) { 
			updatedErrors[element.label] =  `${[element.label]} field is required` ;
			isValid = false;
		  }else if (element.type === 'addMultiItems' && (!formValues?.recipients||formErrors[element.label])) 
		  { 
			updatedErrors[element.label] =  formErrors[element.label] ;
			isValid = false;
		  }


		//   else if (element.type === 'autocomplete' && (!formValues[element.label] || formValues[element.label].length === 0)) {
		// 	updatedErrors[element.label] = true;
		// 	isValid = false;
		//   }
		});
	
		if (!isValid) {
		  setFormErrors(updatedErrors);
		  return;
		}
		
	 
		const { customerId, siteId } = selectedRows[0];
		const mappedSelectedRows =  {
			customerId: customerId,
			siteId: siteId,
			ruleName: formValues["Name"], // Example: Hardcoded name
			mailFormat: formValues["Mail format"],  
			alarmPriority: formValues["Alarm Priority"],
			alarmType: formValues["Alarm Type"],
			recipients: formValues.recipients,
				alarmTags: selectedRows?.map((row) => ({
						id: row.id,
						alarmName: row.alarmName,
						description: row.description
					  }))
		  };
		 
		PostRuleData(createRuleURL, mappedSelectedRows);
	  
		// Proceed with action if form is valid
		alert('Form submitted successfully!'); // Replace with actual action
	
		// Close modal or reset form state as needed
		handleClose();
		setFormValues({});
		setFormErrors({});
	  };

	  const cancelBtnStyle={marginRight: "10px",padding:"10.5px 36.5px",fontWeight:"regular",lineHeight:"normal"}
	  const saveBtnStyle={marginRight: "10px",padding:"11.5px 29.88px",fontWeight:"regular",lineHeight:"normal"}

	// Modal actions configuration
	const modalActions = [
		{sx:cancelBtnStyle, variant:'outlined',label: 'Cancel', onClick: handleClose },
		{sx:saveBtnStyle, variant:'contained', label: 'Save rule', onClick: handleAction },
	];

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div>
					<h4 style={{ fontSize: '14px', fontWeight: '500' }}>{title}</h4>
				</div>

				<CustomButton
					variant="contained"
					disableElevation
					onClick={handleOpen}
					disabled={!alaramTagsData?.length>0}
					sx={{
						padding: '0.5rem 1rem',
						fontSize: '0.9rem',
						textTransform: 'none',
					}}
				>
					{createRuleBtn} +
				</CustomButton>
			</div>
			<SiteListTable emailNotificationHeader={emailNotificationHeader} />

			<CustomModal
				open={openModal}
				onClose={handleClose}
				title="Create rule"
				actions={modalActions}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth="xl"
			>
				  <CreateRuleInfo
					formValues={formValues}
					formErrors={formErrors}
					setFormValues={setFormValues}
					setFormErrors={setFormErrors}
					selectedRows={selectedRows} // Pass selectedRows to CreateRuleInfo
					onSelectRows={setSelectedRows} // Function to update selectedRows
					alaramTagsData={alaramTagsData} 
					/>
			</CustomModal>
		</>
	);
};

export default EmailNotification;
