import React from "react";
import { Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../common/buttons/CustomButton";
import { NWC_SETTING_URL } from "../../common/constants/Constants";
function NoPrefecture({
  selectedCategory,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { settingsPageContent } = t("mainContainer");
  const { customerDetails } = settingsPageContent?.siteManagementContent;
  const handleClick = () => {
    navigate(NWC_SETTING_URL, {
      state: { selectedIdDatum: selectedCategory, noPrefecture: true, from: "addSiteButton" },
    });
  };
  return (
    <>
      <Stack className="noPrefectureStack">
        <>
          <Typography className="noResultText">
            {customerDetails?.noResultMessage}
          </Typography>
          <Typography className="noResultDescription">
            {customerDetails?.noResultDescription}
          </Typography>
          <CustomButton
            variant="contained"
            backgroundColor="primary"
            width="119px"
            padding="16px"
            onClick={handleClick}
            textColor="#FFFFFF"
            fontWeight="400"
          >
            <span style={{ marginRight: "18px" }}>
              {customerDetails?.addSiteBtn}
            </span>
            +{" "}
          </CustomButton>
        </>
        {/* )} */}
      </Stack>
    </>
  );
}

export default NoPrefecture;
