/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, InputLabel, Button, Box, Link } from "@mui/material";
import CustomPasswordField from "../../common/reusable-comps/password-field/CustomPasswordField";
import { hasStrongPassword } from "../../common/utils/CommonUtils";
import { useTranslation } from "react-i18next";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import {
  resetPasswordApi,
  verifyEmailApi,
} from "../../redux/slices/loginInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";
import NotFound from "../../common/reusable-comps/not-found/NotFound";
import { NWC_LOGIN_URL } from "../../common/constants/Constants";
import { resetPasswordEndPoint } from "../../services/apiConfig";
import "./Login.scss";
import CustomButton from "../../common/buttons/CustomButton";
function ResetPassword({
  theme,
  signInButtonLabel,
  setShowResetPassword,
  setShowForgetPassword,
}) {
  const { loading } = useSelector((state) => state?.loginInfo);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formError, setFormError] = useState({});
  const { newPassword, confirmNewPassword } = formData;
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const { forgotPasswordScreen } = t("mainContainer");
  const searchParams = new URLSearchParams(window.location.search);
  const verifyToken = searchParams.get("token");
  const [formTouched, setFormTouched] = useState(false);

  const {
    invalidNewPasswordError,
    newPasswordError,
    confirmPasswordRequiredError,
    confirmPasswordMatchError,
    resetPasswordSuccessfulHeader1,
    resetPasswordSuccessfulHeader2,
    resetPasswordHeader,
    resetPasswordHeaderTwo,
    newPasswordLabel,
    confirmPasswordLabelResetPassword,
    confirmNewPasswordLabel,
    newPasswordLabelResetPassword,
    resetpswdMessage1,
    resetpswdMessage2,
    resetpswdMessage3,
    resetPasswordButtonLabel,resetPasswordLink,returntoLogin
  } = forgotPasswordScreen;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormError((prevError) => ({ ...prevError, [name]: "" }));
  };
  const navigate = useNavigate();
  const handleErrorCheck = () => {
    let newError = {};
    for (const [key, value] of Object.entries(formData)) {
      if (key === "newPassword") {
        if (value && !hasStrongPassword(value)) {
          newError.newPassword = invalidNewPasswordError;
        } else if (!value) {
          newError.newPassword = newPasswordError;
        }
      } else if (key === "confirmNewPassword") {
        if (!value) {
          newError.confirmNewPassword = confirmPasswordRequiredError;
        } else if (value && value !== formData.newPassword) {
          newError.confirmNewPassword = confirmPasswordMatchError;
        }
      }
    }
    setFormError(newError);
    return newError;
  };
  useEffect(() => {
    if (verifyToken) {
      const payload = verifyToken;
      dispatch(verifyEmailApi(payload)).then((res) => {
        if (res?.payload?.status !== 204) {
          setShowVerificationError(true);
        }
      });
    }
  }, [verifyToken]);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    setFormTouched(true); // Set formTouched to true when any form field changes
    const errors = handleErrorCheck();
    if (Object.keys(errors).length > 0) {
      setPasswordChanged(false);
      return; // Prevent form submission if errors exist
    } else {
      const apiPayload = {
        url: resetPasswordEndPoint,
        payload: { password: newPassword },
        token: verifyToken,
      };
      dispatch(resetPasswordApi(apiPayload)).then((res) => {
        if (res?.payload?.status == 204) {
          setPasswordChanged(true);
        }
      });
    }
  };
  const handleNavigateToLogin = () => {
    navigate(NWC_LOGIN_URL);
    setShowForgetPassword(false);
    setShowResetPassword(false);
  };
  const handleButtonClick = () => {
    setShowForgetPassword(false);
    setShowResetPassword(false);
    navigate(NWC_LOGIN_URL);
  };
  const handleNavigateToForgotPassword = () => {
    setShowForgetPassword(true);
    setShowResetPassword(false);
    navigate(NWC_LOGIN_URL);
  };

  const getValidation = (fieldName, value, formTouched) => {
    if (fieldName === "newPassword" && formTouched) {
      if (value && !hasStrongPassword(value)) {
        return invalidNewPasswordError;
      } else if (!value) {
        return newPasswordError;
      }
    } else if (fieldName === "confirmNewPassword" && formTouched) {
      if (!value) {
        return confirmPasswordRequiredError;
      } else if (value && value !== newPassword) {
        return confirmPasswordMatchError;
      }
    }
    return "";
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      width={"330px"}
      marginTop={"20%"}
      marginBottom={"6%"}
      className={passwordChanged ? "passwordChangedMargin" : ""}
    >
      {showVerificationError ? (
        <NotFound
          componentName={resetPasswordLink}
          message={
            <>
              {resetpswdMessage1}{" "}
              <Link
                underline="none"
                href="#"
                onClick={handleNavigateToForgotPassword}
              >
                {resetpswdMessage2}
              </Link>{" "}
              {resetpswdMessage3}
            </>
          }
          messageLine2="forgotPassword"
          messageLine3="page"
          size="small"
          buttonVariant="contained"
          buttonColor="primary"
          buttonText="Return to login"
          messageMarginTop="-10px"
          buttonClick={handleButtonClick}
          width="100%"
          buttonHeight="48px"
          buttonFontSize="16px"
          buttonPadding="12px 16px"
        />
      ) : (
        <>
          {passwordChanged ? (
            <>
              <Typography
                variant="h5"
                theme={theme}
                fontWeight={"500"}
                lineHeight={"28px"}
              >
                {resetPasswordSuccessfulHeader1}
              </Typography>
              <Typography theme={theme} variant="resetPwdHeader1">
                {resetPasswordSuccessfulHeader2}
              </Typography>{" "}
              <CustomButton
                type="submit"
                variant="contained"
                backgroundColor="primary"
                height="48px"
                fontSize="16px"
                onClick={handleNavigateToLogin}
                background="#0F62FE"
                padding="12px 16px"
              >
                {signInButtonLabel}
              </CustomButton>{" "}
            </>
          ) : (
            <>
              <>
                <Typography
                  variant="h5"
                  theme={theme}
                  lineHeight="24px"
                  marginBottom={"-8px"}
                >
                  {resetPasswordHeader}
                </Typography>
                <Typography theme={theme} variant="pFontSize">
                  {resetPasswordHeaderTwo}
                </Typography>
                <InputLabel
                  htmlFor="standard-adornment-password"
                  sx={{ marginBottom: "-10px ", marginTop: "-10px" }}
                  className="globalInputLabelStyle"
                >
                  {newPasswordLabelResetPassword}
                </InputLabel>
                <CustomPasswordField
                  value={newPassword}
                  handleChange={handleChange}
                  error={getValidation("newPassword", newPassword, formTouched)}
                  helperText={getValidation(
                    "newPassword",
                    newPassword,
                    formTouched
                  )}
                  name="newPassword"
                  placeholder={newPasswordLabel}
                />
              </>
              <>
                <InputLabel
                  htmlFor="standard-adornment-password"
                  sx={{ marginBottom: "-10px " }}
                  className="globalInputLabelStyle"
                >
                  {confirmPasswordLabelResetPassword}
                </InputLabel>
                <CustomPasswordField
                  value={confirmNewPassword}
                  handleChange={handleChange}
                  error={getValidation(
                    "confirmNewPassword",
                    confirmNewPassword,
                    formTouched
                  )}
                  helperText={getValidation(
                    "confirmNewPassword",
                    confirmNewPassword,
                    formTouched
                  )}
                  name="confirmNewPassword"
                  placeholder={confirmNewPasswordLabel}
                />
                {loading == "loading" ? (
                  <Box>
                    <CustomCircularProgress color="#0F62FE" />
                  </Box>
                ) : (
                  <CustomButton
                    type="submit"
                    variant="contained"
                    backgroundColor="primary"
                    onClick={handleFormSubmit}
                    background="#0F62FE"
                    fontSize="16px"
                    height="48px"
                    fontWeight="400"
                    padding="12px 16px"
                  >
                    {resetPasswordButtonLabel}
                  </CustomButton>
                )}
              </>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default ResetPassword;
