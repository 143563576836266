import React, { useState, useEffect, useRef } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";
import { Stack, Box, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';  
import { customerTabStyles } from './CustomerFilterPanelStyle';
import spinner from "../../assets/animation/spinner.json";
import { prefectures } from '../../components/setting/CustomerData';
import { getSingleStateCode } from '../utils/CommonUtils';

 

const CustomerFilterPanel = ({
  setNoSiteSelected,
  setNoAreaSelected, 
  isFetchSiteLoading, 
  hasnoSiteData, 
  setCustomerID, 
  activeCustomer, 
  activeAreas, 
  activeSites, 
  areas, 
  sites, 
  isEdit, 
  updateFavoriteData}) => {
 
  const [selectedCustomer, setSelectedCustomer] = useState(activeCustomer);
  const [selectedArea, setSelectedArea]=useState([]);
  const [selectedSites,setSelectedSites]=useState([]);
const [iscategoryChanges,setIscategoryChanges]=useState(false);

        // Language Parameter
        const { t } = useTranslation();
        const { customerTopNavigation } = t("mainContainer");
        const { customerInfoHeader, areaInfoMessage, customerrInfoMessage,selectAllArea,selectAllSites } = customerTopNavigation;

        const { addCustomerForm  } =  t('mainContainer').settingsPageContent?.siteManagementContent;
        const {  prefecturesField } = addCustomerForm;
        
  useEffect(()=>{
    if(activeAreas?.length>0){
      setSelectedArea(activeAreas)
    }
  if(activeSites.length>0){
    const sites = activeSites.map(state => ({
      id : state.siteId||state.id,
      name:state.siteName,
      state:state.state
  }));
    setSelectedSites(sites)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeAreas]);

  const categoryListRef = useRef(null); 
  const { allCustomers } = useSelector(state => state.customer); 

  useEffect(()=>{
    if(iscategoryChanges)
    {
    // Fetch the areas and sites for the selected customer
    const customerAreas = areas; // Assuming areas are fetched based on the selected customer
    const allSites = customerAreas.flatMap(area => sites[area] || []);

    setSelectedArea(customerAreas.map(area => ({
      state: area,
      id: selectedCustomer?.id
    })));
    setSelectedSites(allSites); 
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
  },[areas,iscategoryChanges])


//   useEffect(()=>{
//     if(!isFetchSiteLoading&&!hasnoSiteData)
//  { if(selectedArea?.length>0){
//     setNoAreaSelected(false)
//   }else{
//     setNoAreaSelected(true)
//   }

// }
//      // eslint-disable-next-line react-hooks/exhaustive-deps
// },[selectedArea])
// useEffect(()=>{
//   if(!isFetchSiteLoading&&!hasnoSiteData)
//  { if(selectedSites?.length>0){
//     setNoSiteSelected(false)
//   }else{
//     setNoSiteSelected(true)
//   }}
//      // eslint-disable-next-line react-hooks/exhaustive-deps
// },[selectedSites])
  
   // Handle customer selection
   const handleCategoryChange = (customer) => {
    setSelectedCustomer(customer);
    setCustomerID(customer);
    setIscategoryChanges(true)
    setNoSiteSelected(false);
    setNoAreaSelected(false);
  };

  const handleSelectAllAreas = (event) => {
    if (event.target.checked) {
      // Select all areas and all sites within those areas
      const allAreas = areas.map(state => ({
        state: state,
        id: selectedCustomer.id
      }));
      setSelectedArea(allAreas);
  
      // Gather all sites from the selected areas
      const allSites = allAreas.flatMap(area => sites[area.state] || []);
      setSelectedSites(allSites);
  
      // Update the 'noAreaSelected' and 'noSiteSelected' states
      setNoAreaSelected(false);
      setNoSiteSelected(false);
    } else {
      // Deselect all areas and all sites
      setSelectedArea([]);
      setSelectedSites([]);
  
      // Update the 'noAreaSelected' and 'noSiteSelected' states
      setNoAreaSelected(true);
      setNoSiteSelected(true);
    }
  };
  
  const handleSelectAllSites = (event) => {

    // if(selectedSites?.length>0){
    //       setNoSiteSelected(true)
    //     }else{
    //       setNoSiteSelected(false)
    // }

    if (event.target.checked) {
      const allSites = selectedArea.flatMap(area => sites[area.state] || []);
      setSelectedSites(allSites);
      setNoSiteSelected(false)
    } else {
      setSelectedSites([]);
      setNoSiteSelected(true)
    }
  };

  const handleAreaChange = (event) => {
    const area = event.target.value;
    const isChecked = event.target.checked;
  
    setSelectedArea(prevSelectedAreas => {
      let updatedAreas;
  
      if (isChecked) {
        // Add the area to selected areas if it is checked
        updatedAreas = [...prevSelectedAreas, { state: area, id: selectedCustomer.id }];
      } else {
        // Remove the area from selected areas if it is unchecked
        updatedAreas = prevSelectedAreas.filter(selectedArea => selectedArea.state !== area);
      }
  
      // Update selectedSites based on new selected areas
      const allSitesForSelectedAreas = updatedAreas.flatMap(a => sites[a.state] || []);
      setSelectedSites(prevSelectedSites =>
        isChecked
          ? [...new Set([...prevSelectedSites, ...allSitesForSelectedAreas])]
          : prevSelectedSites.filter(site => !sites[area]?.some(s => s.id === site.id))
      );
  
      // Update noAreaSelected and noSiteSelected states
      setNoAreaSelected(updatedAreas.length === 0);
      setNoSiteSelected(allSitesForSelectedAreas.length === 0);
  
      return updatedAreas;
    });
  };
  

  const handleSiteChange = (event) => {
    const siteId = event.target.value; // This should be the unique identifier (e.g., site.id)
    const isChecked = event.target.checked;
  
    setSelectedSites(prevSelectedSites => {
      if (isChecked) {
        // Find the site based on its ID
        const siteToAdd = selectedArea.flatMap(area => sites[area.state] || [])
          .find(site => site.id === siteId || site.siteId === siteId);

          const newSelectedArea = siteToAdd && !prevSelectedSites.some(selectedSite => selectedSite.id === siteToAdd.id || selectedSite.id === siteId)
          ? [...prevSelectedSites, siteToAdd]
          : prevSelectedSites;

          if(newSelectedArea?.length>0){
            setNoSiteSelected(false)
          }else{
            setNoSiteSelected(true)
          }
        // Add site if it exists and is not already in the selected sites
        return newSelectedArea
          
      } else {
        const removeSelectedSites = prevSelectedSites.filter(selectedSite => selectedSite.id !== siteId && selectedSite.siteId !== siteId);

        if(removeSelectedSites?.length==0){
          setNoSiteSelected(true)
        }else{
          setNoSiteSelected(false)
        }
        // Remove site from the selected sites
        return removeSelectedSites
      }
    });
  };
  
  useEffect(() => {
    updateFavoriteData({ selectedArea, selectedCustomer, selectedSites }); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, selectedSites, selectedArea]);

 // Determine if all areas and sites are selected
 const isAllAreasSelected = areas.length > 0 && areas.every(area => selectedArea.some(selected => selected.state === area));
 const isAllSitesSelected = selectedArea.length > 0 && selectedArea.flatMap(area => sites[area.state] || []).length > 0 &&
   selectedArea.flatMap(area => sites[area.state] || []).every(site => selectedSites.some(selectedSite => selectedSite.id === site.id));

 
   const areaName = (area) =>{
    let prefectureObj;
    
    const stateCode = getSingleStateCode(area)
    prefectureObj = prefecturesField?.options?.find((item) => item?.value === stateCode); 
   return prefectureObj?.label

  }

  return ( 
    <Box sx={{ display: 'flex' }}> 

        <Box sx={{ flex: 1 }} className="customerList"  ref={categoryListRef}>
          <Typography variant="h6" sx={customerTabStyles.customerList.h6}>{customerInfoHeader["customer"]}</Typography> 
          {isEdit ? (
          <FormGroup>
            {allCustomers?.map((customer) => (
              <FormControlLabel  
                key={customer.id}
                sx={selectedCustomer?.id === customer.id ? customerTabStyles.customerList.activeClass : customerTabStyles.customerList}
                className={selectedCustomer?.id === customer.id && "activeClass"}
                control={
                  <Checkbox
                    sx={customerTabStyles.customerList.checkBoxStyle}
                    onChange={() => handleCategoryChange(customer)}  
                    value={customer.name}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="div">{customer.displayName}</Typography>
                    <RightArrow sx={{ marginLeft: "auto" }} />
                  </Box>
                }
                labelPlacement="end"
              />
            ))}
          </FormGroup>
        ) : (
          <List sx={{p:0}}>
            {allCustomers?.filter(customer => customer.id === selectedCustomer?.id).map((customer) => (
              <ListItem key={customer.id} sx={{padding:"6.5px 24px",borderBottom: "1px solid #E0E0E0"}} className='customeList'>
                <ListItemText primary={customer.displayName} />
              </ListItem>
            ))}
          </List>
        )}
        </Box> 


      
        <Box sx={{ flex: 2, p: 0 }} className={`${areas.length==0&&"no-areas"} areaList`}>
          <Typography variant="h6" className='' sx={customerTabStyles.areaList.h6}>{customerInfoHeader["area"]}</Typography>
         
        { areas.length>0&&
          <FormGroup>
          {isEdit&&<FormControlLabel
              sx={customerTabStyles.areaList}
              control={
                <Checkbox
                checked={isAllAreasSelected}
                  onChange={handleSelectAllAreas}
                  sx={customerTabStyles.areaList.checkBoxStyle}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="div">{selectAllArea}</Typography>
                </Box>
              }
            />}
            {areas.map((area) => (
              isEdit?<FormControlLabel
                onChange={handleAreaChange}
                checked={selectedArea?.some(selectedArea => selectedArea.state === area)}
                key={area}
                sx={customerTabStyles.areaList}
                control={
                  <Checkbox
                  
                    value={area}
                    sx={customerTabStyles.areaList.checkBoxStyle}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="div">{areaName(prefectures?.find(pref => pref?.value === area)?.label)}</Typography>
                  </Box>
                }
                labelPlacement="end"
              />: (
                selectedArea.some(selected => selected.state === area) && (
                  <ListItem key={area} sx={{padding:"6.5px 24px",borderBottom: "1px solid #E0E0E0"}} className='customeList'>
                    <ListItemText primary={areaName(prefectures?.find(pref => pref?.value === area)?.label)} />
                  </ListItem> 
                )
              )
            ))}
          </FormGroup>
          }
 
         { !isFetchSiteLoading && hasnoSiteData && <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={564} 
              display="flex"
              >
              <Typography sx={customerTabStyles.infoTitle}>
              {customerrInfoMessage["noResult"]} 
              </Typography>
              <Typography sx={customerTabStyles.infoDesc}>
              {customerrInfoMessage["infoText"]} 
              </Typography>
            </Stack>
          }
           { isFetchSiteLoading  && <div className="spinner-wrapper blue-spinner">
              <Lottie className="spinner" animationData={spinner} autoplay loop />
            </div>}
        </Box>
    

        { areas.length>0 && 
        <Box className="siteList" sx={{ flex: 2, p: 0 }}>
           <Typography variant="h6" sx={customerTabStyles.siteList.h6}>{customerInfoHeader["site"]}</Typography>
           { selectedArea.length>0 && <FormGroup> 
                                      { isEdit && <FormControlLabel
                                                  sx={customerTabStyles.siteList}
                                                  control={
                                                    <Checkbox 
                                                    checked={isAllSitesSelected}
                                                      onChange={handleSelectAllSites}
                                                      sx={customerTabStyles.areaList.checkBoxStyle}
                                                    />
                                                  }
                                                  label={selectAllSites}
                                                />  }

                                      {selectedArea.map((area) => (
                                        <React.Fragment key={area.state}>
                                          {sites[area.state]?.map((site) => (
                                          isEdit?<FormControlLabel  
                                              key={site.id}
                                              onChange={handleSiteChange}
                                              checked={selectedSites?.some(selectedSite => 
                                                selectedSite.siteId === site.id || selectedSite.id === site.id
                                              )} 
                                              sx={customerTabStyles.siteList}
                                              control={
                                                <Checkbox 
                                                  value={site.id}   
                                                  sx={customerTabStyles.areaList.checkBoxStyle}
                                                />
                                              }
                                              label={site.name}
                                            />: (
                                              selectedSites.some(selectedSite => 
                                                selectedSite.siteId === site.id || selectedSite.id === site.id
                                              ) && (
                                                <ListItem key={site.id} sx={{padding:"6.5px 24px",borderBottom: "1px solid #E0E0E0"}} className='customeList'>
                                                  <ListItemText primary={site.name || site.siteName} />
                                                </ListItem>
                                              )
                                            )
                                          ))}
                                        </React.Fragment>
                                      ))}
                                    </FormGroup>}
               
                  

          { !isFetchSiteLoading && !hasnoSiteData && selectedArea.length ===0 && <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              height={200}
              width={280} 
              display="flex"
              >
              <Typography sx={customerTabStyles.infoTitle}>
             {areaInfoMessage["noResult"]}
              </Typography>
              <Typography sx={customerTabStyles.infoDesc}>
              {areaInfoMessage["infoText"]}
              </Typography>
            </Stack>
          }
        </Box>  }

      

    </Box> 
      
  );
};

export default CustomerFilterPanel;
