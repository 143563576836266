import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import CustomButton from "../../buttons/CustomButton";
const StyledNotFound = styled("div")({
  textAlign: "left",
  marginTop: (theme) => theme.spacing(4),
});

function NotFound({
  message,
  size,
  style,
  error,
  componentName,
  buttonVariant,
  buttonColor,
  buttonText,
  buttonClick,
  width,
  buttonHeight = "",
  buttonFontSize = "",
  buttonPadding = "",
  messageMarginTop = "",
}) {
  const TypographyComponent = size === "small" ? Typography : Typography;

  return (
    <StyledNotFound style={style}>
      <h2
        style={{
          fontSize: "24px",
          fontWeight: "500",
          lineHeight: "28px",
          marginBottom: "16px"
        }}
      >
        {componentName}
      </h2>
      <TypographyComponent
        variant={size === "small" ? "h6" : "h4"}
        color="error"
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "0.5px",
        }}
      >
        {error}
      </TypographyComponent>
      <Typography
        variant="body1"
        marginBottom={3}
        fontSize={"14px"}
      >
        {message}
      </Typography>
      <CustomButton
        variant={buttonVariant}
        backgroundColor={buttonColor}
        onClick={buttonClick}
        width={width}
        height={buttonHeight}
        fontSize={buttonFontSize}
        padding={buttonPadding}
        fontWeight="400"
      >
        {buttonText}
      </CustomButton>
    </StyledNotFound>
  );
}

export default NotFound;
