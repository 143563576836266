import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LargeReusableModal = ({
  isOpen,
  onClose,
  header,
  footer,
  children,
  buttonPosition = "center", // Added buttonPosition prop with default value,
  maxWidth = "md",
  maxHeight = "80vh", // Default max height
  // dialogActionStyle,
  footerMarginRight,
  footerMarginLeft,
}) => {
  const buttonAlignment = {
    right: "flex-end",
    center: "center",
    left: "flex-start",
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{ position: "absolute", top: 10, right: 10 }}
      >
        <CloseIcon />
      </IconButton>
      {header && <DialogTitle>{header}</DialogTitle>}
      <Divider />
      <DialogContent>{children}</DialogContent>
      {footer && (
        <DialogActions
          style={{
            justifyContent: buttonAlignment[buttonPosition],
            marginLeft: footerMarginLeft,
            marginRight: footerMarginRight,
          }}
        >
          {footer?.map((action, index) => (
            <Button
              style={action?.style ?? {}}
              key={index}
              onClick={action?.onClick}
              color={action?.color}
              variant={action?.variant}
            >
              {action?.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LargeReusableModal;
