import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ReactComponent as ExportIcon } from "../../assets/images/export-icon.svg";
import "./Chart.scss";
import { convertTimestampToDateTimeString, formatDate as formatDateTime } from "../../common/utils/CommonUtils";
 

/**
 * Formats a numeric value for display, converting large numbers to K, M, or B notation.
 * @param {number} value - The numeric value to format.
 * @returns {string} The formatted value as a string.
 */
const formatValue = (value) => {
  if (value >= 1e9) return `${(value / 1e9).toFixed(0)}B`;
  if (value >= 1e6) return `${(value / 1e6).toFixed(0)}M`;
  const formattedValue = value?.toFixed(2);
  return Number(formattedValue) === Math.floor(value)
    ? value?.toFixed(0)
    : formattedValue;
};

const AmChartComponent = ({
  startDate,
  endDate,
  presetLabel,
  electricityData,
  customerName,
  descriptionName,
}) => {
  const chartDiv = useRef(null);

  const { t } = useTranslation();
  const { energyChart, DateRangeSelection } = t("mainContainer");

  useEffect(() => {
    // Add license (if necessary)
    am5.addLicense(process.env.REACT_APP_AMCHART_LICENSE);

    const root = am5.Root.new(chartDiv.current);
    root.setThemes([am5themes_Animated.new(root)]);
 
// Convert to GMT timestamp (UTC) by adjusting to UTC time
const start = Date.UTC(
  startDate.getFullYear(),
  startDate.getMonth(),         // getMonth is 0-based (January is 0, December is 11)
  startDate.getDate(),
  startDate.getHours(),
  startDate.getMinutes(),
  0,                       // Set seconds to 0
  0                        // Set milliseconds to 0
);

const end = Date.UTC(
  endDate.getFullYear(),
  endDate.getMonth(),         // getMonth is 0-based (January is 0, December is 11)
  endDate.getDate(),
  endDate.getHours(),
  endDate.getMinutes(),
  0,                       // Set seconds to 0
  0                        // Set milliseconds to 0
); 
const timeDiff =  end  - start; 
    const generateChartData = () => {
      // Sort and map electricityData in one go
      return electricityData
        .sort((a, b) => a.time - b.time)
        .map((item) => ({
          date: convertTimestampToDateTimeString(item?.time),
          value: item.electricity,
          currentYearlabel: formatValue(item.electricity) || "",
          comparisonValue: item.comparisonValue,
          lastYearlabel: formatValue(item.comparisonValue) || "",
        }));
    };
    let data = generateChartData();

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 40,
        paddingBottom: 38,
        cursor: am5xy.XYCursor.new(root, {
          behavior: "none",
        }),
      })
    );

    let baseInterval = { timeUnit: "day", count: 1 };

    if (timeDiff > 31 * 24 * 60 * 60 * 1000) {
      baseInterval = { timeUnit: "month", count: 1 };
    } else if (timeDiff > 7 * 24 * 60 * 60 * 1000) {
      baseInterval = { timeUnit: "day", count: 4 };
    } else if (timeDiff > 24 * 60 * 60 * 1000) {
      baseInterval = { timeUnit: "day", count: 1 };
    } else if (
      timeDiff > 12 * 60 * 60 * 1000 &&
      timeDiff <= 24 * 60 * 60 * 1000
    ) {
      baseInterval = { timeUnit: "hour", count: 2 };
    } else {
      baseInterval = { timeUnit: "minute", count: 1 };
    }

       // Create DateAxis
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          groupData: true,
          maxDeviation: 0,
          baseInterval: baseInterval,
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 50,
            tooltip: am5.Tooltip.new(root, {
              labelText: undefined,
              forceHidden: true,
              animationDuration: 0,
            }),
          }),
          strictMinMax: true,
          startLocation: 0,
          min:(new Date(startDate)).getTime(), // Set the minimum value to the exact start time 
          max: (new Date(endDate)).getTime() + 3600000, // Add one hours (3600000 milliseconds)
        })
      );

       // Set up custom axis labels
       xAxis.setAll({
        tooltipDateFormat: "HH:mm",
        dateFormats: {
          minute: "HH:mm",
          hour: "HH:mm",
          day: 'HH:mm', // Display hours and minutes for days in 24-hour format as well
        },
        periodChangeDateFormats: {
          minute: "HH:mm",
          hour: "HH:mm",
        day: 'yyyy/MM/dd', // Full format for larger date ranges
        },
        gridIntervals: [
          { timeUnit: "hour", count: 1 }
        ]
      });
   // Set the date formats for the x-axis based on timeDiff
    if (timeDiff <= 24 * 60 * 60 * 1000) { // Less than 1 day
      xAxis.set('dateFormats', {
       minute: "HH:mm",
          hour: "HH:mm",
          day: 'HH:mm', // Display hours and minutes for days in 24-hour format as well 
      });
    } else {
      xAxis.set('dateFormats', {
        day: 'yyyy/MM/dd', // Full format for larger date ranges
        month: 'yyyy/MM', // Month format
        year: 'yyyy', // Year format
      });
  
      // Optionally set periodChangeDateFormats for better formatting
      xAxis.set('periodChangeDateFormats', {
        day: 'yyyy/MM/dd',
        month: 'yyyy/MM',
        year: 'yyyy',
      });
    }
  
       // Create y-axis (ValueAxis)
       let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      // Set label font size for X-axis
    const xRenderer = xAxis.get('renderer');
    if (xRenderer) {
      const labelsTemplate = xRenderer.labels.template;
      labelsTemplate.setAll({
        fill: "#525252", // Label color
        fontSize: 12, // Set your desired font size here
      });
    }

    const yRenderer = yAxis.get('renderer');
    if (yRenderer) {
      const labelsTemplate = yRenderer.labels.template;
      labelsTemplate.setAll({
        fill:"#525252", // Label color
        fontSize: 12, // Label font size
      });

      labelsTemplate.adapters.add("text", (text, target) => {
        const dataItem = target.dataItem;
        if (dataItem) {
          const value = dataItem.get("value");
          return value !== undefined ? formatValue(value) : text;
        }
        return text; // Return original text if value is undefined
      });
    } 
     
  
    // Custom Tooltip Content
    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      pointerOrientation: "horizontal",
    });

    tooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      stroke: am5.color(0xf7f7f7),
      strokeWidth: 2,
      shadowColor: am5.color(0x161616),
      shadowBlur: 8,
      shadowOffsetX: 0,
      shadowOffsetY: 2,
      shadowOpacity: 0.16,
    });

    // Create series (LineSeries)
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Current Year Data',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: tooltip, 
      })
    );
    // Add bullet for main series 
series.bullets.push(() => {
  let circle = am5.Circle.new(root, {
    radius: 6, 
    stroke: root.interfaceColors.get("background"),
    strokeWidth: 2,   
    interactive: true,
    fill: "#00539A",
    opacity: 0
  });

  // Make sure the bullet becomes visible when hovered over
  circle.states.create("default", {
    opacity: 0
  });
  circle.states.create("hover", {
    opacity: 1
  });

  // Add the bullet to the series
  return am5.Bullet.new(root, {
    sprite: circle
  });
});

    // Parse the date and format it for the chart
    let chartData = data.map((item) => ({
      date: new Date(item.date).getTime(), // Convert date to timestamp
      value: item.value,
      currentYearlabel: item.currentYearlabel,
      comparisonValue: item.comparisonValue,
      lastYearlabel: item.lastYearlabel,
    }));

    // Set the chart data
    series.data.setAll(chartData);

  series.get("tooltip").label.adapters.add("html", (text, target) => {
  const dataItem = target.dataItem;
  if (dataItem) {
    const date = new Date(dataItem.get("valueX"));
    const formattedDate = root.dateFormatter.format(date, "yyyy/MM/dd HH:mm");

    const currentYearlabel = dataItem.dataContext.currentYearlabel;
    const lastYearlabel = dataItem.dataContext.lastYearlabel;

    return `<div class="custom-chart-tooltip">
      <div class="date">${formattedDate}</div>
      <div class="currentData"><strong>${DateRangeSelection?.shortCuts[presetLabel?.replace(/\s+/g, "")]}: </strong>${currentYearlabel}</div>
      <div class="lastYearData"><strong>${energyChart?.Previousperiod}: </strong>${lastYearlabel}</div>
    </div>`;
  }
  return text;
});

    const comparisonSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Last Year Data",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "comparisonValue",
        valueXField: "date",
        stroke: "#AE4787",
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // Add bullet for comparison series
    comparisonSeries.bullets.push(() => {
      let circle = am5.Circle.new(root, {
        radius: 6,
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2,
        opacity: 0,
        interactive: true,
        fill: "#AE4787",
      });
      circle.states.create("default", {
        opacity: 0,
      });
      circle.states.create("hover", {
        opacity: 1,
      });
      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    comparisonSeries.data.setAll(chartData);

    
    // Setup the cursor
    const cursor = chart.get("cursor");
    cursor.setAll({
      xAxis: xAxis,
      yAxis: yAxis,
    });



    cursor.lineY.set("visible", false); 
    
     const updateXAxis = (event) => {
      const delta = event?.originalEvent?.deltaY; 

      // Ensure data is available before proceeding
      if (!data || data.length === 0) return;

      // Update baseInterval based on timeDiff
      let newBaseInterval;
      if (timeDiff <= 24 * 60 * 60 * 1000) {
        // Zoom behavior for less than or equal to 24 hours
        if (delta < 0) {
          newBaseInterval = { timeUnit: 'hour', count: 1 }; // Zoom in
        } else {
          // Zoom out to show exactly the same start and end times
          // xAxis.set('min', start);
          // xAxis.set('max', end);
          newBaseInterval = { timeUnit: 'hour', count: 2 }; // Reset to default
        }
      } else if (timeDiff < 31 * 24 * 60 * 60 * 1000) {
        // Zoom behavior for less than 30 days
        if (delta < 0) {
          newBaseInterval = { timeUnit: 'day', count: 1 }; // Zoom in
        } else {
          newBaseInterval = { timeUnit: 'day', count: 4 }; // Zoom out
        }
      } else {
        // Default behavior for larger time ranges
        newBaseInterval = { timeUnit: 'month', count: 1 }; // Default to month
      }

      // Set the new base interval
      xAxis.set('baseInterval', newBaseInterval);

      // Ensure the chart shows the full range when zooming out
      if (delta > 0) {
        chart.zoomOut();
      }

      // Update series data
      series.data.setAll(chartData);
      comparisonSeries.data.setAll(chartData);
    };

    

     // Modify the legend creation process
    const legendContainer = root.container.children.push(am5.Container.new(root, {
      layout: root.horizontalLayout,
      y: am5.percent(98),
      centerX: am5.p50,
      x: am5.p50,
    }));

    // Create legend for series
    const createLegendItem = (name, color, series) => {
      const legendItem = legendContainer.children.push(am5.Container.new(root, {
        layout: root.horizontalLayout,
        paddingLeft: 6,
        paddingRight: 6,
        cursorOverStyle: "pointer",
      }));

      const innerContainer = legendItem.children.push(am5.Container.new(root, {
        layout: root.verticalLayout,
        centerY: am5.p50,
      }));

      const square = innerContainer.children.push(am5.Rectangle.new(root, {
        width: 16,
        height: 16,
        fill: am5.color(color),
        stroke: am5.color(0xffffff),
        strokeWidth: 2
      }));

      const label = innerContainer.children.push(am5.Label.new(root, {
        text: name,
        paddingTop: -15,
        paddingLeft: 22,
        fontSize: 12,
        fill: am5.color(0x000000)
      }));

      // Add click event to toggle series visibility
      legendItem.events.on("click", () => {
        if (series.get("visible")) {
          series.hide();
          square.set("fill", am5.color(0xcccccc));
          label.set("opacity", 0.5);
        } else {
          series.show();
          square.set("fill", am5.color(color));
          label.set("opacity", 1);
        }
        
        // Force tooltip update
        chart.series.each((s) => {
          if (s.get("tooltip")) {
            s.get("tooltip").hide(0);
          }
        });
      });

      return legendItem;
    };

    // Add series legends
    createLegendItem(`${DateRangeSelection?.shortCuts[presetLabel?.replace(/\s+/g, '')]}`, "#00539A", series);
    createLegendItem(`${energyChart.Previousperiod}`, "#AE4787", comparisonSeries);

 // Create Y-axis legend container
    const yAxisLegendContainer = root.container.children.push(am5.Container.new(root, {
      layout: root.verticalLayout,
      paddingTop: 10,
      paddingLeft: 15,
    }));

    // Create Y-axis legends
    const createYAxisLegendItem = (name, color) => {
      const legendItem = yAxisLegendContainer.children.push(am5.Container.new(root, {
        layout: root.horizontalLayout,
        paddingLeft: 15, 
        paddingRight: 15,
        y: am5.percent(100) , // Positioning it just below the x-axis
        centerX: am5.p50,
        x:  am5.p50 , 
      }));

      const innerContainer = legendItem.children.push(am5.Container.new(root, {
        layout: root.verticalLayout,
        centerY: am5.p50,
      }));

     

      innerContainer.children.push(am5.Label.new(root, { 
        rotation: -90, // Rotate labels to vertical
        text: name,
        paddingTop: -40,
        paddingLeft: 100,
        fontSize: 12, 
        fill: "#525252"
      }));

      return legendItem;
    };

    // Add Y-axis legends
    createYAxisLegendItem(`${energyChart?.Energy} (kWh)`, "#00539A"); 
    
    // Add scrollbar
    //  const scrollbarX = am5.Scrollbar.new(root, { orientation: "horizontal" });
    // chart.set("scrollbarX", scrollbarX);


    chart.events.on("wheel", updateXAxis); 
    // scrollbarX.events.on("rangechanged",updateXAxis)
    chart.appear(1000, 100);
    let previousBulletSprites = [];

    // Add this new function to handle mouse leave
    const handleMouseLeave = () => {
      previousBulletSprites.forEach(sprite => sprite.unhover());
      previousBulletSprites = [];
    };

    cursor.events.on("cursormoved", () => {
      previousBulletSprites.forEach(sprite => sprite.unhover());
      previousBulletSprites = [];

      chart.series.each(series => {
        const dataItem = series?.get("tooltip")?.dataItem;
        if (dataItem && dataItem.bullets && dataItem.bullets.length > 0) { // Check if bullets exist
          const bulletSprite = dataItem.bullets[0].get("sprite");
          if (bulletSprite) {
            bulletSprite.hover();
            previousBulletSprites.push(bulletSprite);
          }
        }
      });
    });

    // Add event listener for mouse leave on the chart's container
    chart.root.dom.addEventListener("mouseleave", handleMouseLeave);

    
    // Cleanup chart on unmount
    return () => {
      root.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, electricityData]);


  
const exportToCSV = (electricityData) => {
  // Group unique siteNames and sort them alphabetically
  const uniqueSiteNames = [...new Set(electricityData.map(item => item.siteName))].sort();
  const siteName = uniqueSiteNames.join(', '); // Join all unique site names with a comma and space

  // const customerName = customerName; // Replace with actual customer name
  const sourceType = "Electricity";
  const country = "Japan"; // Replace with actual country name

  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = end - start;

  // Format the date range correctly
  const formatDate = (date, includeTime = true) => {
  

    const year = date.getFullYear();
   const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    if (includeTime) {
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      return `${year}/${month}/${day} ${hours}:${minutes}`;
    }
    return `${year}/${month}/${day}`;
  };

  const startDateFormatted = formatDateTime(startDate);
  const endDateFormatted =  formatDateTime(endDate);

  let csvRows = [
    `Customer,${customerName},,`,
    `Description,${descriptionName},,`,
    `Source type,${sourceType},,`,
    `Date Range,${startDateFormatted} ~ ${endDateFormatted},,`,
  ];

  let dataFormat;
  if (timeDiff <= 24 * 60 * 60 * 1000) {
    // For Today, yesterday, and the last 24 Hours
    dataFormat = 'hourly';
    csvRows.push('Date and Time,kWh,Date and Time,kWh');
  } else if (timeDiff <= 31 * 24 * 60 * 60 * 1000) {
    // For this week, last week, this month, and last month
    dataFormat = 'daily';
    csvRows.push('Date,kWh,Date,kWh');
  } else {
    // For year and last year
    dataFormat = 'monthly';
    csvRows.push('Year and Month,kWh,Year and Month,kWh');
  }

  const formatDataRow = (item1, item2) => {
    const formatValue = (value) => {
      if (value === null || value === undefined) {
        return 'null';
      }
      
      // Check if the value is a whole number (integer)
      return Number.isInteger(value) ? value.toString() : value.toFixed(1);
    };

    if (dataFormat === 'hourly') {
      const date1 =  formatDate(new Date(item1.time * 1000));
      const value1 = formatValue(item1.electricity);
      const date2 = item2 ? formatDate(new Date(item2.time * 1000)) : '';
      const value2 = item2 ? formatValue(item2.electricity) : '';
      return `${date1},${value1},${date2},${value2}`;
    } else if (dataFormat === 'daily') {
      const date1 = formatDate(new Date(item1.time * 1000), false);
      const value1 = formatValue(item1.electricity);
      const date2 = item2 ? formatDate(new Date(item2.time * 1000), false) : '';
      const value2 = item2 ? formatValue(item2.electricity) : '';
      return `${date1},${value1},${date2},${value2}`;
    } else {
      const date1 = new Date(item1.time * 1000);
      const yearMonth1 = `${date1.getFullYear()}/${String(date1.getUTCMonth() + 1).padStart(2, '0')}`;
      const value1 = formatValue(item1.electricity);
      let yearMonth2 = '', value2 = '';
      if (item2) {
        const date2 = new Date(item2.time * 1000);
        yearMonth2 = `${date2.getFullYear()}/${String(date2.getUTCMonth() + 1).padStart(2, '0')}`;
        value2 = formatValue(item2.electricity);
      }
      return `${yearMonth1},${value1},${yearMonth2},${value2}`;
    }
  };

  // Group data if necessary
  let groupedData = electricityData;
  if (dataFormat === 'daily') {
    groupedData = groupDataByDay(electricityData);
  } else if (dataFormat === 'monthly') {
    groupedData = groupDataByMonth(electricityData);
  }

  // Add data rows
  for (let i = 0; i < groupedData.length; i += 2) {
    const currentItem = groupedData[i];
    const nextItem = groupedData[i + 1];
    csvRows.push(formatDataRow(currentItem, nextItem));
  }

  const csvString = csvRows.join('\r\n') + (csvRows.length > 0 ? '\r\n' : ''); // Ensures last line ends with '\r\n'
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Generate file name based on specifications
  const startPeriod = start.toISOString().split('T')[0].replace(/-/g, '');
  const endPeriod = end.toISOString().split('T')[0].replace(/-/g, '');
  const fileName = `${country}_${sourceType}_${startPeriod}_${endPeriod}.csv`;

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


// Helper function to group data by day case
const groupDataByDay = (data) => {
  const groupedData = [];

  data.forEach((item, index) => {
    const date = new Date(item.time * 1000);
    const dateKey = date.toISOString().split('T')[0];

    // Push a new entry for each item, allowing duplicates for the same date
    groupedData.push({
      date: dateKey,
      time: item.time,
      electricity: item.electricity !== null && item.electricity !== undefined ? item.electricity : null,
      originalIndex: index // Store the original index if needed
    });
  });

  return groupedData;
};


// Helper function to group data by month
const groupDataByMonth = (data) => {
  const groupedData = [];

  data.forEach((item, index) => {
    const date = new Date(item.time * 1000);
    const monthKey = `${date.getFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}`;

    // Push a new entry for each item, allowing duplicates for the same month
    groupedData.push({
      month: monthKey,
      time: item.time,
      electricity: item.electricity !== null && item.electricity !== undefined ? item.electricity : null,
      originalIndex: index // Store the original index if needed
    });
  });

  return groupedData;
};


  return  <>
  <Button onClick={() => exportToCSV(electricityData)} className="exportButton" endIcon={<ExportIcon/> }>  {energyChart.Export }</Button> 
    <div
          id="chartdiv"
          ref={chartDiv}
          style={{ width: '100%', height: 'calc(100vh - 200px)' }}
      />
  </>;
};

export default AmChartComponent;
