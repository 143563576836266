import React from 'react';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import ErrorIcon from '@mui/icons-material/Error';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { Box, InputLabel, InputAdornment, IconButton } from '@mui/material';
import { useAutocomplete } from '@mui/base/useAutocomplete';

import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';

function Tag(props) {
	const { label, onRemove, ...other } = props;
	return (
		<div {...other}>
			<span>{label}</span>
			<CloseIcon onClick={onRemove} />
		</div>
	);
}

const StyledTag = styled(Tag)(
	({ theme }) => `
    display: flex;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: ${
			theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
		};
    border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;
    width: fit-content;
  
    &:focus {
      border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
      background-color: ${
				theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'
			};
    }
  
    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
    & svg {
      font-size: 12px;
      cursor: pointer;
      padding: 4px;
    }
  `
);

const CustomInputField = ({
	key,
	label,
	value,
	selectedItem,
	onRemove,
	placeholder,
	onChange,
	onClick,
	onClear,
	error,
	sx,
	addItem,
	disabled,
}) => {
	return (
		<FormControl error={error} variant="standard" sx={{ width: '100%' }}>
		
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				sx={{ fontSize: '12px', color: '#7D7D7D' }}
			>
			<FormLabel align="left" sx={{ marginBottom: '10px' }}> 
				{label}
			</FormLabel>
				{selectedItem?.length||'0'}/10
			</Stack>
			
			<Stack direction="column">
				<TextField
					id={key}
					sx={sx}
					size="small"
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					fullWidth
					variant="outlined"
					margin="normal"
					error={!!error}
					helperText={error}
					disabled={disabled}
					InputProps={{
						endAdornment: (
							<>
								{error && (
									<InputAdornment position="end">
										<IconButton aria-label="error">
											<ErrorIcon color="error" />
										</IconButton>
									</InputAdornment>
								)}
								{addItem && (
									<>
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												edge="end"
												onClick={onClear}
											>
												<CloseIcon />
											</IconButton>
										</InputAdornment>
									</>
								)}
							</>
						),
					}}
				/>
			</Stack>

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={0}
			>
				{/* {emailExist && <FormHelperText  sx={{color:"#d32f2f"}}>Email already exist</FormHelperText>} */}

				{addItem && value && !error && (
					<Button
						variant="outlined"
						size="small"
						sx={{ p: 0, mt: 0.2, width: '10px', marginLeft: 'auto' }}
						onClick={onClick}
					>
						+ Add
					</Button>
				)}
			</Stack>

			{selectedItem?.map((option, index) => (
				<StyledTag
					key={index} // Use index as key since selectedItem array doesn't have unique keys
					label={option} // Use option as the label for the tag
					onRemove={() => onRemove(index)} // Assuming onRemove function is passed and handles removal
				/>
			))}
		</FormControl>
	);
};

export default CustomInputField;
