import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';

const CustomRadioGroup = ({ label, options, value, onChange, error, sx }) => {
	return (
		<FormControl error={error} variant="standard">
			<FormLabel align="left">{label}</FormLabel>
			<RadioGroup row value={value} onChange={onChange} error={error}>
				{options.map(option => (
					<FormControlLabel
						key={option.value}
						value={option.value}
						control={<Radio sx={sx} />}
						label={option.label}
					/>
				))}
			</RadioGroup>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
};

export default CustomRadioGroup;
