import { configureStore } from "@reduxjs/toolkit";
import loginInfoSlice from "./redux/slices/loginInfoSlice";
import customerSlice from "./redux/slices/customerSlice";
import customerReducer from './redux/slices/customerFilterSlice';

const store = configureStore({
  reducer: {
    loginInfo: loginInfoSlice,
    customerInfo: customerSlice,
    customer: customerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
