import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, TextField, Button, Tooltip, MenuItem, IconButton, Select, Menu, Divider, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import usePost from '../../../common/hook/usePost';
import { userDataEndPoint, deleteUserEndPoint } from '../../../services/apiConfig';
import './../../alarm/AlarmSummaryTab/AlarmSummary.scss';
import useDelete from '../../../common/hook/useDelete';
import { ReactComponent as SearchIcon } from '../../../assets/images/SearchIcon.svg';
import { ReactComponent as ChevronDown } from "../../../assets/images/ChevronDown.svg";
import { ReactComponent as CheckIcon } from "../../../assets/images/CheckMark.svg";
import { ReactComponent as LeftArrow } from "../../../assets/images/PaginationArrowLeft.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/PaginationArrowRight.svg";
import CustomButton from '../../../common/buttons/CustomButton';
import SmallReusableModal from '../../../common/reusable-comps/modals/SmallReusableModal';
import { formatDate, formatToCustomDateString } from '../../../common/utils/CommonUtils';

export default function UserGrid({ onAddUser, onEditUser }) {
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [userLocalData, setUserLocalData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pageSize, setPageSize] = useState(100);
    const [sortField, setSortField] = useState('');
    const [modalDeleteUser, setModalUserDelete] = useState(false);
    const [sortDirection, setSortDirection] = useState('asc');
    const [search, setSearch] = useState('');
    const [shouldFetch, setShouldFetch] = useState(true);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [localeText, setLocaleText] = useState({ noRowsLabel: '' });
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isSearchLoad, setIsSearchLoad] = useState(false);
    const loggedInUserId = localStorage.getItem("userId");
    const { t } = useTranslation();
    const { userManagementContent } = t("mainContainer").settingsPageContent;
    const {
        loading: userDataLoading,
        error: userDataError,
        data: userApiData,
        postData: postUserData
    } = usePost();

    const { loading: deleteUserLoading, error: deleteUserError, data: deleteUserData, deleteRequest } = useDelete(deleteUserEndPoint());

    const pageView = 5;

    const updateLocaleText = () => {
        if (isInitialLoad && !userDataLoading && !userDataError && userApiData?.result?.results && userApiData?.result?.results?.length === 0) {
            setLocaleText({ noRowsLabel: userManagementContent?.noData });
            setIsInitialLoad(false);
        } else if (isSearchLoad && !userDataLoading && !userDataError && userApiData?.result?.results) {
            setLocaleText({ noRowsLabel: userManagementContent?.nosearchData });
        }
    };

    useEffect(() => {
        updateLocaleText();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDataLoading, userDataError, userApiData, userLocalData]);

    const fetchUserData = useCallback(() => {
        if (!shouldFetch) return;
        const payload = {
            search: search || undefined,
            limit: pageSize,
            page: page + 1,
        };
        if (sortField) {
            payload.sortBy = `${getSortFieldName(sortField)}:${sortDirection}`;
        }
        Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);
        postUserData(userDataEndPoint(), payload);
        setShouldFetch(false);
    }, [pageSize, page, sortField, sortDirection, search, postUserData, shouldFetch]);

    const handleSortModelChange = (newSortModel) => {
        if (newSortModel?.length > 0) {
            setSortField(newSortModel[0]?.field);
            setSortDirection(newSortModel[0]?.sort);
        } else {
            setSortField('');
            setSortDirection('asc');
        }
        setPage(0);
        setShouldFetch(true);
    };

    useEffect(() => {
        if (shouldFetch) {
            fetchUserData();
        }
    }, [fetchUserData, shouldFetch]);

    useEffect(() => {
        if (userApiData && userApiData?.result && userApiData?.result?.results) {
            const userData = userApiData?.result?.results.map(user => ({
                id: user.id,
                email: user.email,
                role: user.role,
                lastLogin: user?.lastLoginAt ?? "2024-10-10T05:54:16.101Z",
                displayName: user?.displayName ?? user?.name,
                state: user.state,
            }));
            setUserLocalData(userData);
            setTotalCount(userApiData?.result?.totalResults);
        }
    }, [userApiData]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        setShouldFetch(true);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(0);
        setShouldFetch(true);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        setPage(0);
        setShouldFetch(true);
        updateLocaleText();
        setIsSearchLoad(true);
    };

    const handleClearSearch = () => {
        setSearch('');
        setPage(0);
        setShouldFetch(true);
        setIsSearchLoad(false);
        setIsInitialLoad(true);
    };

    const handleFilterModelChange = (filterModel) => {
        setSearch('');
        setPage(0);
        setShouldFetch(true);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleMenuOpen = (event, rowId) => {
        setMenuAnchorEl(event?.currentTarget);
        setSelectedRowId(rowId);
    };

    const handleAlarmSummaryDetails = (key) => {
        const selectedData = userLocalData?.find(user => user?.id === selectedRowId);
        setSelectedUser(selectedData);
        handleMenuClose();
        if (key === "delete") {
            setModalUserDelete(true);
        } else if (key === "edit") {
            onEditUser(); // Call the edit handler passed from UserManagement
        }
    };

    const columns = [
        {
            field: 'displayName',
            headerName: userManagementContent?.userManagementTableHeader["displayName"],
            filterable: true,
            sortable: true,
            flex: 25,
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'email',
            headerName: userManagementContent?.userManagementTableHeader["email"],
            sortable: true,
            flex: 25,
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'role',
            headerName: userManagementContent?.userManagementTableHeader["role"],
            sortable: true,
            flex: 25,
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'lastLogin',
            headerName: userManagementContent?.userManagementTableHeader["lastLogin"],
            flex: 25,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box className="actionColumn" display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Tooltip title={formatToCustomDateString(formatDate(params?.value))} arrow>
                        <span>{params.value ? formatToCustomDateString(formatDate(params.value)) : "—"}</span>
                    </Tooltip>
                    <IconButton
                        onClick={(event) => handleMenuOpen(event, params.row.id)}
                        size="small"
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    const getSortFieldName = (visibleFieldName) => {
        const fieldMapping = {
            'displayName': 'name',
            'email': 'email',
            'role': 'role',
            'lastLogin': 'lastLogin',
        };
        return fieldMapping[visibleFieldName] || visibleFieldName;
    };

    const handleCloseDeleteModal = () => {
        setModalUserDelete(false);
    };

    const smallModalChildren = () => {
        return <span>{userManagementContent?.deleteModalMessage}</span>;
    };

    const handleUserDelete = async (userId) => {
        await deleteRequest({ id: userId });
    };

    useEffect(() => {
        if (!deleteUserLoading && !deleteUserError && deleteUserData) {
            handleCloseDeleteModal();
            setShouldFetch(true);
        }
    }, [deleteUserLoading, deleteUserError, deleteUserData]);

    const renderFooter = () => {
        return (
            <div>
                <CustomButton
                    variant="text"
                    onClick={handleCloseDeleteModal}
                    marginRight=".5rem"
                    width="108px"
                    padding="11px 16px"
                >
                    {userManagementContent.btnCancel}
                </CustomButton>
                <Button
                    variant="contained"
                    onClick={() => handleUserDelete(selectedUser?.id)}
                    color="error"
                    sx={{ width: "74px", height: "40px" }}
                >
                    {userManagementContent.deletModalBtn}
                </Button>
            </div>
        );
    };

    return (
        <Box className="userGridContainer">
            <Box className="userGridSearchBar">
                <Typography variant="span" gutterBottom className="lastUpdateLabel">
                </Typography>
                <Box className="searchBarInnerContent">
                    <TextField
                        className='commonSearchField'
                        id="search"
                        name="search"
                        value={search}
                        placeholder={userManagementContent?.searchUserPlaceholder}
                        autoComplete="off"
                        sx={{
                            width: '180px',
                            marginRight: '14px',
                            '& .MuiInputBase-root': {
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '18px',
                                letterSpacing: '0.16px',
                                height: '40px',
                                backgroundColor: '#F4F4F4',
                            },
                        }}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ '& .MuiSvgIcon-root': { padding: '10px' } }}>
                                    <SearchIcon />
                                    {search?.length > 0 && (
                                        <span
                                            className="close"
                                            onClick={handleClearSearch}
                                            style={{ cursor: 'pointer', marginRight: '1rem' }}
                                        >
                                            &times;
                                        </span>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomButton
                        variant="contained"
                        backgroundColor="primary"
                        width="124px"
                        padding="16px"
                        onClick={onAddUser}
                        textColor="#FFFFFF"
                        fontWeight="400"
                    >
                        <span className='userGridAddBtn'>
                            {userManagementContent?.addUserBtn}
                        </span>
                        +
                    </CustomButton>
                </Box>
            </Box>

            <Box className="gridOuterBox">
                <DataGrid
                    className="userManagementGrid"
                    rows={userLocalData}
                    columns={columns}
                    pageSize={pageSize}
                    rowCount={totalCount}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortModelChange}
                    sortingMode="server"
                    sortModel={sortField ? [{ field: sortField, sort: sortDirection }] : []}
                    filterMode="server"
                    onFilterModelChange={handleFilterModelChange}
                    loading={isInitialLoad && userDataLoading}
                    hideFooter={true}
                    disableColumnSelector
                    key={JSON.stringify(userLocalData)}
                    disableColumnResize={true}
                    disableColumnMenu={true}
                    disableDensitySelector
                    disableColumnFilter={true}
                    rowHeight={40}
                    localeText={localeText}
                    sx={{
                        '& div.MuiDataGrid-columnHeaders': {
                            backgroundColor: 'background.paper',
                            color: 'text.primary',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    }}
                />
            </Box>

            <Divider className='gridDivider' />
            <CustomPagination
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                pageView={pageView}
            />
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: "160px",
                        marginLeft: "-36px"
                    },
                }}
                sx={{
                    '& .MuiList-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
            >
                {Object.entries(userManagementContent?.actionMenu)?.map(([key, value]) => (
                    <MenuItem key={key} onClick={() => handleAlarmSummaryDetails(key)}
                        disabled={value === "Delete" && loggedInUserId == selectedRowId}
                    >
                        {value}
                    </MenuItem>
                ))}
            </Menu>
            {modalDeleteUser && (
                <SmallReusableModal
                    open={modalDeleteUser}
                    handleClose={handleCloseDeleteModal}
                    title={userManagementContent.deleteModalTitle}
                    footer={renderFooter()}
                    divider={false}
                    dialogWidth="414px"
                    titleAndIconPadding="0"
                    contentPadding="0 0 32px 0"
                >
                    {smallModalChildren()}
                </SmallReusableModal>
            )}
        </Box>
    );
}
function CustomPagination({ totalCount, page, pageSize, onPageChange, onPageSizeChange, pageView }) {
    const totalPages = Math.ceil(totalCount / pageSize);
    const { t } = useTranslation();
    const { alarm } = t("mainContainer");
    const handlePageChange = (event) => {
        const newPage = parseInt(event.target.value, 10) - 1;
        onPageChange(newPage);
    };

    const handlePrevPage = () => {
        if (page > 0) {
            onPageChange(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages - 1) {
            onPageChange(page + 1);
        }
    };

    const handlePageSizeChange = (event) => {
        const newPageSize = parseInt(event.target.value, 10);
        onPageSizeChange(newPageSize);
    };

    const startItem = page * pageSize + 1;
    const endItem = Math.min((page + 1) * pageSize, totalCount);

    const startPage = Math.floor(page / pageView) * pageView + 1;
    const endPage = Math.min(startPage + pageView - 1, totalPages);

    return (
        <Box display="flex" alignItems="center" direction="column" justifyContent="space-between">
            <Box display="flex" alignItems="center" direction="row">
                <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outlined"
                    size="small"
                    IconComponent={ChevronDown}
                    sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                >
                    <MenuItem value={25} className="customMenuItem">
                        {pageSize === 25 && <CheckIcon />}  {/* Add icon for active MenuItem */}
                        25
                    </MenuItem>
                    <MenuItem value={50} className="customMenuItem">
                        {pageSize === 50 && <CheckIcon />}  {/* Add icon for active MenuItem */}
                        50
                    </MenuItem>
                    <MenuItem value={75} className="customMenuItem">
                        {pageSize === 75 && <CheckIcon />}  {/* Add icon for active MenuItem */}
                        75
                    </MenuItem>
                    <MenuItem value={100} className="customMenuItem">
                        {pageSize === 100 && <CheckIcon />}  {/* Add icon for active MenuItem */}
                        100
                    </MenuItem>
                </Select>
                <Divider

                    orientation="vertical"
                    flexItem
                    style={{ marginBottom: "-2px" }}
                />
                <Typography variant="body2" mx={2} className="pageViewHelpText">
                    {startItem} – {endItem} {alarm?.of} {totalCount}  {alarm?.items}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" direction="row">
                <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginBottom: "-2px" }}
                />
                <TextField
                    className="pageSelect"
                    select
                    value={page + 1}
                    onChange={handlePageChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        width: 80, "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        }
                    }}
                    SelectProps={{
                        IconComponent: ChevronDown,
                    }}
                >
                    {[...Array(endPage - startPage + 1)].map((_, index) => {
                        const currentPage = startPage + index; // Calculate the current page
                        return (
                            <MenuItem key={currentPage} value={currentPage} className="customMenuItem">
                                {currentPage === page + 1 && <CheckIcon />} {/* Add active icon if it's the current page */}
                                {currentPage}
                            </MenuItem>
                        );
                    })}
                </TextField>
                <Typography variant="body2" mr={2}>
                    of {totalPages} {alarm?.Pages}
                </Typography>
                <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginBottom: "-2px" }}
                />
                <IconButton
                    className='pageDirection'
                    onClick={handlePrevPage}
                    disabled={page === 0}
                    sx={{
                        display: "flex",
                        padding: "12px",
                        alignItems: "center",
                        alignSelf: "stretch",
                        opacity: page === 0 ? 0.3 : 1, // Add this line
                        "&:hover": {
                            backgroundColor: "rgba(111, 111, 111, 0.12)",
                            transform: "scale(1.2)",
                        },
                    }}
                >
                    <LeftArrow />
                </IconButton>
                <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginBottom: "-2px" }}
                />
                <IconButton
                    onClick={handleNextPage}
                    disabled={page === totalPages - 1}
                    className='pageDirection'
                    sx={{
                        display: "flex",
                        padding: "12px",
                        alignItems: "center",
                        alignSelf: "stretch",
                        opacity: page === totalPages - 1 ? 0.3 : 1, // Add this line
                        "&:hover": {
                            backgroundColor: "rgba(111, 111, 111, 0.12)",
                            transform: "scale(1.2)",
                        },
                    }}
                >
                    <RightArrow />
                </IconButton>


            </Box>
        </Box>
    );
}