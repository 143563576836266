import { useState } from 'react';
import axios from 'axios';

const useFetch = url => {
	const [data, setData] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const token = localStorage?.getItem('accessToken');

	const fetchData = async (url, payload) => {
		setLoading(true);
		setData(false);
		setError(false);
		try {
			let fetchUrl;
			// If payload is provided and it's an object, convert it to URLSearchParams
			if (payload && typeof payload === 'object') {
				const params = new URLSearchParams(payload);
				fetchUrl = `${url}?${params.toString()}`;
			}else{
				fetchUrl = url;
			}

			const response = await axios.get(fetchUrl, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});

			if (response.status === 200) {
				// Parse the response data
				let result;
				// Parse the response data if it's a string
				// Assuming 'data' field is what you're interested in
				setData(response.data.result || response.data.results);
				setError(false);
			} else {
				// Handle unexpected status code
				throw new Error(`Unexpected status code: ${response.status}`);
			}
		} catch (error) {
			setError(true);
			// setError(error.message || 'Something went wrong');
			setData(false);
		} finally {
			setLoading(false);
		}
	};

	return { loading, error, data, fetchData };
};

export default useFetch;
