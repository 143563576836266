import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { io } from 'socket.io-client'; // Import the socket.io-client library
import { consolidateByState, getTotalForAll } from "./MockApi";
import AlarmCard from "./AlarmCard";
import ElectricityCard from "./ElectricityCard";
import PrefectureAccordion from "./PrefectureAccordion";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";
import { landingPageStyle } from "./LandingPageStyle";
import { updateAccordionList } from "../../redux/slices/customerSlice";
import { electricityConsumptionURL, getHomeAllSiteList, socketIOURL } from "../../services/apiConfig";
import useFetch from "../../common/hook/useFetch";
import NoPrefecture from "./NoPrefecture";
import usePost from '../../common/hook/usePost';
import { getSingleStateName } from "../../common/utils/CommonUtils";
import LandingPageSearchBar from "./LandingPageSearchBar";
import { prefectures } from "../setting/CustomerData";
import { formatToCustomDateString } from "../../common/utils/CommonUtils";
import { convertDateTimeToTimestamp } from "../../common/utils/CommonUtils";
import { formatDate } from "../../common/utils/CommonUtils";
import { useSocketAlarmCountOnLandingPage, useSocketElectricityOnLandingPage } from "../../common/utils/socketUtils";

function LandingPage() {
  const [consolidatedData, setConsolidatedData] = useState({});
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [siteListCount, setSiteListCount] = useState("blank");
  const [searchType, setSearchType] = useState("area")
  const [searchQuery, setSearchQuery] = useState('')
  const { accordionList } = useSelector((state) => state.customerInfo);
  const [data, setData] = useState(accordionList);
  const [resetKey, setResetKey] = useState(0)
  const [hasSearched, setHasSearched] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const { recentSearchId, selectedCategory, isUpdateRecentSearchId, DateRangeFilter, allCustomers } = useSelector(
    (state) => state?.customer
  );
  const {
    landingPage: { cards, siteListTableHeader, accordionHeader, noResultFound, noDataText },
  } = t("mainContainer");

  const singleCustomerWithNoSites = useMemo(() => {
    const result = allCustomers.length === 1 && allCustomers[0]?.siteCount === 0;
    return result;
  }, [allCustomers]);

  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost();

  const {
    data: fetchsitesData,
    error: fetchsiteError,
    loading: fetchsiteErrorLoading,
    fetchData: getSitesDataList,
  } = useFetch();

  const { totalElectricity, totalAlarms } = getTotalForAll(data);


  useEffect(() => {
    if (singleCustomerWithNoSites) {
      setSiteListCount('empty');
    }
  }, [singleCustomerWithNoSites]);

  useEffect(() => {
    // Initialize socket connection
    const socketInstance = io(socketIOURL); // Replace with your server URL
    setSocket(socketInstance);
    // Cleanup the socket when the component unmounts
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  // socket function to get alarm count
  useSocketAlarmCountOnLandingPage(socket, updateAccordionList, accordionList, recentSearchId);
  //socket function to get updated electricity count
  useSocketElectricityOnLandingPage(socket, updateAccordionList, DateRangeFilter, accordionList, recentSearchId);

  useEffect(() => {
    setData(accordionList)
  }, [accordionList]);

  useEffect(() => {
    if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
      if (fetchsitesData?.length === 0 || !fetchsitesData?.some(obj => obj?.hasOwnProperty('state'))) {
        setSiteListCount('empty')
      }
    }
  }, [fetchsiteErrorLoading, fetchsitesData, fetchsiteError]);

  useEffect(() => {
    if (recentSearchId) {
      const [dateStartString, dateEndString] = DateRangeFilter?.value;

      // To convert a date string from the format YYYY/MM/DD HH:mm 
      const customDateStartString = formatToCustomDateString(formatDate(dateStartString));
      const customDateEndString = formatToCustomDateString(formatDate(dateEndString));

      // Create and return the timestamp 
      const recentSearchPayload = {
        searchId: recentSearchId,
        startDate: convertDateTimeToTimestamp(customDateStartString),
        endDate: convertDateTimeToTimestamp(customDateEndString)
      };
      doPostPrefectureApi(electricityConsumptionURL, recentSearchPayload);
      setSiteListCount("blank");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentSearchId, isUpdateRecentSearchId]);

  useEffect(() => {
    if (selectedCategory?.id) {
      getSitesDataList(getHomeAllSiteList(selectedCategory?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const consolidatedKeysLength = Object.keys(consolidatedData)?.length;
    setResetKey(consolidatedKeysLength, consolidatedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedData]);

  useEffect(() => {
    if (prefectureApiLoading && !prefectureApiError && !prefectureApiData) {
      setIsInitialLoading(true);
    } else if (
      prefectureApiData && !prefectureApiLoading &&
      !prefectureApiError
    ) {
      dispatch(updateAccordionList(prefectureApiData?.result));
      const consolidated = consolidateByState(prefectureApiData?.result);
      setConsolidatedData(consolidated);
      const consolidatedKeys = Object.keys(consolidated);
      if (consolidatedKeys?.length === 1) {
        setExpandedAccordion(consolidatedKeys[0]);
      } else {
        setExpandedAccordion(null);
      }
      setIsInitialLoading(false);

    }
  }, [prefectureApiData, prefectureApiError, prefectureApiLoading, dispatch]);

  const handleAccordionChange = (state) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? state : null);
  };

  useEffect(() => {
    const updatedConsolidatedData = consolidateByState(data);
    setConsolidatedData(updatedConsolidatedData);
  }, [data]);


  const handleSearch = (searchTyped, searchQuerys) => {
    setSearchType(searchTyped)
    setSearchQuery(searchQuerys)
    setHasSearched(true);
  };
  useEffect(() => {
    const updatedFilteredData = accordionList?.filter((item) => {
      if (searchType === "area") {
        const stateName = prefectures?.find(pref => pref?.value === item?.state)?.label?.toLowerCase();
        return stateName?.includes(searchQuery?.toLowerCase());
      }
      else if (searchType === "site") {
        return item?.siteName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      }
      else if (searchType === "address") {
        return item?.address1?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      }
      else return true;
    });
    setData(updatedFilteredData)
  }, [searchType, searchQuery, accordionList])

  const noSearchFunction = () => {
    return (
      <Stack className="noItemsContainer">
        <Typography>
          {noResultFound}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      {isInitialLoading ? (
        <CustomCircularProgress
          size={44}
          inputSx={landingPageStyle?.loaderStyle}
        />
      ) : (
        <Box className="landingPageParentBox">
          {siteListCount === "blank" && prefectureApiData?.result?.length > 0 ? (
            <>
              <Box className="outerCardContent">
                <ElectricityCard
                  totalElectricity={totalElectricity}
                  cards={cards}
                  noDataText={noDataText}
                />
                <AlarmCard cards={cards} totalAlarms={totalAlarms} noDataText={noDataText} />
              </Box>
              <LandingPageSearchBar resetKey={resetKey} areaCount={Object.keys(consolidatedData)?.length} onSearch={handleSearch} setSearchQuery={setSearchQuery} searchQuery={searchQuery} searchType={searchType} setSearchType={setSearchType} accordionHeader={accordionHeader}></LandingPageSearchBar>
              {data?.length > 0 ? Object.keys(consolidatedData)?.map((state) => {
                const stateData = data?.filter((site) => site?.state === state);
                return (
                  <Box key={state}>
                    <PrefectureAccordion
                      key={state}
                      stateName={getSingleStateName(state)}
                      cards={cards}
                      title={`Sites in ${getSingleStateName(state)}`}
                      data={stateData}
                      columns={siteListTableHeader}
                      expanded={expandedAccordion === state}
                      onChange={handleAccordionChange(state)}
                      selectedCategory={selectedCategory}
                      landingSearchQuery={searchQuery}
                    />
                  </Box>
                );
              }) :
                (hasSearched && noSearchFunction())}
            </>
          ) : (
            (siteListCount === 'empty' || prefectureApiData?.result?.length == 0) && (
              <NoPrefecture selectedCategory={selectedCategory} />
            )
          )}
        </Box >
      )
      }
    </>
  );
}
export default LandingPage;

