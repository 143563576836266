import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import Lottie from "lottie-react";
import { Box, Grid, Divider, Typography, Link } from '@mui/material';
import spinner from "../../assets/animation/spinner.json";
import { getSiteImage } from '../../services/apiConfig';
import blueMarker from '../../assets/images/blueMarker.svg';
import redMarker from '../../assets/images/redMarker.svg';
import buildingImg from '../../assets/images/SiteDefaultImage.svg';
import { ReactComponent as AddressMarker } from '../../assets/images/AddressMarker.svg';
import { ReactComponent as BlueArrow } from "../../assets/images/BlueRightArrow.svg";
import usePost from '../../common/hook/usePost';
import { ReactComponent as Lightening } from '../../assets/images/LighteningSmall.svg';
import { ReactComponent as BellIcon } from "../../assets/images/SmallAlarm.svg";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NWC_ALARM_URL } from '../../common/constants/Constants';
import { formatUnit } from '../../common/utils/CommonUtils';
import { consolidateByState, getTotalForAll } from '../langing-page/MockApi';
// Replace 'DEMO_MAP_ID' with your actual map ID
const MAP_ID = 'DEMO_MAP_ID';
/**
 * PoiMarkers component handles rendering and clustering of markers on the map.
 * @param {Object} props - Component props containing 'pois' array of POI locations.
 */

const PoiMarkers = (props) => {
	const map = useMap();
	const [markers, setMarkers] = useState({});
	const mapped = useRef(false)
	const [selectedMarker, setSelectedMarker] = useState(null); // State to hold selected marker
	const token = localStorage?.getItem("accessToken");
	const { isSiteGridMap, isTopNavigationMap, selectedSite, pois } = props;
	useEffect(() => {
		if (!map) return;
		const bounds = new window.google.maps.LatLngBounds();
		Object.values(markers).forEach(marker => {
			if (marker && marker.getPosition) {
				bounds.extend(marker.getPosition());
			}
		});

		if (!bounds.isEmpty()) {
			map.fitBounds(bounds);
		}
	}, [map, markers]);

	useEffect(() => {
		if (!map) return;
		const geocoder = new window.google.maps.Geocoder();
		const newMarkers = {};
		const geocodeRequests = pois.map(poi => {
			let geocodeRequest = {};
			if (isSiteGridMap && poi?.location?.lat && poi?.location?.lng) {
				geocodeRequest = { latLng: new window.google.maps.LatLng(poi.location.lat, poi.location.lng) };
			} else {
				if (poi?.address) {
					geocodeRequest = { address: `${poi?.address}, ${poi?.country}` };
				} else if (poi?.postalCode) {
					geocodeRequest = {
						componentRestrictions: {
							postalCode: poi.postalCode,
							country: poi.country,
						},
					};
				}
			}
			return geocodeRequest;
		});

		Promise.all(
			geocodeRequests?.map(
				req => new Promise(resolve => {
					if (req?.latLng) {
						resolve([{ geometry: { location: req?.latLng } }]);
					}
					else {
						geocoder.geocode(req, resolve);
					}
				})
			)
		)
			.then(results => {
				results.forEach((result, index) => {
					const poi = pois[index];
					if (result && result?.length > 0) {
						const location = result[0]?.geometry?.location;
						const isSelected = poi?.key === selectedSite?.siteId;
						const labelOrigin = new window.google.maps.Point(64, 15);
						const label = {
							text: poi.siteName,
							color: isSelected && !isTopNavigationMap ? '#E34935' : '#0F62FE',
							fontSize: '14px',
							fontWeight: 'bold',
							className: "marker-custom-label",
						};

						const markerIcon = {
							url: isSelected && !isTopNavigationMap ? redMarker : blueMarker,
							labelOrigin: labelOrigin,
						};

						const marker = new window.google.maps.Marker({
							position: location,
							map: map,
							icon: markerIcon,
							label: label,
						});

						const infowindow = new window.google.maps.InfoWindow();
						map.addListener('idle', async () => {
							if (isSiteGridMap && isSelected && !mapped.current) {
								const selectedLatLng = selectedSite?.location !== null ? new window.google.maps.LatLng(selectedSite?.location?.lat, selectedSite?.location?.lng) : new window.google.maps.LatLng(36.204824, 138.252924);
								map.setCenter(selectedLatLng);
								map.setZoom(12);
								mapped.current = true;
							}
							if (isTopNavigationMap && !mapped.current) {
								map.setZoom(12);
								mapped.current = true;
							}
						});
						if (!isSiteGridMap && !isTopNavigationMap) {
							marker.addListener('mouseover', async () => {
								if (poi?.imageUrl) {
									const payload = { "imageUrl": poi?.imageUrl };
									try {
										const response = await axios.post(getSiteImage, payload, {
											headers: {
												Authorization: `Bearer ${token}`,
												Accept: "application/json",
												"Content-Type": "application/json",
											},
										});
										await new Promise(resolve => {
											resolve();  // Resolves the promise immediately after state is set
										});
										infowindow.setContent(
											`<div class="site-tooltip">
                        <div class="site-default-img"><img src="${response?.data?.signedUrl}" alt="default site"/></div>
                        <div class="site-info">
                          <div class="site-title">${poi.siteName}</div>
                          <div class="site-desc">${poi.description}</div>
                        </div>
                      </div>`
										);
										infowindow.open(map, marker);

										marker.setLabel({
											text: marker.getLabel().text,
											color: '#0043CE',
											fontWeight: 'bold',
											className: "marker-custom-label"
										});
									} catch (error) {
										console.error('Error fetching site image:', error);
									}
								} else {
									infowindow.setContent(
										`<div class="site-tooltip">
                      <div class="site-default-img"><img src="${buildingImg}" alt="default site"/></div>
                      <div class="site-info">
                        <div class="site-title">${poi.siteName}</div>
                        <div class="site-desc">${poi.description}</div>
                      </div>
                    </div>`
									);
									infowindow.open(map, marker);

									marker.setLabel({
										text: marker.getLabel().text,
										color: '#0043CE',
										fontWeight: 'bold',
										className: "marker-custom-label"
									});
								}
							});

							marker.addListener('mouseout', () => {
								infowindow.close();
								if (!marker?.isClicked) {
									marker.setLabel({
										text: marker.getLabel().text,
										color: '#0F62FE',
										fontWeight: 'bold',
										className: "marker-custom-label"
									});
								}
							});
							marker.addListener('click', async () => {
								Object.values(newMarkers).forEach(m => {
									m.setIcon({
										url: blueMarker,
										labelOrigin: labelOrigin,
									});
									m.setLabel({
										text: m.getLabel().text,
										color: '#0F62FE',
										className: "marker-custom-label"
									});
									m.set('isClicked', false);
								});

								marker.setIcon({
									url: redMarker,
									labelOrigin: labelOrigin,
								});
								marker.setLabel({
									text: marker.getLabel().text,
									color: '#E34935',
									fontWeight: 'bold',
									className: "marker-custom-label"
								});
								marker.set('isClicked', true);
								props.onMarkerClick(poi);
								setSelectedMarker(poi);
							});
						}

						newMarkers[poi.key] = marker;
					} else {
						console.error(`Geocode was not successful for ${poi.address}`);
					}
				});

				setMarkers(newMarkers);
				if (isSiteGridMap && selectedSite?.siteId) {
					const selectedPoi = pois?.find(poi => poi.key === selectedSite?.siteId);
					if (selectedPoi?.location?.lat && selectedPoi?.location?.lng) {
						const selectedLatLng = new window.google.maps.LatLng(selectedPoi.location.lat, selectedPoi.location.lng);
						map.setCenter(selectedLatLng);  // Use setCenter to ensure it's at the exact center of the map
						map.setZoom(12);  // Optionally adjust zoom to make sure the marker is clearly visible
					}

				}
				const bounds = new window.google.maps.LatLngBounds();
				Object.values(newMarkers).forEach(marker => {
					if (marker && marker.getPosition) {
						bounds.extend(marker.getPosition());
					}
				});
				if (!bounds.isEmpty()) {
					map.fitBounds(bounds);
				}
			})
			.catch(error => {
				console.error('Geocoding error:', error);
			});

		return () => {
			Object.values(newMarkers).forEach(marker => {
				marker.setMap(null);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pois, map, isSiteGridMap, selectedSite, isTopNavigationMap]);

	return null;
};

/**
 * GMap component renders the Google Map with APIProvider and handles markers using PoiMarkers component.
 */
const GMap = (props) => {
	const { locat, isSiteGridMap, selectedSite, imageError, imageLoading, isTopNavigationMap, setCommonGmapOpen } = props;
	const [showSlider, setShowSlider] = useState(false); // State to control slider visibility
	const [selectedMarkerInfo, setSelectedMarkerInfo] = useState(null); // State to hold selected marker information
	const [currentSiteImage, setCurrentSiteImage] = useState();
	const consolidated = consolidateByState(locat);
	const { totalElectricity, totalAlarms } = getTotalForAll(locat);
	const { t } = useTranslation();
	const navigate = useNavigate()
	const {
		landingPage: {
			cards,
			landingMap,
			areaCountLabel,
			siteCountLabel
		},
	} = t("mainContainer");
	const map = useMap();
	const {
		loading: siteImageLoading,
		error: siteImageError,
		data: siteImageData,
		postData: doPostSiteImage,
	} = usePost();

	const handleMarkerClick = async (markerInfo) => {
		// When a marker is clicked, show the slider and set selected marker info
		setShowSlider(true);
		setSelectedMarkerInfo(markerInfo);
		const payload = {
			"imageUrl": markerInfo?.imageUrl
		};
		await doPostSiteImage(getSiteImage, payload);
	};
	useEffect(() => {
		if (!siteImageLoading && siteImageData && !siteImageError) {
			setCurrentSiteImage(siteImageData)
		}
	}, [siteImageLoading, siteImageError, siteImageData]);

	const handleCommonMapViewAll = () => {
		setCommonGmapOpen(false)
		navigate(NWC_ALARM_URL)
	}
	return (
		<div className="custom-google-map">
			<Box>
				<Grid container>
					{!showSlider && (
						<Grid item xs={3.5} md={3.5} className='multiple-grid-map' >
							{isSiteGridMap && !isTopNavigationMap && <>
								<div className='imgContainer'>
									{imageLoading && !imageError && (
										<div className="spinner-gmap blue-spinner">
											<Lottie className="spinner" animationData={spinner} autoplay loop />
										</div>
									)}
									{(!imageLoading || imageError) && (
										<img
											src={imageError ? buildingImg : selectedSite?.imageUrl}
											alt="static-site"
											className={`mapImage ${imageLoading ? 'loadingImg' : 'notImgLoading'}`}
										/>
									)}
								</div>
								<div className='landingStyleMap'>
									<span>
										{landingMap?.descTitle}
									</span>
									<span>
										{selectedSite?.description}
									</span>
									<div className='addressLanding'>
										<span>{landingMap?.addressTitle}</span>
										<span>
											{[selectedSite?.address, selectedSite?.city, selectedSite?.country]
												.filter(Boolean)
												.join(', ')}
										</span>
									</div>
								</div>
								<div className='landingElectricity'>
									<span >
										{landingMap?.electricityTitle}
									</span>
									<div className='mainDivData'>
										<Lightening className='lighteningDiv' />
										<Typography className='typographyDiv'>
											{selectedSite?.electricity > 0 ? selectedSite?.electricity : '—'}
										</Typography>
										<Typography className="mTypo">
											{formatUnit(selectedSite?.electricity)}
										</Typography>
										{selectedSite?.electricity > 0 && (
											<span
												className='kwhTypo'
											>
												kWh
											</span>
										)}

									</div>
								</div>
								<div className='lowerDiv'>
									<span>{landingMap?.alarmTitle}</span>
									<div className='innerDiv'>
										<BellIcon className='bellIcon' />
										<span className='alarmDiv'>
											{selectedSite?.alarmCount > 0 ? selectedSite?.alarmCount : '—'}
										</span>
									</div>
									<span className='viewAllDiv'>
										<Link className="mapLink" href="#"
										onClick={() => {  navigate(NWC_ALARM_URL, { state: { siteName: selectedMarkerInfo?.siteName } })  }}
										>
											{cards?.viewAllButton}
										</Link>
										<BlueArrow className='blueArrow' />
									</span>
								</div>
							</>

							}
							{!isSiteGridMap && isTopNavigationMap &&
								<>
									<div className="commonMapContainer">
										<div
											className="topContainer"

										>
											<span>{areaCountLabel} {Object.keys(consolidated)?.length}</span>
											<span>{siteCountLabel}: {locat?.length}</span>

										</div>
										<div className='landingElectricity commonMapPadding'>

											<span >
												{landingMap?.electricityTitle}
											</span>
											<div style={{ display: "flex" }}>
												<Lightening className='lighteningDiv' />
												<Typography className='typographyDiv'>
													{totalElectricity > 0 ? totalElectricity : '—'}
												</Typography>
												<Typography className="mTypo">
													{formatUnit(totalElectricity)}
												</Typography>
												{totalElectricity > 0 && (
													<span
														className='kwhTypo'
													>
														kWh
													</span>
												)}

											</div>

										</div>
										<div className="alarmSec" >
											<span>{landingMap?.alarmTitle}</span>
											<div className='innerDiv'>
												<BellIcon className='bellIcon' style={{ marginBottom: "-3px" }} />
												<span className='alarmDiv'>
													{totalAlarms > 0 ? totalAlarms : '—'}
												</span>
											</div>
											<div className='viewAllDiv'>
												<Link className="mapLink" href="#" onClick={handleCommonMapViewAll}>
													{cards?.viewAllButton}
												</Link>
												<BlueArrow className='blueArrow' />
											</div>
										</div>
									</div>
								</>}

						</Grid>)}
					{showSlider && !isSiteGridMap && !isTopNavigationMap && (
						<Grid item xs={2} md={2}>
							<Box
								sx={{ height: '60vh', overflowY: 'auto', paddingTop: '16px' }}
							>
								<Box>
									<div class="site-default-img">
										<img src={siteImageData?.signedUrl || buildingImg} alt="default site" />
									</div>

									{selectedMarkerInfo && (
										<div className="site-info">
											<div className="site-title">
												{selectedMarkerInfo?.siteName}
											</div>
											<p className="site-description">
												{selectedMarkerInfo?.description}
											</p>
											<div className="d-line" />
											<p className="site-address">
												<span className="icon">
													<AddressMarker />
												</span>
												{selectedMarkerInfo.address},<br />
												{selectedMarkerInfo.postalCode}
											</p>
										</div>
									)}
								</Box>
							</Box>
						</Grid>
					)}
					<Grid item xs={showSlider && !isSiteGridMap && !isTopNavigationMap ? 10 : isSiteGridMap || isTopNavigationMap ? 8.5 : 12} md={showSlider && !isSiteGridMap && !isTopNavigationMap ? 10 : isSiteGridMap || isTopNavigationMap ? 8.5 : 12}>
						<Box
							style={{
								width: 'auto',
								height: '34.188rem',
								padding: showSlider && !isSiteGridMap && !isTopNavigationMap ? '16px 16px 16px 0' : isSiteGridMap || isTopNavigationMap ? '0' : '16px'
							}}
						>
							<APIProvider apiKey={process.env.REACT_APP_API_KEY}>
								<Map
									defaultZoom={8}
									defaultCenter={{ lat: 36.2048, lng: 138.2529 }} // Example default center
									gestureHandling={'greedy'}
									onCameraChanged={ev => {
										console.log(
											'camera changed:',
											ev.detail.center,
											'zoom:',
											ev.detail.zoom
										)
									}
									}
									mapId={MAP_ID}
								>
									<PoiMarkers pois={locat} onMarkerClick={handleMarkerClick} selectedSite={selectedSite} isSiteGridMap={isSiteGridMap} isTopNavigationMap={isTopNavigationMap} />
								</Map>
							</APIProvider>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box></Box>
		</div >
	);
};

export default GMap;



