import axios from "axios";
import { refreshTokensEndPoint } from "../services/apiConfig";

// Create an axios instance
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_NWC_BASE_URL}:${process.env.REACT_APP_NWC_PORT}`,
});

// Function to refresh the token
export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    try {
      const response = await axios.post(refreshTokensEndPoint, {
        refreshToken,
      });
      localStorage.setItem("accessToken", response?.data?.access?.token);
      localStorage.setItem("refreshToken", response.data?.refresh?.token);
      localStorage.setItem("accessExpire", response?.data?.access?.expires);
      scheduleTokenRefresh();
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  }
};

// Schedule token refresh
const scheduleTokenRefresh = () => {
  const accessTokenExpiry = localStorage.getItem("accessExpire");
  if (accessTokenExpiry) {
    const expirationTime = new Date(accessTokenExpiry).getTime();
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime - 30000; // 30 seconds before expiration
    if (timeUntilExpiration > 0) {
      setTimeout(scheduleTokenRefresh, timeUntilExpiration);
    } else {
      refreshToken(); // Token has already expired or is about to expire
    }
  }
};
// Call the function initially to set up the timer
scheduleTokenRefresh();
axiosInstance.interceptors.request.use(async (req) => {
  const accessTokenExpiry = localStorage.getItem("accessExpire");
  if (accessTokenExpiry) {
    const expirationTime = new Date(accessTokenExpiry).getTime();
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;
    // Refresh token if it's about to expire within the next 30 seconds
    if (timeUntilExpiration <= 800000) {
      await refreshToken();
      // Recalculate token expiration and reschedule
      scheduleTokenRefresh();
    }
  }
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }
  return req;
});

export default axiosInstance;
