import React, { useState, useEffect } from "react";
import {
  ListItemIcon,
  SvgIcon,
  ListItemButton,
  ListItem,
  Divider,
  List,
  Drawer,
  Box,
  Link,
} from "@mui/material";
import { SidenavContent } from "./SidenavContent";
import { useNavigate, useLocation } from "react-router-dom";
import { NWC_LOGIN_URL } from "../../common/constants/Constants";
import { useSelector, useDispatch } from "react-redux";
import { updateLoader, logoutApi } from "../../redux/slices/loginInfoSlice";
import { logoutEndPoint } from "../../services/apiConfig";
import "../langing-page/LandingPages.scss";
import { useTranslation } from "react-i18next";
import { setRecentSearchId } from "../../redux/slices/customerFilterSlice";

export default function Sidenav() {
  const drawerWidth = 180;
  const [menuData, setMenuData] = useState(SidenavContent);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { sideNav } = t("mainContainer");

  // Set selected index based on current path
  useEffect(() => {
    const currentPath = location?.pathname;
    const foundIndex = menuData?.findIndex(
      (item) => item?.path === currentPath
    );
    if (foundIndex !== -1) {
      setSelectedIndex(foundIndex);
    }
  }, [location?.pathname, menuData]);

  const handleSignout = () => {
    const token = localStorage.getItem("refreshToken");
    const payload = { refreshToken: token };
    const url = logoutEndPoint;
    const apiPayload = { url, payload };
    dispatch(logoutApi(apiPayload)).then((res) => {
      if (res?.payload?.status == "204") {
        const lang = localStorage.getItem("i18nextLng");
        dispatch(updateLoader("loading"));
        localStorage.clear();
        navigate(NWC_LOGIN_URL, { state: { from: location?.pathname } });
        localStorage.setItem("i18nextLng", lang);
        dispatch(setRecentSearchId(""));
      }
    });
    
  };

  const handleNavigate = (item, index) => {
    setSelectedIndex(index);
    if (item?.path === "/login") {
      handleSignout();
    } else {
      navigate(item?.path, { state: { from: "sideNav" } });
    }
  };
  const listItemStyles = {
    width: "100%",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#0353E9",
      boxShadow: "none",
      width: "100%",
      color: "white",
      borderRadius: "4px",
      "& .listIcon .svgColor": {
        // stroke: "#ffffff",
        filter: "invert(1)",
      },
    },
    "&.Mui-selected": {
      width: "100%",
      backgroundColor: "#0F62FE",
      color: "white",
      borderRadius: "4px",
      "& .listIcon .svgColor": {
        // stroke: "#ffffff",
        filter: "invert(1)",
      },
    },
  };
  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          // height: "calc(100vh - 48px)",
          height: "100%",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            overflow: "hidden",
            position: "relative",
          },
        }}
      >
        <Box>
          <List className="sidenavListPadding">
            {menuData?.map((item, index) => (
              <div key={index}>
                {index === menuData?.length - 1 && (
                  <Divider sx={{ margin: "16px 0px" }} />
                )}
                <ListItem
                  sx={listItemStyles}

                  disablePadding
                  selected={selectedIndex === index}
                  onClick={() => handleNavigate(item, index)}
                  className="listItems"
                >
                  <ListItemButton sx={{ padding: "10px" }}>
                    <ListItemIcon className="listIcon">
                      <SvgIcon className="svgColor">{item?.icon}</SvgIcon>
                    </ListItemIcon>
                    <Link
                      marginTop={0.5}
                      marginLeft={"10px"}
                      color="inherit"
                      className="noTextDecoration"
                      fontSize={"14px"}
                      fontWeight={"400"}
                    >
                      {sideNav[index]}
                      {/* {item?.name} */}
                    </Link>
                  </ListItemButton>
                </ListItem>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
