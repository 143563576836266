import React from 'react';
import { Button } from '@mui/material';
import spinner from "../../assets/animation/spinner.json";
const ButtonWithIcon = ({
    postLoading,
    postError,
    postData,
    buttonText,
    handleClick,
    ErrorIcon,
    LoaderIcon,
    SuccessIcon,
    DefaultIcon,
    connectionType,
    flag,
    buttonStatus,
    color,
    disabled
}) => {
    const renderButtonContent = (flag) => {
        switch (true) {
          case postLoading && flag:
            return <>{buttonText?.loading}</>;
          case postError:
            return <>{buttonText?.failed}</>;
          case postData:
            return <>{buttonText?.success}</>;
          default:
            return <>{buttonText?.actionBtnSync}</>;
        }
      };
      const renderIcon = (flag) => {
        switch (true) {
          case postLoading && flag:
            return <LoaderIcon className="spinner" animationData={spinner} autoplay loop />;
          case postError:
            return <ErrorIcon />;
          case postData:
            return <SuccessIcon />;
          default:
            return <DefaultIcon />;
        }
      };
      
    return (
        <Button
            variant="contained"
            onClick={handleClick}
            disabled={disabled}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: color,
                width: '168px',
                height: "40px !important",
                boxShadow: "none",
                "&:hover": { backgroundColor: "#0353E9", boxShadow: "none", }
            }}
        >
            <span style={{ flexGrow: 1, textAlign: 'left' }}>{renderButtonContent(flag)}</span>
            {buttonStatus && <span style={{ marginLeft: '8px' }}>{renderIcon(flag)}</span>}
            {/* {renderButtonContent()}
      {buttonStatus &&  <span className="inline-icon">{renderIcon(connectionType)}</span>} */}
        </Button>
    );
};

export default ButtonWithIcon;
