import { margin } from "@mui/system";

 
// Define your style objects
export const customerTabStyles = {
    infoTitle : { 
        color:"#0043CE",
        fontSize: "14px", 
        fontWeight: "500" 
    },
    infoDesc:{
        color:"#161616",
        fontSize: "12px", 
        fontWeight: "400",
        textAlign:"center"
    },
    
   
    customerList:{ 
        padding: "10px 16px 10px 24px",  
        borderBottom: "1px solid #E0E0E0",
       
        margin:0,
        h6:{
            display: "flex",
            padding: "10px 16px 10px 24px",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",  
            width:"242px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            color:"#525252", 
            borderBottom: "1px solid #E0E0E0"
        },
        activeClass:{
            backgroundColor:"#E0E0E0",
            padding: "10px 16px 10px 24px", 
            borderRight: "1px solid  #E0E0E0",
            borderBottom: "1px solid #E0E0E0",
            margin:0,
        },
        checkBoxStyle:{
            display:"none"
        }, 
    }, 
    areaList:{ 
        padding: "9px 16px", 
        borderBottom: "1px solid #E0E0E0", 
        h6:{
            display: "flex",
            padding: "10px 16px 10px 24px",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",  
            width:"100%",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            color:"#525252", 
            borderBottom: "1px solid #E0E0E0"
        },
        margin:0,  
        checkBoxStyle:{
           margin:0,
           padding:"0 8px 0 0", 
        }, 
        areaListIcon: {
            marginLeft: 'auto', // Push the icon to the end
        }, 
    },
    siteList:{ 
        padding: "9px 16px", 
        borderBottom: "1px solid #E0E0E0", 
        margin:0, 
        h6:{
            display: "flex",
            padding: "10px 16px 10px 24px",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",  
            width:"100%",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            color:"#525252", 
            borderBottom: "1px solid #E0E0E0"
        },
        checkBoxStyle:{
           margin:0,
           padding:"0 8px 0 0", 
        }, 
    }
  }; 