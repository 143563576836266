import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon.svg";
const SmallReusableModal = ({
  open,
  handleClose,
  title,
  children,
  footer,
  divider,
  dialogWidth,
  dialogHeight,
  titleAndIconPadding = "0",
  contentPadding,
  minWidth,
  minHeight,
  headerHeight = "60px"
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Dialog
      open={open}
      width={dialogWidth}
      onClose={handleClose}
      maxWidth={"md"}
      sx={{
        "& .MuiDialog-paper": {
          padding: "16px",
          width: dialogWidth,
          height: dialogHeight,
          minWidth: minWidth,
          minHeight: minHeight,
          borderRadius: "8px"
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: headerHeight,
        }}
      >
        <DialogTitle sx={{ padding: titleAndIconPadding }}>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: titleAndIconPadding }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      {divider && <Divider />}
      <DialogContent
        sx={{
          padding: contentPadding,
          overflow: "hidden", // Control overflow for content
          maxHeight: dialogHeight ? `calc(${dialogHeight} - 64px)` : "auto", // Adjust max height for content
        }}
      >
        {children}
      </DialogContent>
      {footer && (
        <>
          {divider && <Divider />}
          <DialogActions
            sx={{
              "&.MuiDialogActions-root": {
                padding: 0,
              },
            }}
          >
            {footer}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SmallReusableModal;
