/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomStepper from "../../common/reusable-comps/stepper/CustomStepper";
import SiteAddress from "./SiteAddress";
import EdgeDevice from "./EdgeDevice";
import SiteDetails from "./SiteDetails";
import EmailNotification from "./EmailNotification";
import {
  isEmpty,
  isValidJapanesePostalCode,
  isValidUrl,
  setSiteApiRequest,
} from "../../common/utils/CommonUtils";
import "./setting.scss";
import CustomButton from "../../common/buttons/CustomButton";
import {
  createSiteApi,
  getSingleSiteApi,
  updateSiteApi,
} from "../../redux/slices/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  siteEndPoint,
  testConnectionURL,
  getAlaramTagsURL,
  nameMockupApi,
} from "../../services/apiConfig";
import SmallReusableModal from "../../common/reusable-comps/modals/SmallReusableModal";
import { formatDate } from "../../common/utils/CommonUtils";
// import { updateEmsAccessVerified,updateWebAccessVerified } from "../../redux/slices/customerSlice";
import {
  getNameByActiveStep,
  siteErrorObj,
  siteInitialObj,
  siteStepKeys,
} from "./siteDetails/siteDetails.Data";
import usePost from "../../common/hook/usePost";
import useFetch from "../../common/hook/useFetch";

function isValidBase64(str) {
  try {
    // A Base64 string must be a valid base64-encoded string
    return btoa(atob(str)) === str;
  } catch (e) {
    return false;
  }
}
const SiteForm = ({
  selectedCustomerData,
  homeCustomer,
  setSiteScreenType,
  isSiteEdit,
  setSiteId,
  setIsSiteEdit,
  siteId,
  siteScreenType,
  editSiteId,
  setEditSiteId,
  setShowEmptyPage,
  isSiteCreated,
  setIsSiteCreated,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [val, setVal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [activeStepName, setActiveStepName] = useState("siteDetail");
  const [isTestingConnection, setIsTestingConnection] = useState(false)
  const { siteFormData, selectedCustomer } = useSelector(
    (state) => state?.customerInfo
  );
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [formError, setFormError] = useState(siteErrorObj);
  const [formData, setFormData] = useState(siteInitialObj);
  const [formTouched, setFormTouched] = useState(false);
  const [siteIsDuplicate, setSiteIsDuplicate] = useState("");
  const { t } = useTranslation();
  const { settingsPageContent, siteInfoModal } = t("mainContainer");
  const [testConnectionClicked, setTestConnectionClicked] = useState("");
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [connectionType, setConnectionType] = useState("");
  const [syncButtonStatus, setSyncButtonStatus] = useState(false);
  const [exitType, setExitType] = useState("");
  const debounceRef = useRef(null);
  const [submitFrom, setSubmitFrom] = useState("");
  const dispatch = useDispatch();
  const [locationInfo, setLocationInfo] = useState(null);

  const {
    webAccessUser,
    webAccessUrl,
    webAccessPassword,
    emsAccessUser,
    emsAccessUrl,
    emsAccessPassword,
    emsConnectionVerified,
    webAccessConnectionVerified,
    lastOnline,
    deviceStatus,
  } = formData?.edgeDevice || {};
  const [prevFormData, setPrevFormData] = useState({
    webAccessUser: siteFormData?.webAccessUrl,
    webAccessUrl: siteFormData?.webAccessUser,
    webAccessPassword: siteFormData?.webAccessPassword,
    emsAccessUser: siteFormData?.emsAccessUser,
    emsAccessUrl: siteFormData?.emsAccessUrl,
    emsAccessPassword: emsAccessPassword,
    emsConnectionVerified: emsConnectionVerified,
    webAccessConnectionVerified: webAccessConnectionVerified,
    lastOnline: lastOnline,
    deviceStatus: deviceStatus,
  });
  const [webFormUpdated, setWebFormUpdated] = useState(false);
  const [emsFormUpdated, setEmsFormUpdated] = useState(false);
  const isWebAccessFilled = webAccessUrl && webAccessUser;
  const isEMSFilled = emsAccessUrl && emsAccessUser;
  const [proceedClickedOnEdgeDevice, setProceedClickedOnEdgeDevice] =
    useState(false);
  const {
    loading: nameApiLoading,
    error: nameApiError,
    data: nameApiData,
    postData: doPostNameApiData,
  } = usePost();
  const {
    loading: postWebAccessLoading,
    error: postWebAccessError,
    data: postWebAccessData,
    postData: doWebAccessPostData,
  } = usePost();

  const {
    loading: postEMSLoading,
    error: postEMSError,
    data: postEMSData,
    postData: doEMSPostData,
  } = usePost();

  const {
    data: alaramTagsData,
    error: alaramTagsError,
    loading: alaramTagsLoading,
    fetchData: fetchAlaram,
  } = useFetch();

  const {
    loading: postAlarmLoading,
    error: postAlarmError,
    data: postAlarmData,
    postData: doAlarmPostData,
  } = usePost();
  const [syncTime, setSyncTime] = useState();

  const [alarmData, setAlarmData] = useState();

  useEffect(() => {
    if (postAlarmData && !postAlarmError && !postAlarmLoading) {
      setAlarmData(postAlarmData.result);
    }
  }, [alaramTagsData, postAlarmData, postAlarmError, postAlarmLoading]);

  const {
    steps,
    actionType,
    backto,
    saveSite,
    proceedto,
    cancel,
    exitWithoutSaving,
    saveandExit,
    unSaveMsg,
    proceedMsg,
    edgeDevice,
    siteDetails,
  } = settingsPageContent?.siteManagementContent?.customerDetails;
  const { siteName, description, fileUpload, uploadErrors } = siteDetails;
  const getEditSiteDetails = async (editSiteId) => {
    const payload = {
      url: siteEndPoint,
      siteId: editSiteId,
      token: localStorage.getItem("accessToken"),
    };
    const resData = await dispatch(getSingleSiteApi(payload));
    if (resData?.payload?.status === 200) {
      let siteStepKeysVal = { ...siteStepKeys };
      let resVal = setSiteApiRequest(siteStepKeysVal, resData?.payload?.data);
      // Decode existing Base64 password
      // const decodedEmsPassword = atob(resData?.payload?.data.emsAccessPassword);
      let emsAccessPassword = resData?.payload?.data.emsAccessPassword;
      let decodedEmsPassword = '';

      if (emsAccessPassword && isValidBase64(emsAccessPassword)) {
        decodedEmsPassword = atob(emsAccessPassword);
      } else {
        decodedEmsPassword = emsAccessPassword;
        console.error('Invalid Base64 string:', emsAccessPassword);
        // Handle the invalid case, maybe assign a default value or notify the user
      }
      const updatedResval = {
        ...resVal,
        edgeDevice: {
          ...resVal.edgeDevice,
          emsAccessPassword: decodedEmsPassword
        }
      };
      setFormData({ ...updatedResval });
    }
  };

  // Do not Delete
  // useEffect(() => {
  //   if (
  //     (exitType === "saveAndExit" || exitType === "proceed") &&
  //     postWebAccessData &&
  //     !postEMSLoading &&
  //     !postWebAccessError && !syncTime
  //   ) {
  //     const { edgeDevice } = formData;
  //     const WebAccessAlarmPayload = {
  //       webAccessUrl: edgeDevice?.webAccessUrl,
  //       webAccessUser: edgeDevice?.webAccessUser,
  //       webAccessPassword: edgeDevice?.webAccessPassword,
  //     };

  //     const EmsAlarmPayload = {
  //       webAccessUrl: edgeDevice?.emsAccessUrl,
  //       webAccessUser: edgeDevice?.emsAccessUser,
  //       webAccessPassword: edgeDevice?.emsAccessPassword,
  //     };

  //     const payload = WebAccessAlarmPayload || EmsAlarmPayload;
  //     fetchAlaram(getAlaramTagsURL, payload);
  //   }
  // }, [postWebAccessData, postEMSLoading, postWebAccessError]);

  const handleGetNextStep = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep + 1;
      setActiveStepName(getNameByActiveStep(nextStep));
      if (nextStep > steps?.length) {
        setIsComplete(true);
        setFormTouched(false);
      }
      return nextStep;
    });
  };

  useEffect(() => {
    if (
      exitType === "sync" &&
      alaramTagsData &&
      !alaramTagsError &&
      !alaramTagsLoading &&
      !syncTime
    ) {
      setSyncTime(new Date().getTime());
    }
    // Logic to execute after timeout
    if (
      (exitType === "saveAndExit" || exitType === "proceed") &&
      alaramTagsData &&
      !alaramTagsError &&
      !alaramTagsLoading &&
      (!syncTime || siteId) &&
      isSiteCreated
    ) {
      // setEnableSync(false);
      // setResetSync(false);
      setSyncTime(new Date().getTime());
      const alarmTags = alaramTagsData?.alarmTags?.map((item, index) => ({
        customerId: siteFormData?.customerId,
        siteId: siteFormData?.id,
        alarmName: item.name,
        description: item.desc,
      }));

      doAlarmPostData(getAlaramTagsURL, { alarmTags });
    }
  }, [
    alaramTagsData,
    alaramTagsError,
    alaramTagsLoading,
    siteId,
    isSiteCreated,
  ]);

  const handleSuccess = async (res) => {
    if (res?.payload?.status === 201 || res?.payload?.status === 200) {
      let siteStepKeysVal = { ...siteStepKeys };
      let resVal = setSiteApiRequest(siteStepKeysVal, res?.payload?.data);

      let emsAccessPassword = res?.payload?.data.emsAccessPassword;
      let decodedEmsPassword = '';

      if (emsAccessPassword && isValidBase64(emsAccessPassword)) {
        decodedEmsPassword = atob(emsAccessPassword);
      } else {
        decodedEmsPassword = emsAccessPassword;
        console.error('Invalid Base64 string:', emsAccessPassword);
        // Handle the invalid case, maybe assign a default value or notify the user
      }

      const updatedResval = {
        ...resVal,
        edgeDevice: {
          ...resVal.edgeDevice,
          emsAccessPassword: decodedEmsPassword
        }
      };
      setFormData({ ...updatedResval });

      if (activeStepName === "emailNotification") {
        // setSiteScreenType("siteList");
        setShowEmptyPage(false);

      }
      if (res?.payload?.status === 201) {
        if (activeStepName === "edgeDevice") {
          setIsSiteCreated(true);
        }
        setCustomerId(res?.payload?.data?.customerId);
        setSiteId(res?.payload?.data?.id);
      }
      setFormTouched(false);

      setEmsFormUpdated(false);
      setWebFormUpdated(false);
    }
    if (res?.payload?.response?.status == 400) {
      setSiteIsDuplicate(res?.payload?.response?.data?.message);
    } else {
      setSiteIsDuplicate("");
    }
    return res;
  };

  useEffect(() => {
    if (submitFrom === "saveSite") {
      setSiteScreenType("siteList");
    }
  }, [submitFrom]);

  // Function to create and merge payload data
  const createMergedPayload = (params, payloadData) => {
    // Define payloadObj with the parameters
    const payloadObj = { ...params };

    // Merge payloadObj with payloadData
    return { ...payloadData, ...payloadObj };
  };

  // useEffect(()=>{
  //   if(postEMSData&&!postEMSLoading&&!postEMSError){
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       edgeDevice: {
  //         ...prevData?.edgeDevice, 
  //         emsProjectInfo: postEMSData?.emsProjectInfo,
  //       },
  //     }));
  //   } 
  // },[postEMSData,postEMSLoading,postEMSError])

  // Create SIte - Sanjeev code ------------------------------------------Do not Delete
  useEffect(() => {

    console.log(connectionType)
    const isCreate = !isSiteCreated && !editSiteId && siteScreenType == "addSite";
    // !isSiteEdit &&
    // activeStepName === "edgeDevice" &&
    // !siteId &&

    // createFlag == "add";
    const isEdit = editSiteId && siteScreenType == "editSite";
    // const tempPayload = getTemporaryPayloadFields();
    const { edgeDevice, siteDetail, siteAddress, emailNotification } = formData;
    const payloadData = constructPayload(
      {
        ...edgeDevice,
        ...siteDetail,
        ...siteAddress,
        ...locationInfo,
        country: selectedCustomerData?.country,
        id: editSiteId ? editSiteId : siteFormData?.id,
        lastSyncTime: syncTime,
        tagCount: alaramTagsData?.alarmTags?.length,
        emsProjectInfo: postEMSData?.emsProjectInfo ? postEMSData?.emsProjectInfo : edgeDevice?.emsProjectInfo,
        edgeDeviceToken: postEMSData?.edgeDeviceToken ? postEMSData?.edgeDeviceToken : edgeDevice?.edgeDeviceToken,
        webAccessProjectInfo: postWebAccessData?.webAccessProjectInfo ? postWebAccessData?.webAccessProjectInfo : edgeDevice?.webAccessProjectInfo,
      },
      isCreate,
      isEdit
    );
    const apiPayload = {
      payload: payloadData,
      url: siteEndPoint,
      token: localStorage.getItem("accessToken"),
    };
    if (exitType === "saveAndExit") {
      setModalOpen(false);
    }

    console.log("emas", emsFormUpdated);
    console.log("web form", webFormUpdated);
    if(isTestingConnection) return;
    if (emsFormUpdated && webFormUpdated) {
      if (
        postWebAccessData &&
        !postWebAccessLoading &&
        !postWebAccessError &&
        alaramTagsData &&
        !alaramTagsLoading &&
        !alaramTagsError &&
        postEMSData &&
        !postEMSLoading &&
        !postEMSError
      ) {
        // Parameters to pass
        const params = {
          deviceStatus: "online",
          webAccessConnectionVerified: true,
          emsConnectionVerified: true,
          lastOnline: new Date().getTime(),
        };
        // Call the function to get merged payload data
        const mergedPayloadData = createMergedPayload(
          params,
          apiPayload.payload
        );

        // Create a new apiPayload with the merged payloadData
        const updatedApiPayload = {
          ...apiPayload,
          payload: mergedPayloadData,
        };
        if (exitType === "saveAndExit") {
          if (isCreate) {
            dispatch(createSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 201) {
                  setSiteScreenType("siteList");
                }
              });
            // console.log("create both ==> redirect to Step 1")
          } else if (!isCreate) {
            dispatch(updateSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 200) {
                  setSiteScreenType("siteList");
                }
              });

            // console.log("update both ==> redirect to Step 1")
          }
        } else {
          if (isCreate) {
            dispatch(createSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 201) {
                  handleGetNextStep();
                }
              });

            // console.log("create ==> redirect to Email notification")
          } else if (!isCreate) {
            dispatch(updateSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 200) {
                  if (exitType === "proceed") {
                    handleGetNextStep();
                  }
                }
              });
            // console.log("update both ==> redirect to Email notification")
          }
        }
      } else if (
        (postEMSError && !postEMSLoading && !postEMSData) ||
        (postWebAccessError && !postWebAccessData && !postWebAccessLoading) ||
        (!alaramTagsData && !alaramTagsLoading && alaramTagsError)
      ) {
        return;
        // console.log("web and ems  stay with error")
      }
    } else if (webFormUpdated && !emsFormUpdated) {
      if (
        postWebAccessData &&
        !postWebAccessLoading &&
        !postWebAccessError &&
        alaramTagsData &&
        !alaramTagsLoading &&
        !alaramTagsError
      ) {
        // Parameters to pass
        const params = {
          deviceStatus: "online",
          webAccessConnectionVerified: true,
          emsConnectionVerified: false,
          lastOnline: new Date().getTime(),
        };
        // Call the function to get merged payload data
        const mergedPayloadData = createMergedPayload(
          params,
          apiPayload.payload
        );

        // Create a new apiPayload with the merged payloadData
        const updatedApiPayload = {
          ...apiPayload,
          payload: mergedPayloadData,
        };

        if (isCreate) {
          dispatch(createSiteApi(updatedApiPayload))
            .then(handleSuccess)
            .then((res) => {
              if (res?.payload?.status == 201) {
                if (exitType === "saveAndExit") {
                  setSiteScreenType("siteList");
                }
                handleGetNextStep();
              }
            });

          // console.log("Web access create ==> redirect to Email notification")
        } else if (!isCreate) {
          dispatch(updateSiteApi(updatedApiPayload))
            .then(handleSuccess)
            .then((res) => {
              if (res?.payload?.status == 200) {
                if (exitType === "saveAndExit") {
                  setSiteScreenType("siteList");
                }
                if (exitType === "proceed") {
                  handleGetNextStep();
                }
              }
            });
          // console.log("Web access update ==> redirect to Email notification")
        }
      } else if (
        postWebAccessError &&
        !postWebAccessData &&
        !postWebAccessLoading
      ) {
        // Parameters to pass
        const params = {
          deviceStatus: "disable",
          webAccessConnectionVerified: false,
          emsConnectionVerified: false,
          lastOnline: new Date(formData?.lastOnline).getTime(),
        };
        // Call the function to get merged payload data
        const mergedPayloadData = createMergedPayload(
          params,
          apiPayload.payload
        );

        // Create a new apiPayload with the merged payloadData
        const updatedApiPayload = {
          ...apiPayload,
          payload: mergedPayloadData,
        };
        if (exitType === "saveAndExit") {
          if (isCreate) {
            dispatch(createSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 201) {
                  setSiteScreenType("siteList");
                }
              });

            // console.log("web access create  - disable ==> redirect to step 1")
          } else if (!isCreate) {
            dispatch(updateSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 200) {
                  setSiteScreenType("siteList");
                }
              });

            // console.log("web access update  - disable ==> redirect to step 1")
          }
        } else if (exitType === "proceed") {
          return;
          // console.log("webaccess not success stay")
        }
      }
    } else if (emsFormUpdated && !webFormUpdated) {
      if (postEMSData && !postEMSLoading && !postEMSError) {
        // Parameters to pass
        const params = {
          deviceStatus: "online",
          webAccessConnectionVerified: false,
          emsConnectionVerified: true,
          lastOnline: new Date().getTime(),
        };
        // Call the function to get merged payload data
        const mergedPayloadData = createMergedPayload(
          params,
          apiPayload.payload
        );

        // Create a new apiPayload with the merged payloadData
        const updatedApiPayload = {
          ...apiPayload,
          payload: mergedPayloadData,
        };
        if (isCreate) {
          dispatch(createSiteApi(updatedApiPayload))
            .then(handleSuccess)
            .then((res) => {
              if (res?.payload?.status == 201) {
                if (exitType === "saveAndExit") {
                  setSiteScreenType("siteList");
                }
                handleGetNextStep();
              }
            });
          // console.log("ems access create ==> redirect to Email notification")
        } else if (!isCreate) {
          dispatch(updateSiteApi(updatedApiPayload))
            .then(handleSuccess)
            .then((res) => {
              if (res?.payload?.status == 200) {
                if (exitType === "saveAndExit") {
                  setSiteScreenType("siteList");
                }
                if (exitType === "proceed") {
                  handleGetNextStep();
                }
              }
            });
          // console.log("ems access update ==> redirect to Email notification")
        }
      } else if (postEMSError && !postEMSLoading && !postEMSData) {
        // Parameters to pass
        const params = {
          deviceStatus: "disable",
          webAccessConnectionVerified: false,
          emsConnectionVerified: false,
          lastOnline: new Date(formData?.lastOnline).getTime(),
        };
        // Call the function to get merged payload data
        const mergedPayloadData = createMergedPayload(
          params,
          apiPayload.payload
        );

        // Create a new apiPayload with the merged payloadData
        const updatedApiPayload = {
          ...apiPayload,
          payload: mergedPayloadData,
        };
        if (exitType === "saveAndExit") {
          if (isCreate) {
            dispatch(createSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 201) {
                  setSiteScreenType("siteList");
                }
              });

            // console.log("ems access create - disable ==> redirect to step 1");
          } else if (!isCreate) {
            dispatch(updateSiteApi(updatedApiPayload))
              .then(handleSuccess)
              .then((res) => {
                if (res?.payload?.status == 200) {
                  setSiteScreenType("siteList");
                }
              });
            // setSiteScreenType("siteList");
            // console.log("ems access update - disable ==> redirect to step 1")
          }
        } else if (exitType === "proceed") {
          return;
          // console.log("ems not success stay")
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postWebAccessData,
    postWebAccessLoading,
    postWebAccessError,
    alaramTagsData,
    alaramTagsLoading,
    alaramTagsError,
    postEMSData,
    postEMSLoading,
    postEMSError,
  ]);

  useEffect(() => {
    const isCreate =
      !isSiteCreated &&
      !isSiteEdit &&
      activeStepName === "edgeDevice" &&
      !siteId &&
      !editSiteId &&
      siteScreenType == "addSite";
    // createFlag == "add";
    const isEdit = editSiteId && siteScreenType == "editSite";
    // const tempPayload = getTemporaryPayloadFields();
    const { edgeDevice, siteDetail, siteAddress, emailNotification } = formData;
    const payloadData = constructPayload(
      {
        ...edgeDevice,
        ...siteDetail,
        ...siteAddress,
        ...locationInfo,
        country: selectedCustomerData?.country,
        id: editSiteId ? editSiteId : siteFormData?.id,
        lastSyncTime: syncTime,
        tagCount: alaramTagsData?.alarmTags?.length,
        emsProjectInfo: edgeDevice?.emsProjectInfo ? edgeDevice?.emsProjectInfo : postEMSData?.emsProjectInfo,
        edgeDeviceToken: edgeDevice?.edgeDeviceToken ? edgeDevice?.edgeDeviceToken : postEMSData?.edgeDeviceToken,
        webAccessProjectInfo: edgeDevice?.webAccessProjectInfo ? edgeDevice?.webAccessProjectInfo : postWebAccessData?.webAccessProjectInfo,

      },
      isCreate,
      isEdit
    );
    const apiPayload = {
      payload: payloadData,
      url: siteEndPoint,
      token: localStorage.getItem("accessToken"),
    };
    if (exitType === "saveAndExit") {
      setModalOpen(false);
    }
    if (!emsFormUpdated && !webFormUpdated) {
      if (exitType === "saveAndExit" || activeStepName == "emailNotification") {
        if (siteScreenType === "addSite" && !siteId) {
          // Parameters to pass
          const params = {
            deviceStatus: "disable",
            webAccessConnectionVerified: false,
            emsConnectionVerified: false,
            lastOnline: new Date(formData?.lastOnline).getTime(),
          };
          // Call the function to get merged payload data
          const mergedPayloadData = createMergedPayload(
            params,
            apiPayload.payload
          );

          // Create a new apiPayload with the merged payloadData
          const updatedApiPayload = {
            ...apiPayload,
            payload: mergedPayloadData,
          };

          dispatch(createSiteApi(updatedApiPayload))
            .then(handleSuccess)
            .then((res) => {
              if (res?.payload?.status == 201) {
                setSubmitFrom("");
                if (exitType === "saveAndExit") {
                  setSiteScreenType("siteList");
                }
              }
            });
        } else {

          dispatch(updateSiteApi(apiPayload))
            .then(handleSuccess)
            .then((res) => {
              if (res?.payload?.status == 200) {
                setSubmitFrom("");
                if (exitType === "saveAndExit") { 
                  setSiteScreenType("siteList");
                }
              }
            });
        }
        // console.log("ems access create - disable ==> redirect to step 1");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitType]);

  useEffect(() => {
    if (siteScreenType === "editSite" && editSiteId) {
      setFormData({ ...siteFormData });
      setPrevFormData({ ...siteFormData });
    }
  }, [siteScreenType, editSiteId, siteFormData]);
  useEffect(() => {
    setPrevFormData({ ...siteFormData });
  }, [isSiteCreated]);

  useEffect(() => {
    if (siteScreenType === "editSite" && editSiteId) {
      getEditSiteDetails(editSiteId);
    }
  }, [siteScreenType, editSiteId]);

  useEffect(() => {
    setActiveStepName(getNameByActiveStep(activeStep));
  }, [activeStep]);

  const handleErrorCheck = () => {
    let newErrors = {};
    // Validate based on activeStepName
    if (activeStepName === "siteDetail") {
      validateSiteDetail(newErrors);
    } else if (activeStepName === "siteAddress") {
      validateSiteAddress(newErrors);
    } else if (activeStepName === "edgeDevice") {
      validateEdgeDevice(newErrors);
    }
    // Update form errors state
    setFormError((prevErrors) => ({
      ...prevErrors,
      [activeStepName]: newErrors,
    }));
    return newErrors;
  };

  const validateSiteDetail = (errors) => {
    const { name } = formData[activeStepName];
    if (isEmpty(name)) {
      errors.name = "Please enter site name.";
    }
    if (name && siteIsDuplicate) {
      errors.name = siteName?.error2;
    }
  };
  const validateSiteAddress = (errors) => {
    const { postalCode, state, city, address1 } =
      formData[activeStepName] || {};
    if (isEmpty(postalCode)) {
      errors.postalCode = "Please enter postal code.";
    } else if (!isValidJapanesePostalCode(postalCode)) {
      errors.postalCode = "Please enter a valid Japan postal code.";
    }

    if (isEmpty(state)) {
      errors.state = "Please select prefectures.";
    }

    if (isEmpty(city)) {
      errors.city = "Enter a city name.";
    }

    if (isEmpty(address1)) {
      errors.address1 = "Enter a building name or unit number.";
    }
  };

  const validateEdgeDevice = (errors) => {
    if (webAccessUrl && !isValidUrl(webAccessUrl)) {
      errors.webAccessUrl = edgeDevice?.url1?.error;
    }

    if (emsAccessUrl && !isValidUrl(emsAccessUrl)) {
      errors.emsAccessUrl = edgeDevice?.url2?.error;
    }

    if (!isWebAccessFilled && !isEMSFilled) {
      errors.general = "Please fill out at least one form (WebAccess or EMS).";
      setShowErrorBar(true);
    }
  };
  const constructPayload = (data, isCreate) => {
    let payload = { ...data };
    payload.customerId = selectedCustomerData?.id ?? homeCustomer;
    delete payload?.imageFile;
    if (!payload?.imageName || payload?.imageName == null) {
      delete payload?.imageName;
    }
    if (!payload?.imageUrl || payload?.imageUrl == null) {
      delete payload.imageUrl;
    }
    if (!payload?.thumbnailUrl || payload?.thumbnailUrl == null) {
      delete payload?.thumbnailUrl;
    }
    if (payload?.id) {
      payload.id = payload?.id;
    } else {
      delete payload?.id;
    }
    if (!payload?.postalCode) {
      delete payload?.postalCode;
    }
    if (payload?.lastOnline) {
      payload.lastOnline = new Date(payload?.lastOnline).getTime();
    } else {
      delete payload?.lastOnline;
    }
    if (!payload?.state) {
      delete payload?.state;
    }
    if (!payload?.city) {
      delete payload?.city;
    }
    if (!payload?.address1) {
      delete payload?.address1;
    }

    if (isCreate) {
      payload.name = formData?.siteDetail?.name.toLowerCase();
      payload.displayName = formData?.siteDetail?.displayName;
      payload.edgeDeviceToken = postEMSData?.edgeDeviceToken;

      delete payload?.nextStep;
      if (payload?.emsAccessPassword) {
        payload.emsAccessPassword = btoa(
          formData?.edgeDevice?.emsAccessPassword
        );
      }
      payload.country = selectedCustomerData?.country;
    } else {
      payload.name = formData?.siteDetail?.name.toLowerCase();
    }

    if (activeStepName === "siteAddress") {
      payload.country = selectedCustomerData?.country;
    }
    return payload;
  };
  const getTemporaryPayloadFields = () => {
    let payloadObj = {
      deviceStatus: "disable",
      webAccessConnectionVerified: false,
      emsConnectionVerified: false,
      lastOnline: null,
    };
    if (editSiteId) {
      if (
        activeStepName === "emailNotification" ||
        activeStepName === "edgeDevice"
      ) {
        payloadObj.deviceStatus = "online";
        payloadObj.webAccessConnectionVerified = postWebAccessData
          ? true
          : false;
        payloadObj.emsConnectionVerified = postEMSData ? true : false;
        formData?.edgeDevice?.emsConnectionVerified;
        payloadObj.lastOnline = siteFormData?.lastOnline
          ? siteFormData?.lastOnline
          : new Date().getTime();
      } else {
        payloadObj.deviceStatus =
          formData?.edgeDevice?.webAccessConnectionVerified ||
            formData?.edgeDevice?.emsConnectionVerified
            ? "online"
            : "disable";
        payloadObj.webAccessConnectionVerified =
          formData?.edgeDevice?.webAccessConnectionVerified;
        payloadObj.emsConnectionVerified =
          formData?.edgeDevice?.emsConnectionVerified;
        payloadObj.lastOnline =
          formData?.edgeDevice?.webAccessConnectionVerified ||
            formData?.edgeDevice?.emsConnectionVerified
            ? new Date().getTime()
            : null;
      }
    }
    return payloadObj;
  };

  useEffect(() => {
    if (
      activeStepName === "edgeDevice" &&
      proceedClickedOnEdgeDevice
      // && exitType == "proceed"
    ) {
      if (isSiteCreated || editSiteId) {
        if (postWebAccessData && postEMSData) {
          handleGetNextStep();
        }
      }
    }
  }, [
    isSiteCreated,
    // proceedClickedOnEdgeDevice,
    postEMSData,
    proceedClickedOnEdgeDevice,
    postWebAccessData,
    isFormChanged,
  ]);
  useEffect(() => {
    if (
      exitType === "proceed" &&
      activeStepName === "edgeDevice" &&
      !proceedClickedOnEdgeDevice
    ) {
      if (!isSiteCreated && !editSiteId) {
        if ((postWebAccessData || postEMSData) && isFormChanged) {
          setProceedClickedOnEdgeDevice(true);
        }
      }
    }
  }, [isSiteCreated, editSiteId, postWebAccessData, postEMSData]);

  useEffect(() => {
    if (isSiteCreated) {
      setFormData((prevData) => ({
        ...prevData,
        edgeDevice: {
          ...prevData?.edgeDevice,
          lastOnline:
            postEMSData || postWebAccessData ? new Date().getTime() : null,
          webAccessConnectionVerified: postWebAccessData ? true : false,
          emsConnectionVerified: postEMSData ? true : false,
        },
      }));
    }
  }, [postEMSData, postWebAccessData]);

  useEffect(() => {
    if (
      exitType === "proceed" &&
      activeStepName === "edgeDevice" &&
      proceedClickedOnEdgeDevice
    ) {
      if (postEMSData || postWebAccessData) {
        if (!isSiteCreated && !editSiteId) {
          handleNextSiteApiCall("create");
        } else {
          handleNextSiteApiCall("update");
        }
      }
    }
  }, [exitType, postWebAccessData, postEMSData, activeStepName]);

  const handleSaveAndExit = async () => {
    setExitType("saveAndExit");

    if (emsFormUpdated && webFormUpdated) {
      await handleWebAccessTest();
      await handleEMSTest();
      await handleSync();
      setIsTestingConnection(false)
    } else if (webFormUpdated && !emsFormUpdated) {
      await handleWebAccessTest();
      await handleSync();
      setIsTestingConnection(false)
    } else if (emsFormUpdated && !webFormUpdated) {
      await handleEMSTest();
      setIsTestingConnection(false)
    }

    // setProceedClickedOnEdgeDevice(false);
    if (activeStepName === "siteAddress" || activeStepName === "siteDetail") {
      setFormTouched(true); // Set formTouched to true when any form field changes
    }
    const errors = handleErrorCheck();
    if (Object.keys(errors).length > 0) {
      setFormError((prevState) => ({
        ...prevState,
        [activeStepName]: errors,
      }));
      setModalOpen(false);
      return;
    } else {
      setFormData({ ...formData });
    }

    if (activeStepName === "emailNotification") {
      
      setSiteScreenType("siteList");
    }
  };

  const handleSync = async () => {
    setIsTestingConnection(true)
    setConnectionType("sync");
    const { edgeDevice } = formData;
    const WebAccessAlarmPayload = {
      webAccessUrl: edgeDevice?.webAccessUrl,
      webAccessUser: edgeDevice?.webAccessUser,
      webAccessPassword: edgeDevice?.webAccessPassword,
    };

    const EmsAlarmPayload = {
      webAccessUrl: edgeDevice?.emsAccessUrl,
      webAccessUser: edgeDevice?.emsAccessUser,
      webAccessPassword: edgeDevice?.emsAccessPassword,
    };

    const payload = WebAccessAlarmPayload || EmsAlarmPayload;
    await fetchAlaram(getAlaramTagsURL, payload);
  };
  const handleNextSiteApiCall = (key) => {
    const isCreate =
      !isSiteCreated &&
      !isSiteEdit &&
      activeStepName === "edgeDevice" &&
      !siteId &&
      !editSiteId &&
      siteScreenType === "addSite";
    const isEdit = editSiteId && siteScreenType === "editSite";
    const tempPayload = getTemporaryPayloadFields();
    const { edgeDevice, siteDetail, siteAddress, emailNotification } = formData;
    const payloadData = constructPayload(
      {
        ...edgeDevice,
        ...tempPayload,
        ...siteDetail,
        ...siteAddress,
        ...locationInfo,
        country: selectedCustomerData?.country,
        id: editSiteId ? editSiteId : siteFormData?.id,
        lastSyncTime: syncTime,
        tagCount: alaramTagsData?.alarmTags?.length,
        emsProjectInfo: formData?.edgeDevice?.emsProjectInfo ? formData?.edgeDevice?.emsProjectInfo : edgeDevice?.emsProjectInfo,
        edgeDeviceToken: formData?.edgeDevice?.edgeDeviceToken ? formData?.edgeDevice.edgeDeviceToken : edgeDevice?.edgeDeviceToken,
        webAccessProjectInfo: formData?.edgeDevice?.webAccessProjectInfo ? formData?.edgeDevice?.webAccessProjectInfo : edgeDevice?.webAccessProjectInfo,
      },
      isCreate,
      isEdit
    );
    const apiPayload = {
      payload: payloadData,
      url: siteEndPoint,
      token: localStorage.getItem("accessToken"),
    };

  };
  const handleNameMockApi = (newSiteName) => {
    const payload = {
      siteName: newSiteName,
      customerId: selectedCustomer ?? homeCustomer,
    };
    doPostNameApiData(nameMockupApi, payload);
  };
  const debouncedHandleNameMockApi = (newSiteName) => {
    if (debounceRef?.current) {
      clearTimeout(debounceRef?.current);
    }
    debounceRef.current = setTimeout(() => {
      handleNameMockApi(newSiteName);
    }, 100); // 500ms delay
  };
  const handleProceed = async (submitButton) => {
  
    if (activeStepName === "siteAddress" || activeStepName === "siteDetail") {
      setFormTouched(true);
    }
    if (
      activeStepName === "siteDetail" &&
      formData?.siteDetail?.name &&
      !editSiteId
    ) {
      // Wait until the debounce and API call are finished
      await new Promise((resolve) => {
        debouncedHandleNameMockApi(formData.siteDetail.name);
        const checkCompletion = setInterval(() => {
          if (!nameApiLoading && (nameApiError || nameApiData)) {
            clearInterval(checkCompletion);
            resolve();
          }
        }, 0);
      });
      if (nameApiError) {
        return;
      }
    }
    const errors = handleErrorCheck();
    if (Object.keys(errors)?.length > 0) {
      return;
    }

    if (activeStepName === "edgeDevice") {
 

      if (emsFormUpdated && webFormUpdated) {
        await handleWebAccessTest();
        await handleEMSTest();
        await handleSync();
        setIsTestingConnection(false)
      } else if (webFormUpdated && !emsFormUpdated) {
        await handleWebAccessTest();
        await handleSync();
        setIsTestingConnection(false)
      } else if (emsFormUpdated && !webFormUpdated) {
        await handleEMSTest();
        setIsTestingConnection(false)
      } else {
        handleGetNextStep();  
      }

      setSubmitFrom(submitButton);
    }
    if (activeStepName === "emailNotification") {
      setNextButtonClicked(true);
      setSiteScreenType("siteList");
    }
    setFormData({ ...formData });
    if (activeStepName != "edgeDevice") { 
      handleGetNextStep();
    }
  };
  const handleBack = () => {
    setProceedClickedOnEdgeDevice(false);
    setIsBackClicked(true);
    setExitType("");
    setActiveStep((prevStep) => {
      const prevStepName = getNameByActiveStep(prevStep - 1);
      setActiveStepName(prevStepName);
      if (prevStep === 1) {
        return prevStep;
      } else {
        setIsComplete(false);
        return prevStep - 1;
      }
    });
  };
  const handleWebAccessTest = async () => {
    setIsTestingConnection(true)
    setConnectionType("webAccess");
    // Your payload with the enums
    const payload = {
      connectionType: "webAccess",
      url: formData?.edgeDevice?.webAccessUrl,
      userName: formData?.edgeDevice?.webAccessUser,
      password: formData?.edgeDevice?.webAccessPassword,
    };

    await doWebAccessPostData(testConnectionURL, payload);
    setFormTouched(true);
  };
  const handleEMSTest = async () => {
    setIsTestingConnection(true)
    setConnectionType("ems");
    const encodedPassword = btoa(formData?.edgeDevice?.emsAccessPassword);
    // Your payload with the enums
    const payload = {
      connectionType: "ems",
      url: formData?.edgeDevice?.emsAccessUrl,
      userName: formData?.edgeDevice?.emsAccessUser,
      password: encodedPassword,
    };
    await doEMSPostData(testConnectionURL, payload);
    setFormTouched(true);
  };
  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <SiteDetails
            siteDetail={formData?.siteDetail}
            setFormData={setFormData}
            formError={formError?.siteDetail}
            setFormError={setFormError}
            formTouched={formTouched}
            siteIsDuplicate={siteIsDuplicate}
            setFormTouched={setFormTouched}
            setSiteIsDuplicate={setSiteIsDuplicate}
            nameApiLoading={nameApiLoading}
            nameApiError={nameApiError}
            nameApiData={nameApiData}
            doPostNameApiData={doPostNameApiData}
            handleNameMockApi={handleNameMockApi}
            editSiteId={editSiteId}
            debounceRef={debounceRef}
            siteScreenType={siteScreenType}
          />
        );
      case 2:
        return (
          <SiteAddress
            isSiteEdit={isSiteEdit}
            formData={formData}
            siteAddress={formData?.siteAddress}
            setFormData={setFormData}
            setFormError={setFormError}
            setLocationInfo={setLocationInfo}
            formTouched={
              formError[activeStepName] &&
              Object.values(formError?.[activeStepName])?.filter((f1) => f1)
                .length > 0
            }
          />
        );
      case 3:
        return (
          <EdgeDevice
            webFormUpdated={webFormUpdated}
            setWebFormUpdated={setWebFormUpdated}
            emsFormUpdated={emsFormUpdated}
            setEmsFormUpdated={setEmsFormUpdated}
            isSiteEdit={isSiteEdit}
            edgeDevice={formData?.edgeDevice}
            siteName={formData?.siteDetail?.name}
            setFormData={setFormData}
            formError={formError?.edgeDevice}
            setFormError={setFormError}
            setShowErrorBar={setShowErrorBar}
            showErrorBar={showErrorBar}
            formTouched={formTouched}
            setFormTouched={setFormTouched}
            siteId={siteId}
            handleEMSTest={handleEMSTest}
            handleWebAccessTest={handleWebAccessTest}
            testConnectionClicked={testConnectionClicked}
            setTestConnectionClicked={setTestConnectionClicked}
            nextButtonClicked={nextButtonClicked}
            connectionType={connectionType}
            setConnectionType={setConnectionType}
            postWebAccessData={postWebAccessData}
            postWebAccessError={postWebAccessError}
            postWebAccessLoading={postWebAccessLoading}
            postEMSData={postEMSData}
            postEMSError={postEMSError}
            postEMSLoading={postEMSLoading}
            setProceedClickedOnEdgeDevice={setProceedClickedOnEdgeDevice}
            handleSync={handleSync}
            syncButtonStatus={syncButtonStatus}
            alaramTagsData={alaramTagsData}
            alaramTagsError={alaramTagsError}
            alaramTagsLoading={alaramTagsLoading}
            syncTime={syncTime}
          />
        );
      case 4:
        return (
          <EmailNotification
            emailNotification={formData?.emailNotification}
            setFormData={setFormData}
            setFormError={setFormError}
            alaramTagsData={alarmData}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleClearFields = () => {
    setModalOpen(false);
    setIsSiteEdit(false);
    setEditSiteId("");
    setFormData({
      siteDetail: {
        name: "",
        displayName: "",
        description: "",
        customerId: "",
      },
      siteAddress: {
        country: "",
        postalCode: "",
        state: "",
        city: "",
        address1: "",
        // contactNumber: "",
        customerId: "",
      },
      edgeDevice: {
        webAccessUrl: "",
        webAccessUser: "",
        webAccessPassword: "",
        emsAccessUrl: "",
        emsAccessUser: "",
        emsAccessPassword: "",
        customerId: "",
      },
      emailNotification: {},
    });
    setFormError({
      siteDetail: {},
      siteAddress: {},
      edgeDevice: {},
      // emailNotification: {},
    });
    setSiteScreenType("");
    // setIsEdit(false);
  };

  const modalFooter = () => {
    return (
      <div>
        <CustomButton
          variant="text"
          onClick={() => {
            setModalOpen(false);
            setIsSiteEdit(false);
            setEditSiteId("");
          }}
          marginRight=".5rem"
          padding="11px 16px"
        >
          {siteInfoModal.actionBtnCancel}
        </CustomButton>
        {activeStep !== 4 && (
          <CustomButton
            variant="outlined"
            marginRight=".5rem"
            onClick={handleClearFields}
            padding="11px 16px"
            lineHeight="18px"
            letterSpacing="-0.1px"
          >
            {siteInfoModal.actionBtnExit}
          </CustomButton>
        )}
        <CustomButton
          variant="contained"
          backgroundColor="primary"
          onClick={handleSaveAndExit}
          background="#0F62FE"
        >
          {siteInfoModal.actionBtnSave}
        </CustomButton>
      </div>
    );
  };
  const smallModalChildren = () => {
    return (
      <Typography
        style={{
          marginTop: "0px",
          color: "black",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          textAlign: "left",
        }}
      >
        {siteInfoModal.content1} <br />
        {siteInfoModal.content2}
      </Typography>
    );
  };

  return (
    <Grid item className="boxSite">
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginY: "4px",
          }}
        >
          <Typography sx={{ my: 1.5 }} className="commonHeaderStyle">
            {siteScreenType === "editSite" ? actionType[0] : actionType[1]}
          </Typography>
          <span
            className="siteCloseIcon"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            &times;
          </span>
        </Box>
        <Divider
          sx={{
            marginX: "-23px",
            marginBottom: "0px",
          }}
        />
      </Box>
      <Typography className="commonSubheaderStyle" my={2}>
        {selectedCustomerData?.displayName}
      </Typography>
      <Box
        style={{
          // paddingTop: 1,
          marginTop: "8px",
          marginBottom: "12px",
        }}
      >
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          isLastStep={activeStep === steps.length - 1}
          isComplete={isComplete}
          error={
            formError[activeStepName] &&
            Object.values(formError?.[activeStepName])?.filter((f1) => f1)
              .length > 0
          }
        />
      </Box>
      <Grid item className="siteFormContainer">
        <Box className="siteInnerContainer">{getStepContent(activeStep)}</Box>
      </Grid>
      <div>
        <Divider
          sx={{
            mx: "-23px",
            borderTop: "1px solid #E0E0E0",
            marginTop: "8px",
          }}
        />
      </div>
      <Box className="siteButtonContainer">
        {!isComplete && activeStep > 1 && (
          <CustomButton variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
            {backto} {steps[activeStep - 2]}
          </CustomButton>
        )}
        {!isComplete && (
          <CustomButton
            variant="contained"
            onClick={() =>
              handleProceed(activeStep === steps?.length && "saveSite")
            }
          >
            {activeStep === steps?.length
              ? saveSite
              : `${proceedto} ${steps[activeStep]}`}
          </CustomButton>
        )}
      </Box>
      {modalOpen && (
        <SmallReusableModal
          open={modalOpen}
          handleClose={() => {
            setModalOpen(false);
          }}
          title={siteInfoModal.title}
          footer={modalFooter()}
          divider={false}
          dialogWidth="442px"
          dialogHeight="204px"
          titleAndIconPadding="0"
          contentPadding="0px 16px 0px 0px"
        >
          {smallModalChildren()}
        </SmallReusableModal>
      )}
    </Grid>
  );
};

export default SiteForm;
