import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import data from "./SiteTableData.json"; // Adjust the path to your data.json file
import LongMenu from "../../common/long-menu/LongMenu";

export default function SiteListTable({ list, emailNotificationHeader }) {
  const [orderBy, setOrderBy] = useState(""); // Track current column to sort by
  const [order, setOrder] = useState("asc"); // Track current sort order

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAscending ? "desc" : "asc");
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === "") return 0;
    const comparison = a[orderBy] > b[orderBy] ? 1 : -1;
    return order === "asc" ? comparison : -comparison;
  });

  return (
    <div className="table-container emailNotificationBox">
      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ tableLayout: "fixed", overflowX: "clip" }}>
          <TableHead sx={{ backgroundColor: "#f5f5f5", boxShadow: "0px 1px 0px 0px #C6C6C6 inset" }}>
            <TableRow sx={{ height: "40px" }}>
              {emailNotificationHeader?.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: "6px 16px 6px 16px",
                    height: "40px",
                    lineHeight: "40px",
                    width: index === 4 ? "6%" : "24%",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === index.toString()}
                    direction={orderBy === index.toString() ? order : "asc"}
                    onClick={() => handleRequestSort(index.toString())}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 }, height: "20px" }}
              >
                <TableCell sx={{ padding: "6px 16px 6px 16px", height: "20px", lineHeight: "20px", width: "24%" }}>
                  {row?.name}
                </TableCell>
                <TableCell sx={{ padding: "0px 16px", height: "20px", lineHeight: "20px", width: "24%" }}>
                  {row?.owner}
                </TableCell>
                <TableCell sx={{ padding: "0px 16px", height: "20px", lineHeight: "20px", width: "24%" }}>
                  {row?.recipients}
                </TableCell>
                <TableCell sx={{ padding: "0px 16px", height: "20px", lineHeight: "20px", width: "24%" }}>
                  {row?.tagCount}
                </TableCell>
                <TableCell sx={{ padding: "0px 16px", height: "20px", lineHeight: "20px", width: "6%" }}>
                  <LongMenu /> {/* Render the LongMenu component here */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
