import React, { useState } from 'react';
import { Box,Grid, Typography, Button, Stepper, Step, StepLabel, Modal, TextField, Radio, FormControlLabel, Checkbox, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useTheme, useMediaQuery, Divider} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomStepper from '../../../common/reusable-comps/stepper/CustomStepper';
import CustomTextField from '../../../common/reusable-comps/text-field/CustomTextField';
import { 
    siteErrorObj, 
  } from "../siteDetails/siteDetails.Data"; 
import CustomModal from '../../../common/commonPopup/CustomModal';
import { ReactComponent as downArrow } from "../../../assets/images/smallArrowDown.svg";
import { ReactComponent as StatickTick } from "../../../assets/images/statickTick.svg";
import { ReactComponent as DisableCross } from "../../../assets/images/disableCross.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/unassignDeleteIcon.svg";
import { ReactComponent as SearchIcon } from '../../../assets/images/SearchIcon.svg';
import CustomButton from '../../../common/buttons/CustomButton';
import './userManagement.scss';
import CustomSelect from '../../../common/reusable-comps/select/CustomSelect';
import useFetch from '../../../common/hook/useFetch';
import { customerListURL } from '../../../services/apiConfig';
import { getHomeAllSiteList } from '../../../services/apiConfig'; 
import spinner from "../../../assets/animation/spinner.json";
import { createUserURL } from '../../../services/apiConfig';
import usePost from '../../../common/hook/usePost';

const steps = ['User details', 'Role', 'Assign'];


  // Define the generateRandomPassword function first
  const generateRandomPassword = () => {
    const length = 12; // Length of the password
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^';
    
    const allChars = lowercase + uppercase + numbers + specialChars;
    let password = '';
    
    // Ensure at least one character from each category
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];
    
    // Fill the rest of the password length with random characters
    for (let i = 4; i < length; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }
    
    return password.split('').sort(() => Math.random() - 0.5).join(''); // Shuffle the password
};

// Dummy customer data
const dummyCustomers = [
    { id: 1, name: 'Customer A' },
    { id: 2, name: 'Customer B' },
    { id: 3, name: 'Customer C' },
    // Add more customers as needed
];

// Dummy site data for demonstration
const dummySites = [
    { id: 1, name: 'Site A' },
    { id: 2, name: 'Site B' },
    { id: 3, name: 'Site C' },
];

const roles =   [{label: "Account Administrator",value:"account_admin"},
    {label: "Account Operator",value:"account_operator"},
    {label: "Organization Administrator",value:"org_admin"},
    {label: "Organization Operator",value:"org_operator"},
    {label: "super_admin",value:"Super Administrator"}]

const rolePermissions = {
    account_admin: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: true,
        enableDisableSite: true,
        addSite: true,
        editSite: true,
    },
    account_operator: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: false,
        enableDisableSite: true,
        addSite: true,
        editSite: false,
    },
    org_admin: {
        viewAlarmPage: false,
        acknowledgeAlarm: false,
        viewCustomerSiteManagement: false,
        addCustomer: false,
        enableDisableSite: false,
        addSite: false,
        editSite: false,
    },
    org_operator: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: false,
        enableDisableSite: false,
        addSite: false,
        editSite: false,
    },
    super_admin: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: true,
        enableDisableSite: true,
        addSite: true,
        editSite: true,
    },
};

export default function UserCreation({ onClose, openModal, setOpenModal }) {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(1);
    
  
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        confirmEmail: '',
        password: generateRandomPassword(), // Generate default password
        role: 'org_admin', // Default selected role
    });
    const [errors, setErrors] = useState({}); // New state for error messages
    const [passwordOption, setPasswordOption] = useState('auto'); // New state for password option
    const [showPassword, setShowPassword] = useState(false); // New state for password visibility
    const [showConfirmEmail, setShowConfirmEmail] = useState(false); // New state for confirm email visibility
    const [requirePasswordChange, setRequirePasswordChange] = useState(true); // Default checked
    const [sendPasswordEmail, setSendPasswordEmail] = useState(true); // Default checked
    const [openAssignModal, setOpenAssignModal] = useState(false); // State for Assign Customers modal
    const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected customer
    const [customers, setCustomers] = useState([]); // State for customer list
    const [sites, setSites] = useState([]); // State for site list based on selected customer
    const [selectedSites, setSelectedSites] = useState(); // Initialize with all site IDs
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [filteredCustomers, setFilteredCustomers] = useState(); // State for filtered customers
    const [openUnassignModal, setOpenUnassignModal] = useState(false); // State for Unassign modal
    const [isCustomerAssigned, setIsCustomerAssigned] = useState(false); // State to track if a customer is assigned
    const [sortDirectionSites, setSortDirectionSites] = useState('asc'); // New state for sorting direction for sites
    const [sortedSites, setSortedSites] = useState([]); // New state for sorted sites
    const [sortDirectionCustomers, setSortDirectionCustomers] = useState('asc'); // New state for sorting direction for customers
    const [sortedCustomers, setSortedCustomers] = useState(); // New state for sorted customers
    const [displayedCustomerName, setDisplayedCustomerName] = useState(''); // New state for displayed customer name
    const [permissions, setPermissions] = useState({
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: false,
        enableDisableSite: false,
        addSite: false,
        editSite: false,
        // Add other permissions as needed
    });
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [activeStepName, setActiveStepName] = useState("siteDetail"); 
    const [isComplete, setIsComplete] = useState(false);
    const [formError, setFormError] = useState(siteErrorObj);
    const [customersFetched, setCustomersFetched] = useState(false); // New state to track if customers have been fetched

    const {
        data: fetchCustomers,
        error: fetchCustomersError,
        loading: fetchCustomersLoading,
        fetchData: getCustomersList,
      } = useFetch();

      const {
        data: fetchsitesData,
        error: fetchsiteError,
        loading: fetchsiteErrorLoading,
        fetchData: getSitesDataList,
      } = useFetch();
      
      const { 
        loading: createUserLoading, 
        error: createUserError, 
        data: createUserData, 
        postData: postCreateUserHandler
      } = usePost();

      useEffect(() => {
        if ( !customersFetched) { // Check if modal is open and customers haven't been fetched
            getCustomersList(customerListURL); // Fetch customers when opening the modal
            setCustomersFetched(true); // Set the flag to true after fetching
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  customersFetched]); // Add customersFetched to dependencies



      useEffect(() => {
        if (!fetchCustomersLoading && !fetchCustomersError && fetchCustomers) {
            const sortedCustomer = fetchCustomers?.sort((a, b) =>
                a.name.localeCompare(b.name)
              ); 
              setSortedCustomers(sortedCustomer);
              setFilteredCustomers(sortedCustomer)
        }
      }, [fetchCustomers, fetchCustomersError, fetchCustomersLoading]);

      useEffect(() => {
        if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
            const sortedSites = fetchsitesData?.sort((a, b) =>
                a.name.localeCompare(b.name)
              ); 
            setSortedSites(sortedSites)
            setSelectedSites(sortedSites?.map(site => site.id))
        }
      }, [fetchsiteErrorLoading, fetchsiteError, fetchsitesData]);
      
      useEffect(() => {
        if ( !createUserLoading && !createUserError && createUserData) { // Check if modal is open and customers haven't been fetched
            handleModalClose(true) 
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  createUserLoading,createUserError,createUserData]); // Add customersFetched to dependencies

    const handlePasswordOptionChange = (event) => {
        setPasswordOption(event.target.value);
        if (event.target.value === 'auto') {
            const generatedPassword = generateRandomPassword();
            setFormData({ ...formData, password: generatedPassword }); // Set generated password
            setErrors({ ...errors, password: '' }); // Clear password error
        } else {
            setFormData({ ...formData, password: '' }); // Clear password field for manual entry
        }
    };

    const handleNext = () => {
        // Validate form data based on activeStep
        if (activeStep === 1) {
            // Validate user details
            const newErrors = {}; // Collect errors
            if (!formData.displayName) newErrors.displayName = 'Please enter display name.';
            if (!formData.email) {
                newErrors.email = 'Please enter email address.';
            } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                newErrors.email = 'Please enter a valid email format.';
            } else if (false) {
                newErrors.email = 'The email already exists.';
            }
            if (!formData.confirmEmail) {
                newErrors.confirmEmail = 'Please confirm email.';
            } else if (formData.email !== formData.confirmEmail) {
                newErrors.confirmEmail = 'Email and Confirm email do not match.';
            }
            if (formData.password.length < 8 || !/[a-z]/.test(formData.password) || !/[A-Z]/.test(formData.password) || !/\d/.test(formData.password)) {
                newErrors.password = 'Password must be at least 8 or more characters and contain 1 lowercase, 1 uppercase, and 1 number. Only the following special characters are allowed: !@#$%^';
            }
            setErrors(newErrors); // Update errors state
            if (Object.keys(newErrors).length === 0) {
                // Set permissions based on selected role
                setPermissions(rolePermissions[formData.role]);
                setActiveStep((prevActiveStep) => prevActiveStep + 1); // Proceed if no errors
            }
        } else if (activeStep === 2) {
            // Validate role permissions
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 3) {
            // Final step, handle user creation API call
            createUser();
        }
    };

    const createUser = () => {
        console.log(selectedCustomer)
        // Create the payload with all necessary information
        const payload = {
            userInfo: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                displayName: formData.displayName,
                email: formData.email,
                password: formData.password,
                requirePasswordChange: requirePasswordChange,
                sendPasswordEmail: sendPasswordEmail,
            },
            roleInfo: {
                role: formData.role,
                permissions: permissions, // Include permissions in the payload
            },
            assignedCustomers: {
                customerId: selectedCustomer,
                siteIds: selectedSites,
            },
        };

        const payload2 =  {
            "email": formData.email,
            "password": formData.password,
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "name": formData.displayName,
            "displayName": formData.displayName,
            "role": "Orgnization administrator",
            "customerId": selectedCustomer
          }
          postCreateUserHandler(createUserURL, payload2);
        // Log the payload for debugging (you can remove this later)
        console.log('User Creation Payload:', payload2);

        // API call to create user
        // Example: 
        // axios.post('/api/users', payload)
        //     .then(response => {
        //         // Handle success (e.g., redirect to UserGrid)
        //     })
        //     .catch(error => {
        //         // Handle error
        //     });

        // Redirect to UserGrid after successful creation (if applicable)
    };

    const handleClose = () => {
        setOpenModal(true); // Open confirmation modal
    };

    const handleModalClose = (confirm) => {
        if (confirm) {
            // Removed Temporarily
            // Validate all required fields before closing
            // const newErrors = {};
            // if (activeStep === 0) {
            //     if (!formData.displayName) newErrors.displayName = 'Please enter display name.';
            //     if (!formData.email) {
            //         newErrors.email = 'Please enter email address.';
            //     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            //         newErrors.email = 'Please enter a valid email format.';
            //     } else if (false) { // Replace with actual email existence check when available
            //         newErrors.email = 'The email already exists.';
            //     }
            //     if (!formData.confirmEmail) {
            //         newErrors.confirmEmail = 'Please confirm email.';
            //     } else if (formData.email !== formData.confirmEmail) {
            //         newErrors.confirmEmail = 'Email and Confirm email do not match.';
            //     }
            //     if (formData.password.length < 8 || !/[a-z]/.test(formData.password) || !/[A-Z]/.test(formData.password) || !/\d/.test(formData.password)) {
            //         newErrors.password = 'Password must be at least 8 characters and contain 1 lowercase, 1 uppercase, and 1 number.';
            //     }
            // }
            // // Add additional validation for other steps if necessary

            // setErrors(newErrors); // Update errors state
            // if (Object.keys(newErrors).length === 0) {
            //     onClose(); // Call onClose if confirmed and no errors
            // }
            setOpenModal(false); // Close modal without action
            onClose(); // Call onClose if confirmed and no errors
            
        } else {
            setOpenModal(false); // Close modal without action
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev); // Toggle password visibility
    };

    const handleClickShowConfirmEmail = () => {
        setShowConfirmEmail((prev) => !prev); // Toggle confirm email visibility
    };

    const validateConfirmEmail = (email) => {
        if (!email) {
            return 'Please confirm email.';
        } else if (formData.email !== email) {
            return 'Email and Confirm email do not match.';
        }
        return '';
    };

    const validatePassword = (password) => {
        if (password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/\d/.test(password)) {
            return 'Password must be at least 8 or more characters and contain 1 lowercase, 1 uppercase, and 1 number.';
        }
        return '';
    };

    // Function to handle opening the Assign Customers modal
    const handleOpenAssignModal = async() => { 
        setOpenAssignModal(true);
    };

    // Function to handle assigning the selected customer
    const handleAssignCustomer = async () => {
    console.log(selectedCustomer)
    
       getSitesDataList(getHomeAllSiteList(selectedCustomer));
        setIsCustomerAssigned(true); // Mark customer as assigned
        // setSelectedSites(sortedSites.map(site => site.id)); // Select all sites
        setOpenAssignModal(false); // Close the modal

        // Update the sites based on the selected customer
        const customerSites = getSitesForCustomer(selectedCustomer); // Function to get sites for the selected customer
        setSortedSites(customerSites); // Update the sorted sites with the new customer sites

        // Update the displayed customer name
        const selectedCustomerData = filteredCustomers.find(c => c.id === selectedCustomer);
        setDisplayedCustomerName(selectedCustomerData ? selectedCustomerData : ''); // Set the displayed customer name

        // Reorder sortedCustomers to place the selected customer at the first index
        const updatedSortedCustomers = filteredCustomers.filter(customer => customer.id === selectedCustomer)
            .concat(filteredCustomers.filter(customer => customer.id !== selectedCustomer));
        setSortedCustomers(updatedSortedCustomers); // Update the sorted customers 
         
    };

    // Function to get sites for a specific customer (you need to implement this logic)
    const getSitesForCustomer = (customerId) => {
        // Replace this with actual logic to fetch sites based on the customer ID
        return sortedSites; // This is a placeholder; replace with actual site data for the customer
    };

    // Function to handle unassigning the customer
    const handleUnassignCustomer = () => {
        
        setOpenUnassignModal(true); // Open the unassign confirmation modal
         setSelectedCustomer(null); // Remove the selected customer when sites are deselected
    };

    const confirmUnassignCustomer = () => {
        setIsCustomerAssigned(true); // Mark customer as unassigned
        setSelectedSites([]); // Clear selected sites (this will uncheck the header checkbox)
        // setSelectedCustomer(null); // Clear selected customer
        setOpenUnassignModal(false); // Close the modal
    };

    // Function to handle search input change
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        // Filter customers based on search term
        setSortedCustomers(filteredCustomers?.filter(customer => customer.name.toLowerCase().includes(value.toLowerCase())));
    };

    // Function to handle sorting for sites
    const handleSortSites = () => {
        const newSortDirection = sortDirectionSites === 'asc' ? 'desc' : 'asc';
        setSortDirectionSites(newSortDirection);
        
        const sorted = [...sortedSites].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
        
        setSortedSites(sorted);
    };

    // Function to handle sorting for customers
    const handleSortCustomers = () => {
        const newSortDirection = sortDirectionCustomers === 'asc' ? 'desc' : 'asc';
        setSortDirectionCustomers(newSortDirection);
        
        const sorted = [...filteredCustomers].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
        
        setSortedCustomers(sorted);
    };

    // Function to handle permission checkbox changes
    const handlePermissionChange = (event) => {
        const { name, checked } = event.target;
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [name]: checked,
        }));
    };

    const cancelBtnStyle={marginRight: "10px",padding:"10.5px 36.5px",fontWeight:"regular",lineHeight:"normal"}
    const saveBtnStyle={marginRight: "10px",padding:"11.5px 29.88px",fontWeight:"regular",lineHeight:"normal"}

  // Modal actions configuration
  const modalActions = [
      {sx:cancelBtnStyle, variant:'outlined',label: 'No', onClick: () => handleModalClose(false) },
      {sx:saveBtnStyle, variant:'contained', label: 'Yes', onClick: () => handleModalClose(true) },
  ];

  const assignCustomerActions = [
    {sx:cancelBtnStyle, variant:'outlined',label: 'Cancel', onClick: () => setOpenAssignModal(false) },
    {sx:saveBtnStyle, variant:'contained', label: 'Assign', onClick: handleAssignCustomer },
];
 
const handleClearSearch = () => {
    setSearchTerm(''); // Reset the search term
    setSortedCustomers(filteredCustomers); // Reset the sorted customers to the original list
};

    return (
        <Box>   
            <Box className="stepperContainer">  
                <CustomStepper
                steps={steps}
                activeStep={activeStep}
                isLastStep={activeStep === steps.length - 1}
                isComplete={isComplete}
                error={
                    formError[activeStepName] &&
                    Object.values(formError?.[activeStepName])?.filter((f1) => f1)
                    .length > 0
                }
                />
            </Box>
            {activeStep === 1 && (
                <Box className="userDetails">  
                    <Grid container spacing={2}>
                        {/* First Name and Last Name on the same line */}
                        <Grid item xs={6}> 
                              <CustomTextField
                                fullWidth
                                id="firstName"
                                name="firstName" 
                                label="First Name"
                                handleChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                value={formData.firstName}
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={6}> 
                              <CustomTextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName" 
                                    label="Last Name"
                                    handleChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                    value={formData.lastName}
                                    width="100%"
                                />
                        </Grid>
                        <Grid item xs={12}> 
                            	<CustomTextField
                                id="displayName"
                                name="displayName"
                                label="Display Name" 
                                value={formData.displayName}
                                handleChange={(e) => {
                                    const newDisplayName = e.target.value;
                                    setFormData({ ...formData, displayName: newDisplayName });
                                    // Validate display name
                                    if (!newDisplayName) {
                                    setErrors((prevErrors) => ({ ...prevErrors, displayName: 'Please enter display name.' }));
                                    } else {
                                    setErrors((prevErrors) => ({ ...prevErrors, displayName: '' }));
                                    }
                                }}
                                onBlur={() => {
                                    // Validate display name on focus out
                                    if (!formData.displayName) {
                                        setErrors((prevErrors) => ({ ...prevErrors, displayName: 'Please enter display name.' }));
                                    }
                                }} 
                                width="100%"
                                error={!!errors.displayName}
                                helperText={errors.displayName}
                            />

                         </Grid>
                         <Grid item xs={12}> 
                      
                            <CustomTextField
                                id="email"
                                name="email"
                                label="Email" 
                                value={formData.email} 
                                handleChange={(e) => {
                                    const newEmail = e.target.value;
                                    setFormData({ ...formData, email: newEmail });
                                    // Validate email on change
                                    const newErrors = {};
                                    if (!newEmail) {
                                        newErrors.email = 'Please enter email address.';
                                    } else if (!/\S+@\S+\.\S+/.test(newEmail)) {
                                        newErrors.email = 'Please enter a valid email format.';
                                    } else if (false) { // Replace with actual email existence check when available
                                        newErrors.email = 'The email already exists.';
                                    } else{
                                        newErrors.email =''; 
                                    }
                                    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

                                    // Validate confirm email against the new email
                                    if (formData.confirmEmail && formData.confirmEmail !== newEmail) {
                                        setErrors((prevErrors) => ({ ...prevErrors, confirmEmail: 'Email and Confirm email do not match.' }));
                                    } else {
                                        setErrors((prevErrors) => ({ ...prevErrors, confirmEmail: '' }));
                                    }
                                }}
                                onBlur={() => {
                                    // Validate email on focus out
                                    const newErrors = {};
                                    if (!formData.email) {
                                        newErrors.email = 'Please enter email address.';
                                    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                                        newErrors.email = 'Please enter a valid email format.';
                                    } else if (false) { // Replace with actual email existence check when available
                                        newErrors.email = 'The email already exists.';
                                    } else{
                                        newErrors.email =''; 
                                    }
                                    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

                                    // Validate confirm email against the email
                                    if (formData.confirmEmail && formData.confirmEmail !== formData.email) {
                                        setErrors((prevErrors) => ({ ...prevErrors, confirmEmail: 'Email and Confirm email do not match.' }));
                                    }
                                }} 
                                error={!!errors.email} 
                                helperText={errors.email} 
                                width="100%" 
                            />
                          </Grid>
                          <Grid item xs={12}> 

                            <CustomTextField
                                id="confirmEmail"
                                name="confirmEmail"
                                label="Confirm Email" 
                                fieldType="password"
                                value={formData.confirmEmail} 
                                type={showConfirmEmail ? 'text' : 'password'}
                                handleChange={(e) => {
                                    const newConfirmEmail = e.target.value;
                                    setFormData({ ...formData, confirmEmail: newConfirmEmail });
                                    // Validate confirm email on change
                                    if (newConfirmEmail !== formData.email) {
                                        setErrors((prevErrors) => ({ ...prevErrors, confirmEmail: 'Email and Confirm email do not match.' }));
                                    } else {
                                        setErrors((prevErrors) => ({ ...prevErrors, confirmEmail: '' }));
                                    }
                                }}
                                onBlur={() => {
                                    // Validate confirm email on focus out
                                    if (formData.confirmEmail !== formData.email) {
                                        setErrors((prevErrors) => ({ ...prevErrors, confirmEmail: 'Email and Confirm email do not match.' }));
                                    }
                                }} 
                                isViewText={showConfirmEmail}
                                handleViewText={handleClickShowConfirmEmail}
                                onPaste={(e) => e.preventDefault()}
                                error={!!errors.confirmEmail} 
                                helperText={errors.confirmEmail}   
                                width="100%" 
                            />

                          </Grid>
                          <Grid container direction="column" item xs={12}>
                            <Grid item>
                                <FormControlLabel 
                                control={<Radio checked={passwordOption === 'auto'} onChange={handlePasswordOptionChange} value="auto"  sx={{
                                    color: "#161616", 
                                    '&.Mui-checked': {
                                      color: "#161616",
                                    },
                                  }}/>} 
                                label="Auto-generate password" 
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel 
                                control={<Radio checked={passwordOption === 'manual'} onChange={handlePasswordOptionChange} value="manual"  sx={{
                                    color: "#161616", 
                                    '&.Mui-checked': {
                                      color: "#161616",
                                    },
                                  }}/>} 
                                label="Let me create the password" 
                                />
                            </Grid>
                            </Grid>

                        {/* Password below radio buttons */}
                        <Grid item xs={12}>
                           
                            <CustomTextField
                                id="password"
                                name="password"
                                label="Password" 
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password} 
                                fieldType="password"  
                                handleChange={(e) => {
                                    const newPassword = e.target.value;
                                    setFormData({ ...formData, password: newPassword });
                                    // Validate password on change
                                    setErrors({ ...errors, password: validatePassword(newPassword) });
                                }}
                                onBlur={() => {
                                    // Validate password on focus out
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        password: validatePassword(formData.password)
                                    }));
                                }} 
                                isViewText={showPassword}
                                handleViewText={handleClickShowPassword} 
                                error={!!errors.password} 
                                helperText={errors.password} 
                                width="100%" 
                            />


                            </Grid>
                            {/* Checkboxes below password field */}
                            <Grid container direction="column" item xs={12}>
                                <Grid item>
                                    <FormControlLabel 
                                    control={<Checkbox checked={requirePasswordChange} onChange={() => setRequirePasswordChange(prev => !prev)}   sx={{
                                        color: "#161616",
                                        '&.Mui-checked': {
                                          color: "#161616",
                                        },
                                      }} />} 
                                    label={requirePasswordChange ? "Require this user to change their password when they first sign in" : "Require this user to change their password when they first sign in and cc to me"} 
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel 
                                    control={<Checkbox checked={sendPasswordEmail} onChange={() => setSendPasswordEmail(prev => !prev)}   sx={{
                                        color: "#161616",
                                        '&.Mui-checked': {
                                          color: "#161616",
                                        },
                                      }}/>} 
                                    label={sendPasswordEmail ? "Send password in email upon completion" : "Send password in email upon completion and cc to me"} 
                                    />
                                </Grid>
                            </Grid>

                                  {/* Note at the last */}
                            <Grid item xs={12}>
                
                            <Typography variant="body2" className='userDeatils-note'>
                            <span>Note:</span> 
                            In addition, users who create a user profile will automatically receive a copy of the password via email.
                            </Typography>

                            </Grid> 
                        
                    </Grid> 
                </Box> 
            )}
            {activeStep === 2 && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" className="rolePermission">
                    <Box width="20%">
                        <Typography variant="h6">Role</Typography> 
                        <CustomSelect 
                            options={roles}
                            id="country"
                            name={"role"}
                            value={formData.role}
                            displayEmpty={true}
                            onChange={(e) => setFormData({ ...formData, role: e.target.value })}  
                            width="100%"
                            inputComponent={downArrow}
                            noMarginIcon={false}
                            />
                     <Box className="roleNoteInfo">
                        <Typography variant="body3" className="note-title">Notes:</Typography>
                        <Box className="note-item">
                            <StatickTick />
                            <Typography variant="body2" color="green" className="list">Accessible</Typography>
                        </Box>
                        <Box className="note-item">
                            <DisableCross />
                            <Typography variant="body2" color="red" className="list">Not Accessible</Typography>
                        </Box>
                    </Box>

                    </Box>
                    <Box width="80%">
                        {formData.role === 'org_admin' && (
                            <Box mb={2}>
                                <Typography variant="h6">Permissions</Typography>
                                <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between">
                                <Box width="48%" className="panel">
                                    <Typography variant="subtitle1" align="center">Home</Typography> 
                                    <Box className="panel-content">
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>View home page</Typography>
                                        </Box>
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>Create favorites</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width="48%" className="panel">
                                    <Typography variant="subtitle1" align="center">Alarms </Typography> 
                                    <Box className="panel-content">
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>View alarm page</Typography>
                                        </Box>
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>Acknowledge alarm</Typography>
                                        </Box>
                                    </Box>
                                </Box> 
                                </Box>
                                <Box mb={2}>
                                    <Box className="panel">
                                    <Typography variant="subtitle1" align="center">Settings </Typography>  
                                        <Box  display="flex" flexDirection="row" justifyContent="space-between">
                                            <Box width="48%" className="panel-content"  >
                                            <Box className="list-item">
                                                <StatickTick />
                                                    <Typography variant="body2" className='list'>View customer and site management page</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <DisableCross />
                                                    <Typography variant="body2" className='list disabled'>Add customer</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Edit customer</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Delete customer</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Add site</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Edit site</Typography>
                                                </Box>

                                            </Box>
                                            <Box width="48%"  className="panel-content" >
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Delete site</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Enable/disable site</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>View users and permissions management</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Add/edit user and permissions</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>Delete user</Typography>
                                                </Box> 
                                            </Box>
                                
                                        </Box> 
                                    </Box> 
                                </Box>  
                                <Box width="25%" className="panel">
                                    <Typography variant="subtitle1" align="center">Audit Log </Typography> 
                                    <Box className="panel-content">
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>View audit log page</Typography>
                                        </Box>
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>View all types of log</Typography>
                                        </Box>
                                    </Box>  
                                </Box>
                            </Box>
                        )}
                        {formData.role === 'account_operator' && (
                            <Box mb={2}>
                                <Typography variant="h6">Permissions</Typography>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Box width="48%">
                                        <Typography variant="subtitle1">Alarms</Typography>
                                        <FormControlLabel 
                                            control={<Checkbox name="viewAlarmPage" checked={permissions.viewAlarmPage} onChange={handlePermissionChange} />} 
                                            label="View alarm page" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="acknowledgeAlarm" checked={permissions.acknowledgeAlarm} onChange={handlePermissionChange} />} 
                                            label="Acknowledge alarm" 
                                        />
                                    </Box>
                                    <Box width="48%">
                                        <Typography variant="subtitle1">Settings</Typography>
                                        <FormControlLabel 
                                            control={<Checkbox name="viewCustomerSiteManagement" checked={permissions.viewCustomerSiteManagement} onChange={handlePermissionChange} />} 
                                            label="View customer and site management page" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="addCustomer" checked={permissions.addCustomer} onChange={handlePermissionChange} disabled />} 
                                            label="Add customer" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="enableDisableSite" checked={permissions.enableDisableSite} onChange={handlePermissionChange} />} 
                                            label="Enable/disable site" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="addSite" checked={permissions.addSite} onChange={handlePermissionChange} />} 
                                            label="Add site" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="editSite" checked={permissions.editSite} onChange={handlePermissionChange} />} 
                                            label="Edit site" 
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {/* Add similar blocks for other roles as needed */}
                    </Box>
                </Box>
            )}
            {activeStep === 3 && (
                <Box className="assignCustomerInfo">
                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Customers</Typography> 
                        <CustomButton variant="contained" onClick={handleOpenAssignModal}> Assign customer </CustomButton>
                    </Box>

                    {isCustomerAssigned ? (
                        <Box width="100%"> 
                         
                         <Box sx={{
                                    p: 0,
                                    display: 'flex',
                                    flex: '1 1 100%',
                                    justifyContent: 'flex-end',  // Align items to the right
                                    alignItems: 'center',
                                    borderColor: 'divider',
                                    borderRadius: 2,
                                }}
                                >
                                {selectedSites?.length > 0 && ( // Only show button if a customer is assigned    
                                  <Button
                                  className="customTextIconButton"
                                   variant="text"
                                  component="label"
                                  role={undefined} 
                                  onClick={handleUnassignCustomer}
                                  tabIndex={-1}
                                  endIcon={<DeleteIcon />}
                                >  
                                  Unassign customer
                                </Button>
                                )}
                                
                                <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                    sx={{ fontSize: "12px", color: "#525252" }}
                                >
                                    {selectedSites?.length === 0 ? 0 : (selectedCustomer ? 1 : 0)} total
                                </Typography>

                                <>
                                    <Divider
                                    sx={{
                                        ml: { sm: 1 },
                                        mr: { sm: 1 },
                                        height: "20px"
                                    }}
                                    orientation="vertical"
                                    variant="middle"
                                    />
                                    
                                    <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                    sx={{ fontSize: "12px", color: "#525252" }}
                                    >
                                    {selectedSites?.length === 0 ? 0 : (selectedCustomer ? 1 : 0)} selected
                                    </Typography>
                                </>
                            </Box>  

                            <TableContainer sx={{ maxHeight: 581,marginRight:"14px" }}>
                            <Table stickyHeader aria-labelledby="tableTitle" size="medium"  className="customTable">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell  sx={{width:"20px"}}>
                                                <Checkbox
                                                 sx={{
                                                    color: "#161616",
                                                    '&.Mui-checked,&.MuiCheckbox-indeterminate': {
                                                      color: "#161616",
                                                    },
                                                  }}
                                                    checked={selectedSites?.length === sortedSites?.length}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedSites(sortedSites.map(site => site.id)); // Select all sites
                                                            // Assuming you want to set the first customer from the filteredCustomers as selected
                                                            // setSelectedCustomer(displayedCustomerName?.id)
                                                            // const firstCustomer = filteredCustomers[0]; // Adjust this logic as needed
                                                            if (displayedCustomerName) {
                                                                setSelectedCustomer(displayedCustomerName?.id); // Add the selected customer when checked
                                                            }
                                                        } else {
                                                            setSelectedSites([]); // Deselect all sites
                                                            setSelectedCustomer(null); // Remove the selected customer when sites are deselected
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={handleSortSites}>
                                                    {displayedCustomerName?.name}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                                sortedSites?.length > 0 ? (
                                                    sortedSites?.map((site) => (
                                                        <TableRow key={site.id}>
                                                        <TableCell>
                                                            <Checkbox
                                                            checked={selectedSites?.includes(site.id)}
                                                            onChange={() => {
                                                                setSelectedSites((prev) =>
                                                                prev.includes(site.id)
                                                                    ? prev.filter((id) => id !== site.id)
                                                                    : [...prev, site.id]
                                                                );
                                                            }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{site.name}</TableCell>
                                                        </TableRow>
                                                    ))
                                                    ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={2}>No data available</TableCell>
                                                    </TableRow>
                                                    )
                                        }
                                   
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box className="no-customer-list">
                            <Typography className="title">No customer found!</Typography>
                            <Typography  className="info">We recommend assign customers.  </Typography>
                        </Box>
                    )}

                    <CustomModal
                        open={openAssignModal}
                        onClose={() => setOpenAssignModal(false)}
                        title="Assign Customers"
                        actions={assignCustomerActions}
                        fullScreen={fullScreen}
                        fullWidth={true}
                        maxWidth="sm"
                    >
                       
                                <TextField
                                 fullWidth 
                                    className='commonSearchField'
                                        id="search"
                                        name="search"
                                        value={searchTerm} 
                                        placeholder="Search customer"
                                        autoComplete="off"
                                        sx={{
                                        width: '100%',
                                        marginRight: '14px',
                                        '& .MuiInputBase-root': {
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            letterSpacing: '0.16px',
                                            height: '40px',
                                            backgroundColor: '#F4F4F4',
                                        },
                                        }}
                                        onChange={handleSearchChange} 
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{ '& .MuiSvgIcon-root': { padding: '10px' } }}>
                                            <SearchIcon />
                                            {searchTerm?.length > 0 && (
                                                <span
                                                className="close" 
                                                onClick={handleClearSearch}
                                                style={{ cursor: 'pointer', marginRight: '1rem' }}
                                                >
                                                &times;
                                                </span>
                                            )}
                                            </InputAdornment>
                                        ),
                                        }}
                                    />
                         <Box sx={{
                                    p: 0,
                                    display: 'flex',
                                    flex: '1 1 100%',
                                    justifyContent: 'flex-end',  // Align items to the right
                                    alignItems: 'center',
                                    borderColor: 'divider',
                                    borderRadius: 2,
                                }}
                                >
                               
                                <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                    sx={{ fontSize: "12px", color: "#525252" }}
                                >
                                     {sortedCustomers?.length} total
                                </Typography>

                                <>
                                    <Divider
                                    sx={{
                                        ml: { sm: 1 },
                                        mr: { sm: 1 },
                                        height: "20px"
                                    }}
                                    orientation="vertical"
                                    variant="middle"
                                    />
                                    
                                    <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                    sx={{ fontSize: "12px", color: "#525252" }}
                                    >
                                   {selectedCustomer ? 1 : 0} selected
                                    </Typography>
                                </>
                            </Box>  
                            <TableContainer>
                                <Table className="assign-customers">
                                <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                                    <TableRow>
                                    <TableCell>
                                        <TableSortLabel onClick={handleSortCustomers}>
                                        Customer Name
                                        </TableSortLabel>
                                    </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ display: 'block', maxHeight: '440px', overflowY: 'auto' }}>
                                        {sortedCustomers?.map((customer) => (
                                           <TableRow key={customer.id} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                                 <TableCell className={selectedCustomer === customer.id ? 'selected-radio' : ''}>  
                                                    <FormControlLabel
                                                        control={<Radio   sx={{
                                                            color: "#161616", 
                                                            '&.Mui-checked': {
                                                              color: "#161616",
                                                            },
                                                          }} checked={selectedCustomer === customer.id} onChange={() => setSelectedCustomer(customer.id)} />}
                                                        label={customer.name}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </CustomModal>
 
                  
                    {/* Unassign Confirmation Modal */}
                    <Dialog open={openUnassignModal} onClose={() => setOpenUnassignModal(false)}>
                        <DialogTitle>Unassign Customer</DialogTitle>
                        <DialogContent>
                            <Typography>Are you sure you want to unassign this customer?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenUnassignModal(false)}>Cancel</Button>
                            <Button onClick={confirmUnassignCustomer}>Unassign</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
 
            <Box className="createUser-footer">

           
           
         

                {activeStep === 1 ? (
                   <CustomButton variant="contained" onClick={handleNext}> Proceed to Role </CustomButton>
                ) : activeStep === 2 ? (
                    <> 
                        <CustomButton variant="outlined" onClick={() => setActiveStep(activeStep - 1)}> Back to User Details </CustomButton> 
                        <CustomButton variant="contained" onClick={() => setActiveStep(activeStep + 1)}> Proceed to Assign </CustomButton>
                    </>
                ) : (
                    <>
                       <CustomButton variant="outlined" onClick={() => setActiveStep(activeStep - 1)}> Back to Role </CustomButton> 
                       <CustomButton variant="contained" onClick={handleNext}> Save User </CustomButton> 
                    </>
                )}
            </Box>
            {/* Confirmation Modal For Exit */}
            <CustomModal
			    open={openModal}
				onClose={() => setOpenModal(false)}
				title="Exit"
				actions={modalActions}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth="xs"
			>
                <Typography>Are you sure you want to exit without saving?</Typography> 
			</CustomModal>
            
        </Box>
    );
}