import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  ListItemText,
  Menu,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as LeftArrow } from "../../../assets/images/PaginationArrowLeft.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/PaginationArrowRight.svg";
import { getRequestCustomerApi } from "../../utils/CommonUtils";
import { sortingKeys } from "../../../components/setting/siteDetails/siteDetails.Data";
import { ReactComponent as Check } from "../../../assets/images/CheckMark.svg";
import { styled } from "@mui/system";
import { ReactComponent as ChevronDown } from "../../../assets/images/ChevronDown.svg";

const CommonPagination = ({
  endItemIndex,
  setEndIndexItem,
  count,
  page,
  onChange,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25, 50],
  disabled = false,
  pageNumberEnabled = false,
  searchText = "",
  additionalParams = {},
  arrowWidth,
  arrowBoxMarginRight,
  currentFilter,
  startIndex,
  setStartIndex,
}) => {
  const [totalPages, setTotalPages] = useState(1);
  const [isRowsPerPageOpen, setIsRowsPerPageOpen] = useState(false); // State to track if rows per page menu is open
  const [isPageMenuOpen, setIsPageMenuOpen] = useState(false); // State to track if page menu is open
  const { t } = useTranslation();
  const { settingsPageContent } = t("mainContainer");
  const { paginationContent } = settingsPageContent?.siteManagementContent;

  const { filterSelectionOption } = useSelector(
    (state) => state?.customerInfo
  );

  // Styled components
  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2.5),
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(4),
    },
    "&.Mui-selected": {
      marginLeft: "-10px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }));

  useEffect(() => {
    setTotalPages(Math.ceil(count / rowsPerPage));
  }, [count, rowsPerPage]);

  const handleItemsPerPageChange = (event) => {
    const keyOfAction = "itemsPerPage";
    let requestParams = getRequestCustomerApi({
      name: searchText,
      limit: parseInt(event.target.value),
      page: 1,
      sortBy: sortingKeys?.[filterSelectionOption],
      ...additionalParams,
    });
    onChange(1, parseInt(event.target.value), keyOfAction, requestParams);
  };

  const handlePreviousPage = () => {
    if (page > 1 && !disabled) {
      const keyOfAction = "previous";
      let apiParamsVal = getRequestCustomerApi({
        page: Number(page) - 1,
        limit: rowsPerPage,
        name: searchText,
        sortBy: sortingKeys?.[filterSelectionOption],
      });
      onChange(Number(page) - 1, rowsPerPage, keyOfAction, apiParamsVal);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages && !disabled) {
      const keyOfAction = "next";
      onChange(
        Number(page) + 1,
        rowsPerPage,
        keyOfAction,
        getRequestCustomerApi({
          page: Number(page) + 1,
          limit: rowsPerPage,
          name: searchText,
          sortBy: sortingKeys?.[filterSelectionOption],
        })
      );
    }
  };

  useEffect(() => {
    if (count > 0) {
      setStartIndex((page - 1) * rowsPerPage + 1);
    } else {
      setStartIndex((page - 1) * rowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, count]);

  useEffect(() => {
    setEndIndexItem(Math.min(page * rowsPerPage, count));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, count]);

  const handlePageChange = (event) => {
    const keyOfAction = "pageChange";
    let apiParamsVal = getRequestCustomerApi({
      page: event?.target?.value,
      limit: rowsPerPage,
      name: searchText,
      sortBy: sortingKeys?.[filterSelectionOption],
      ...additionalParams,
    });
    onChange(event?.target?.value, rowsPerPage, keyOfAction, apiParamsVal);
  };

  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  const customMenuProps = {
    PaperProps: {
      sx: {
        borderRadius: "6px",
        border: "1px solid #A3A5A7",
        background: "#F0F0F0",
        boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.25)",
        "& .MuiPaper-root": {
          top: "746px",
        },
        "& .MuiMenuItem-root": {
          backgroundColor: "#F0F0F0",
        },
        "& .MuiMenuItem-root:hover": {
          backgroundColor: "#0F62FE",
          color: "white",
        },
        "& .MuiList-root": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxHeight: "100%",
        paddingRight: "8px",
        paddingLeft: "8px",
      }}
    >
      <div className="selectParent">
        <Typography variant="span">{paginationContent?.itemPerPage}</Typography>
        <Select
          value={rowsPerPage}
          onChange={handleItemsPerPageChange}
          onOpen={() => setIsRowsPerPageOpen(true)}
          onClose={() => setIsRowsPerPageOpen(false)}
          IconComponent={ChevronDown}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          {rowsPerPageOptions?.map((option) => (
            <CustomMenuItem
              key={option}
              value={option}
              selected={rowsPerPage === option}
              className="customMenu"
            >
              {rowsPerPage === option && isRowsPerPageOpen && (
                <div className="checkIcon">
                  <Check></Check>
                </div>
              )}
              <ListItemText
                className="optionText"
                primary={option}
              ></ListItemText>
            </CustomMenuItem>
          ))}
        </Select>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginBottom: "-2px" }}
      />
      <div style={{ width: pageNumberEnabled && "40%" }}>
        <Typography variant="span" sx={{ marginX: 1 }}>
          {`${startIndex} - ${endItemIndex} ${paginationContent?.of} ${count} ${paginationContent?.items}`}
        </Typography>
      </div>

      {pageNumberEnabled && (
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginBottom: "-2px" }}
        />
      )}
      {pageNumberEnabled && (
        <div className="selectParent">
          <Select
            value={page}
            onChange={handlePageChange}
            MenuProps={customMenuProps}
            onOpen={() => setIsPageMenuOpen(true)}
            onClose={() => setIsPageMenuOpen(false)}
            IconComponent={ChevronDown}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            {pageOptions?.map((option) => (
              <CustomMenuItem
                key={option}
                value={option}
                selected={page === option}
                className="customMenu"
              >
                {isPageMenuOpen && page === option && (
                  <div className="checkIcon">
                    <Check></Check>
                  </div>
                )}
                <ListItemText className="optionText" primary={option}></ListItemText>
              </CustomMenuItem>
            ))}
          </Select>
          <Typography variant="span">
            {paginationContent?.outof} {totalPages} {paginationContent?.pages}
          </Typography>
        </div>
      )}

      <div
        style={{
          display: "flex",
          marginRight: arrowBoxMarginRight,
          marginBottom: "4px",
          width: arrowWidth,
          alignSelf: "end",
          justifyContent: "space-between",
        }}
      >
        <Divider
          orientation="vertical"
          flexItem
          className="paginationDividerMargin"
        />
        <IconButton
          disabled={page === 1 || disabled}
          onClick={handlePreviousPage}
          sx={{
            display: "flex",
            padding: "12px",
            alignItems: "center",
            alignSelf: "stretch",
            opacity: (page === 1 || disabled) ? 0.3 : 1,
            "&:hover": {
              backgroundColor: "rgba(111, 111, 111, 0.12)",
              transform: "scale(1.2)",
            },
          }}
        >
          <LeftArrow />
        </IconButton>

        <Divider
          orientation="vertical"
          flexItem
          className="paginationDividerMargin"
        />

        <IconButton
          disabled={page === totalPages || disabled}
          onClick={handleNextPage}
          sx={{
            display: "flex",
            padding: "12px",
            alignItems: "center",
            alignSelf: "stretch",
            opacity: (page === totalPages || disabled) ? 0.3 : 1,
            "&:hover": {
              backgroundColor: "rgba(111, 111, 111, 0.12)",
              transform: "scale(1.2)",
            },
          }}
        >
          <RightArrow />
        </IconButton>
      </div>
    </Box>
  );
};

export default CommonPagination;
