import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, TextField, Button, Tooltip,Popover, Checkbox, FormControlLabel, Grid, List, ListItem, MenuItem, IconButton, Select, Dialog, DialogTitle, DialogContent, DialogActions, Menu, Divider, InputAdornment, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client'; // Import the socket.io-client library
import { useTranslation } from "react-i18next";
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewColumnIcon from '@mui/icons-material/ViewColumn'; 
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import usePost from '../../../common/hook/usePost';
import { alarmLogEndPoint } from '../../../services/apiConfig';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';
import { prefectures } from '../../../components/setting/CustomerData';
import './AlarmLogs.scss'
import { ReactComponent as SearchIcon } from '../../../assets/images/SearchIcon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/Filter.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/CloseIcon.svg';
import { ReactComponent as ColumnIcon } from '../../../assets/images/Column.svg';
import { ReactComponent as DefaultCheckmark } from '../../../assets/images/defaultCheckmark.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as ChevronDown } from "../../../assets/images/ChevronDown.svg";
import { ReactComponent as LeftArrow } from "../../../assets/images/PaginationArrowLeft.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/PaginationArrowRight.svg";
import { ReactComponent as LowIcon } from "../../../assets/images/lowIcon.svg";
import { ReactComponent as MediumIcon } from "../../../assets/images/mediumIcon.svg";
import { ReactComponent as HighIcon } from "../../../assets/images/heighIcon.svg";
import { ReactComponent as NormalIcon } from "../../../assets/images/normalIcon.svg";
import alaramAnimoData from "../../../assets/animation/alarmAnimo.json"; 
import { formatDate } from '../../../common/utils/CommonUtils';
import { convertDateTimeToTimestamp } from '../../../common/utils/CommonUtils';
import { formatToCustomDateString } from '../../../common/utils/CommonUtils';
import { ReactComponent as CheckIcon } from "../../../assets/images/CheckMark.svg";
import { useSocketUpdatedAlarmLog } from '../../../common/utils/socketUtils';
import { socketIOURL } from '../../../services/apiConfig'; 



const defaultVisibleColumns = ['status', 'timestamp', 'description', 'value', 'action'];
 
const formatToCustomDateStringFullYear = (dateString) => {
  const [date, time] = dateString?.split(' ');
  const [year, month, day] = date?.split('/').map(Number);
  const [hours, minutes] = time?.split(':').map(Number);

  // Format the new date string with full year
  const formattedDate = `${year.toString()}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`; // Append seconds as '00'
  
  return formattedDate;
}
export default function AlarmLogs() {
  const [alarms, setAlarms] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [alarmType, setAlarmType] = useState('');
  const [ackStatus, setAckStatus] = useState('');
  const [alarmPriority, setAlarmPriority] = useState('');
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [sortModel, setSortModel] = useState([]);
  const [columnsAnchorEl, setColumnsAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(['priority', 'timestamp', 'description','name','prefecture','siteName','action']);
  const [filters, setFilters] = useState({});
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [acknowledgeStatus, setAcknowledgeStatus] = useState(null);
  const [acknowledgedAlarmId, setAcknowledgedAlarmId] = useState(null);
  const [localeText, setLocaleText] = useState({ noRowsLabel: '' });
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Flag to track initial load
  const [isSearchLoad, setIsSearchLoad] = useState(false); // Flag to track initial load
  const [socket, setSocket] = useState(null);
  const [isnavigateOutside,setIsnavigateOutside] = useState(false)

  const [alarmLogData, setAlarmLogData] = useState([]); // New state to hold alarm data
  const [alarmLogList, setAlarmLogList] = useState([]); // Local state to hold accordion list
  const [socketDataCount,setSocketDataCount] = useState(null)

  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");

  const location = useLocation();

  const { recentSearchId , DateRangeFilter} = useSelector((state) => state?.customer);
  const { loading, error, data, postData } = usePost(alarmLogEndPoint());
  const { 
    loading: acknowledgeLoading, 
    error: acknowledgeError, 
    data: acknowledgeData, 
    postData: acknowledgeAlarm 
  } = usePost();

  const pageView = 5; // Define pageView constant

  // Add this new ref every 30 Seconds polling
  const intervalRef = useRef(null);

  // Function to update localeText based on current data and alarms
  const updateLocaleText = () => {
     if (isInitialLoad && !loading&&!error&&data&&data?.result?.results?.length === 0) {
      setLocaleText({ noRowsLabel: alarm?.NoData }); // No data available
      setIsInitialLoad(false); // Set the flag to false after the initial load
    } else if (isSearchLoad &&!loading&&!error&&data&& alarms?.length === 0) {
      setLocaleText({ noRowsLabel: alarm?.nosearchData }); // Data available but no alarms found
    } 
  };
  // useEffect(() => {
  //   setFilters({})
  //   setSearch('')
  // }, [recentSearchId]);
  // Call updateLocaleText whenever relevant state changes
  useEffect(() => {
    updateLocaleText();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data, alarms]);

  const fetchAlarmData = useCallback(() => {
    if (!shouldFetch) return;
    
    const payload = {
      Search: search.trim() || undefined,
      limit: pageSize,
      page: page + 1,
      searchId: recentSearchId,
      sortBy : "Time:desc",
    };

    // Check if DateRangeFilter is not an empty object and has a valid 'value' property
    if (DateRangeFilter && Object.keys(DateRangeFilter).length > 0 && DateRangeFilter.value && DateRangeFilter.value[1]) { 

    const [dateStartString, dateEndString] = DateRangeFilter.value;  
    const customFormattedDate = formatToCustomDateString(formatDate(dateEndString)); 
      // Set the adjusted end date in the payload
    payload.endDate = convertDateTimeToTimestamp(customFormattedDate) ;

    }

    // Handle sorting
    if (sortField) {
      if (sortField === 'action') {
        payload.sortBy = `Action:${sortDirection}`;
      } else {
        payload.sortBy = `${getSortFieldName(sortField)}:${sortDirection}`;
      }
    }

    // Add filters to the payload
    Object.entries(filters).forEach(([filterType, selectedOptions]) => {
      if (selectedOptions && selectedOptions.length > 0) {
        switch (filterType) {
          case 'status':
            payload.AckStatus = selectedOptions.map(status => status === 'Alarm' ? [1, 2] : 0).flat();
            break;
          case 'priority':
            // Change this part to send an array of strings
            payload.Priority = selectedOptions.map(priority => priority.toLowerCase());
            break;
          case 'acknowledgmentStatus':
            payload.AckStatus = selectedOptions.map(status => status === 'Acknowledged' ? 2 : [0, 1]).flat();
            break;
          case 'type':
            payload.AlarmType = selectedOptions.map(type => {
              const typeMapping = {
                'HH alarm': 1,
                'High alarm': 2,
                'Low alarm': 3,
                'LL alarm': 4,
                'State 0': 8,
                'State 1': 9,
                'State 2': 10,
                'State 3': 11,
                'State 4': 12,
                'State 5': 13,
                'State 6': 14,
                'State 7': 15,
                'Other': 0
              };
              return typeMapping[type] || 0;
            });
            break;
          default:
            break;
        }
      }
    });

    // Remove undefined properties
    Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);
    if(recentSearchId!==""){
    postData(alarmLogEndPoint(), payload);
    }
    setShouldFetch(false);
  }, [pageSize, page, recentSearchId, sortField, sortDirection, search, postData, shouldFetch, filters, DateRangeFilter]);

  useEffect(() => {
   
      setShouldFetch(true);
   
  }, [recentSearchId, DateRangeFilter]);

  // Starts every 10 seconds polling allrarm info 
  // Add this new useEffect for polling
  // useEffect(() => {
  //   // Function to fetch data
  //   const fetchData = () => {
  //     setShouldFetch(true);
  //   };

  //   // Initial fetch
  //   fetchData();

  //   // Set up the interval
  //   intervalRef.current = setInterval(fetchData, 10000); // 10 seconds

  //   // Clean up function
  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, []);  // Empty dependency array means this effect runs once on mount
// Ends Here every 10 seconds polling allrarm info 
  
  // Modify the existing useEffect that calls fetchAlarmData
  useEffect(() => {
    if (shouldFetch) {
      fetchAlarmData();
      
    }
  }, [fetchAlarmData, shouldFetch]);


  useEffect(() => {
    // Initialize socket connection
    const socketInstance = io(socketIOURL); // Replace with your server URL
    setSocket(socketInstance);

    // Cleanup the socket when the component unmounts
    return () => {
      socketInstance.disconnect();
    };
  }, []);


  useEffect(() => {
    if(alarmLogList?.length>0){
      setSocketDataCount(alarmLogData?.totalResults+alarmLogList?.length)
    }
  
    // Combine existing alarmLogData with new alarmLogList data
    const combinedAlarmData = [
      ...alarmLogData?.results || [],
      ...alarmLogList?.map(socketAlarm => ({
        ...socketAlarm,
        id: Math.random().toString(36).substr(2, 9) // Generate a random ID for each new entry
      })) || []
    ];
  
    // Filter combinedAlarmData based on selected filters and search
    const filteredData = combinedAlarmData.filter(alarm => {
      // Search filter
      const searchTerm = search?.toLowerCase().trim();
      const matchesSearch = !searchTerm || 
        alarm.Description?.toLowerCase().includes(searchTerm) || 
        alarm.TagName?.toLowerCase().includes(searchTerm) ||
        alarm.Action?.toLowerCase().includes(searchTerm) ||
        alarm.siteName?.toLowerCase().includes(searchTerm);
  
      // Priority filter
      const matchesPriority = !filters.priority?.length || 
        filters.priority.includes(getPriorityLabel(alarm.Priority));
  
      // State/Prefecture filter
      const matchesState = !filters.state?.length || 
        filters.state.includes(alarm.state);
  
      // Site Name filter
      const matchesSiteName = !filters.siteName?.length || 
        filters.siteName.includes(alarm.siteName);
  
      // Action filter
      const matchesAction = !filters.action?.length || 
        filters.action.includes(alarm.Action);
  
      // Time/Date filter - assuming DateRangeFilter is available in the component
      let matchesDate = true;
      if (DateRangeFilter?.value?.length === 2) {
        const [startDate, endDate] = DateRangeFilter.value;
        const alarmTime = alarm.Time * 1000; // Convert to milliseconds
        const startTimestamp = new Date(startDate).getTime();
        const endTimestamp = new Date(endDate).getTime();
        matchesDate = alarmTime >= startTimestamp && alarmTime <= endTimestamp;
      }
  
      // Combine all filter conditions
      return matchesSearch && 
             matchesPriority && 
             matchesState && 
             matchesSiteName && 
             matchesAction && 
             matchesDate;
    });
  
    // Sort the filtered data if needed
    if (sortField && sortDirection) {
      filteredData.sort((a, b) => {
        const aValue = a[getSortFieldName(sortField)];
        const bValue = b[getSortFieldName(sortField)];
        
        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }
    // Update the state with the combined alarm data 
   setAlarmLogData({ 
    ...alarmLogData, 
    results: filteredData,
    totalResults: filteredData.length 
  });
    // handleFilterApply(filters); // Call onApply instead of onFilterChange
    // handleFilterChange(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmLogList]);

// Use the custom hook to handle socket updates
useSocketUpdatedAlarmLog(socket, setAlarmLogList, alarmLogList, recentSearchId);

 
useEffect(() => {
  if (!loading &&!error &&data && data.result && data.result.results&&!isnavigateOutside) { 
    setAlarmLogData(data.result); // Update the new state with fetched data 
  }
}, [loading,error,data,location.state,isnavigateOutside]); // Add data as a dependency

  useEffect(() => {
    if (alarmLogData?.results&&!loading &&!error &&data) {
      const alarmData = alarmLogData?.results?.map(alarm => ({
        id: alarm.id, 
        priority: getPriorityLabel(alarm.Priority), 
        timestamp:formatTimestamp(alarm.Time * 1000),
        description: alarm.Description, 
        name: alarm.TagName, 
        prefecture: getPrefectureLabel(alarm.state),
        siteName: alarm.siteName,  
        // Keep the original properties for reference or future use 
        AlarmPriority: alarm.Priority, 
        Time: alarm.Time, 
        siteId: alarm.siteId, 
        state: alarm.state, // Keep the original state value 7
        action:alarm.Action 
      }));

      const filteredAlarms = alarmData.filter(alarm => {
        const fromSiteName = location?.state?.siteName;
        const fromState = location?.state?.state;
        
        // Check if filtering by siteName or state
        const matchesSiteName = fromSiteName ? alarm.siteName === fromSiteName : true;
        const matchesState = fromState ? alarm.state === fromState : true;
    
        return matchesSiteName && matchesState;
    });
 
    if (location?.state?.state) {
        // Execute logic specific to navigating from the "View all" button 
        setAlarms(filteredAlarms); // Set the filtered alarms
       
        setIsnavigateOutside(true)
        if(socketDataCount!==null){
          setTotalCount(socketDataCount);
        }else{
          setTotalCount(filteredAlarms.length);
        }
    } else if (location?.state?.siteName) {
        // Logic for other types of navigation 
        setAlarms(filteredAlarms); // Set the filtered alarms
    
        setIsnavigateOutside(true)
        if(socketDataCount!==null){
          setTotalCount(socketDataCount);
        }else{
          setTotalCount(filteredAlarms.length);
        }
    } else {
        // Default case
        setAlarms(alarmData); // Set all alarms if no filtering
     
        setIsnavigateOutside(false)
        if(socketDataCount!==null){
          setTotalCount(socketDataCount);
        }else{
          setTotalCount(alarmLogData?.totalResults);
        }
    } 
     
    if(alarmLogData?.results?.length>0){
    // Find the latest timestamp
    const latestTimestamp = Math.max(...alarmLogData.results.map(alarm => alarm.Time));
    setLastUpdateTime(formatTimestamp(latestTimestamp * 1000));
    }else{
      setLastUpdateTime(null)
    }
     
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmLogData]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setShouldFetch(true);
    
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    setShouldFetch(true);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
    setShouldFetch(true);
    updateLocaleText(); // Update localeText when search changes
    setIsSearchLoad(true)
  };

  const handleClearSearch = () => {
    setSearch('');
    setPage(0);
    setShouldFetch(true);
    setIsSearchLoad(false);
    setIsInitialLoad(true); // Set the flag to false after the initial load
  };

  const handleSortModelChange = (newSortModel) => {
    if (newSortModel.length > 0) {
      setSortField(newSortModel[0].field);
      setSortDirection(newSortModel[0].sort);
    } else {
      setSortField('');
      setSortDirection('asc');
    }
    // setPage(0);
    setShouldFetch(true);
  };

  const handleFilterModelChange = (filterModel) => { 
    // Reset all filters
    setAlarmType('');
    setAckStatus('');
    setAlarmPriority('');
    setStatus('');
    setSearch('');

    // Apply new filters
    filterModel.items.forEach(filter => {
      switch (filter.columnField) {  
        case 'priority':
          setAlarmPriority(filter.value);
          break; 
        default:
          break;
      }
    });
    setPage(0);
    setShouldFetch(true);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setPage(0);
    setShouldFetch(true);
  };

  const handleMenuOpen = (event, rowId) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedRowId(null);
  };
 

  useEffect(() => {
    if (!acknowledgeLoading && acknowledgedAlarmId) {
      if (acknowledgeError) {
        setAcknowledgeStatus({ 
          type: 'error', 
          title:'Error',
          message: 'An acknowledgment request has failed to execute.' 
        });
      } else if (acknowledgeData) {
        // Update the local state
        setAlarms(prevAlarms => prevAlarms.map(alarm => 
          alarm.id === acknowledgedAlarmId 
            ? { ...alarm, acknowledgmentStatus: 'Acknowledged' } 
            : alarm
        ));
        // Update the selected alarm if it's currently displayed in the modal
        setSelectedAlarm(prevAlarm => 
          prevAlarm && prevAlarm.id === acknowledgedAlarmId 
            ? { ...prevAlarm, acknowledgmentStatus: 'Acknowledged' } 
            : prevAlarm
        );
        if(acknowledgeData?.success){
        setAcknowledgeStatus({ 
          type: 'success', 
          title:'Acknowledge',
          message: acknowledgeData.message
        });
        fetchAlarmData(); // Fetch alarm data immediately after acknowledgment
      }else if(!acknowledgeData?.success){
          setAcknowledgeStatus({ 
            type: 'error', 
            title:'Error',
            message: acknowledgeData.message
          })
        }
        setShouldFetch(true);
      }
      setAcknowledgedAlarmId(null);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledgeLoading, acknowledgeError, acknowledgeData, acknowledgedAlarmId]);

  const handleColumnToggle = (columnField, isChecked) => {
    setVisibleColumns(prev => {
      if (isChecked === undefined) {
        // Toggle behavior
        return prev.includes(columnField)
          ? prev.filter(field => field !== columnField)
          : [...prev, columnField];
      } else {
        // Set behavior
        return isChecked
          ? [...new Set([...prev, columnField])]
          : prev.filter(field => field !== columnField);
      }
    });
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    // Update visible columns based on selected filters
    const selectedFilterColumns = Object.keys(newFilters).filter(key => newFilters[key].length > 0);
    setVisibleColumns(prevColumns => {
      const updatedColumns = [...new Set([...prevColumns, ...selectedFilterColumns])];
      return updatedColumns;
    });
  };

  const handleFilterApply = (newFilters) => {
    setFilters(newFilters);
    setFilterAnchorEl(null);
    setPage(0); // Reset to first page when applying new filters
    setShouldFetch(true); // Trigger a new data fetch with the updated filters
  };

  const handleFilterReset = () => {
    setFilters({});
    setFilterAnchorEl(null);
    setShouldFetch(true);
  };

  // Add this function to count selected filters
  const getSelectedFiltersCount = () => {
    return Object.values(filters).reduce((count, filterArray) => count + filterArray.length, 0);
  };

  const handleClearAllFilters = (event) => {
    if (event) event.stopPropagation(); // Prevent the filter popover from opening if event is provided
    setFilters({});
    setShouldFetch(true);
  };

  const columns = [
    
    { 
      field: 'priority', 
      headerName: alarm?.alarmSummaryHeader["Priority"] , 
      filterable: true, 
      sortable: true, 
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <Box display="flex" alignItems="center">
            {params.value === 'Low' && <LowIcon style={{ marginRight: '8px' }} />}
            {params.value === 'Medium' && <MediumIcon style={{ marginRight: '8px' }} />}
            {params.value === 'High' && <HighIcon style={{ marginRight: '8px' }} />}
            {params.value}
          </Box>
        </Tooltip>
      ),
    },
    { 
      field: 'timestamp', 
      headerName: alarm?.alarmSummaryHeader["Timestamp"], 
      sortable: true, 
      flex: 1,
      renderCell: (params) => {
           // To convert a date string from the format YYYY/MM/DD HH:mm 
           const customFormattedDate = formatToCustomDateStringFullYear(formatDate(params?.value)); 
           convertDateTimeToTimestamp(customFormattedDate) ;
          
       
    
        return (
          <Tooltip title={params?.value} arrow>
            <span>{params?.value}</span>
          </Tooltip>
        );
      },
    },
    { 
      field: 'description', 
      headerName:  alarm?.alarmSummaryHeader["Description"], 
      sortable: true, 
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    { 
      field: 'name', 
      headerName:  alarm?.alarmSummaryHeader["Name"] , 
      sortable: true, 
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
      
    { 
      field: 'prefecture', 
      headerName: alarm?.alarmSummaryHeader["Prefecture"] , 
      sortable: true, 
      flex: .8,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value || 'Unknown'}</span>
        </Tooltip>
      ),
    },
    { 
      field: 'siteName', 
      headerName: alarm?.alarmSummaryHeader["Site Name"] , 
      sortable: true, 
      flex: .6,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    } , { 
      field: 'action', 
      headerName:  alarm?.alarmSummaryHeader["Action"], 
      sortable: true, 
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    } 
  ];

  const handleAlarmSummaryDetails = () => {
    const selectedAlarm = alarms.find(alarm => alarm.id === selectedRowId);
    setSelectedAlarm(selectedAlarm);
    handleMenuClose();
  };

  const getPriorityLabel = (priority) => {
    if (priority <= 10) return 'Low';
    if (priority <= 30) return 'Medium';
    return 'High';
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    
    // Get the individual components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Format the string as YYYY/MM/DD HH:MM:SS
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  const getAcknowledgmentStatus = (ackStatus) => {
    return ackStatus === 2 ? 'Acknowledged' : 'Unacknowledged';
  };

   
  // Add this function to map the visible column names to API property names
  const getSortFieldName = (visibleFieldName) => {
    const fieldMapping = { 
      'priority': 'Priority',
      'timestamp': 'Time',
      'description': 'Description',
      'name': 'TagName', 
      'prefecture': 'state',
      'siteName': 'siteName', 
      'action':"Action"
    };
    return fieldMapping[visibleFieldName] || visibleFieldName;
  };

  // Update this function to handle undefined state
  const getPrefectureLabel = (stateValue) => {
    if (!stateValue) return 'Unknown';
    const prefecture = prefectures.find(p => p.value === stateValue);
    return prefecture ? prefecture.label : stateValue;
  };

   // Function to format and adjust time
   const formatAndAdjustTime = (time) => {
    if (!time) return '-';

    // Parse the timestamp (assuming it's in ISO format)
    const originalTime = new Date(time);

    // Subtract 5 hours and 30 minutes
    const adjustedTime = new Date(originalTime.getTime() - (5.5 * 60 * 60 * 1000));

    // Format the adjusted time as YYYY/MM/DD HH:mm:ss
    const year = adjustedTime.getFullYear();
    const month = String(adjustedTime.getMonth() + 1).padStart(2, '0');
    const day = String(adjustedTime.getDate()).padStart(2, '0');
    const hours = String(adjustedTime.getHours()).padStart(2, '0');
    const minutes = String(adjustedTime.getMinutes()).padStart(2, '0');
    const seconds = String(adjustedTime.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  // const noRowsLabel = !loading && !error && data.length!==0 &&alarms?.length === 0 ? alarm?.nosearchData : alarm?.NoData;
  const noRowsLabelText = () => {
    if (!loading && !error && data?.result?.results?.length === 0) {
      return alarm?.NoData;
    } else if (!loading && !error && data?.result?.results?.length > 0 && alarms?.length === 0) {
      return alarm?.nosearchData;
    } 
    return alarm?.NoData; // Default value when no conditions are met
  };



  return (
    <Box sx={{ height: "100%"}} >
      <Box display="flex" justifyContent="space-between" className="filterbar"> 
      <Typography variant="span" gutterBottom className="lastUpdateLabel">
      {alarm.Lastupdate}: {lastUpdateTime? lastUpdateTime:""  }
      </Typography>
        
        <Box display="flex" alignItems="center">
          <TextField
          className='commonSearchField'
            id="search"
            name="search"
            value={search}
            placeholder={alarm.SearchText}
            autoComplete="off"
            sx={{
              width: '180px',
              marginRight: '14px',
              '& .MuiInputBase-root': {
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.16px',
                height: '40px',
                backgroundColor: '#F4F4F4',
              },
            }}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ '& .MuiSvgIcon-root': { padding: '10px' } }}>
                  <SearchIcon />
                  {search.length > 0 && (
                    <span
                      className="close"
                      onClick={handleClearSearch}
                      style={{ cursor: 'pointer', marginRight: '1rem' }}
                    >
                      &times;
                    </span>
                  )}
                </InputAdornment>
              ),
            }}
          />
          
          <Button
            className={`${(filterAnchorEl || getSelectedFiltersCount() > 0) &&"filterOpen"} filterButton`} 
            startIcon={<FilterIcon />}
            onClick={(e) => setFilterAnchorEl(e.currentTarget)}
            endIcon={
              getSelectedFiltersCount() > 0 && (
                <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                  : {getSelectedFiltersCount()}
                  <IconButton
                    size="small"
                    onClick={handleClearAllFilters} 
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Box>
              )
            }
          >
            {alarm.Filter}
          </Button>
          

        </Box>
      </Box>
      <Box sx={{  width: '100%' }}>
        <DataGrid
        className="alarmSummaryGrid"
          rows={alarms}
          columns={columns.filter((col) => visibleColumns.includes(col.field))}
          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          sortModel={sortField ? [{ field: sortField, sort: sortDirection }] : []}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          loading={isInitialLoad&&loading}
          hideFooter={true} 
          disableColumnSelector
          key={JSON.stringify(alarms)} // Add this line
          disableColumnResize={true} 
          disableColumnMenu={true} 
          disableDensitySelector={true}
          disableColumnFilter={true}
          rowHeight={40} 
          localeText={localeText} // Use the dynamic localeText state
          sx={{
            '& div.MuiDataGrid-columnHeaders': {
              backgroundColor: 'background.paper',
              color: 'text.primary',
              fontWeight: 'bold', 
            },
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
            
          }}
        />
      </Box>
      <CustomPagination
        totalCount={totalCount}
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange} 
        onPageSizeChange={handlePageSizeChange}
        pageView={pageView}
      />
      <ColumnsPopover
        anchorEl={columnsAnchorEl}
        onClose={() => setColumnsAnchorEl(null)}
        columns={columns}
        visibleColumns={visibleColumns}
        onColumnToggle={handleColumnToggle}
        filters={filters} // Pass filters to ColumnsPopover
      />
      <FilterPopover
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        filters={filters}
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        onReset={handleFilterReset}
        onClearAll={handleClearAllFilters} // Pass the clear all function to FilterPopover
      />
      <Menu
      className='summary-popover-btn'
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAlarmSummaryDetails}>{alarm.summaryDetails}</MenuItem>
      </Menu>
       
    </Box>
  );
}

function CustomPagination({ totalCount, page, pageSize, onPageChange, onPageSizeChange, pageView }) {
  const totalPages = Math.ceil(totalCount / pageSize);
  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");
  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10) - 1;
    onPageChange(newPage);
  };
 
  const handlePrevPage = () => {
    if (page > 0) {
      onPageChange(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      onPageChange(page + 1);
    }
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  const startItem = page * pageSize + 1;
  const endItem = Math.min((page + 1) * pageSize, totalCount);

  const startPage = Math.floor(page / pageView) * pageView + 1;
  const endPage = Math.min(startPage + pageView - 1, totalPages);

  return (
    <Box display="flex" alignItems="center" direction="column" justifyContent="space-between">
     <Box display="flex"  alignItems="center" direction="row">
      <Select 
        value={pageSize}
        onChange={handlePageSizeChange}
        variant="outlined"
        size="small"
        IconComponent={ChevronDown}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
       <MenuItem value={25} className="customMenuItem">
          {pageSize === 25 && <CheckIcon />}  {/* Add icon for active MenuItem */}
          25
        </MenuItem>
        <MenuItem value={50} className="customMenuItem">
          {pageSize === 50 && <CheckIcon />}  {/* Add icon for active MenuItem */}
          50
        </MenuItem>
        <MenuItem value={75} className="customMenuItem">
          {pageSize === 75 && <CheckIcon />}  {/* Add icon for active MenuItem */}
          75
        </MenuItem>
        <MenuItem value={100} className="customMenuItem">
          {pageSize === 100 && <CheckIcon />}  {/* Add icon for active MenuItem */}
          100
        </MenuItem>
      </Select>
      <Divider
     
        orientation="vertical"
        flexItem
        style={{ marginBottom: "-2px" }}
      />
      <Typography variant="body2" mx={2}  className="pageViewHelpText">
        {startItem} – {endItem} {alarm?.of} {totalCount}  {alarm?.items}
      </Typography>
    </Box>
      <Box display="flex"  alignItems="center" direction="row">
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginBottom: "-2px" }}
      />
      <TextField
      className="pageSelect"
        select
        value={page + 1}
        onChange={handlePageChange}
        variant="outlined"
        size="small"
        sx={{ width: 80,  "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          } }}
          SelectProps={{
            IconComponent: ChevronDown,
          }}
      >
       {[...Array(endPage - startPage + 1)].map((_, index) => {
          const currentPage = startPage + index; // Calculate the current page
          return (
            <MenuItem key={currentPage} value={currentPage} className="customMenuItem">
              {currentPage === page + 1 && <CheckIcon />} {/* Add active icon if it's the current page */}
              {currentPage} 
            </MenuItem>
          );
        })}
      </TextField>
      <Typography variant="body2" mr={2}>
        of {totalPages} {alarm?.Pages}
      </Typography>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginBottom: "-2px" }}
      />
      <IconButton 
       className='pageDirection'
        onClick={handlePrevPage} 
        disabled={page === 0}  
        sx={{
          display: "flex",
          padding: "12px",
          alignItems: "center",
          alignSelf: "stretch",
          opacity: page === 0 ? 0.3 : 1, // Add this line
          "&:hover": {
            backgroundColor: "rgba(111, 111, 111, 0.12)",
            transform: "scale(1.2)",
          },
        }}
      >
        <LeftArrow />
      </IconButton>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginBottom: "-2px" }}
      />
      <IconButton 
        onClick={handleNextPage} 
        disabled={page === totalPages - 1}  
        className='pageDirection'
        sx={{
          display: "flex",
          padding: "12px",
          alignItems: "center",
          alignSelf: "stretch",
          opacity: page === totalPages - 1 ? 0.3 : 1, // Add this line
          "&:hover": {
            backgroundColor: "rgba(111, 111, 111, 0.12)",
            transform: "scale(1.2)",
          },
        }}
      >
        <RightArrow />
      </IconButton>
      
      
      </Box>
    </Box>
  );
}

function ColumnsPopover({ anchorEl, onClose, columns, visibleColumns, onColumnToggle, filters }) {
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");
  useEffect(() => {
    setSelectAll(columns.every(column => visibleColumns.includes(column.field)));
  }, [columns, visibleColumns]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    columns.forEach(column => {
      if (!defaultVisibleColumns.includes(column.field)) {
        onColumnToggle(column.field, isChecked);
      }
    });
  };

  return (
    <Popover
      className="columnPopover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List>
        <ListItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                indeterminate={visibleColumns.length > defaultVisibleColumns.length && visibleColumns.length < columns.length}
              />
            }
            label={alarm?.alarmSummaryHeader["SelectAll"]}
          />
        </ListItem>
        <Divider />
        {columns.map((column) => (
          <ListItem 
            key={column.field}
            className={
              !defaultVisibleColumns.includes(column.field) && visibleColumns.includes(column.field) 
                ? 'selected-column' 
                : ''
            }
          >
            <FormControlLabel
              control={
                defaultVisibleColumns.includes(column.field) ? (
                  <DefaultCheckmark style={{ margin: '2px 8px', width:"24px",height:"24px" ,}} />
                ) : (
                  <Checkbox
                    sx={{ padding: '2px 8px' }}
                    checked={visibleColumns.includes(column.field)}
                    onChange={() => onColumnToggle(column.field)}
                  />
                )
              }
              label={column.headerName}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}

function FilterPopover({ anchorEl, onClose, filters, onFilterChange, onApply, onReset, onClearAll }) {
  const [tempFilters, setTempFilters] = useState(filters);
  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");
  const filterOptions = { 
    priority: ['Low', 'Medium', 'High']
  };

  const handleTempFilterChange = (filterType, option, isChecked) => {
    setTempFilters(prev => {
      let updatedFilters = { ...prev };
      
      if (filterType === 'acknowledgmentStatus' || filterType === 'action') {
        const isAcknowledgmentStatus = filterType === 'acknowledgmentStatus';
        const correspondingOption = isAcknowledgmentStatus
          ? (option === 'Unacknowledged' ? 'Acknowledge' : 'Acknowledged')
          : (option === 'Acknowledge' ? 'Unacknowledged' : 'Acknowledged');

        updatedFilters = {
          ...updatedFilters,
          acknowledgmentStatus: isAcknowledgmentStatus
            ? (isChecked ? [...(updatedFilters.acknowledgmentStatus || []), option] : (updatedFilters.acknowledgmentStatus || []).filter(item => item !== option))
            : (isChecked ? [...(updatedFilters.acknowledgmentStatus || []), correspondingOption] : (updatedFilters.acknowledgmentStatus || []).filter(item => item !== correspondingOption)),
          action: !isAcknowledgmentStatus
            ? (isChecked ? [...(updatedFilters.action || []), option] : (updatedFilters.action || []).filter(item => item !== option))
            : (isChecked ? [...(updatedFilters.action || []), correspondingOption] : (updatedFilters.action || []).filter(item => item !== option))
        };
      } else {
        // For other filter types, update normally
        updatedFilters[filterType] = isChecked
          ? [...(updatedFilters[filterType] || []), option]
          : (updatedFilters[filterType] || []).filter(item => item !== option);
      }
      
      return updatedFilters;
    });
  };

  // Add this function to check if any filters are selected
  const isAnyFilterSelected = () => {
    return Object.values(tempFilters).some(filterArray => filterArray.length > 0);
  };

  const handleApply = () => {
    onApply(tempFilters); // Call onApply instead of onFilterChange
    onFilterChange(tempFilters);
    onClose();
  };

  const handleReset = () => {
    setTempFilters({});
    onReset();
  };

  // Add this effect to update tempFilters when filters prop changes
  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  return (
    <Popover
      className="filterPopover alarmLog"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className="filterPopupHeader" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px 24px' }}>
        <Typography className="filterHeaderText" variant="subtitle1">{alarm.Filter}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box className="filterCategory">
        <Grid container className="filteringContainer" >
          {/* First row */}
          
          <Grid p={2} item xs={12}>
            <Typography variant="subtitle1">{alarm?.alarmSummaryHeader["Priority"]} </Typography>
            <List dense>
              {filterOptions.priority.map((option) => (
                <ListItem key={option} sx={{ padding: '0px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox sx={{ padding: '4px 8px' }}
                        checked={tempFilters.priority?.includes(option) || false}
                        onChange={(e) => handleTempFilterChange('priority', option, e.target.checked)}
                      />
                    }
                    label={alarm.priority[option]}
                  />
                </ListItem>
              ))}
            </List>
          </Grid> 
        </Grid>
        <Box display="flex" justifyContent="flex-end" className="filterFooter">
          <Button 
            variant="outlined" 
            className="resetBtn" 
            onClick={handleReset} 
            style={{ marginRight: 8 }}
            disabled={!isAnyFilterSelected()} // Disable the button if no filters are selected
          >
             {alarm.Reset}  
          </Button>
          <Button onClick={handleApply} variant="contained" color="primary" className="applyBtn">
            {alarm.Apply}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}
