// breadcrumbStyles.js

// Define your style objects
export const breadcrumbStyles = {
  label : {
    color: '#525252',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  areaLink:{
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    maxWidth: "220px",
    color: '#525252',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  activeLink:{
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    maxWidth: "220px",
    color: '#525252',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  }
}; 