import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import CustomerFilterDropdown from './customer-filter-dropdown/CustomerFilterDropdown';
import AddFavoriteDropdown from './customer-favorite-dropdown/AddFavoriteDropdown';
import { setSelectedCategory, setSelectedAreas, setSelectedSites } from '../../../redux/slices/customerFilterSlice';

const CustomerNavigationDropdowns = ({ setbreadcrumbData, breadcrumbData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabActiveFrom, setTabActive] = useState('1')
  const { accordionList } = useSelector((state) => state.customerInfo);
  const dispatch = useDispatch();
  const customerPopoverisOpen = Boolean(anchorEl);
  const [actionType, setActionType] = useState('')


  const handleClick = (event, activeTab, type) => {
    setAnchorEl(event.currentTarget);
    setTabActive(activeTab);
    setActionType(type)
    if (activeTab === '1') {
      dispatch(setSelectedCategory(breadcrumbData?.selectedCategory));
      dispatch(setSelectedAreas(breadcrumbData?.selectedAreas));
      dispatch(setSelectedSites(breadcrumbData?.selectedSites));
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <CustomerFilterDropdown
          setbreadcrumbData={setbreadcrumbData}
          breadcrumbData={breadcrumbData}
          handleClick={(e) => handleClick(e, "1", "actionbyCustomer")}
          anchorEl={anchorEl}  // Pass the anchorEl to the dropdown
          setAnchorEl={setAnchorEl}
          tabActiveFrom={tabActiveFrom}
          setTabActive={setTabActive}
        />
       {accordionList?.length>0 &&  <AddFavoriteDropdown
          breadcrumbData={breadcrumbData}
          handleClick={(e) => handleClick(e, "2", "actionbyfav")} // Pass handleClick to AddFavoriteDropdown 
          customerPopoverisOpen={customerPopoverisOpen}
          actionType={actionType}
        />}
      </Box>
    </React.Fragment>)
}
export default CustomerNavigationDropdowns;
