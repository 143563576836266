import React, { useEffect, useState } from "react";
import { Grid, Box, Stack, useMediaQuery, createTheme } from "@mui/material";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LanguageSelector from "../../common/language-selector/LanguageSelector";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { useLocation } from "react-router-dom";
import { NWC_RESET_PASSWORD } from "../../common/constants/Constants";
function RightSection() {
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const { t } = useTranslation();
  const { formInfo, defaultLanguage } = useSelector(
    (state) => state?.loginInfo
  );
  const location = useLocation();
  const { signinButton, forgotPasswordScreen } = t("mainContainer");
  const theme = createTheme({
    typography: {
      headerStyle: {
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
      },
      h6: {
        fontSize: 12,
      },
      customSpan: {
        fontSize: 9,
        color: "black",
      },
      h5: {
        fontSize: "24px",
        fontWeight: 500,
      },
      customFontPara: {
        fontSize: 35,
        textAlign: "left",
        marginTop: "20px",
      },
      paragraphFont: {
        fontSize: 12,
        overflowWrap: "break-word",
        textAlign: "left",
      },
      pFontSize: {
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "12px",
        fontWeight: "400",
        lineHeight: "18px",
        letterSpacing: "0.5px",
      },
      resetPwdHeader1: {
        fontSize: "14px",
        marginTop: "5px",
        marginBottom: "6px",
      },
      resetPwdHeader2: {
        fontSize: "14px",
        marginTop: "5px",
        marginBottom: "2px",
      },
    },
  });
  useEffect(() => {
    if (location?.pathname === NWC_RESET_PASSWORD) {
      setShowResetPassword(true);
    }
    if (location?.pathname === "/login") {
      setShowLogin(true);
    }
  }, [location]);
  const isSmallScreen = useMediaQuery("(max-height:600px)");
  const isMediumScreen = useMediaQuery("(max-height:800px)");
  const isLargeScreen = useMediaQuery(
    "(min-height:801px) and (max-height:1000px)"
  );
  const isExtraLargeScreen = useMediaQuery("(min-height:1001px)");

  const getTopMargin = () => {
    if (isSmallScreen) return "10%";
    if (isMediumScreen) return "17%";
    if (isLargeScreen) return "20%";
    if (isExtraLargeScreen) return "33%";
    return "20%"; // default
  };
  return (
    <Grid
      item
      lg={6}
      xs={12}
      // padding={3}
      display={"flex"}
      justifyContent={"center"}
    >
      <Stack alignItems={"self-start"} sx={{ pt: getTopMargin() }}>
        {showForgetPassword && !showResetPassword && (
          <ForgotPassword
            oldData={formInfo}
            forgotPassword={forgotPasswordScreen}
            // setForgotPasswordClicked={setForgotPasswordClicked}
            theme={theme}
            signInButtonLabel={signinButton}
            defaultLanguage={defaultLanguage}
            setShowResetPassword={setShowResetPassword}
            enteredEmail={enteredEmail}
            setShowLogin={setShowLogin}
          />
        )}
        {!showForgetPassword && !showResetPassword && (
          <LoginForm
            setShowForgetPassword={setShowForgetPassword}
            showForgetPassword={showForgetPassword}
            defaultLanguage={defaultLanguage}
            setEnteredEmail={setEnteredEmail}
            theme={theme}
            showLogin={showLogin}
          />
        )}
        {showResetPassword && (
          <ResetPassword
            theme={theme}
            signInButtonLabel={signinButton}
            showForgetPassword={showForgetPassword}
            setShowForgetPassword={setShowForgetPassword}
            setShowResetPassword={setShowResetPassword}
            enteredEmail={enteredEmail}
          />
        )}

        <Box
          display={"flex"}
          justifyContent={"center"}
          width={"100%"}
          marginTop={"4vh"}
        >
          <LanguageSelector />
        </Box>
      </Stack>
    </Grid>
  );
}

export default RightSection;
