// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRangeCalender {
  display: flex;
  position: absolute;
  top: 12px;
  right: 50px;
}

.chartInfo {
  color: #161616;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  height: 48px;
  line-height: 48px;
}
.chartInfo .sourceType, .chartInfo .energyFormat, .chartInfo .comparisonLabel {
  color: #161616;
  font-size: 14px;
}
.chartInfo .sourceType.comparisonLabel, .chartInfo .energyFormat.comparisonLabel, .chartInfo .comparisonLabel.comparisonLabel {
  color: #A8A8A8;
}
.chartInfo .EnergyValue {
  color: #161616;
  font-size: 24px !important;
  line-height: 28px;
}
.chartInfo .energyFormat {
  letter-spacing: 0.5px;
}
.chartInfo .sourceType {
  letter-spacing: 0.25px;
  line-height: 24px;
}
.chartInfo .comparisonLabel {
  letter-spacing: 0.5px;
  line-height: 20px;
}

.loading.spinner-wrapper {
  width: 100%;
  height: 659px;
}

.chart-info {
  margin-top: -16px;
}`, "",{"version":3,"sources":["webpack://./src/components/langing-page/ElectricityCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AACJ;;AACA;EAEI,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAAI;EAGI,cAAA;EACA,eAAA;AAAR;AAGQ;EACI,cAAA;AADZ;AAKI;EACI,cAAA;EACA,0BAAA;EACA,iBAAA;AAHR;AAOI;EACI,qBAAA;AALR;AAQI;EACI,sBAAA;EACA,iBAAA;AANR;AASI;EACI,qBAAA;EACA,iBAAA;AAPR;;AAWC;EACG,WAAA;EACA,aAAA;AARJ;;AAUA;EACI,iBAAA;AAPJ","sourcesContent":[".DateRangeCalender{\n    display: flex;\n    position: absolute;\n    top: 12px;\n    right: 50px;\n}\n.chartInfo {\n    // Shared styles\n    color: #161616;\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 1.5; // Use a consistent unitless line height for better scalability\n    height: 48px;\n    line-height: 48px;\n    & .sourceType, \n    & .energyFormat,\n    & .comparisonLabel {\n        color: #161616; // Default text color\n        font-size: 14px;\n\n        // Specific adjustments for comparison label\n        &.comparisonLabel {\n            color: #A8A8A8; \n        }\n    }\n\n    & .EnergyValue {\n        color: #161616;\n        font-size: 24px !important; // Keep !important if necessary\n        line-height: 28px; // You can adjust this based on your design needs\n    }\n\n    // Adjustments for specific utility styles\n    & .energyFormat {\n        letter-spacing: 0.5px;\n    }\n\n    & .sourceType {\n        letter-spacing: 0.25px;\n        line-height: 24px; // Adjust line height as necessary\n    }\n\n    & .comparisonLabel {\n        letter-spacing: 0.5px;\n        line-height: 20px; // Adjust line height as necessary\n    }\n   \n}\n .loading.spinner-wrapper{\n    width: 100%;\n    height: 659px;\n}\n.chart-info {\n    margin-top: -16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
