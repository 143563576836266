import React, { useState } from 'react';
import { Box,Grid,Switch,InputLabel, Typography, Button, Stepper, Step, StepLabel, Modal, TextField, Radio, FormControlLabel, Checkbox, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useTheme, useMediaQuery, Divider} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomStepper from '../../../common/reusable-comps/stepper/CustomStepper';
import CustomTextField from '../../../common/reusable-comps/text-field/CustomTextField';
import { 
    siteErrorObj, 
  } from "../siteDetails/siteDetails.Data"; 
import CustomModal from '../../../common/commonPopup/CustomModal';
import { ReactComponent as downArrow } from "../../../assets/images/smallArrowDown.svg";
import { ReactComponent as StatickTick } from "../../../assets/images/statickTick.svg";
import { ReactComponent as DisableCross } from "../../../assets/images/disableCross.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/unassignDeleteIcon.svg";
import { ReactComponent as SearchIcon } from '../../../assets/images/SearchIcon.svg';
import { ReactComponent as ContentCopyIcon } from '../../../assets/images/ContentCopyIcon.svg';
import CustomButton from '../../../common/buttons/CustomButton';
import './userManagement.scss';
import CustomSelect from '../../../common/reusable-comps/select/CustomSelect';
import useFetch from '../../../common/hook/useFetch';
import { customerListURL } from '../../../services/apiConfig';
import { getHomeAllSiteList } from '../../../services/apiConfig'; 
import spinner from "../../../assets/animation/spinner.json";
import { createUserURL, isEmailExistURL } from '../../../services/apiConfig';
import usePost from '../../../common/hook/usePost'; 
import usePatch from '../../../common/hook/usePatch';

const steps = ['User details', 'Role', 'Assign']; 

    // Function to get label by role value
const getRoleValue = (roleText) => {
    const role = roles?.find(r => r.label === roleText);
    return role ? role.value : null;  // Return the label if found, otherwise null
};

  // Define the generateRandomPassword function first
  const generateRandomPassword = () => {
    const length = 12; // Length of the password
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^';
    
    const allChars = lowercase + uppercase + numbers + specialChars;
    let password = '';
    
    // Ensure at least one character from each category
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];
    
    // Fill the rest of the password length with random characters
    for (let i = 4; i < length; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }
    
    return password.split('').sort(() => Math.random() - 0.5).join(''); // Shuffle the password
}; 

const roles =   [
    // {label: "Account administrator",value:"account_admin"},
    // {label: "Account operator",value:"account_operator"},
    {label: "Organization administrator",value:"org_admin"},
    // {label: "Organization operator",value:"org_operator"},
    // {label: "Super administrator",value:"super_admin"}
]

const rolePermissions = {
    account_admin: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: true,
        enableDisableSite: true,
        addSite: true,
        editSite: true,
    },
    account_operator: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: false,
        enableDisableSite: true,
        addSite: true,
        editSite: false,
    },
    org_admin: {
        viewAlarmPage: false,
        acknowledgeAlarm: false,
        viewCustomerSiteManagement: false,
        addCustomer: false,
        enableDisableSite: false,
        addSite: false,
        editSite: false,
    },
    org_operator: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: false,
        enableDisableSite: false,
        addSite: false,
        editSite: false,
    },
    super_admin: {
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: true,
        enableDisableSite: true,
        addSite: true,
        editSite: true,
    },
};

export default function UserCreation({ onClose, openModal, setOpenModal, userData }) {
    const { t } = useTranslation();

    const { userManagementContent } = t("mainContainer").settingsPageContent;
    const {userDetails,roleDetails,assignDetails,userRoles} = userManagementContent;

    const [activeStep, setActiveStep] = useState(1);    
    const language = localStorage.getItem("i18nextLng") || "jp"; // Default to 'jp' if no language is set in localStorage
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        confirmEmail: '',
        password: generateRandomPassword(), // Generate default password
        role: 'org_admin', // Default selected role
    });

    // Add state to track if form has been interacted with
    const [formTouched, setFormTouched] = useState(false);
    const [fieldsTouched, setFieldsTouched] = useState({
        displayName: false,
        email: false,
        confirmEmail: false,
        password: false
    });

    const [errors, setErrors] = useState({}); // New state for error messages
    const [passwordOption, setPasswordOption] = useState('auto'); // New state for password option
    const [showPassword, setShowPassword] = useState(false); // New state for password visibility
    const [showConfirmEmail, setShowConfirmEmail] = useState(false); // New state for confirm email visibility
    const [requirePasswordChange, setRequirePasswordChange] = useState(true); // Default checked
    const [sendPasswordEmail, setSendPasswordEmail] = useState(true); // Default checked
    const [openAssignModal, setOpenAssignModal] = useState(false); // State for Assign Customers modal
    const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected customer
    const [assignedCustomer, setAssignedCustomer] = useState(null)
    const [customers, setCustomers] = useState([]); // State for customer list
    const [sites, setSites] = useState([]); // State for site list based on selected customer
    const [selectedSites, setSelectedSites] = useState(); // Initialize with all site IDs
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [filteredCustomers, setFilteredCustomers] = useState(); // State for filtered customers
    const [openUnassignModal, setOpenUnassignModal] = useState(false); // State for Unassign modal
    const [isCustomerAssigned, setIsCustomerAssigned] = useState(false); // State to track if a customer is assigned
    const [sortDirectionSites, setSortDirectionSites] = useState('asc'); // New state for sorting direction for sites
    const [sortedSites, setSortedSites] = useState([]); // New state for sorted sites
    const [sortDirectionCustomers, setSortDirectionCustomers] = useState('asc'); // New state for sorting direction for customers
    const [sortedCustomers, setSortedCustomers] = useState(); // New state for sorted customers
    const [displayedCustomerName, setDisplayedCustomerName] = useState(''); // New state for displayed customer name
    const [allCustomer,setAllCustomer]=useState(null)
    
    const [permissions, setPermissions] = useState({
        viewAlarmPage: true,
        acknowledgeAlarm: true,
        viewCustomerSiteManagement: true,
        addCustomer: false,
        enableDisableSite: false,
        addSite: false,
        editSite: false,
        // Add other permissions as needed
    });
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [activeStepName, setActiveStepName] = useState("siteDetail"); 
    const [isComplete, setIsComplete] = useState(false);
    const [formError, setFormError] = useState(siteErrorObj);
    const [customersFetched, setCustomersFetched] = useState(false); // New state to track if customers have been fetched
    const [tooltipText, setTooltipText] = useState(userDetails?.copyTooltipText); // State for tooltip text
    const [resetPassword, setResetPassword]=useState(false);
    const { data: updateUserData,
        error: updateUserDataError,
        loading: updateUserDataLoading, patch: updateUser } = usePatch(createUserURL); // Initialize the patch function

    const {
        data: fetchCustomers,
        error: fetchCustomersError,
        loading: fetchCustomersLoading,
        fetchData: getCustomersList,
      } = useFetch();

      const {
        data: fetchsitesData,
        error: fetchsiteError,
        loading: fetchsiteErrorLoading,
        fetchData: getSitesDataList,
      } = useFetch();
      
      const { 
        loading: createUserLoading, 
        error: createUserError, 
        data: createUserData, 
        postData: postCreateUserHandler
      } = usePost();

      const { loading: searchLoading, error: searchUserError, data: searchUserData,  fetchData: checkEmailExistence } = useFetch();

      useEffect(() => {
        setTooltipText(userDetails?.copyTooltipText);
    }, [userDetails?.copyTooltipText]); // This will run whenever the language changes and translations update
     
   


    useEffect(() => {
        if (userData) {
            const generatedPassword = generateRandomPassword();
            setFormData({
                firstName: userData.firstName || '',
                lastName: userData.lastName || '',
                displayName: userData.displayName || '',
                email: userData.email || '',
                confirmEmail: userData.email || '', // Prepopulate confirm email
                password: userData?.passwordOption==="auto"?generatedPassword:'', // Do not prepopulate password for security
                role: getRoleValue(userData.role) , // Prepopulate role
                customerId: userData.customerId || null,
            });
            setSelectedCustomer(userData?.customerId || '' )
            setAssignedCustomer(userData?.customerId || null )
            setPasswordOption(userData?.passwordOption || 'auto')
            setRequirePasswordChange(userData?.passwordChangeRequired)
            setSendPasswordEmail(userData?.sendPasswordByEmail)
            setActiveStep(1); // Set to the user details step 
        }else{
            setShowPasswordFields(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    const [isInitialLoad, setIsInitialLoad] = useState(true); // State to track initial load

    useEffect(() => {
        if (activeStep === 2 && selectedCustomer && userData) { // Check if in the third step and a customer is selected
            if (isInitialLoad) {
                getSitesDataList(getHomeAllSiteList(selectedCustomer)); // Fetch sites for the selected customer
                setIsCustomerAssigned(true); // Mark customer as assigned
                setIsInitialLoad(false); // Set to false after initial load
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, selectedCustomer, userData]); // Add userData to dependencies
    
      useEffect(() => {
        if ( !customersFetched) { // Check if modal is open and customers haven't been fetched
            getCustomersList(customerListURL); // Fetch customers when opening the modal
            setCustomersFetched(true); // Set the flag to true after fetching
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  customersFetched]); // Add customersFetched to dependencies



      useEffect(() => {
        if (!fetchCustomersLoading && !fetchCustomersError && fetchCustomers) {
            const sortedCustomer = fetchCustomers?.sort((a, b) =>
                a.name.localeCompare(b.name)
              ); 
              setAllCustomer(fetchCustomers)
              setSortedCustomers(sortedCustomer); 
              setFilteredCustomers(sortedCustomer)
                // Update the displayed customer name
                const selectedCustomerData = fetchCustomers.find(c => c.id === userData?.customerId);
                setDisplayedCustomerName(selectedCustomerData ? selectedCustomerData : ''); // Set the displayed customer name
        }
             // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fetchCustomers, fetchCustomersError, fetchCustomersLoading]);

      useEffect(() => {
        if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
            const sortedSites = fetchsitesData?.sort((a, b) =>
                a.name.localeCompare(b.name)
              ); 
            setSortedSites(sortedSites)
            setSelectedSites(sortedSites?.map(site => site.id))
        }
      }, [fetchsiteErrorLoading, fetchsiteError, fetchsitesData]);
      
      useEffect(() => {
        if ( !createUserLoading && !createUserError && createUserData) { // Check if modal is open and customers haven't been fetched
            handleModalClose(true) 
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  createUserLoading,createUserError,createUserData]); // Add customersFetched to dependencies

    useEffect(() => {
        if ( !updateUserDataLoading && !updateUserDataError && updateUserData) { // Check if modal is open and customers haven't been fetched
            handleModalClose(true) 
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  updateUserData,updateUserDataLoading,updateUserDataError]); // Add customersFetched to dependencies
    
    const handlePasswordOptionChange = (event) => {
        setPasswordOption(event.target.value);
        if (event.target.value === 'auto') {
            const generatedPassword = generateRandomPassword();
            setFormData({ ...formData, password: generatedPassword }); // Set generated password
            setErrors({ ...errors, password: '' }); // Clear password error
        } else {
            setFormTouched(false); // Mark entire form as touched when proceeding
            setFieldsTouched({
                displayName: true,
                email: true,
                confirmEmail: true,
                password: false
            });
            setFormData({ ...formData, password: '' }); // Clear password field for manual entry
        }
    };

    // Add this function to check if form is valid
const isFormValid = () => {
    // Check if any required fields are empty or have errors
    const hasEmptyFields = !formData.displayName || !formData.email || !formData.confirmEmail || 
        (showPasswordFields && !formData.password);
    
    // Check if there are any existing errors
    const hasErrors = Object.keys(errors).length > 0;
    
    // Check if email format is valid
    const isEmailValid = /\S+@\S+\.\S+/.test(formData.email);
    
    // Check if emails match
    const emailsMatch = formData.email === formData.confirmEmail;
    
    // Check password requirements if password fields are shown
    const isPasswordValid = !showPasswordFields || 
        (formData.password.length >= 8 && 
         /[a-z]/.test(formData.password) && 
         /[A-Z]/.test(formData.password) && 
         /\d/.test(formData.password));

    return !hasEmptyFields && isEmailValid && emailsMatch && isPasswordValid;
};

    const handleNext = () => { 
           
      
         // Validate form data based on activeStep
         if (activeStep === 1) {
            const newErrors = {};
            // Validate all fields
            if (getValidation("displayName", formData.displayName, true)) {
                newErrors.displayName = getValidation("displayName", formData.displayName, true);
            }
            if (getValidation("email", formData.email, true, errors.email)) {
                newErrors.email = getValidation("email", formData.email, true, errors.email);
            }
            if (getValidation("confirmEmail", formData.confirmEmail, true)) {
                newErrors.confirmEmail = getValidation("confirmEmail", formData.confirmEmail, true);
            }
            if (showPasswordFields && getValidation("password", formData.password, true)) {
                newErrors.password = getValidation("password", formData.password, true);
            }
            setFormTouched(true); // Mark entire form as touched when proceeding
            setFieldsTouched({
                displayName: true,
                email: true,
                confirmEmail: true,
                password: true
            });
        
            setErrors(newErrors);
    
            if (Object.keys(newErrors).length === 0) {
                setPermissions(rolePermissions[formData.role]);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 3) {
            createUser();
        }

    };

      // Function to get label by role value
      const getRoleLabel = (roleValue) => {
        const role = roles?.find(r => r.value === roleValue);
        return role ? role.label : null;  // Return the label if found, otherwise null
    };
    
    const createUser = () => {
      
        // Create the payload with all necessary information
        //DON'T Delete Payload of Role specific
        // const dummyPayload = {
        //     userInfo: {
        //         firstName: formData.firstName,
        //         lastName: formData.lastName,
        //         displayName: formData.displayName,
        //         email: formData.email,
        //         password: formData.password,
        //         requirePasswordChange: requirePasswordChange,
        //         sendPasswordEmail: sendPasswordEmail,
        //     },
        //     roleInfo: {
        //         role: formData.role,
        //         permissions: permissions, // Include permissions in the payload
        //     },
        //     assignedCustomers: {
        //         customerId: selectedCustomer,
        //         siteIds: selectedSites,
        //     },
        // };

        const userPayload =  { 
            "role": getRoleLabel(formData.role),
            "customerId": assignedCustomer, 
            "sendPasswordByEmail":sendPasswordEmail,
            "passwordChangeRequired":requirePasswordChange, 
            "passwordOption":passwordOption
          }
          
            // Check if each field has changed and add it to the payload if it has
        if (!userData || formData.firstName !== userData.firstName) {
            userPayload.firstName = formData.firstName;
        }
        if (!userData || formData.lastName !== userData.lastName) {
            userPayload.lastName = formData.lastName;
        }
        if (!userData || formData.displayName !== userData.displayName) {
            userPayload.displayName = formData.displayName;
        }
        if (!userData || formData.displayName !== userData.displayName) {
            userPayload.name = formData.displayName;
        }
        if (!userData || formData.email !== userData.email) {
            userPayload.email = formData.email;
        }



           // Add password to payload only if:
            // 1. It's a new user creation (userData is null) OR
            // 2. It's edit mode (userData exists) AND showPasswordFields is true
            if (!userData || (userData && showPasswordFields)) {
                userPayload.password = formData.password;
            }
            
          if (userData) {
            // If userData exists, include the id for the PATCH request
            const patchPayload = {
                ...userPayload,
                "id": userData.id // Include the user ID only for the PATCH request
            };
            updateUser(patchPayload); // Use the patch function to update user
        } else {
            // Include password only for new user creation
            postCreateUserHandler(createUserURL, userPayload); // Create a new user
        }
    };

    const handleClose = () => {
        setOpenModal(true); // Open confirmation modal
    };

    const handleModalClose = (confirm) => {
        if (confirm) { 
            setOpenModal(false); // Close modal without action
            onClose(); // Call onClose if confirmed and no errors
            
        } else {
            setOpenModal(false); // Close modal without action
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev); // Toggle password visibility
    };

    const handleClickShowConfirmEmail = () => {
        setShowConfirmEmail((prev) => !prev); // Toggle confirm email visibility
    };

    const validateConfirmEmail = (email) => {
        if (!email) {
            return 'Please confirm email.';
        } else if (formData.email !== email) {
            return 'Email and Confirm email do not match.';
        }
        return '';
    };

    const validatePassword = (password) => {
        if (password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/\d/.test(password)) {
            return userDetails.password.error;
        }
        return '';
    };

    // Function to handle opening the Assign Customers modal
    const handleOpenAssignModal = async() => { 
        setOpenAssignModal(true);
    // Reorder sortedCustomers to place the selected customer at the first index
    const updatedSortedCustomers = filteredCustomers?.filter(customer => customer.id === selectedCustomer)
    .concat(filteredCustomers.filter(customer => customer.id !== selectedCustomer));
      setSortedCustomers(updatedSortedCustomers); // Update the sorted customers 

    if(assignedCustomer==null){
        setSelectedCustomer(null)
    } 

    };

    // Function to handle assigning the selected customer
    const handleAssignCustomer = async () => {
        console.log(selectedCustomer)
        setAssignedCustomer(selectedCustomer) 
       
       getSitesDataList(getHomeAllSiteList(selectedCustomer));
        setIsCustomerAssigned(true); // Mark customer as assigned
        // setSelectedSites(sortedSites.map(site => site.id)); // Select all sites
        setOpenAssignModal(false); // Close the modal

        // Update the sites based on the selected customer
        const customerSites = getSitesForCustomer(selectedCustomer); // Function to get sites for the selected customer
        setSortedSites(customerSites); // Update the sorted sites with the new customer sites

        // Update the displayed customer name
        const selectedCustomerData = filteredCustomers.find(c => c.id === selectedCustomer);
        setDisplayedCustomerName(selectedCustomerData ? selectedCustomerData : ''); // Set the displayed customer name

        // Reorder sortedCustomers to place the selected customer at the first index
        const updatedSortedCustomers = filteredCustomers.filter(customer => customer.id === selectedCustomer)
            .concat(filteredCustomers.filter(customer => customer.id !== selectedCustomer));
        setSortedCustomers(updatedSortedCustomers); // Update the sorted customers 
         
    };

    // Function to get sites for a specific customer (you need to implement this logic)
    const getSitesForCustomer = (customerId) => {
        // Replace this with actual logic to fetch sites based on the customer ID
        return sortedSites; // This is a placeholder; replace with actual site data for the customer
    };

    // Function to handle unassigning the customer
    const handleUnassignCustomer = () => {
        
        setOpenUnassignModal(true); // Open the unassign confirmation modal 
    };

    const confirmUnassignCustomer = () => {
        setIsCustomerAssigned(false); // Mark customer as unassigned
        setSelectedSites([]); // Clear selected sites (this will uncheck the header checkbox)
        // setSelectedCustomer(null); // Clear selected customer
        setSelectedCustomer(null); // Remove the selected customer when sites are deselected
        setAssignedCustomer(null);
        setOpenUnassignModal(false); // Close the modal
        
    };

    // Function to handle search input change
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        // Filter customers based on search term
        setSortedCustomers(filteredCustomers?.filter(customer => customer.name.toLowerCase().includes(value.toLowerCase())));
    };

    // Function to handle sorting for sites
    const handleSortSites = () => {
        const newSortDirection = sortDirectionSites === 'asc' ? 'desc' : 'asc';
        setSortDirectionSites(newSortDirection);
        
        const sorted = [...sortedSites].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
        
        setSortedSites(sorted);
    };

    // Function to handle sorting for customers
    const handleSortCustomers = () => {
        const newSortDirection = sortDirectionCustomers === 'asc' ? 'desc' : 'asc';
        setSortDirectionCustomers(newSortDirection);
        
        const sorted = [...filteredCustomers].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
        
        setSortedCustomers(sorted);
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 48,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(21px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#2ABB7F',
              opacity: 1,
              border: 0,
              ...theme.applyStyles('dark', {
                backgroundColor: '#2ECA45',
              }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
              color: theme.palette.grey[600],
            }),
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
              opacity: 0.3,
            }),
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#8D8D8D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
          ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
          }),
        },
      }));

    // Function to handle permission checkbox changes
    const handlePermissionChange = (event) => {
        const { name, checked } = event.target;
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [name]: checked,
        }));
    };

    const cancelBtnStyle={marginRight: "10px",padding:"10.5px 36.5px",fontWeight:"regular",lineHeight:"normal"}
    const saveBtnStyle={marginRight: "10px",padding:"11.5px 29.88px",fontWeight:"regular",lineHeight:"normal"}

    // Modal actions configuration
    const modalActions = [
        {sx:cancelBtnStyle, variant:'outlined',label: assignDetails.exitModal.cancel, onClick: () => handleModalClose(false) },
        {sx:saveBtnStyle, variant:'contained', label: assignDetails.exitModal.yes, onClick: () => handleModalClose(true) },
    ];

    const assignCustomerActions = [
        {sx:cancelBtnStyle, variant:'outlined',label: assignDetails.assignCustomerModal.cancel, onClick: () => setOpenAssignModal(false) },
        {sx:saveBtnStyle, variant:'contained', label: assignDetails.assignCustomerModal.assign, disabled:!(selectedCustomer),onClick: handleAssignCustomer },
    ];    

    const unAssignCustomerActions = [
        {sx:cancelBtnStyle, variant:'outlined',label: assignDetails.unAssignCustomerModal.cancel, onClick:() => setOpenUnassignModal(false) },
        {sx:saveBtnStyle, variant:'contained', label: assignDetails.unAssignCustomerModal.unAssign,color:"error", onClick: confirmUnassignCustomer },
    ];
 
    const handleClearSearch = () => {
        setSearchTerm(''); // Reset the search term
        setSortedCustomers(filteredCustomers); // Reset the sorted customers to the original list
    };

    const handleEmailChange = async (e) => {
        const newEmail = e.target.value;
        setFormData(prev => ({ ...prev, email: newEmail }));
        setFieldsTouched(prev => ({ ...prev, email: true }));
        if(!!userData){
            setFieldsTouched(prev => ({ ...prev, confirmEmail: true }));
        }
        setFormTouched(true);
    
        // Clear email error when value changes
        if (errors.email) {
            setErrors(prev => ({ ...prev, email: '' }));
        }
    
        // Basic email validation
        if (!newEmail) {
            setErrors(prev => ({ ...prev, email: userDetails.email.error }));
        } else if (!/\S+@\S+\.\S+/.test(newEmail)) {
            setErrors(prev => ({ ...prev, email: userDetails.email.error2 }));
        } else {
            // Only check email existence if email format is valid
            try {
                await checkEmailExistence(isEmailExistURL(newEmail, userData?.id));
            } catch (error) {
                console.error("Email check failed:", error);
            }
        }
    
        // Cross-validate confirmEmail if it's already been touched
        if (fieldsTouched.confirmEmail && formData.confirmEmail) {
            if (formData.confirmEmail !== newEmail) {
                setErrors(prev => ({ 
                    ...prev, 
                    confirmEmail: userDetails.confirmEmail.error1 
                }));
            } else {
                setErrors(prev => ({ 
                    ...prev, 
                    confirmEmail: '' 
                }));
            }
        }
    };

    useEffect(() => {
        if (!searchLoading && !searchUserError && searchUserData) {
      
          
        setFieldsTouched(prev => ({ ...prev, email: true }));
                                
             // Validate email on change
             const newErrors = {};
           if(searchUserData?.error){   
            newErrors.email = userDetails.email.error1; 
           }else{
            newErrors.email = ''; // Clear error if email does not exist
           }
         setErrors((prevErrors) => ({ ...prevErrors, ...newErrors })); 
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchLoading, searchUserError, searchUserData]);

    // Add new state to track if password fields should be shown
    const [showPasswordFields, setShowPasswordFields] = useState(false);

    // Modify the reset password switch handler
    const handleResetPasswordChange = (event) => {
        setResetPassword(event.target.checked);
        setShowPasswordFields(event.target.checked);
        
        // If turning on reset password, set default password and force require password change
        if (event.target.checked) {
            const generatedPassword = generateRandomPassword();
          if( userData?.passwordOption==="auto")  setFormData(prev => ({ ...prev, password: generatedPassword }));
            setRequirePasswordChange(false); // Force require password change when reset is enabled
        } else {
            // If turning off reset password, clear password-related fields
            setPasswordOption('auto');
            setFormData(prev => ({ ...prev, password: '' }));
            setRequirePasswordChange(userData?.passwordChangeRequired); // Force require password change when reset is enabled
            setSendPasswordEmail(userData?.sendPasswordByEmail);
        }
    };

    // Add getValidation helper function
const getValidation = (fieldName, value, formTouched, errorMessage = "") => {
    if (fieldName === "displayName" && formTouched) {
        if (!value) {
            return userDetails.displayName.error;
        }
    }
    else if (fieldName === "email" && formTouched) {
        if (!value) {
            return userDetails.email.error;
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            return userDetails.email.error2;
        } else if (errorMessage) {
            return userDetails.email.error1; // Email already exists error
        } else if (errors.email) { // Check if there is any error related to email
            return  userDetails.email.error1; // Set the error message directly from the error state
        }
    }
    else if (fieldName === "confirmEmail" && formTouched) {
        if (!value) {
            return userDetails.confirmEmail.error;
        } else if (value !== formData.email) {
            return userDetails.confirmEmail.error1;
        }
    }
    else if (fieldName === "password" && formTouched && showPasswordFields) {
        if (value.length < 8 || !/[a-z]/.test(value) || !/[A-Z]/.test(value) || !/\d/.test(value)) {
            return userDetails.password.error;
        }
    }
    return "";
};

const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setFormData(prev => ({ ...prev, password: newPassword }));
    setFieldsTouched(prev => ({ ...prev, password: true }));

    // Clear password error when value changes
    if (errors.password) {
        setErrors(prev => ({ ...prev, password: '' }));
    }
};

useEffect(() => {
    if (formTouched) {
        const newErrors = {};
        // Validate all fields
        if (getValidation("displayName", formData.displayName, true)) {
            newErrors.displayName = getValidation("displayName", formData.displayName, true);
        }
        if (getValidation("email", formData.email, true, errors.email)) {
            newErrors.email = getValidation("email", formData.email, true, errors.email);
        }
        if (getValidation("confirmEmail", formData.confirmEmail, true)) {
            newErrors.confirmEmail = getValidation("confirmEmail", formData.confirmEmail, true);
        }
        if (showPasswordFields && getValidation("password", formData.password, true)) {
            newErrors.password = getValidation("password", formData.password, true);
        }
        setErrors(newErrors);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
}, [formData, formTouched, showPasswordFields]);

    return (
        <Box>   
            <Box className="stepperContainer">  
                <CustomStepper
                isTab={false}
                steps={userDetails.steps}
                activeStep={activeStep}
                isLastStep={activeStep === steps.length - 1}
                isComplete={isComplete}
                error={
                    formError[activeStepName] &&
                    Object.values(formError?.[activeStepName])?.filter((f1) => f1)
                    .length > 0
                }
                />
            </Box>
            {activeStep === 1 && (
                <Box className="userDetails">  
                    <Grid container spacing={2}>
                        {/* First Name and Last Name on the same line */}
                        <Grid item xs={6}> 
                              <CustomTextField
                                fullWidth
                                placeholder={userDetails.firstName.placeholder}
                                id="firstName"
                                name="firstName" 
                                label={userDetails.firstName.label}
                                handleChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                value={formData.firstName}
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={6}> 
                              <CustomTextField
                                    fullWidth
                                    placeholder={userDetails.lastName.placeholder}
                                    id="lastName"
                                    name="lastName" 
                                    label={userDetails.lastName.label}
                                    handleChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                    value={formData.lastName}
                                    width="100%"
                                />
                        </Grid>
                        <Grid item xs={12}> 
                            	<CustomTextField
                                placeholder={userDetails.displayName.placeholder}
                                id="displayName"
                                name="displayName"
                                label={userDetails.displayName.label}
                                value={formData.displayName}
                                handleChange={(e) => {
                                    const newDisplayName = e.target.value;
                                    setFormData({ ...formData, displayName: newDisplayName });
                                    setFieldsTouched(prev => ({ ...prev, displayName: true }));
                                }}
                                onBlur={() => {
                                    setFieldsTouched(prev => ({ ...prev, displayName: true }));
                                    // Only validate if field has been touched
                                    if (fieldsTouched.displayName) {
                                        const error = getValidation("displayName", formData.displayName, true);
                                        if (error) {
                                            setErrors(prev => ({ ...prev, displayName: error }));
                                        }
                                    }
                                }}
                                width="100%"
                                error={fieldsTouched.displayName ? getValidation("displayName", formData.displayName, true) : ""}
                                helperText={fieldsTouched.displayName ? getValidation("displayName", formData.displayName, true) : ""}
                            />

                         </Grid>
                         <Grid item xs={12}> 
                      
                            <CustomTextField
                                placeholder={userDetails.email.placeholder}
                                id="email"
                                name="email"
                                label={userDetails.email.label}
                                value={formData.email} 
                                handleChange={handleEmailChange}
                                onBlur={() => {
                                    setFieldsTouched(prev => ({ ...prev, email: true }));
                                    // Only validate if field has been touched
                                    if (fieldsTouched.email) {
                                        const error = getValidation("email", formData.email, true, errors.email);
                                        if (error) {
                                            setErrors(prev => ({ ...prev, email: error }));
                                        }
                                    }
                                }}
                                error={fieldsTouched.email ? getValidation("email", formData.email, true, formError?.email) : ""}
                                helperText={fieldsTouched.email ? getValidation("email", formData.email, true, formError?.email) : ""}
                                width="100%" 
                            />
                          </Grid> 
                    
                        <Grid item xs={12}> 
                    
                        <CustomTextField
                            placeholder={userDetails.confirmEmail.placeholder}
                            id="confirmEmail"
                            name="confirmEmail"
                            label={userDetails.confirmEmail.label}
                            fieldType="password"
                            value={formData.confirmEmail} 
                            type={showConfirmEmail ? 'text' : 'password'}
                            handleChange={(e) => {
                                const newConfirmEmail = e.target.value;
                                setFormData({ ...formData, confirmEmail: newConfirmEmail });
                                // setFieldsTouched(prev => ({ ...prev, confirmEmail: true }));
                                
                                // // Clear error when value changes
                                // if (errors.confirmEmail) {
                                //     setErrors(prev => ({ ...prev, confirmEmail: '' }));
                                // }
                            }
                        }
                            onBlur={() => {
                                setFieldsTouched(prev => ({ ...prev, confirmEmail: true }));
                                // Only validate if field has been touched
                                if (fieldsTouched.confirmEmail) {
                                    const error = getValidation("confirmEmail", formData.confirmEmail, true);
                                    if (error) {
                                        setErrors(prev => ({ ...prev, confirmEmail: error }));
                                    }
                                    
                                }
                            }}
                            isViewText={showConfirmEmail}
                            handleViewText={handleClickShowConfirmEmail}
                            onPaste={(e) => e.preventDefault()}
                            error={fieldsTouched.confirmEmail ? getValidation("confirmEmail", formData.confirmEmail, true) : ""}
                            helperText={fieldsTouched.confirmEmail ? getValidation("confirmEmail", formData.confirmEmail, true) : ""}
                            width="100%" 
                        />

                        </Grid> 
                        {/* Reset Password Radio Button and Toggle in Edit Mode */}
                        {userData && (
                            <Grid container direction="row" item xs={12} alignItems="center"> 
                                <Grid item xs={12}> 
                                    <FormControlLabel
                                        className="resetPasswordSwitch" 
                                        control={
                                            <IOSSwitch 
                                                sx={{ m: 1 }}  
                                                checked={resetPassword}
                                                onChange={handleResetPasswordChange}
                                            />
                                        } 
                                        label={userDetails.resetPassword}
                                        labelPlacement="start"
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {/* Show password fields only in create mode or when reset password is enabled in edit mode */}
                        {(showPasswordFields) && (
                            <>
                                <Grid container direction="column" item xs={12} className={`${userData&&'resetPasswordRadioGroup'}`}>
                                    <Grid item>
                                        <FormControlLabel 
                                            control={
                                                <Radio 
                                                    checked={passwordOption === 'auto'} 
                                                    onChange={handlePasswordOptionChange} 
                                                    value="auto"
                                                    sx={{
                                                        color: "#161616", 
                                                        '&.Mui-checked': {
                                                            color: "#161616",
                                                        },
                                                    }}
                                                />
                                            } 
                                            label={userDetails.autoGenerateLabel}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel 
                                        
                                            control={
                                                <Radio 
                                                    checked={passwordOption === 'manual'} 
                                                    onChange={handlePasswordOptionChange} 
                                                    value="manual"
                                                    sx={{
                                                        color: "#161616", 
                                                        '&.Mui-checked': {
                                                            color: "#161616",
                                                        },
                                                    }}
                                                />
                                            } 
                                            label={userDetails.createPasswordLabel}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Password field */}
                                <Grid item xs={12}> 
                                    <Box display="flex" alignItems="center" className="customPassword">
                                        <CustomTextField
                                            placeholder={userDetails.password.placeholder}
                                            id="password"
                                            name="password"
                                            label={userDetails.password.label}
                                            type={showPassword ? 'text' : 'password'}
                                            value={formData.password} 
                                            fieldType="password"  
                                            handleChange={handlePasswordChange}
                                            onBlur={() => {
                                                setFieldsTouched(prev => ({ ...prev, password: true }));
                                                // Only validate if field has been touched and password fields are shown
                                                if (fieldsTouched.password && showPasswordFields) {
                                                    const error = getValidation("password", formData.password, true);
                                                    if (error) {
                                                        setErrors(prev => ({ ...prev, password: error }));
                                                    }
                                                }
                                            }}
                                            isViewText={showPassword}
                                            handleViewText={handleClickShowPassword} 
                                            error={fieldsTouched.password ? getValidation("password", formData.password, true) : ""}
                                            helperText={fieldsTouched.password ? getValidation("password", formData.password, true) : ""}
                                            width="100%" 
                                        />
                                        
                                        <Tooltip title={tooltipText} arrow>
                                            <IconButton 
                                                aria-label="close" 
                                                className="copyIconBtn"  
                                                onClick={() => {
                                                    navigator.clipboard.writeText(formData.password);
                                                    setTooltipText(userDetails.copiedTooltipText);
                                                    setTimeout(() => setTooltipText(userDetails.copyTooltipText), 2000);
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                  {/* Checkboxes below password field */}
                        <Grid container direction="column" item xs={12}>
                            <Grid item>
                                <FormControlLabel 
                                control={<Checkbox checked={requirePasswordChange} onChange={() => setRequirePasswordChange(prev => !prev)}   sx={{
                                    color: "#161616",
                                    '&.Mui-checked': {
                                        color: "#161616",
                                    },
                                    }} />} 
                                label={requirePasswordChange ? userDetails.changePasswordText: userDetails.changePasswordText} 
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel 
                                control={<Checkbox checked={sendPasswordEmail} onChange={() => setSendPasswordEmail(prev => !prev)}   sx={{
                                    color: "#161616",
                                    '&.Mui-checked': {
                                        color: "#161616",
                                    },
                                    }}/>}  
                                label={sendPasswordEmail ? userDetails.sendPasswordText : userDetails.sendPasswordText} 
                                />
                            </Grid>
                        </Grid>
                            </>
                        )}
                        
 
                      
                       
                        {/* ... existing code ... */}
                        {/* Note at the last */}
                        <Grid item xs={12}>
                
                            <Typography variant="body2" className='userDeatils-note'>
                            <span>{ userDetails.note}</span> 
                            { userDetails.noteText}
                            </Typography>

                        </Grid> 
                        
                    </Grid> 
                </Box> 
            )}
            {activeStep === 2 && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" className="rolePermission">
                    <Box width="20%">
                        <Typography variant="h6">{roleDetails.roleTitle}</Typography> 
                        <CustomSelect 
                        className="roleSelect"
                            options={userRoles}
                            id="country"
                            name={"role"}
                            value={formData.role}
                            displayEmpty={true}
                            onChange={(e) => setFormData({ ...formData, role: e.target.value })}  
                            width="100%"
                            inputComponent={downArrow}
                            noMarginIcon={false}
                            />
                     <Box className="roleNoteInfo">
                        <Typography variant="body3" className="note-title">{roleDetails.note.title}</Typography>
                        <Box className="note-item">
                            <StatickTick />
                            <Typography variant="body2" color="green" className="list">{roleDetails.note.list[0]}</Typography>
                        </Box>
                        <Box className="note-item">
                            <DisableCross />
                            <Typography variant="body2" color="red" className="list">{roleDetails.note.list[1]}</Typography>
                        </Box>
                    </Box>

                    </Box>
                    <Box width="80%">
                        {formData.role === 'org_admin' && (
                            <Box mb={2}>
                                <Typography variant="h6">{roleDetails.permissionsTitle}</Typography>
                                <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between">
                                <Box width="48%" className="panel">
                                    <Typography variant="subtitle1" align="center">{roleDetails.home.title}</Typography> 
                                    <Box className="panel-content">
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>{roleDetails.home.list[0]}</Typography>
                                        </Box>
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>{roleDetails.home.list[1]}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width="48%" className="panel">
                                    <Typography variant="subtitle1" align="center">{roleDetails.alarms.title} </Typography> 
                                    <Box className="panel-content">
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>{roleDetails.alarms.list[0]}</Typography>
                                        </Box>
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>{roleDetails.alarms.list[1]}</Typography>
                                        </Box>
                                    </Box>
                                </Box> 
                                </Box>
                                <Box mb={2}>
                                    <Box className="panel">
                                    <Typography variant="subtitle1" align="center">{roleDetails.settings.title} </Typography>  
                                        <Box  display="flex" flexDirection="row" justifyContent="space-between">
                                            <Box width="48%" className="panel-content"  >
                                            <Box className="list-item">
                                                <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[0]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <DisableCross />
                                                    <Typography variant="body2" className='list disabled'>{roleDetails.settings.list[1]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[2]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[3]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[4]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[5]}</Typography>
                                                </Box>

                                            </Box>
                                            <Box width="48%"  className="panel-content" >
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[6]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[7]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[8]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[9]}</Typography>
                                                </Box>
                                                <Box className="list-item">
                                                    <StatickTick />
                                                    <Typography variant="body2" className='list'>{roleDetails.settings.list[10]}</Typography>
                                                </Box> 
                                            </Box>
                                
                                        </Box> 
                                    </Box> 
                                </Box>  
                                <Box width="25%" className="panel inprogress">
                                    <Typography variant="subtitle1" align="center">{roleDetails.auditlog.title} </Typography> 
                                    <Box className="panel-content">
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>{roleDetails.auditlog.list[0]}</Typography>
                                        </Box>
                                        <Box className="list-item">
                                            <StatickTick />
                                            <Typography variant="body2" className='list'>{roleDetails.auditlog.list[1]}</Typography>
                                        </Box>
                                    </Box>  
                                </Box>
                            </Box>
                        )}
                        {formData.role === 'account_operator' && (
                            <Box mb={2}>
                                <Typography variant="h6">Permissions</Typography>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Box width="48%">
                                        <Typography variant="subtitle1">Alarms</Typography>
                                        <FormControlLabel 
                                            control={<Checkbox name="viewAlarmPage" checked={permissions.viewAlarmPage} onChange={handlePermissionChange} />} 
                                            label="View alarm page" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="acknowledgeAlarm" checked={permissions.acknowledgeAlarm} onChange={handlePermissionChange} />} 
                                            label="Acknowledge alarm" 
                                        />
                                    </Box>
                                    <Box width="48%">
                                        <Typography variant="subtitle1">Settings</Typography>
                                        <FormControlLabel 
                                            control={<Checkbox name="viewCustomerSiteManagement" checked={permissions.viewCustomerSiteManagement} onChange={handlePermissionChange} />} 
                                            label="View customer and site management page" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="addCustomer" checked={permissions.addCustomer} onChange={handlePermissionChange} disabled />} 
                                            label="Add customer" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="enableDisableSite" checked={permissions.enableDisableSite} onChange={handlePermissionChange} />} 
                                            label="Enable/disable site" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="addSite" checked={permissions.addSite} onChange={handlePermissionChange} />} 
                                            label="Add site" 
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox name="editSite" checked={permissions.editSite} onChange={handlePermissionChange} />} 
                                            label="Edit site" 
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {/* Add similar blocks for other roles as needed */}
                    </Box>
                </Box>
            )}
            {activeStep === 3 && (
                <Box className="assignCustomerInfo">
                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{assignDetails.assignTitle}</Typography> 
                        <CustomButton variant="contained" onClick={handleOpenAssignModal}> {assignDetails.assignCustomerBtn} </CustomButton>
                    </Box>

                    {isCustomerAssigned ? (
                        <Box width="100%"> 
                         
                         <Box sx={{
                                    p: 0,
                                    display: 'flex',
                                    flex: '1 1 100%',
                                    justifyContent: 'flex-end',  // Align items to the right
                                    alignItems: 'center',
                                    borderColor: 'divider',
                                    borderRadius: 2,
                                    marginBottom:"8px"
                                }}
                                >
                                {/* {selectedSites?.length > 0 && ( // Only show button if a customer is assigned     */}
                                  <Button
                                  className="customTextIconButton"
                                   variant="text"
                                  component="label"
                                  role={undefined} 
                                  onClick={handleUnassignCustomer}
                                  tabIndex={-1}
                                  endIcon={<DeleteIcon />}
                                >  
                                 {assignDetails.unAssignCustomerBtn}
                                </Button>
                                {/* )} */}
                                
                                <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                    sx={{ fontSize: "12px", color: "#525252" }}
                                >
                                    {selectedSites?.length === 0 ? 0 : (selectedCustomer ? 1 : 0)}   {assignDetails.total}
                                </Typography>

                                <>
                                    <Divider
                                    sx={{
                                        ml: { sm: 1 },
                                        mr: { sm: 1 },
                                        height: "20px"
                                    }}
                                    orientation="vertical"
                                    variant="middle"
                                    />
                                    
                                    <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                    sx={{ fontSize: "12px", color: "#525252" }}
                                    >
                                    {selectedSites?.length === 0 ? 0 : (selectedCustomer ? 1 : 0)} {assignDetails.selected}
                                    </Typography>
                                </>
                            </Box>  

                            <TableContainer sx={{ maxHeight: 581,marginRight:"14px" }}>
                            <Table stickyHeader aria-labelledby="tableTitle" size="medium"  className="siteGrid customTable">
                                    <TableHead>
                                        <TableRow> 
                                            <TableCell>
                                                <TableSortLabel 
                                                    active={sortDirectionSites !== 'asc'} // Set active state based on sort direction
                                                    direction={sortDirectionSites} // Set the direction for the sort arrow
                                                    onClick={handleSortSites}
                                                >
                                                    {displayedCustomerName?.displayName}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                   
                                    { 
                                        fetchsiteErrorLoading && !fetchsiteError && !fetchsitesData ? ( // New loading state block
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <div className="noSite loading blue-spinner"><Lottie className="spinner" animationData={spinner} autoplay loop /></div> {/* Specify loading state */}
                                                </TableCell>
                                            </TableRow>
                                        ) : !fetchsiteErrorLoading && !fetchsiteError && fetchsitesData && fetchsitesData.length > 0 ? (
                                            sortedSites?.map((site) => (
                                                <TableRow key={site.id}> 
                                                    <TableCell>{site.name}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell className="no-site-tablecell" colSpan={2}>
                                                <Box className="no-customer-list">
                                                    <Typography className="title">{assignDetails.noSiteFound}</Typography>
                                                    <Typography  className="info">{assignDetails.noSiteFoundInfo}  </Typography>
                                                </Box> 
                                                </TableCell>
                                            </TableRow>
                                        ) 
                                    }
                                        
                                   
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box className="no-customer-list">
                            <Typography className="title">{assignDetails.noCustomerTitle}</Typography>
                            <Typography  className="info">{assignDetails.noCustomerInfo}  </Typography>
                        </Box>
                    )}

                    <CustomModal
                        className={`${allCustomer?.length==0&&'noCustomerdata'} assignCustomerModal`}
                        open={openAssignModal}
                        onClose={() => setOpenAssignModal(false)}
                        title={assignDetails.assignCustomerModal.title}
                        actions={assignCustomerActions}
                        fullScreen={fullScreen}
                        fullWidth={true}
                        maxWidth="sm"
                    >
                       {allCustomer?.length>0?(<>
                                <TextField
                        fullWidth 
                        className='commonSearchField'
                            id="search"
                            name="search"
                            value={searchTerm} 
                            placeholder={assignDetails.assignCustomerModal.searchPlaceholder}
                            autoComplete="off"
                            sx={{
                            width: '100%',
                            marginRight: '14px',
                            '& .MuiInputBase-root': {
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '18px',
                                letterSpacing: '0.16px',
                                height: '40px',
                                backgroundColor: '#F4F4F4',
                            },
                            }}
                            onChange={handleSearchChange} 
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ '& .MuiSvgIcon-root': { padding: '10px' } }}>
                                <SearchIcon />
                                {searchTerm?.length > 0 && (
                                    <span
                                    className="close" 
                                    onClick={handleClearSearch}
                                    style={{ cursor: 'pointer', marginRight: '1rem' }}
                                    >
                                    &times;
                                    </span>
                                )}
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Box sx={{
                                p: 0,
                                display: 'flex',
                                flex: '1 1 100%',
                                justifyContent: 'flex-end',  // Align items to the right
                                alignItems: 'center',
                                borderColor: 'divider',
                                borderRadius: 2,
                            }}
                            >
                            
                            <Typography
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                                sx={{ fontSize: "12px", color: "#525252" }}
                            >
                                    {sortedCustomers?.length} {assignDetails.assignCustomerModal.total}
                            </Typography>

                            <>
                                <Divider
                                sx={{
                                    ml: { sm: 1 },
                                    mr: { sm: 1 },
                                    height: "20px"
                                }}
                                orientation="vertical"
                                variant="middle"
                                />
                                
                                <Typography
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                                sx={{ fontSize: "12px", color: "#525252" }}
                                >
                                {selectedCustomer ? 1 : 0} {assignDetails.assignCustomerModal.selected}
                                </Typography>
                            </>
                        </Box>  
                        <TableContainer>
                            <Table className="assign-customers">
                            <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                                <TableRow>
                                <TableCell>
                                    <TableSortLabel 
                                        active={sortDirectionCustomers !== 'asc'} // Set active state based on sort direction
                                        direction={sortDirectionCustomers} // Set the direction for the sort arrow
                                        onClick={handleSortCustomers}
                                    >
                                      {assignDetails.assignCustomerModal.headerText}
                                    </TableSortLabel>
                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ display: 'block', maxHeight: '363px', overflowY: 'auto' }}>
                                    {sortedCustomers?.map((customer) => (
                                        <TableRow key={customer.id} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                                <TableCell className={selectedCustomer === customer.id ? 'selected-radio' : ''}>  
                                                <FormControlLabel
                                                    control={<Radio   sx={{
                                                        color: "#161616", 
                                                        '&.Mui-checked': {
                                                            color: "#161616",
                                                        },
                                                        }} checked={selectedCustomer === customer.id} onChange={() => setSelectedCustomer(customer.id)} />}
                                                    label={customer.displayName}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer></>):<div className="no-customer">  {assignDetails.assignCustomerModal.noCustomer}</div>}
                   
                      {  sortedCustomers?.length==0&& <div className="no-result">{assignDetails.assignCustomerModal.noSearch}</div>}
                    </CustomModal>
 
                  
                    <CustomModal
                        className="unassignCustomer"
                        open={openUnassignModal}
                        onClose={() => setOpenUnassignModal(false)}
                        title={assignDetails.unAssignCustomerModal.title}
                        actions={unAssignCustomerActions}
                        fullScreen={fullScreen}
                        fullWidth={true}
                        maxWidth="xs"
                    >
                           <Typography>{assignDetails.unAssignCustomerModal.content}</Typography>
                    </CustomModal>

                    
                </Box>
            )}
 
            <Box className="createUser-footer"> 
                {activeStep === 1 ? (
                   <CustomButton variant="contained" onClick={handleNext} disabled={!isFormValid()}> {assignDetails.proceedBut} </CustomButton>
                ) : activeStep === 2 ? (
                    <> 
                        <CustomButton variant="outlined" onClick={() => setActiveStep(activeStep - 1)}> {assignDetails.backBtn} </CustomButton> 
                        <CustomButton variant="contained" onClick={() => setActiveStep(activeStep + 1)}>{assignDetails.proceedAssignBtn} </CustomButton>
                    </>
                ) : (
                    <>
                       <CustomButton variant="outlined" onClick={() => setActiveStep(activeStep - 1)}> {assignDetails.backRoleBtn}  </CustomButton> 
                       <CustomButton variant="contained" disabled={!userData ? (!assignedCustomer || fetchsiteErrorLoading || (fetchsitesData && fetchsitesData.length === 0)) : false} onClick={handleNext}> {assignDetails.saveUser} </CustomButton> 
                    </>
                )}
            </Box>
            {/* Add loading spinner */}
            {(createUserLoading || updateUserDataLoading) && (
                <Box 
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 9999
                    }}
                >
                     <div className="noSite loading blue-spinner"><Lottie className="spinner" animationData={spinner} autoplay loop /></div> {/* Specify loading state */}
                </Box>
            )}
            {/* Confirmation Modal For Exit */}
            <CustomModal
            className="exitUserCreation"
			    open={openModal}
				onClose={() => setOpenModal(false)}
				title={assignDetails.exitModal.title}
				actions={modalActions}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth="xs"
			>
                <Typography>{assignDetails.exitModal.content}</Typography> 
			</CustomModal>
            
        </Box>
    );
}